@import 'variables.module';
.timezone-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 40px;
    .timezone-paragraph {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        line-height: 19px;
        text-transform: uppercase;
        margin: 0;
        span {
            margin-inline-start: 5px;
        }
    }
}
.add-to-calendar {
    width: 180px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.add-timeslot-to-calendar-icon {
    padding-inline-start: 15px;
    cursor: pointer;
}
.tip-container {
    color: $black-variant;
    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 28px;
        margin-right: 11px;
    }
    span {
        font-weight: 700;
        color: $black-variant;
    }
}
.download-program {
    width: 180px;
    display: flex;
    align-items: center;
    padding: 10px;
}
.download-program-sm-screen {
    position: absolute;
    z-index: 99999;
    background: #fff;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.download-program-link {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: $secondary;
    font-size: 14px;
    font-weight: 600;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    line-height: 120%;
    text-transform: uppercase;
    span {
        margin-inline-start: 6px;
    }
    svg {
        width: 16px;
    }
}
.auditorium-page {
    height: calc(100vh - 69px);
    position: relative;
    width: 100%;
}
.slot-title {
    position: absolute;
    left: 0;
    z-index: 1;
    padding: 10px 383px 10px 32px;
    color: #fff;
    background-color: rgba(43, 43, 43, 0.8);
    width: 100%;
    text-align: left;
    top: 0;
    margin: 0;
    font-size: 24px;

    transition: 0.3s;

    &__hide {
        @media screen and (max-width: 479px) {
            opacity: 0;
        }

        top: -100px;
    }

    @media screen and (min-width: 1025px) {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &.more-right-spacing {
        @media screen and (min-width: 1025px) {
            padding-inline-end: 590px;
        }
    }
}
.auditorium-archive-page.has-live-button {
    .slot-title {
        padding-right: 590px;
    }
}
@keyframes opacityfadein {
    0% {
        max-height: 22px;
    }
    100% {
        max-height: 500px;
    }
}
.auditorium-page .slot-title {
    &:hover {
        p {
            &.title {
                max-height: 500px;
                transition: max-height 0.8s ease-in 0.5s;
                word-break: normal;
                padding-inline-end: 0;
                overflow: visible;
                white-space: normal;

                &:after {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        &:hover {
            p {
                &.title {
                    max-height: initial;
                    transition: none;
                }
            }
        }
    }

    & > div {
        overflow: hidden;

        @media screen and (max-width: 1024px) {
            width: auto;
            overflow: initial;

            &:hover {
                p {
                    &.title {
                        max-height: initial;
                        transition: none;
                    }
                }
            }

            p {
                &.title {
                    white-space: normal;
                    max-height: initial;
                    word-break: normal;
                }

                &:after {
                    display: none !important;
                }
            }
        }
    }
}
.auditorium-page .slot-title p {
    color: white;
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    text-align: start;
}
.auditorium-page .slot-title p.title {
    color: white;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    max-height: 22px;
    position: relative;
    word-break: break-all;

    @media screen and (min-width: 1025px) {
        font-size: 18px;
    }
}
.auditorium-page .slot-title .subtitle {
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
    color: $primary;

    @media screen and (min-width: 1025px) {
        font-size: 14px;
    }
}
.auditorium-page .slot-title .title.hide {
    display: none;
}
.auditorium-page .slot-title:hover .title.hide {
    display: block;
}
.auditorium-page.fullscreen {
    height: 100vh;
    top: 0;
    z-index: 1399 !important;
    padding-bottom: 0;
}
.auditorium-page.dropdown-component {
    max-width: 800px;
    width: 800px;
    overflow: initial;
    background-color: #f5f5f5;
    height: auto;
    transform: translateX(-50%);
    top: 56px;
    cursor: initial;
    &.no-program {
        max-width: 515px;
        h3 {
            text-align: center;
        }
        .program-container {
            flex: 1;
        }
    }
}
.auditorium-name {
    .auditorium-name-paragraph {
        margin: 0;
    }
}
.auditorium-page.dropdown-component .auditorium-name {
    word-break: break-all;
    display: block;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    font-weight: 600;
    color: $dark;
    text-align: left;
    padding: 26px 10px 16px 10px;
}
.dropdown-component .auditorium-day-program-container {
    width: 100%;
    max-width: 270px;
    margin: auto;
}
.auditorium-page.dropdown-component .page-title {
    font-size: 0;
    height: 40px;
    line-height: 40px;
    padding: 0;
    position: relative;
}
.auditorium-page.dropdown-component .timezone-text {
    display: block;
    width: 100%;
    flex: 1;
    text-align: left;
    color: $dark;
    font-size: 14px;
    font-weight: 600;
    padding-top: 5px;
}
.auditorium-page.dropdown-component .page-title .arrow {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: center;
    padding: 0 10px;
    font-size: 12px;
    color: #ffffff;
    background-color: $primary;
}
.auditorium-page.dropdown-component .page-title .arrow span {
    padding: 0 10px;
}
.auditorium-page.dropdown-component .page-title .arrow svg {
    width: 10px;
    height: auto;
}
.auditorium-page.dropdown-component .page-title .arrow svg path {
    stroke: #ffffff;
}
.auditorium-page.dropdown-component .page-title .arrow.previous {
    left: 0;
    top: 0;
}
.auditorium-page.dropdown-component .page-title .arrow.next {
    right: 0;
    top: 0;
}
.auditorium-page.dropdown-component .program-arrow h3 {
    font-weight: 100;
}
.program-arrow .arrow.prev {
    outline: none;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0px 15px 10px -10px $secondary;
        }
    }
}
.program-arrow .arrow.next {
    outline: none;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0px 15px 10px -10px $secondary;
        }
    }
}
.auditorium-page.dropdown-component .page-title .arrow.next svg {
    transform: rotate(180deg);
}
.auditorium-page.dropdown-component .page-title p {
    margin: 0;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    font-size: 10px;
    padding-top: 3px;
}
.auditorium-page.dropdown-component .audioriums-program-slider .program-slider-wrapper {
    padding: 0 30px;
}
.auditorium-page.dropdown-component .auditorium-program-arrow {
    position: absolute;
    top: 9px;
    cursor: pointer;
    background: $disabled-text;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    width: 30px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.auditorium-page.dropdown-component .auditorium-program-arrow:hover {
    background: $secondary;
}
.auditorium-page.dropdown-component .auditorium-program-arrow.prev {
    left: -40px;
    border-radius: 0 60px 60px 0;
}
.auditorium-page.dropdown-component .auditorium-program-arrow.next {
    right: -40px;
    border-radius: 60px 0 0 60px;
}
.auditorium-page.dropdown-component .auditorium-program-arrow.next svg {
    transform: rotate(180deg);
}
.auditorium-page.dropdown-component .auditorium-program-arrow svg {
    width: 15px;
}
.auditorium-page.dropdown-component .auditorium-program-arrow svg path {
    stroke: #fff;
}
.auditorium-page.dropdown-component .auditorium-program {
    margin: 0 auto;
}
.auditorium-page .program-entry {
    padding: 0;
    margin: 0;
    border: 0;

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            filter: drop-shadow(0 0 1px $secondary);
            -webkit-filter: drop-shadow(0 0 1px $secondary);
            -ms-flex-item-align: drop-shadow(0 0 1px $secondary);
        }
    }
}
.auditorium-page .program-entry .speakers-name {
    padding-inline-end: 0;
    font-size: 12px;
}
.auditorium-page .program-entry ul {
    padding: 0;
}
.auditorium-page .program-wrapper {
    padding-right: 10px;
}
.auditorium-page ul {
    list-style-type: none;
    margin: 0;
    padding: 0 20px;
    position: relative;
}
.auditorium-page ul:not(.comments-list-container) li {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.auditorium-page ul > span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 94%;
    text-align: right;
}
.speakers-avatars {
    max-width: 52px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-right: 10px;
}
.speakers-avatars .speaker {
    font-size: 0;
    display: inline-block;
}
.speakers-name {
    font-size: 14px;
    line-height: 16px;
    color: $dark;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-right: 15px;
    text-align: left;
}
.speakers-name > div {
    display: inline-block;
}
.program-wrapper .program-time {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 11.5px;
    color: $dark;
    text-align: right;
    flex-basis: 45px;
}
.program-entry .program-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-bottom: 10px;
    border-bottom: 0.5px solid $disabled-text;
    margin-bottom: 10px;
}
.program-entry .program-container.is-empty {
    border-bottom: none;
}
.program-entry .program-container.is-empty .empty-program {
    width: 100%;
}
.program-entry:last-child .program-container {
    padding-bottom: 25px;
}
.program-container .topic {
    color: $secondary;
    word-break: break-word;
    font-weight: bold;
}
.program-container .topic.is-link {
    cursor: pointer;
}
.next-video {
    position: absolute;
    width: calc((100vh - 70px) * 1.10804);
    height: calc((100vh - 70px) * 0.62516);
    top: calc((-100vh + 70px) * 0.36929);
    left: calc((-100vh + 70px) * 0.55394);
}
.next-video .next-video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;

    &.restricted {
        @media screen and (max-width: 1024px) {
            height: auto;

            p {
                font-size: 24px;
                padding: 30px 16px;
            }
        }
    }
}
.next-video .next-video-container .timeslot-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.next-video .next-video-container .timeslot-background-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.next-video .next-video-container .timeslot-background-image .overlay {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}
.next-video p {
    font-weight: 600;
    font-size: 46px;
    color: rgb(53, 62, 64);
    margin-top: 0;
    position: relative;
    z-index: 2;
    margin-bottom: 0;

    & > .timer-text-clock {
        font-size: 46px;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        &.show {
            opacity: 1;
        }
    }
}
.next-video p.auditorium-program-ended-text {
    font-size: 24px;
    line-height: 48px;
    padding: 0 15px;
}
.next-video span {
    font-size: 24px;
}
.next-video .next-video-container.restricted svg {
    display: block;
    margin: 20px auto 0;
    font-size: 70px;
}
.videoWrapper {
    position: absolute;
    width: calc((100vh - 70px) * 1.10804);
    height: calc((100vh - 70px) * 0.62516);
    top: calc((-100vh + 70px) * 0.36929);
    left: calc((-100vh + 70px) * 0.55394);
    display: flex;
    align-items: center;
    justify-content: center;

    &.video-plates-container {
        flex-wrap: wrap;
        background-color: #000;
        .session-user-playback video {
            width: 100%;
            height: 100%;
        }
        .is-screen-sharing {
            width: 100% !important;
            height: 100% !important;
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            background: #000;
        }
    }

    &.video-ended {
        & ~ .videoWrapper {
            .video-container {
                .auditoriums-navigation {
                    opacity: 1;
                }
            }
        }
    }

    &.grey-bar {
        .video-container {
            @media screen and (min-width: 1025px) {
                &:hover {
                    .progress-bar {
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }

            .progress-bar {
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
                position: relative;
                bottom: 13px;
                height: 13px;
                background-color: #f5f5f5;
                padding: 0;

                .MuiSlider-rail {
                    opacity: 0;
                    height: 0;
                }

                .MuiSlider-track {
                    height: 100%;
                }

                .MuiSlider-thumb {
                    opacity: 0;
                }

                &.hide {
                    opacity: 0;
                }
            }
        }
    }
}
.videoWrapper .video-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    &.video-has-ended {
        width: 0;
        height: 0;
        visibility: hidden;
    }

    &:hover {
        .auditoriums-navigation {
            opacity: 1;
            transition: opacity 0.5s ease-in-out;

            &.active-on-player {
                display: flex;
            }
        }
    }

    .auditoriums-navigation {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        &.no-timeslot {
            opacity: 1;
        }
    }
    .spinner {
        z-index: 1;
    }
}
.videoWrapper .video-actions-container {
    position: absolute;
    bottom: -5.5vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 8;
    height: 50px;
    top: calc(((100vh - 70px) * 0.62516) + 10px);
    overflow: hidden;
    border-radius: 25px;

    @media screen and (min-width: 1025px) {
        background-color: rgba(43, 43, 43, 0.8);

        svg {
            color: #ffffff;
        }
    }
}
.fullscreen-video .centerOfPage {
    position: static;
}
.videoWrapper.fullscreen {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 1000 !important;

    .auditoriums-navigation {
        &.active-on-player {
            display: none !important;
        }
    }
}
.videoWrapper.fullscreen .video-actions-container {
    position: absolute;
    bottom: 2vh !important;
    width: 90%;
    top: initial !important;
    height: 50px;
    transition: height 0.2s;

    @media screen and (max-width: 1024px) {
        background-color: rgba(47, 47, 47, 0.8);
    }
}
.videoWrapper.fullscreen .video-actions-container.hide {
    height: 0;
    transition: height 0.2s;
}
.auditoriumOverlayPlayer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;

    & > div {
        width: 100%;
        height: 100%;
        position: relative;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        &.show {
            opacity: 1;
            transition: opacity 0.5s ease-in-out;

            button {
                display: block;
            }
        }

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
        }

        img {
            width: 100%;
            height: 100% !important;
            object-position: center;
            object-fit: cover;
        }

        button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: transparent;
            padding: 0;
            border: 0;
            outline: 0;
            z-index: 10;
            cursor: pointer;
            display: none;

            svg {
                fill: #ffffff;
                font-size: 4rem;
            }
        }
    }
}
.auditoriumOverlayPlayer.audio-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    background-color: rgba(0, 0, 0, 0.65);
    color: #fff;
}
.image-ended-video {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}
.image-ended-video p {
    font-size: 24px;
}
.auditoriums-navigation {
    position: absolute;
    z-index: 8;
    height: 0;
    top: calc(-48px - 69px / 2);
    display: flex;
    justify-content: space-between;
    left: calc(-50vw - 48px);
    width: calc(100vw + 96px);

    &.sidebar-opened {
        width: calc(100vw + 96px - 323px);
    }
}
.auditoriums-navigation {
    &.active-on-player {
        height: 100%;
        bottom: 0;
        top: initial;
        min-width: initial;
        width: calc(100% + 96px);
        left: -48px;

        .button {
            display: flex;
            align-items: center;

            .button-container {
                transform: initial;
            }
        }
    }
}
.auditoriums-navigation .button-container .details {
    width: 96px;
    height: 96px;
    padding: 20px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    svg {
        width: 16px;
    }
}
.auditoriums-navigation .button-container:hover .details {
    background-color: white;
}
.auditoriums-navigation .previous .button-container .details {
    transform: rotate(180deg);
}
.auditoriums-navigation .button-container {
    position: relative;
    opacity: 0.9;
}
.auditoriums-navigation .button-container:hover {
    opacity: 1;
}
.auditoriums-navigation .button-container .auditorium-name {
    font-size: 16px;
    text-transform: uppercase;
    left: 48px;
    position: absolute;
    padding-inline-start: 48px;
    top: 50%;
    height: 78px;
    transform: translateY(-50%);
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    box-shadow: 0 14px 24px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    z-index: -1;
    display: none;
}
.auditoriums-navigation .button-container:hover .auditorium-name {
    display: flex;
}
.auditoriums-navigation .next .button-container .auditorium-name {
    right: 48px;
    padding-left: 0;
    padding-inline-end: 48px;
    left: initial;
}
.auditoriums-navigation .button-container .auditorium-name p {
    margin: 0;
    padding: 0 16px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: $dark;
    min-width: 225px;
    text-align: center;
    -webkit-line-clamp: 3;
    position: relative;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.videoWrapper .image-ended-video img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.video-actions-container {
    &.session-buttons {
        @media screen and (max-height: 859px) {
            z-index: 9;
        }
    }
    button {
        background-color: transparent;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: 0;
        cursor: pointer;
        font-size: 30px;
        width: 50px;
        height: 50px;
        padding: 5px;
        font-family: $fontFamily, sans-serif;

        &:disabled {
            cursor: initial;
            svg {
                color: $text-grey;
            }
        }
        &.request-mic-button {
            display: flex;
            width: auto;
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            line-height: 120%;
            @media screen and (max-width: 1024px) {
                display: none;
            }
            span {
                padding-left: 10px;
            }
        }
    }
}
.videoWrapper.fullscreen .mute-button {
    margin-left: 0;
}
.auditorium-archive-page .smallContainer {
    max-width: 1160px;
}
.video-archive-list {
    padding: 0;
}
.auditorium-archive-page .auditoriums-archive-container {
    height: calc(calc(var(--vh, 1vh) * 100) - 190px);

    &.event-archive {
        @media screen and (max-width: 1024px) {
            height: calc(calc(var(--vh, 1vh) * 100) - 253px);
        }
    }
}
.auditorium-archive-page .wall-and-networking-container.close {
    position: fixed;
}
.auditorium-archive-page .wall-and-networking-container.close + .program-dashboard {
    overflow: visible;
    height: auto;
}
.auditorium-archive-page .program-dashboard {
    background-color: #f5f5f5;
    overflow: auto;
    height: calc(calc(var(--vh, 1vh) * 100) - 70px);

    @media screen and (max-width: 1024px) {
        height: calc(calc(var(--vh, 1vh) * 100) - 112px);
    }
}
.auditorium-archive-page .program-section {
    padding-top: 16px;
    margin-bottom: 0;
}
.auditorium-archive-page .program-section .page-title {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    padding: 0 18px;
}
.auditorium-archive-page .program-section .page-title .name {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $text-grey;
    padding-inline-end: 26px;
    text-transform: uppercase;
}
.auditorium-archive-page .program-section .page-title input {
    background: #ffffff;
    border: 1px solid $disabled-text;
    border-radius: 8px;
    padding: 8px 60px 8px 16px;
    color: rgba(43, 43, 43, 0.5);
    outline: none;
    font-size: 16px;
    line-height: 30px;
    width: 400px;
}
.auditorium-archive-page .program-section .page-title .search-container svg {
    position: absolute;
    right: 16px;
    top: 11px;
    cursor: pointer;
}
.auditorium-archive-page .program-dashboard .program-entry {
    padding: 0 8px 32px;
    border-bottom: none;

    @media screen and (max-width: 479px) {
        max-width: 100%;
        flex-basis: 100%;
    }
}
.auditorium-archive-page .single-auditorium-archive-container {
    margin-bottom: 32px;
}
.auditorium-archive-page .single-auditorium-archive-container:last-of-type {
    margin-bottom: 0;
}
.auditorium-archive-page .single-auditorium-archive-container h4 {
    margin-top: 0;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: $secondary;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    padding-inline-start: 12px;
    text-transform: capitalize;

    @media screen and (max-width: 479px) {
        display: block;
    }
}
.auditorium-archive-page .single-auditorium-archive-container h4 .break,
.auditorium-archive-page .single-auditorium-archive-container h4 .dates {
    font-size: 16px;
    line-height: 20px;
    color: $text-grey;
}
.auditorium-archive-page .single-auditorium-archive-container h4 .break {
    padding: 0 16px;

    @media screen and (max-width: 479px) {
        position: relative;
        bottom: 2px;
    }
}
.video-archive-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.video-archive-list .video-preview-container,
.auditorium-archive-page .clickable-element {
    height: 153px;

    @media screen and (max-width: 479px) {
        height: 200px;
    }

    @media screen and (max-width: 375px) {
        height: 170px;
    }

    @media screen and (max-width: 335px) {
        height: 160px;
    }
}
.video-archive-list .video-preview-container a {
    display: block;
    height: 100%;
}
.auditorium-archive-page .image-preview-video {
    position: relative;
    height: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border: 0.5px solid #ffffff;
    border-radius: 8px;
}
.auditorium-archive-page .program-description {
    margin-top: 0;
}
.auditorium-archive-page .video-duration {
    position: absolute;
    bottom: 8px;
    right: 0;
    color: white;
    background-color: $secondary;
    width: 56px;
    line-height: 18px;
    text-align: center;
    font-size: 14px;
}
.video-error-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    & > div {
        position: relative;
        z-index: 10;
        p {
            cursor: pointer;
            @media screen and (max-height: 800px) {
                font-size: 20px;
            }
        }
        svg {
            cursor: pointer;
            font-size: 60px;
            @media screen and (max-height: 800px) {
                font-size: 50px;
            }
        }
    }
}
.auditorium-archive-page .image-preview-video img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.auditorium-archive-page .video-details-container .speakers-container {
    padding-top: 10px;
}
.auditorium-archive-page .video-details-container .speakers-container .speaker {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: $secondary;
    padding: 0;
    display: inline;
}
.auditorium-archive-page .video-details-container .program-date-hash .date {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: $text-grey;
}
.auditorium-archive-page .video-details-container .program-date-hash .hashtag {
    padding-left: 5px;
    letter-spacing: -0.04em;
    color: $secondary;
    word-break: break-word;
}
.auditorium-archive-page .video-details-container a {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $dark;
    letter-spacing: -0.04em;
    word-break: break-word;
    display: block;
    padding: 5px 0;
}
.video-archive-list .speakers-container .speaker {
    display: block;
    text-align: right;
    padding: 0;
}
.video-archive-list .speaker img {
    margin: 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}
.video-archive-list .right-column .speaker div > p {
    margin: 0;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $dark;
    padding-top: 5px;
    padding-right: 0;
}
.video-archive-list .left-column {
    max-width: 200px;
}
.auditorium-archive-page .program-description p {
    font-family: $fontFamily, sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $text-grey;
    margin: 0;
}
.auditoriums-popup {
    position: absolute;
    bottom: 0;
    z-index: 12;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
}
.auditoriums-popup .popup-wrapper {
    position: relative;
}
@keyframes createBox {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
.auditoriums-popup .popup-wrapper .has-slider {
    transition: all 1s ease;
    padding: 0;
    position: relative;
    z-index: 3;
    background-color: rgba(43, 43, 43, 0.9);
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.35);
    border-radius: 6px 6px 0 0;
    border-top: 1px solid #f5f5f5;
    border-left: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    overflow: hidden;

    &.opened {
    }

    .slider-header {
        display: flex;
        font-size: 14px;
        color: #ffffff;
        align-items: center;
        justify-content: space-between;
        padding: 12px 18px;

        p {
            margin: 0;
            text-transform: uppercase;
            font-weight: 600;
        }

        .hide-button {
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                margin-inline-end: 10px;
            }

            &:hover {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }

    .slick-track {
        display: flex !important;

        .slick-slide {
            height: inherit !important;
            opacity: 0;
            transition: opacity 1s ease-in-out;

            & > div {
                height: 100%;

                & > div {
                    height: 100%;

                    .single-auditorium-container {
                        height: 100%;

                        .auditorium-details-container {
                            //min-height: initial;
                            height: 100%;
                            word-break: break-word;
                        }
                    }
                }
            }
        }
    }
}
.auditoriums-popup .toggle-auditoriums-button {
    transition: all 1s ease;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07);
    cursor: pointer;
    width: 155px;
    max-height: 43px;
    opacity: 1;
    z-index: 2;
    border-radius: 6px 6px 0 0;
    margin: 0 auto;
    background: linear-gradient(156.39deg, $secondary 48.1%, $secondaryDarken 105%);

    & > div {
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: all 0.5s ease;
        transition-delay: 0.3s;
    }
}
.auditoriums-popup .toggle-auditoriums-button.close {
    right: initial;
    width: 36px;
    position: relative;
    margin-left: auto;
    margin-right: 20px;
}
.auditoriums-popup .toggle-auditoriums-button.show.hide {
    background: transparent;
    cursor: default;
    width: 100%;
    max-height: 500px;
    background: linear-gradient(156.39deg, $secondary 48.1%, $secondaryDarken 105%);

    & > p {
        display: none;
    }

    & > div {
        opacity: 1;
        transition: opacity 0.1s ease;
        transition-delay: 0.3s;
        height: 100%;
        border-radius: 6px 6px 0 0;
    }

    &.is-finished {
        background: transparent;
        border-radius: 6px 6px 0 0;

        .slick-track {
            .slick-slide {
                opacity: 1;
                transition: opacity 1s ease-in-out;
                transition-delay: 0.01s;
            }
        }
    }
}
.auditoriums-popup .toggle-auditoriums-button.close.show ~ .toggle-auditoriums-button {
    display: none;
}
.auditoriums-popup .toggle-auditoriums-button.close p {
    background: $secondary;
    padding: 8.5px 7px;
}
.auditoriums-popup .toggle-auditoriums-button > p {
    background: #ffffff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    border-radius: 6px 6px 0 0;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: $secondary;
    margin: 0 auto;
    padding: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    width: 205px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    justify-content: center;

    &:hover {
        background: linear-gradient(156.39deg, $secondary 48.1%, $secondaryDarken 105%);
        box-shadow: 0px -4px 6px rgba(255, 255, 255, 0.07), 0px 0px 7px rgba(255, 255, 255, 0.12),
            0px 5px 12px rgba(255, 255, 255, 0.2);
        color: #ffffff;
        border-radius: 6px 6px 0 0;

        svg {
            path {
                fill: #ffffff;
            }
        }
    }

    svg {
        margin-inline-start: 10px;
    }
}
.auditoriums-popup .all-auditoriums-button p {
    margin: 0;
}
.auditoriums-popup .slider-container > div:last-of-type {
    font-size: 20px;
    padding: 32px 26px 40px;
}
/*.auditoriums-popup .slider-container.has-slider {*/
/*    padding: 32px 80px 40px;*/
/*}*/
.auditoriums-popup .slider-container.has-slider .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -35px;
    padding: 0;
}
.auditoriums-popup .slider-container.has-slider .slick-arrow {
    width: 96px;
    height: 96px;
    padding: 20px;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: 1px solid $primary;
    background: $dark;

    &:hover {
        background: linear-gradient(136.19deg, $primary 24.48%, #7bd643 62.79%, #cec82e 78.54%), $dark;

        svg {
            path {
                fill: #ffffff;
            }
        }
    }

    &.slick-prev {
        left: -137px;
        justify-content: flex-end;
    }

    &.slick-next {
        right: -137px;
        justify-content: flex-start;

        svg {
            transform: rotate(180deg);
        }
    }
}
.auditoriums-popup .slider-container.has-slider .slick-prev:before,
.auditoriums-popup .slider-container.has-slider .slick-next:before {
    display: none !important;
}
.auditoriums-popup .slider-container.has-slider .slick-dots li {
    padding: 0;
}
.auditoriums-popup .slider-container.has-slider .slick-dots li button:before {
    font-size: 8px;
    color: $disabled-text;
    opacity: 1;
}
.auditoriums-popup .slider-container.has-slider .slick-dots li.slick-active button:before {
    color: $secondary;
}
.auditoriums-popup .slider-container .auditorium-details-container {
    position: relative;
    padding: 10px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    width: 192px;
    min-height: 108px;
    border: 1px solid #f5f5f5;
    margin: 0 2px;

    &:not(.active) {
        &:hover {
            box-shadow: 0 1px 5px rgba(255, 255, 255, 0.5);
            border-color: #ffffff;
        }
    }

    .auditorium-wrapper-content {
        display: flex;
        flex-direction: column;
        p {
            margin: 0;
        }
        .auditorium-description {
            padding-top: 10px;
        }
    }
}
.auditoriums-popup .slider-container .restricted .auditorium-details-container {
    cursor: initial;
}
.auditoriums-popup .slider-container .auditoriums-list > div {
    padding: 0 8px;
}
.auditoriums-popup .slider-container .auditorium-details-container img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    border-radius: 8px;
}
.auditoriums-popup .slider-container .auditorium-details-container .image-filter {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
}
.auditoriums-popup .slider-container .auditorium-details-container.active .image-filter {
    background: linear-gradient(334.17deg, $secondary-rooms1 5.01%, $secondary-rooms2_56 77.79%);

    &.no-image {
        background: linear-gradient(334.17deg, $secondary-rooms1 5.01%, $secondary-rooms2_80 77.79%), #000000;
    }
}
.auditoriums-popup .slider-container .auditorium-details-container .auditorium-name {
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    opacity: 1;
    margin: 0;
    position: relative;
    z-index: 2;
}
.auditoriums-popup .slider-container .auditorium-details-container svg {
    color: #fff;
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 16px;
    z-index: 2;
}
.auditoriums-popup .slider-container .auditorium-details-container .auditorium-description {
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin: 0;
    opacity: 0.8;
    max-width: 150px;
    ul,
    ol {
        padding: 0;
        margin: 0;
    }
    ul li {
        display: list-item;
        list-style: disc;
    }
}
.auditoriums-popup .slider-container .auditoriums-list {
    display: flex;
}
.auditorium-banner-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
#bannerLeft,
#bannerRight {
    mix-blend-mode: multiply;
}
#bannerLeft {
    width: calc((100vh - 70px) * 0.1776239);
    height: calc((100vh - 70px) * 0.340253);
    top: calc((-100vh + 70px) * 0.0668973);
    left: calc((-100vh + 70px) * 0.9423298);
}
#bannerRight {
    width: calc((100vh - 70px) * 0.1776239);
    height: calc((100vh - 70px) * 0.340253);
    top: calc((-100vh + 70px) * 0.0668973);
    left: calc((100vh - 70px) * 0.9423298);
}
.auditorium-snackbar .MuiSnackbarContent-message {
    width: 100%;
}
@media all and (max-width: 1440.9px) {
    .auditoriums-popup .toggle-auditoriums-button.close p {
        padding: 6.5px 6px;
    }
    .auditoriums-popup .toggle-auditoriums-button.close p svg {
        width: 20px;
        height: auto;
    }
    .auditoriums-popup .slider-container.has-slider > div:last-of-type {
        padding: 15px 40px 15px;
    }
    .auditoriums-popup .slider-container .auditorium-details-container {
        padding: 10px;
    }
    .auditoriums-popup .slider-container .auditorium-details-container .auditorium-name {
        font-size: 16px;
        line-height: 20px;
    }
}

/* Program mobile version */
#program-mobile {
    max-width: initial !important;
    min-width: initial !important;
    width: 100% !important;
    transform: translateX(0) !important;
    top: 56px;
    height: calc(calc(var(--vh, 1vh) * 100) - 112px);
    right: initial;
    .program-dropdown-auditorium {
        .box {
            padding-left: 10px !important;
        }
    }
    .auditorium-name {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }
}
#program-mobile.auditorium-page.dropdown-component .page-title {
    height: 50px;
    line-height: 50px;
}
#program-mobile.auditorium-page.dropdown-component .page-title .arrow {
    padding-left: 5px;
    padding-right: 5px;
    height: 50px;
}
#program-mobile.auditorium-page.dropdown-component .page-title .arrow span {
    padding-left: 5px;
    padding-right: 5px;
}
#program-mobile.dropdown-component .program-dropdown-day h3 span.day-position {
    font-size: 16px;
}
#program-mobile.dropdown-component .program-dropdown-day h3 span.date {
    font-size: 14px;
}
#program-mobile.dropdown-component .auditorium-day-program-container {
    max-width: initial;
}
#program-mobile.auditorium-page.dropdown-component .audioriums-program-slider .program-slider-wrapper {
    padding: 0;
    position: relative;
}
#program-mobile .auditorium-page .program-wrapper {
    padding: 0;
}
#program-mobile.auditorium-page.dropdown-component .auditorium-name {
    height: 53px;
    padding: 0 40px;
    background: #ffffff;
    position: absolute;
    width: 100vw;
    bottom: 0;
    z-index: 1;
}
#program-mobile.auditorium-page.dropdown-component .auditorium-name .time-zone-mobile,
#program-mobile.auditorium-page.dropdown-component .auditorium-name .time-zone-mobile span {
    position: initial;
    padding: 0;
}
#program-mobile.auditorium-page.dropdown-component .auditorium-name .time-zone-mobile {
    display: flex;
    justify-content: space-between;
}
#program-mobile.auditorium-page.dropdown-component .auditorium-name .time-zone-mobile span {
    display: inline-flex;
    width: auto;
}
#program-mobile.auditorium-page.dropdown-component .auditorium-name p {
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    white-space: normal;
    padding-top: 10px;
}
#program-mobile .auditorium-name span {
    font-size: 14px;
    text-transform: uppercase;
    display: block;
    text-align: right;
    font-weight: 500;
    color: $text-grey;
}
#program-mobile.auditorium-page.dropdown-component .auditorium-program-arrow {
    height: 52px;
    border-radius: 0 52px 52px 0;
    top: initial;
    bottom: 0;
    z-index: 9999999;
}
#program-mobile.auditorium-page.dropdown-component .auditorium-program-arrow:hover {
    background-color: $secondary;
}
#program-mobile.auditorium-page.dropdown-component .auditorium-program-arrow.prev {
    left: 0;
}
#program-mobile.auditorium-page.dropdown-component .auditorium-program-arrow.next {
    right: 0;
    border-radius: 52px 0 0 52px;
}
#program-mobile .program-entry:last-child .program-container {
    padding-bottom: 52px;
    border: 0;
}
#program-mobile.auditorium-page .program-wrapper {
    padding: 16px 0 0;
    min-height: calc(calc(var(--vh, 1vh) * 100) - 149px);
}
#program-mobile.auditorium-page .program-entry .speakers-name {
    font-size: 14px;
}
#program-mobile .program-wrapper .program-time {
    font-size: 12px;
}
@media screen and (min-width: 1025px) {
    .auditorium-page.dropdown-component {
        z-index: 6;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
}
@media screen and (max-width: 1250px) {
    .auditoriums-popup .slider-container.has-slider {
        .slider-header {
            padding: 12px 95px 0;
        }
    }
}
@media screen and (max-width: 1024px) {
    .auditorium-archive-page .program-section .page-title .search-container {
        margin-top: 16px;
        width: 100%;
        max-width: 500px;
    }
    .auditorium-archive-page .program-section .page-title input {
        width: 100%;
    }
    .auditorium-archive-page .program-section .page-title {
        padding: 0 16px;
        display: flex;
        flex-flow: column;

        .name {
            padding-inline-end: 0;
        }
    }
    .auditorium-page {
        display: flex;
        flex-flow: column;
    }
    .auditorium-page .centerOfPage {
        flex: 1;
    }
    .auditorium-page .centerOfPage.hide {
        position: absolute;
        z-index: -1;
    }
    .auditorium-page .relativeCenter {
        height: 100%;
        display: flex;
        flex-flow: column;
    }
    .auditorium-page .page-title {
        color: white;
        text-align: start;
        padding: 0 16px;
        font-size: 16px;
    }
    .auditorium-page .tabs-container {
        background-color: #ffffff;
        min-height: initial;
        height: 48px;
        display: flex;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
    }
    .auditorium-page .tab {
        font-size: 14px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $grey;
        span {
            padding: 0 8px;
        }
    }
    .auditorium-page .tab.active {
        font-weight: 600;
        font-size: 14px;
        color: $secondary;
        border-bottom: 2px solid $secondary;
    }
    #bannerLeft,
    #bannerRight,
    .auditoriums-popup {
        display: none;
    }
    .videoWrapper {
        position: relative;
        width: 100%;
        height: auto;
        top: initial;
        left: initial;
        flex-flow: column;
    }
    .videoWrapper .video-container {
        height: calc(100vw * 9 / 16);
    }
    .exhibitor-booth-mobile .videoWrapper .video-container {
        max-height: calc(calc(var(--vh, 1vh) * 100) - 402px);

        .booth-thumbnail {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            padding: 0;
            opacity: 0;
            transition: 800ms ease;
            transition-property: opacity;
            &.show {
                opacity: 1;
            }
        }

        .progress-bar {
            z-index: 10;
            height: 13px;
            position: relative;
            bottom: 13px;
            padding: 0;
            background-color: #f5f5f5;
            opacity: 0;
            transition: opacity 0.5s ease;

            .MuiSlider-rail {
                opacity: 0;
                height: 0;
            }

            .MuiSlider-track {
                height: 100%;
            }

            .MuiSlider-thumb {
                opacity: 0;
            }

            &.hide {
                opacity: 0;
            }
        }

        &:hover {
            .progress-bar {
                opacity: 1;
            }
        }
    }
    .videoWrapper.fullscreen .video-container {
        height: 100% !important;
        max-height: 100% !important;
    }
    .videoWrapper .video-actions-container {
        position: initial;
        left: initial;
        bottom: initial;
        top: initial;
        background-color: $primary;
        height: 50px;
        padding: 16px;
        border-radius: 0;
    }
    .videoWrapper.fullscreen .video-actions-container {
        height: auto !important;
    }
    .video-actions-container button {
        color: #fff;
    }
    .fullscreen .video-actions-container button {
        color: #fff;
    }
    .slot-mobile-details {
        display: flex;
        flex-flow: column;
        flex: 1;
        padding-bottom: 52px;
    }
    .slot-title {
        position: initial;
        padding: 8px 16px;
        background-color: #f5f5f5;
        left: initial;
        top: initial;
        flex: 1;
    }
    .auditorium-page .slot-title p,
    .auditorium-page .slot-title p.title {
        padding: 0;
        color: $dark;
        white-space: initial;
        text-overflow: initial;
        overflow: initial;
    }
    .next-video {
        position: initial;
        width: 100%;
        height: calc(100vw * 9 / 16);
        top: initial;
        left: initial;
    }
    .auditoriums-navigation-mobile {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: 56px;
        z-index: 1;
        background-color: #fff;
    }
    .auditoriums-navigation-mobile svg {
        width: 12px;
        height: auto;
    }
    .auditoriums-navigation-mobile svg path {
        stroke: #fff;
    }
    .auditoriums-navigation-mobile .button {
        height: 52px;
        width: 26px;
        background: $grey;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        border-bottom-left-radius: 52px;
        border-top-left-radius: 52px;
        font-size: 0;
        &.previous {
            transform: rotate(180deg);
        }
        &:active,
        &:focus {
            background-color: $secondary;
        }
    }
    .auditoriums-navigation-mobile .auditorium-title-container {
        flex: 1;
        padding: 0 12px;
    }
    .auditoriums-navigation-mobile .auditorium-title-container p {
        margin: 0;
        display: flex;
        justify-content: space-between;
        color: $text-grey;
        font-size: 14px;
        font-weight: 500;
    }
    .auditoriums-navigation-mobile .auditorium-title-container p.name {
        font-size: 16px;
        line-height: 1.25;
        font-weight: 600;
        color: $dark;
    }
    #program-mobile.auditorium-page .program-wrapper {
        min-height: calc(calc(var(--vh, 1vh) * 100) - 149px);
    }
    #program-mobile.auditorium-page .program-wrapper.has-program-file {
        padding-top: 70px;
    }
    #program-mobile.auditorium-page .program-wrapper {
        min-height: calc(calc(var(--vh, 1vh) * 100) - 149px);
    }
    .videoWrapper.fullscreen .video-actions-container {
        height: 50px !important;
        border-radius: 25px;
    }
    .videoWrapper.fullscreen .video-actions-container.hide {
        display: none;
    }
    .auditorium-page .slot-title > div {
        width: 100%;
    }
    .auditorium-page-wrapper {
        .auditorium-snackbar {
            bottom: 60px;
        }
    }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
    .exhibitor-booth-mobile .videoWrapper .video-container {
        max-height: calc(calc(var(--vh, 1vh) * 100) - 112px);
    }
}
@media screen and (max-width: 480px) {
    #program-mobile.dropdown-component .program-dropdown-day h3 span.day-position {
        font-size: 14px;
    }
    #program-mobile.dropdown-component .program-dropdown-day h3 span.date {
        font-size: 12px;
    }
}
@media screen and (max-width: 340px) {
    #program-mobile.dropdown-component .program-dropdown-day h3 span.day-position {
        font-size: 12px;
    }
    #program-mobile.dropdown-component .program-dropdown-day h3 span.date {
        font-size: 10px;
    }
}
