@import 'CSS/variables.module';

.resources {
  .resources-list {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 50px;
    list-style: none;
    margin-top: 50px;
  }

  a:hover{
    cursor: pointer;
  }

  .hide {
    display: none;
  }

  .uploaded-text {
    margin-right: 8px;
  }

  .resource-title {
    color: $grey-dark;
    font-size: 20px;
    font-weight: 500;
    &.description {
      margin-top: 0;
    }
  }

  .resource-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .resource-inputs {
      width: 498px;
      padding: 0 24px 0 0;
      border-right: 1px solid $disabled-text;
      display: flex;
      flex-direction: column;
      &.links {
        gap: 32px;
      }
      &.documents {
        gap: 10px;
      }
      &.description-container {
        min-width: 498px;
        border-right: none;
        font-size: 16px;
        line-height: 24px;
        color: #777081;
      }
    }
  }

  .new-file-upload-container {
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    label {
      padding-left: 0;
      display: flex;
      align-items: center;
      max-width: 473px;

      span.MuiButton-label {
        padding-left: 5px !important;
        color: rgba(0, 0, 0, 0.87);
      }
    }

    p {
      margin: 5px 0 0 0;
    }
  }

  .resource-button {
    margin-left: 10px;
  }
}