.text-align-left {
    text-align: left;
}


.align-helper {
    .MuiFormControlLabel-root {
        display: block;

        span {
            display: block;
            float: left;
        }

        span.MuiTypography-root {
            margin-top: 9px;
        }
    }

    .MuiFormLabel-root {
        text-align: left;
    }

    .MuiSelect-select {
        text-align: left;
    }
}

.select-field .MuiFormControl-root.MuiFormControl-marginNormal {
    margin: 0px;
}

.fields-container {
    display: grid;
    grid-row-gap: 20px;
}

.align-helper .MuiFormControl-root.MuiFormControl-marginNormal {
    margin: 0px;
}

.mobile-fields-container {
    padding: 20px 0px;
}

#select-field-profile div {
    width: 100%;
}

html.lang-rtl {
    .align-helper .MuiFormControlLabel-root span {
        float: right;
    }
}