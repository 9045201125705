@import 'variables.module';

#header-mobile {
    height: 56px;
}
#header-mobile .main-menu-container {
    padding-inline-start: 0;
}
#header-mobile .left-header-wrapper {
    flex: 1;
}
#header-mobile #my-events {
    height: 56px;
    margin-inline-start: 0;
    padding-inline-start: 16px;
    border: 0;
    padding-inline-end: 28px;
    flex: 1;
    justify-content: flex-start;
}
#header-mobile #my-events p {
    justify-content: flex-start;
    color: $dark;
    font-weight: 800;
    padding: 8px 0;
}
#header-mobile #my-events p span {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    text-align: start;
    white-space: initial;
}
#header-mobile .main-menu-container .right-header-wrapper > div:last-of-type {
    margin-inline-end: 16px;
}
#menu-bottom {
    position: fixed;
    bottom: 0;
    z-index: 1199;
    height: 56px;
    width: 100%;
    background-color: #ffffff;
    border-top: 1px solid $disabled-text;
    display: flex;
   
}

.menu-bottom.is-webview {
     height: 100px !important;
     padding: 20px;
 }

#menu-bottom .menu-bottom-wrapper {
    width: 100%;
}
#menu-bottom .main-menu-container {
    height: 100%;
}
#menu-bottom .main-menu-container .main-header-wrapper > div {
    padding: 0;
    display: flex;
    cursor: pointer;
    text-align: center;
    line-height: 1;
    flex-flow: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: calc(100vw / 5);
}
#menu-bottom .main-menu-container .main-header-wrapper > div.active p {
    color: $primary;
    font-weight: bold;
}
#menu-bottom .menu-bottom-wrapper p {
    margin: 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: $text-grey;
    padding-top: 6px;
}
#menu-bottom .menu-bottom-wrapper > div > div {
    display: flex;
    align-items: center;
    height: 100%;
}
.menu-slow-index {
    .menu {
        .top-container {
            z-index: 9;
        }
    }
}
@media screen and (max-width: 1024px) {
    .event-page.hide #menu-bottom,
    .event-page.hide-new-comment #menu-bottom,
    .event-page.hide-new-chat-message #menu-bottom {
        display: none;
    }
    .event-page.hide-new-comment .add-new-post-container {
        display: none;
    }
    /*.chat-opened #chat-messages-container {*/
    /*    position: fixed;*/
    /*    bottom: 66px;*/
    /*    z-index: 1;*/
    /*}*/
    /*.chat-opened .chat-header-container {*/
    /*    position: relative;*/
    /*    z-index: 2;*/
    /*}*/
    /*.chat-opened #header-mobile .main-menu-container {*/
    /*    z-index: 2;*/
    /*}*/
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    #menu-bottom .menu-bottom-wrapper > div > div {
        justify-content: center;
    }
    #menu-bottom .main-menu-container .main-header-wrapper > div {
        width: auto;
        padding: 0 14px;
    }
    .main-menu-container .main-header-wrapper #chats > div {
        right: 15px;
        left: initial;
        bottom: 18px;
    }
}
@media screen and (max-width: 766px) {
    .main-menu-container .main-header-wrapper #chats > div {
        bottom: 18px;
        left: 50%;
    }
}
@media screen and (max-width: 480px) {
    #menu-bottom .menu-bottom-wrapper > div > div {
        justify-content: space-around;
    }
    #menu-bottom .main-menu-container .main-header-wrapper > div {
        width: auto;
        padding: 0 5px;
    }
}
@media screen and (max-width: 400px) {
    #menu-bottom .menu-bottom-wrapper p {
        font-size: 11px;
    }
}
@media screen and (max-width: 330px) {
    #menu-bottom .menu-bottom-wrapper p {
        font-size: 10px;
    }
}
