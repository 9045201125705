@import '../../CSS/variables.module';
.network-filters {
    position: absolute;
    width: 100%;
    right: -10000px;
    transition: 200ms;
    background-color: $white-variant;
    z-index: 9999;
    animation: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: break-word;
    &--show {
        right: 0;
        transition: 200ms;
        animation: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    }
    &__header {
        width: 100%;
        flex: 1 1 5%;
        max-height: 44px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 598px;
        background: #f5f5f5;
        p {
            text-align: left;
            font-family: $fontFamily, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin: 0;
            padding: 12px 8px 12px 8px;
            color: $dark;
        }
    }
    &__body {
        flex: 2 1 80%;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            margin: 0;
        }
    }
    &__footer {
        background-color: $white;
        border-top: 1px solid hsla(0, 0%, 53.7%, 0.5);
        width: 89%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1024px) {
            width: 100%;
            margin: 0;
            padding-top: 6px;
            padding-bottom: 14px;
            height: 70px;
           
        }
        @media screen and (min-width: 1279px) {
            height: 60px;
            width: max-content;
            padding-inline-start: 36px;
            padding-inline-end: 36px;
        }
    }
    &__reset-btn {
        margin-right: 15px !important;
    }
    &__apply-btn {
        font-weight: 600;
    }
    &__apply-btn--disabled {
        background-color: black !important;
    }
}
.network-filters__footer{
    min-height: 55px;
    width: 100%;
    button {
        display: flex;
        flex: none;
    }
    .apply-filters-button-container{
        min-width: fit-content;
    }
}
@media screen and (min-width: 1279px) {
    .network-filters__footer.no-filter-add {
        padding-inline-end: 45px;
        padding-inline-start: 45px;
    }
}
.network-filter {
    margin-bottom: 8px;
    max-width: 598px;
    width: 100%;
    &__accordion {
        border: 1px solid $disabled-text;
        box-sizing: border-box;
        border-radius: 6px;
    }
    &__title-counter {
        background: $primary;
        width: 16px;
        height: 16px;
        display: block;
        color: #fff;
        font-family: $fontFamily, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        border-radius: 50%;
        margin-right: 5px;
    }
    &__title-details {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        p {
            flex: 1;
            text-align: left;
            font-family: $fontFamily, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $dark;
            &.title--active {
                color: $primary;
            }
        }
    }

    .MuiAccordionSummary-content {
        margin: 0 !important;
    }
}

.MuiFormControlLabel-label {
    user-select: none;
}

.network-filter.ar-lang {
    .MuiButtonBase-root.MuiAccordionSummary-root.summary {
        padding-left: 0;
    }
    p.title {
        text-align: right;
        padding-right: 20px;
    }
    label.MuiFormControlLabel-root {
        margin-right: -2px;
    }
}
// body {
//     overflow-x: hidden;
// }
.network-filter:last-child {
    margin-bottom: 70px;
}
.network-filters__footer.fr-helper {
    padding-inline-start: 4px;
    padding-inline-end: 4px;
    span,
    button {
        font-size: 12px;
    }
}
.no-filter-add.network-filters__footer.fr-helper {
    padding-inline-start: 12px;
    padding-inline-end: 12px;
    span,
    button {
        font-size: 12px;
    }
}

.network-filters__header {
    padding-inline-end: 8px;
}
