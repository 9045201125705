@import '../../../CSS/variables.module';

.exhibitors-overlay {
    box-shadow: 0 14px 24px rgba(32, 15, 54, 0.25);
    border-radius: 6px 6px 0 0;
    padding: 32px;
    position: absolute;
    bottom: -64px;
    background-color: white;
    z-index: 100;
    height: 0;
    transition: height 1.5s ease-in-out;
    margin: 0;
    width: calc(100% - 210px);
    transform: translateX(-50%);
    left: 50%;
    max-width: 933px;
    &.show {
        height: calc(100vh - 46px);
        transition: height 1.5s ease-in-out;
        display: flex;
        flex-direction: column;
    }
    .close-button {
        border-radius: 6px;
        padding: 12px 22px;
        position: absolute;
        top: 25px;
        right: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &[data-content='1'] {
            top: 48px;

            &[data-exhibitors='0'] {
                top: 32px;
            }
        }
        &.with-collapse-button {
            padding: 15px 14px;
            top: 30px;
            right: 32px;
        }
        span {
            font-weight: 600;
            font-size: 14px;
            line-height: 120%;
            color: $dark;
            padding-inline-start: 10px;
        }
        &:hover {
            svg path {
                fill: $secondary;
            }
        }
    }

    .exhibitors-container {
        flex: 1;
        overflow: hidden;
        padding-top: 24px;
        border-top: 1px solid $disabled-text;
        margin-top: 16px;
        &[data-content='1'] {
            border: 0;
            padding: 0;
            margin: 0;
        }

        &.posters-list-container {
            .exhibitors-container-title {
                .posters-title {
                    text-transform: uppercase;
                    margin-bottom: 5px;
                }
            }
            .empty-exhibitors-list {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 100px;

                @media screen and (max-height: 799px) {
                    margin-top: 50px;
                }

                p {
                    white-space: pre-line;
                    text-align: center;
                    color: $text-grey;
                    font-weight: 600;
                    font-size: 16px;

                    span {
                        display: block;

                        &:first-letter {
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
        .exhibitors-container-title {
            display: flex;
            justify-content: space-between;
            padding-bottom: 16px;
            padding-top: 8px;

            & > div {
                &:first-of-type {
                    flex: 1;
                    padding-inline-end: 10px;
                }
            }
            .title {
                padding: 0 0 4px 0;
                margin: 0;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: $secondary;
            }
            .subtitle {
                margin: 0;
                padding: 0;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                color: $dark;
            }

            .search-exhibitor-container {
                @media screen and (min-width: 1199px) {
                    width: 470px;
                }

                &.hide {
                    display: none;
                }

                .Mui-focused {
                    color: $secondary;

                    fieldset {
                        border-color: $secondary;
                    }
                }

                svg {
                    fill: $grey;

                    &.delete-text {
                        fill: $secondary;
                        cursor: pointer;
                    }
                }

                input {
                    color: $dark;
                    font-weight: 500;
                    font-size: 16px;
                    min-height: 32px;
                }
            }
        }
        .exhibitors-list {
            display: flex;
            flex-wrap: wrap;
            max-height: calc(100% - 100px);
            padding-bottom: 20px;
            overflow: auto;
            scrollbar-width: thin;
            scrollbar-color: rgb(137, 137, 137) #ffffff;
            direction: rtl;
            justify-content: flex-end;

            @keyframes delay-overflow {
                from,
                to {
                    overflow: hidden;
                }
            }

            &.overflow-auto {
                animation: delay-overflow 1s backwards;
                animation-delay: 1.5s;
            }

            &[data-empty='0'] {
                overflow: hidden;
                direction: ltr;
                .empty-exhibitors-list {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 100px;

                    @media screen and (max-height: 799px) {
                        margin-top: 50px;
                    }

                    p {
                        white-space: pre-line;
                        text-align: center;
                        color: $text-grey;
                        font-weight: 600;
                        font-size: 16px;

                        span {
                            display: block;

                            &:first-letter {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
            .exhibitors-list-inner-container {
                direction: ltr;
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }
            .single-exhibitor {
                padding: 8px;
                width: 20%;
                flex: 0 0 20%;
                direction: ltr;
                .exhibitor-container {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                    padding: 16px;
                    cursor: pointer;
                    .logo-container {
                        width: 100%;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            object-fit: contain;
                            width: 100%;
                            height: 100%;
                        }
                        svg {
                            width: 40.5px;
                            height: 31px;
                        }
                    }
                    p {
                        width: 100%;
                        word-break: keep-all;
                        margin: 0;
                        padding-top: 16px;
                        font-size: 16px;
                        line-height: 20px;
                        color: $dark;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
                &.active {
                    .exhibitor-container {
                        border: 1px solid $secondary;
                        border-radius: 8px;
                    }
                }
            }
        }
        .posters-list {
            max-height: calc(100% - 100px);
            padding-inline-end: 5px;

            @keyframes delay-overflow {
                from,
                to {
                    overflow: hidden;
                }
            }

            &.overflow-auto {
                animation: delay-overflow 1s backwards;
                animation-delay: 1.5s;
            }

            &[data-empty='0'] {
                padding-inline-end: 0;
                margin-inline-end: 0;
            }
            &[data-scroll='false'] {
                padding-inline-end: 0;
                margin-inline-end: 0;
            }
            .single-poster {
                padding: 9px 14px;
                border-radius: 6px;
                margin-bottom: 15px;
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: flex-start;
                justify-content: space-between;
                .vote-container {
                    display: flex;
                }
                .poster-container {
                    word-break: break-word;
                    cursor: pointer;
                    .poster-title {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        color: $secondary;
                        margin: 0;
                        padding-bottom: 8px;
                    }
                    .poster-authors {
                        font-size: 16px;
                        line-height: 24px;
                        color: $text-grey;
                        margin: 0;
                        padding-bottom: 4px;
                    }
                    .poster-highlights {
                        font-size: 16px;
                        line-height: 24px;
                        color: $dark;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            .single-poster:hover,
            .single-poster.active {
                background-color: #efeef1;
            }
        }
    }
}
.showfloors-container {
    &[data-content='1'] {
        border: 0;
        padding: 0;
        margin: 0;

        h4 {
            display: none;
        }

        .showfloors-list {
            display: none;
        }

        & ~ .exhibitors-container {
            .exhibitors-container-title {
                margin-inline-end: 32px;
            }
        }
    }

    &.collapsed {
        .showfloors-list {
            max-height: 117px;
            .single-showfloor {
                opacity: 0;
            }
        }
    }

    .title-wrapper-collapsed {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-inline-end: 65px;
        padding-bottom: 10px;
        &.collapsed {
            & > div {
                span {
                    &.see-list {
                        opacity: 1;
                    }
                    &.collapse-list {
                        opacity: 0;
                    }
                }
                svg {
                    transform: rotate(-180deg);
                    transition-duration: 0.5s;
                    transition-property: transform;
                }
            }
        }
        & > div {
            display: flex;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            padding: 12px 8px;
            cursor: pointer;
            & > div {
                position: relative;
                min-width: 112px;
            }
            span {
                color: $secondary;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 14px;
                transition: opacity 1s ease-in;
                position: absolute;
                top: 0;
                &.see-list {
                    opacity: 0;
                }
                &.collapse-list {
                    opacity: 1;
                }
            }
            svg {
                margin-inline-start: 12px;
                transform: rotate(0deg);
                transition-duration: 0.5s;
                transition-property: transform;
                path {
                    fill: $secondary;
                }
            }
        }
        .title {
            padding: 0;
            flex: 1;
            padding-inline-end: 20px;
            line-height: 26px;
            span {
                display: block;
                font-weight: 500;
                font-size: 14px;
                color: $text-grey;
            }
        }
    }

    .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: $dark;
        padding: 0 0 8px 0;
        margin: 0;
    }
    .showfloors-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
        overflow: hidden;
        max-height: 1000px;
        transition: max-height 1s ease-in-out;
        .single-showfloor {
            padding: 8px;
            width: 20%;
            flex: 0 0 20%;
            opacity: 1;
            transition: opacity 1s ease-in;
            &:nth-child(-n + 5) {
                opacity: 1;
            }
            .showfloor-container {
                border: 1px solid $secondary;
                border-radius: 8px;
                text-align: center;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $dark;
                position: relative;
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
                background: rgba(255, 255, 255, 0.7);
                .image-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -2;
                    border-radius: 8px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 8px;
                    }
                }
                .text-container {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    border-radius: 6px;
                    p {
                        overflow: hidden;
                        white-space: nowrap;
                        display: inline-block !important;
                        text-overflow: ellipsis;
                        margin: 0;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 120%;
                        text-transform: uppercase;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
                &:hover {
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), $secondary;
                    color: $secondary;
                }
            }
            &.active {
                .showfloor-container {
                    border: 1px solid $secondary;
                    color: #fff;
                    background: linear-gradient(156.39deg, $showfloorActive1 48.1%, $showfloorActive2 105%);

                    &:hover {
                        pointer-events: none;
                        color: #ffffff;
                    }
                }

                &.with-image {
                    .showfloor-container {
                        position: relative;
                        background: transparent;

                        &:after {
                            content: '';
                            background: linear-gradient(156.39deg, $showfloorActive1 48.1%, $showfloorActive2 105%);
                            opacity: 0.7;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                            top: 0;
                            z-index: 2;
                            left: 0;
                            right: 0;
                            bottom: 0;
                        }

                        &:hover {
                            pointer-events: none;
                            color: #ffffff;
                        }

                        .image-container {
                            z-index: 1;
                        }

                        .text-container {
                            position: relative;
                            z-index: 3;
                        }
                    }
                }
            }
            &.inactive {
                .showfloor-container {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &.with-image {
                .showfloor-container {
                    &:hover {
                        background: rgba(255, 255, 255, 0.7);
                        color: $secondary;
                    }
                }
            }
            &.restricted {
                .showfloor-container {
                    border: 1px solid $grey !important;
                    color: $text-grey !important;
                    box-shadow: none;

                    &:hover {
                        background: transparent;
                        pointer-events: none;
                    }

                    .restricted-icon {
                        position: absolute;
                        top: 6px;
                        right: 6px;
                    }
                    .text-container {
                        background: transparent !important;
                    }
                }
                &.with-image {
                    .showfloor-container {
                        &:hover {
                            background: rgba(255, 255, 255, 0.7);
                        }
                    }
                }
            }
        }
    }
}
.multiple-showfloors {
    display: none;

    &.opened {
        display: block;
        position: absolute;
        top: 48px;
        height: calc(100% - 48px);
        width: 100%;
        background: #ffffff;
        z-index: 12;

        .booth-dialog-content {
            height: 100%;

            &.search-opened {
                h4 {
                    display: none;
                }

                .search-exhibitor-container {
                    display: flex;
                }

                .showfloors-container {
                    display: none;
                }

                .exhibitors-list-mobile {
                    height: calc(100% - 48px);
                    background: #f5f5f5;

                    & > div {
                        .single-exhibitor {
                            background-color: #ffffff;

                            &.active {
                                background-color: $primary;
                            }

                            &.scholar {
                                &.active {
                                    background-color: #f5f5f5;
                                }
                            }
                        }

                        &[data-empty='0'] {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .empty-exhibitors-list {
                                text-align: center;

                                p {
                                    color: $text-grey;
                                    font-weight: 600;
                                    font-size: 16px;
                                    white-space: pre-line;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.hide {
            .search-exhibitors {
                display: none;
            }
        }
    }

    .showfloors-container {
        margin-bottom: 0;
        border: 0;
        padding-top: 16px;
        overflow: hidden;

        &[data-content='1'] {
            padding: 0;

            & ~ .exhibitors-list-mobile {
                height: calc(100% - 48px);
            }
        }

        .showfloors-list {
            flex-wrap: nowrap;
            white-space: nowrap;
            overflow-x: auto;

            .single-showfloor {
                width: 161px;
                flex: 0 0 161px;
                padding-bottom: 12px;

                &:first-of-type {
                    padding-inline-start: 24px;
                    width: 177px;
                    flex: 0 0 177px;
                }

                &:last-of-type {
                    padding-inline-end: 24px;
                    width: 177px;
                    flex: 0 0 177px;
                }

                .showfloor-container {
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    .exhibitors-list-mobile {
        height: calc(100% - 160px);

        & > div {
            margin: 0;
            padding: 0;
            overflow-y: auto;
            height: 100%;

            &[data-empty='0'] {
                display: flex;
                align-items: center;
                justify-content: center;

                & > div {
                    text-align: center;
                    margin-top: -24px;

                    p {
                        font-weight: 600;
                        color: $text-grey;

                        span {
                            display: block;

                            &:first-letter {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }

            .single-exhibitor {
                &.scholar {
                    &.active {
                        background-color: $primary;
                        color: $white;
                    }
                }
                &.active {
                    background-color: $primary;
                    color: #ffffff;
                }
            }

            .exhibitor-container {
                display: flex;
                padding: 10px 8px;
                border-bottom: 1px solid $disabled-text;
                align-items: flex-start;

                &.scholar {
                    min-height: 90px;
                    padding: 16px;
                    p {
                        -webkit-line-clamp: 2;
                        padding-inline-start: 0;
                        padding-inline-end: 16px;
                    }
                    .poster-title-wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 100%;
                    }
                }

                .logo-container {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                p {
                    font-weight: 600;
                    font-size: 16px;
                    padding-inline-start: 12px;
                    margin: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    word-break: break-word;
                    /*   -webkit-line-clamp: 3;*/
                    -webkit-box-orient: vertical;
                    flex: 1;
                }
            }
        }
    }
}
.exhibitors-overlay-mobile {
    background-color: #ffffff;
}
.event-page {
    &.hide {
        .event-mobile-page {
            &.showfloor-page {
                @media screen and (max-width: 1024px) {
                    height: calc(calc(var(--vh, 1vh) * 100) - 56px);
                }

                .exhibitors-list-mobile {
                    & > div[data-empty='0'] {
                        align-items: flex-start;
                        padding-top: 40px;
                        .empty-exhibitors-list {
                            margin-top: 0;

                            p {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 799px) {
    .no-exhibitors-wrapper {
        height: 70%;

        p {
            &.no-exhibitors-text {
                height: 30%;
                font-size: 20px;
            }
        }
    }
}
.poster-title-wrapper {
    p {
        padding-top: 8px;
    }
}
