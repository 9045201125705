.back-to-live-button {
    position: absolute;
    left: -250px;
    top: 7px;
    width: auto;
    color: #ffffff;
    background: #ca2f25;
    padding: 6px 11px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #ffffff;
    border-radius: 6px;
    cursor: pointer;
    span {
        width: auto !important;
        padding-inline-start: 10px;
    }
}
