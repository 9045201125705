@import '../../CSS/variables.module';


.welcome-screen-dialog{
    .spacer{
        flex: 1;
    }
    #form-dialog-title{
        background-color: #fff;
        padding: 15px 18px;
        height: auto;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        h2{
            color: #211E16;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
            text-align: center;
            position: relative;
            padding: 0 40px;
            .close-button{
                position: absolute;
                top: -4px;
                right: 0;
                min-width: 40px;
                width: 40px;
                height: 40px;
                color: $grey-dark;
                &:focus {
                    box-shadow: 0px 4px 10px #6926d3;
                }
            }
        }
    }

    .MuiPaper-root{
        max-width: 100%;
    }

    .booth-video-container{
        width: 868px;
        height: 0;
        padding-top: 56.25%;
        position: relative;
        @media screen and (max-width: 1365px){
            width: 700px;
        }
        &.portrait{
            padding-top: 0;
            height: 100%;
            flex: 1
        }
    }

    .media-container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .thumbnail-image-background {
            display: flex;
            align-items: center;
            background-color: black;
            width: 100%;
            height: 100%;
            &.hide {
                opacity: 0;
                width: 0;
                height: 0;
            }
        }
        .booth-thumbnail{
            padding: 0;
        }
    }

    .booth-video-actions-container{
        padding: 18px 0 2px;
        margin: 0;
        justify-content: center;
        button{
            margin: 0 25px;
            font-size: 24px;
        }
    }

    .MuiDialogContent-root{
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
    }


    &.type-fullscreen{
        .MuiDialogContent-root{
            position: relative;
            padding: 0;
            overflow: hidden;
            .booth-video-container{
                width: 100%;
                height: 100%;
            }
            .booth-video-actions-container{
                position: absolute;
                bottom: 16px;
                background-color: #fff;
                border-radius: 40px;
                padding: 15px 0;
                margin: 0;
                left: 50%;
                transform: translateX(-50%);
                button{
                    margin: 0 25px;
                }
            }
        }
    }
    .MuiDialogActions-root{
        justify-content: space-between;
        padding: 14px 16px;
        background: #F5F5F5;
        box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
        @media all and (max-width: 767.9px){
            padding: 8px;
        }
    }
    .welcome-image{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .checkbox-label{
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        font-family: 'Montserrat', sans-serif;
    }
    .show-again-checkbox{
        border-radius: 6px;
        padding-inline-end: 12px;
        &:hover{
            color: $primary;
            svg{
                color: $primary
            }
        }
        &:focus{
           box-shadow: 0px 4px 10px #6926d3;
           border: none;
           outline: none;
        }
    }
    &.is-mobile{
        .booth-video-container{
            width: 100%;
        }
        #form-dialog-title h2{
            font-size: 16px;
            line-height: 20px;
            .close-button{
                top: -10px;
                &:focus {
                    box-shadow: 0px 4px 10px #6926d3;
                }
            }
        }
    }
    .refresh-overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 60px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        svg{
            cursor: pointer;
        }
    }
    #welcome-screen-video:fullscreen{
        .booth-video-container{
            width: 100%;
        }
        .booth-video-actions-container{
            background-color: #fff;
            position: absolute;
            bottom: 20px;
            padding: 10px 0;
            border-radius: 8px;
        }
    }
}

html.lang-rtl{
    .welcome-screen-dialog #form-dialog-title h2 button{
        left: 0;
        right: auto;
    }
}