.icon-cover {
    margin-right: 5px;
}
.snackbar-email-domains {
    opacity: 1 !important;
}
.snackbar-email-domains.snackBarClose {
    transition-delay: 4000ms !important;
    transition-property: all !important;
    opacity: 0 !important;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}
.addZIndex {
    z-index: 9999 !important;
}
