@import '../../../../CSS/variables.module';
.main-speakers {
    max-width: 1112px;
    .speaker-headline {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
}
.speaker-form-container-wrapper {
    margin-top: 16px;
}

//accordion
.expand-accordion.form-accordion {
    transition: height 0.4s;
    height: 1400px;
    padding: 33px 17px;
    .form-controller {
        margin-bottom: 0 !important;
    }
}
.no-speaker-height.expand-accordion.form-accordion {
    height: 1017px;
    transition: height 0.4s;
}

.collapse-accordion.form-accordion {
    height: 80px;
    transition: height 0.4s;
    padding: 25px 17px;
}

.form-accordion {
    overflow: hidden;
    height: 80px;
    min-width: 1112px;
    transition: height 0.4s;
    padding: 25px 17px;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0 1px 5px #00000033;

    // box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
    border-radius: 6px;
    // padding: 33px 17px;
    display: flex;
    margin-bottom: 25px;
    .speaker-form {
        width: 526px;
        position: relative;
    }
    .form-controller {
        align-items: center;
        justify-content: flex-end;
        display: flex;
        width: 100%;
        gap: 14px;
        h3 {
            font-weight: 500;
            font-size: 20px;
            text-transform: uppercase;
            color: $grey-dark;
            margin: 0;
        }
    }
}
.form-controller {
    display: flex;
    align-items: center;
    gap: 20px;
}
h3.speaker-title {
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    color: $grey-dark;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: 12px;
}

.form-accordion.expand-accordion {
    h3.speaker-title {
        margin-top: -36px;
    }
}
.mt-12 {
    margin-top: 12px;
}
.addDeleteFormItem {
    position: relative;
    height: 85px;
    max-width: 520px;
}
h3.form-h {
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    color: $grey-dark;
    margin: 14px 0 16px 0;
}
.button-speakers.MuiButton-outlined {
    border: 0;
}
.button-speakers .button-content {
    display: flex;
    align-items: center;
}
.current-banner-container.cover-img-container.speaker-prop {
    max-width: 100%;
    width: 100%;
    height: 284px;
    margin-bottom: 0;
}
.show-speaker {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
}
.delete-speaker {
    .Svg.IconTrash {
        width: 24px;
        height: 24px;
        margin-bottom: 0;
    }
}

.no-item {
    overflow: hidden;
    height: 80px;
    transition: height 0.4s;
    padding: 25px 17px;
    align-items: center;
    justify-content: space-between;

    background-color: $white;
    box-shadow: 0 1px 5px #00000033;
    border-radius: 6px;
    display: flex;
    margin-bottom: 25px;
    h3 {
        font-weight: 500;
        font-size: 20px;
        color: $grey-dark;
    }
}
.actions-container.speaker-helper {
    padding-bottom: 0;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    label {
        height: 40px;
    }
    button {
        margin-top: 0;
    }
}
.no-saved-warning {
    margin: 0;
    padding-top: 24px;
    font-size: 14px;
    font-weight: 600;
    color: $grey;
}
.input-description.prop-speakers {
    width: 506px;
}
.relative {
    position: relative !important;
}
.speaker-img-container {
    margin-bottom: 0 !important;
    // min-width: 1078px;
    display: flex;
    gap: 20px;

    .image-explain.def-prop-img {
        margin-top: 61px;
    }
}
[data-rbd-drag-handle-context-id] {
    margin-bottom: 25px;
}

.speaker-upload-image-button {
    color: #000000de;
}
.add-border-to-left-side {
    padding-left: 15px;
    border-left: 1px solid $disabled-text;
}
.show-switch-container {
    padding-top: 2px;
    margin-right: 5px;
}
.editor-wrapper.speaker-prop {
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.23);
    margin-bottom: 10px;
}
.speaker-list .spinner {
    background: transparent;
}
.speaker-accordion {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .speaker-information {
        display: flex;
    }
}
.dragDrop-element {
    margin: 0;
}
.social-networks-container {
    max-width: 520px;
}
.speaker-form-container-wrapper {
    min-width: 100%;
    button {
        margin-top: 16px;
    }
}
body {
    overscroll-behavior: none !important;
}
.speaker-prop {
    .editor-class.rdw-editor-main {
        font-family: $fontFamily, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.04em;
        color: rgba(0, 0, 0, 0.87);
    }
}