@import 'CSS/variables.module';

.item-list-styles-container {
    display: flex;
    flex-direction: row;
    gap: 23px;
    margin-right: 8px;
    svg {
        cursor: pointer;
        path {
            fill: $grey-variant;
        }
    }
    svg:hover,
    svg.active {
        path {
            fill: $primary;
        }
    }
}
.list-style-and-calendar-container {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: row;
}
