@import 'variables.module';

.no-building-access {
    height: calc(100vh - 70px);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    font-size: 60px;
    padding: 32px;
    color: var(--theme-primary-color);
    span {
        font-size: 32px;
        line-height: 48px;
        padding-top: 32px;
    }
    &.mobile {
        height: calc(100vh - 112px);
        padding: 16px;
        span {
            font-size: 1.5rem;
            line-height: 2rem;
            max-width: 400px;
            padding-top: 16px;
        }
    }
}

.village-page {
    font-size: 30px;
    .building {
        box-shadow: none !important;
        transition: box-shadow 0.35s ease-out !important;
        .positioning-container {
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.35s ease-out;
        }
        &:hover {
            z-index: 2;
            box-shadow: 0 0 200vw 200vh rgba(0, 0, 0, 0.35) !important;
            transition: box-shadow 0.35s ease-out !important;
            .positioning-container {
                opacity: 1;
                transition: opacity 0.35s ease-out;
            }
            .building-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid $disabled-text;
            }
            .building-data {
                width: calc((100vh - 70px) * 0.2);
                height: 100%;
                display: flex;
                flex-flow: column;
                background-color: #f5f5f5;
                padding: 8px 16px;
                position: absolute;
                text-align: left;
                justify-content: space-between;
                color: #211e16;
                border: 1px solid $disabled-text;
                &-name {
                    margin: 0 0 8px 0;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                }
                &-description {
                    margin: 0;
                    overflow: hidden;
                    max-height: calc(100% - 34px);
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.4px;
                    color: #000000;
                }
                &-link {
                    margin-top: 8px;
                    display: block;
                    color: $error-light;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 20px;
                }
                .building-info-container {
                    flex: 1;
                    max-height: calc(100% - 40px);
                }
            }
        }
    }

    .building1 {
        width: calc((100vh - 70px) * 0.17);
        height: calc((100vh - 70px) * 0.3);
        top: calc((-100vh + 70px) * 0.172);
        left: calc((-100vh + 70px) * 0.92);
        border-radius: 6px 0 0 6px;
        .building-overlay {
            border-radius: 6px 0 0 6px;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-right: none !important;
        }
        .building-data {
            left: 100%;
            border-radius: 0 6px 6px 0;
            border-left: none !important;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building2 {
        width: calc((100vh - 70px) * 0.14);
        height: calc((100vh - 70px) * 0.3);
        top: calc((-100vh + 70px) * 0.305);
        left: calc((-100vh + 70px) * 0.695);
        border-radius: 6px 0 0 6px;
        .building-overlay {
            border-radius: 6px 0 0 6px;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-right: none !important;
        }
        .building-data {
            left: 100%;
            border-radius: 0 6px 6px 0;
            border-left: none !important;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building3 {
        width: calc((100vh - 70px) * 0.14);
        height: calc((100vh - 70px) * 0.37);
        top: calc((-100vh + 70px) * 0.245);
        left: calc((-100vh + 70px) * 0.44);
        border-radius: 6px 0 0 6px;
        .building-overlay {
            border-radius: 6px 0 0 6px;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-right: none !important;
        }
        .building-data {
            left: 100%;
            border-radius: 0 6px 6px 0;
            border-left: none !important;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building4 {
        width: calc((100vh - 70px) * 0.2);
        height: calc((100vh - 70px) * 0.275);
        top: calc((100vh - 70px) * 0.028);
        left: calc((-100vh + 70px) * 0.61);
        border-radius: 6px 0 0 6px;
        .building-overlay {
            border-radius: 6px 0 0 6px;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-right: none !important;
        }
        .building-data {
            left: 100%;
            border-radius: 0 6px 6px 0;
            border-left: none !important;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building5 {
        width: calc((100vh - 70px) * 0.21);
        height: calc((100vh - 70px) * 0.35);
        top: calc((100vh - 70px) * 0.23);
        left: calc((-100vh + 70px) * 0.91);
        border-radius: 6px 0 0 6px;
        .building-overlay {
            border-radius: 6px 0 0 6px;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-right: none !important;
        }
        .building-data {
            left: 100%;
            border-radius: 0 6px 6px 0;
            border-left: none !important;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building6 {
        width: calc((100vh - 70px) * 0.23);
        height: calc((100vh - 70px) * 0.3);
        top: calc((100vh - 70px) * 0.285);
        left: calc((-100vh + 70px) * 0.7);
        border-radius: 6px 0 0 6px;
        .building-overlay {
            border-radius: 6px 0 0 6px;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-right: none !important;
        }
        .building-data {
            left: 100%;
            border-radius: 0 6px 6px 0;
            border-left: none !important;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building7 {
        width: calc((100vh - 70px) * 0.21);
        height: calc((100vh - 70px) * 0.31);
        top: calc((100vh - 70px) * 0.315);
        left: calc((-100vh + 70px) * 0.43);
        border-radius: 6px 0 0 6px;
        .building-overlay {
            border-radius: 6px 0 0 6px;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-right: none !important;
        }
        .building-data {
            left: 100%;
            border-radius: 0 6px 6px 0;
            border-left: none !important;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building8 {
        width: calc((100vh - 70px) * 0.17);
        height: calc((100vh - 70px) * 0.36);
        top: calc((-100vh + 70px) * 0.25);
        left: calc((100vh - 70px) * 0.07);
        border-radius: 0 6px 6px 0;
        .building-overlay {
            border-radius: 0 6px 6px 0;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-left: none !important;
        }
        .building-data {
            right: 100%;
            border-radius: 6px 0 0 6px;
            border-right: none !important;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building9 {
        width: calc((100vh - 70px) * 0.18);
        height: calc((100vh - 70px) * 0.29);
        top: calc((-100vh + 70px) * 0.285);
        left: calc((100vh - 70px) * 0.285);
        border-radius: 0 6px 6px 0;
        .building-overlay {
            border-radius: 0 6px 6px 0;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-left: none !important;
        }
        .building-data {
            right: 100%;
            border-radius: 6px 0 0 6px;
            border-right: none !important;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building10 {
        width: calc((100vh - 70px) * 0.14);
        height: calc((100vh - 70px) * 0.3);
        top: calc((-100vh + 70px) * 0.225);
        left: calc((100vh - 70px) * 0.48);
        border-radius: 0 6px 6px 0;
        .building-overlay {
            border-radius: 0 6px 6px 0;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-left: none !important;
        }
        .building-data {
            right: 100%;
            border-radius: 6px 0 0 6px;
            border-right: none !important;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }

    .building11 {
        width: calc((100vh - 70px) * 0.17);
        height: calc((100vh - 70px) * 0.22);
        top: calc((100vh - 70px) * 0.02);
        left: calc((100vh - 70px) * 0.23);
        border-radius: 0 6px 6px 0;
        .building-overlay {
            border-radius: 0 6px 6px 0;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-left: none !important;
        }
        .building-data {
            right: 100%;
            border-radius: 6px 0 0 6px;
            border-right: none !important;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building12 {
        width: calc((100vh - 70px) * 0.17);
        height: calc((100vh - 70px) * 0.28);
        top: calc((100vh - 70px) * 0.068);
        left: calc((100vh - 70px) * 0.57);
        border-radius: 0 6px 6px 0;
        .building-overlay {
            border-radius: 0 6px 6px 0;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-left: none !important;
        }
        .building-data {
            right: 100%;
            border-radius: 6px 0 0 6px;
            border-right: none !important;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building13 {
        width: calc((100vh - 70px) * 0.19);
        height: calc((100vh - 70px) * 0.27);
        top: calc((100vh - 70px) * 0.31);
        left: calc((100vh - 70px) * 0.11);
        border-radius: 0 6px 6px 0;
        .building-overlay {
            border-radius: 0 6px 6px 0;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-left: none !important;
        }
        .building-data {
            right: 100%;
            border-radius: 6px 0 0 6px;
            border-right: none !important;
            box-shadow: -12px 12px 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building14 {
        width: calc((100vh - 70px) * 0.17);
        height: calc((100vh - 70px) * 0.33);
        top: calc((100vh - 70px) * 0.225);
        left: calc((100vh - 70px) * 0.335);
        border-radius: 0 6px 6px 0;
        .building-overlay {
            border-radius: 0 6px 6px 0;
            box-shadow: 12px 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-left: none !important;
        }
        .building-data {
            right: 100%;
            border-radius: 6px 0 0 6px;
            border-right: none !important;
            box-shadow: -12px 0 24px 0 rgba(32, 15, 54, 0.5);
        }
    }
    .building15 {
        width: calc((100vh - 70px) * 0.6);
        height: calc((100vh - 70px) * 0.3);
        top: calc((100vh - 70px) * 0.005);
        left: calc((-100vh + 70px) * 0.16);
        border-radius: 0 0 6px 6px;
        .building-data {
            bottom: 100%;
            width: 100% !important;
            height: 70% !important;
            box-shadow: 0 -12px 24px 0 rgba(32, 15, 54, 0.5) !important;
        }
        .building-overlay {
            border-radius: 0 0 6px 6px;
            box-shadow: 0 12px 24px 0 rgba(32, 15, 54, 0.5);
            border-top: none !important;
        }
        .building-data {
            bottom: 100%;
            width: 100% !important;
            border-radius: 6px 6px 0 0;
            border-bottom: none !important;
        }
    }
}
