.MuiTooltip-tooltip{
    padding-inline-start: 48px;
    padding-inline-end: 9px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid #EFEEF1;
    border-radius: 12px;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDBDNC40OCAwIDAgNC40OCAwIDEwQzAgMTUuNTIgNC40OCAyMCAxMCAyMEMxNS41MiAyMCAyMCAxNS41MiAyMCAxMEMyMCA0LjQ4IDE1LjUyIDAgMTAgMFpNMTAgMTFDOS40NSAxMSA5IDEwLjU1IDkgMTBWNkM5IDUuNDUgOS40NSA1IDEwIDVDMTAuNTUgNSAxMSA1LjQ1IDExIDZWMTBDMTEgMTAuNTUgMTAuNTUgMTEgMTAgMTFaTTExIDE1SDlWMTNIMTFWMTVaIiBmaWxsPSIjNUFCNDUzIi8+Cjwvc3ZnPgo=") no-repeat;
    background-color: white;
    background-position: 14px 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    max-width: 260px;
}
.MuiTooltip-arrow{
    color: #5AB453;
}
.MuiTooltip-arrow::before{
    border: 2px solid #5AB453;
}

[dir="rtl"] .MuiTooltip-tooltip{
    background-position: right 14px top 12px;
    &.ignore-rtl{
        background-position: 14px 14px;
    }
}