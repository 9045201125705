@import 'variables.module';

.auditorium-archive-page {
    .on-demand-videos-container {
        padding: 0;
        .break {
            display: initial;
        }
        .single-auditorium-archive-container {
            width: 100%;
        }
        .on-demand-videos-list,
        .archive-videos-list {
            display: flex;
            flex-wrap: wrap;
            .on-demand-video,
            .archive-video {
                flex: 0 0 33.3333%;
                .clickable-element {
                    height: auto;
                }
                @media all and (max-width: 1024px) {
                    flex: 0 0 50%;
                }
                @media all and (max-width: 767px) {
                    flex: 0 0 100%;
                }
            }
        }
    }
    .no-videos {
        .no-videos-message-container {
            font-family: $fontFamily, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
            text-align: center;
            color: #2b2b2b;
        }
        @media all and (max-width: 1024px) {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f5f5f5;
        }
    }
    .archive-videos-list {
        display: flex;
        flex-wrap: wrap;

        .archive-video {
            flex: 0 0 33.3333%;
            padding: 0 12px 12px;

            @media screen and (max-width: 1024px) {
                flex: 0 0 50%;
            }
            @media screen and (max-width: 767px) {
                flex: 0 0 100%;
            }

            .clickable-element {
                height: auto;

                .image-preview-video {
                    border: 0.5px solid #ffffff;
                    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
                    border-radius: 8px;
                    font-size: 0;
                    overflow: hidden;
                    height: 0;
                    width: 100%;
                    padding-top: 56.25%;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                    }

                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: linear-gradient(
                            107.43deg,
                            rgba(54, 0, 250, 0.4) 0.46%,
                            rgba(84, 47, 217, 0.356) 52.7%,
                            rgba(52, 18, 172, 0) 98.37%
                        );
                    }

                    .play-button {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 30px;
                    }
                }
            }
        }
    }
}
