@import 'variables.module';

.contacts-page .custom-scroll-container.networking-wrapper {
    border-top: 0;
    padding-top: 0;
    height: auto;
    box-shadow: -6px 6px 14px rgba(16, 16, 16, 0.14);
}
.contacts-page .custom-scroll-container .scrollbar {
    height: 100%;
    min-height: 87px;
}
.contacts-page .custom-scroll-container .scrollbar .box {
    padding-bottom: 0 !important;
}
.contacts-page .custom-scroll-container .received-request-container {
    height: auto;
    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
    text-align: left;
}
.contacts-page .custom-scroll-container .received-request-container:last-of-type {
    border: 0;
}
.contacts-page .user-container .user-name p {
    max-width: 100%;
}
.networking-wrapper .received-requests-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $text-grey;
    margin: 0;
    text-align: start;
    padding-inline-start: 10px;
    padding-top: 10px;
}
.contacts-page {
    max-width: 323px;
}
.contacts-page .search-user-container {
    display: none;
}
.contacts-page .page-title,
.auditorium-page .page-title,
.notifications-page .page-title,
.my-account-component .page-title,
.meetings .page-title {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: $primary;
}
.contacts-page .page-title h3,
.auditorium-page .page-title h3,
.notifications-page .page-title h3,
.my-account-component .page-title h3,
.meetings .page-title h3 {
    margin: 0;
    font-weight: 800;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
}
.contacts-page .friends-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.contacts-page .friends-list li {
    padding-top: 0;
}
.contacts-page .friends-list li:last-child .user-details {
    border: 0;
}
.contacts-page .user-container .user-name p:last-child {
    margin-bottom: 17px;
    margin-inline-end: 10px;
}
.contacts-page .received-request-container .user-container .user-name p:last-child {
    margin-bottom: 26px;
}
.user-details .accept-request,
.user-details .reject-request {
    border: 0;
    background-color: transparent;
    outline: 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    margin: 0;
    font-size: 0;
}
.user-details .accept-request {
    bottom: 16px;
    right: 0;
    padding: 0;
    display: flex;
    align-items: center;
}
.user-details .accept-request span {
    font-family: $fontFamily, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-inline-start: 8px;
}
.user-details .reject-request {
    right: 105px;
    font-size: 0;
    padding: 0;
    bottom: 16px;
    display: flex;
    align-items: center;
}
.user-details .reject-request span {
    font-family: $fontFamily, sans-serif;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-inline-start: 8px;
}
.contacts-page .user-container .user-details {
    padding-bottom: 5px;
}
.contacts-page .contacts-fixed-members div:last-child .user-container .user-details {
    border-bottom: 0;
}
.contacts-page .user-details > div:first-child {
    font-size: 0;
    text-align: start;
}
.contacts-page .user-details .social-button {
    font-size: 0;
}
.contacts-page .buttons-container {
    min-height: 70px;
}
.empty-contacts,
.empty-notifications,
.empty-program {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    background-color: #f5f5f5;
}
.empty-contacts {
    height: 100%;
}
.empty-contacts p,
.empty-notifications p,
.booth-visitors-container[data-empty='true'] .empty-visitors p,
.empty-program p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(137, 137, 137, 0.5);
    margin-top: 0;
    text-align: center;
}
.contacts-page .received-request-container .user-details > div:first-child {
    display: inline-flex;
    flex-direction: column;
    min-height: 76px;
}
.contacts-page .user-container .user-details .user-name p {
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    line-height: 14px;
    color: $dark;
    font-family: $fontFamily, sans-serif;
    text-align: start;
    padding-top: 0;
}
.received-requests-wrapper {
    background-color: #fff;
}
.received-requests-wrapper[data-height='true'] {
    height: 333px;
}
.contacts-page .already-contacts-wrapper {
    height: 485px;
}
.contacts-fixed-members,
.already-contacts-wrapper {
    background-color: #f5f5f5;
    box-shadow: -6px 6px 14px rgba(16, 16, 16, 0.14);
    border-top: 1px solid rgba(137, 137, 137, 0.5);
    padding: 0 10px;
}
.already-contacts-wrapper {
    padding-left: 0;
    padding-right: 0;
}
.already-contacts-wrapper .friends-list {
    padding-left: 10px;
    padding-right: 10px;
}
.dropdown-component .program-dropdown-day h3 {
    display: flex;
    flex-flow: column;
}
.dropdown-component .program-dropdown-day h3 span.day-position {
    font-size: 18px;
    line-height: 22px;
}
.dropdown-component .program-dropdown-day h3 span.date {
    font-size: 12px;
    line-height: 11px;
    color: #f5f5f5;
}
/* Contacts page - mobile */
.contacts-mobile .already-contacts-wrapper .box > div:last-of-type .user-details {
    border: 0;
    padding: 0;
}
.contacts-page .received-request-container > div:last-of-type .user-details {
    border: 0;
}
.contacts-page .received-request-container > div .user-details {
    padding-bottom: 16px;
}
.contacts-mobile .user-container {
    padding-left: 16px;
    padding-right: 16px;
}
.contacts-mobile .already-contacts-wrapper .user-container {
    padding-left: 6px;
    padding-right: 6px;
}
.contacts-mobile .participant-avatar {
    width: 40px;
    height: 40px;
}
.contacts-mobile .already-contacts-wrapper .user-details > div:last-of-type {
    min-height: 41px;
}
.contacts-mobile.dropdown-component
    .already-contacts-wrapper
    .user-details
    .connection.no-action
    .participant-button.open-chat {
    top: 0;
}
.contacts-mobile .user-container .user-details .user-name p {
    font-size: 16px;
}
.contacts-mobile .user-details .reject-request span {
    font-size: 16px;
}
.contacts-mobile .user-details .accept-request span {
    font-size: 16px;
}
.contacts-mobile .user-details .reject-request {
    right: 125px;
}
@media screen and (max-width: 1024px) {
    .dropdown-component .program-dropdown-day h3 {
        text-align: center;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .contacts-mobile .already-contacts-wrapper .box > div:last-of-type .user-details {
        padding-bottom: 5px;
    }
}
