@import 'CSS/variables.module';

.exhibitors-overlay .exhibitors-container .exhibitors-container-title .search-exhibitor-container.poster-list-width {
    width: 470px;
    .MuiInputLabel-outlined {
        transform: translate(14px, 25px) scale(1);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
    }
}

.exhibitors-overlay .exhibitors-container .exhibitors-container-title .search-exhibitor-container {
    label {
        /*
        padding-right: 40px;
        */
        min-height: 32px;
        //display: flex;
        &.MuiInputLabel-shrink {
            padding-right: 0;
        }
    }
    .MuiInputLabel-outlined {
        transform: translate(14px, 25px) scale(1);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
    }
}

.transform-language .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
}

.posters-list-container {
    .filled,
    .Mui-focused {
        legend {
            max-width: calc(100% - 115px);
        }
    }
    label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 30px);
        padding-inline-end: 8px;
        &.MuiInputLabel-shrink {
            font-size: 16px !important;
        }
    }
}
/*
label#search-in-posters-list-label {
    !*
    padding-inline-end: 65px;
    *!
    min-height: 44px;
    max-width: 420px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 13px;
    top: -14px;
    align-items: center;
    justify-content: center;
}*/
#ssearch-in-posters-list-label.Mui-focused {
    top: -11px;
}
.search-posters.ar-safe #search-in-posters-list-label {
    left: auto;
    right: 63px;
}
.search-posters.ar-safe #search-in-posters-list-label.Mui-focused {
    right: -65px;
    left: auto;
    /*  font-size: 17px !important;*/
}
.vote-actions-poster.desktop {
    width: auto;
}

.vote-actions-poster {
    .vote-action span.MuiCheckbox-root .MuiSvgIcon-root {
        width: auto;
    }
    margin-top: -5px;
    width: 90px;
    border-radius: 6px;
    display: flex;
    align-items: flex-start;
    gap: 0;
    /*
    padding: 10px;
    */
    flex-direction: column;
    .vote-action,
    .vote-number {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 110%;
    }
    @media (min-width: 1190px) {
        .vote-number {
            margin-top: -5px;
        }
    }
    .vote-action {
        padding: 0 !important;
        background: transparent;
        position: relative;
    }
    .vote-number {
        color: $primary;
        svg {
            width: 18px;
        }
        svg path {
            fill: $primary;
        }
    }
    span.MuiCheckbox-root {
        padding: 0;
        margin: 0;
    }

    span.vote-text {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        text-transform: uppercase;
        color: $black-variant;
    }
}
.poster-container {
    width: 98%;
}
