@import 'CSS/variables.module';

label#search-in-exhibitors-list-label {
    /*
    padding-inline-end: 65px;
    */
    min-height: 44px;
    max-width: 420px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    /*
    display: flex;
    */
    padding-top: 13px;
    top: -14px;
    align-items: center;
    justify-content: center;
}
#search-in-exhibitors-list-label.Mui-focused {
    top: -11px;
}
.search-exhibitor.ar-safe #search-in-exhibitors-list-label {
    left: auto;
    right: 63px;
}
.search-exhibitor.ar-safe #search-in-exhibitors-list-label.Mui-focused {
    right: -59px;
    left: auto;
    font-size: 20px;
}
.vote-action-single-item {
    height: 37px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 6px;
    position: absolute;
    padding: 10px;
    top: 5px;
    left: 2px;
    span.MuiCheckbox-root {
        padding: 0;
        margin: 0;
    }
    span.MuiCheckbox-root:hover {
        background: transparent;
    }
    span.vote-text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: $black-variant;
    }
}

.vote-action-single-item.vote-action-ar-support {
    right: 2px;
    left: auto;
}

.single-exhibitor {
    position: relative;
}
.exhibitors-overlay .exhibitors-container .exhibitors-list .single-exhibitor .exhibitor-container {
    padding: 30px 16px 16px 16px;
}
.vote-text.text-uppercase.disabled {
    color: $grey;
}
