@import '../../CSS/variables.module';
.on-demand-videos-container {
    font-size: 14px;
    text-align: left;
    padding: 0 25px;
    .auditorium-name {
        font-size: 22px;
        color: $secondary;
        font-weight: 500;
        margin: 15px 0;
        word-break: break-all;
        .break {
            display: none;
        }
        .dates {
            display: block;
            font-size: 14px;
            color: $text-grey;
            padding-top: 5px;
            text-transform: capitalize;
        }
    }
    .no-videos {
        display: flex;
        flex: 1;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: rgba(137, 137, 137, 0.5);
            margin-top: 0;
            margin-bottom: 32px;
            text-align: center;
            padding: 0 16px;
        }
    }
}
