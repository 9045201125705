@import 'variables.module';

// FIX: Platform level focused Mui-Input lines overlapping label fix

//.MuiInputBase-root {
//    font-family: unset !important;
//}
//.MuiInputLabel-outlined.MuiInputLabel-shrink {
//    font-family: $fontFamily, sans-serif;
//}

// END

.organizer-navigation-accordion {
    // style to remove borders and shadows in navigation
    &:before {
        content: none;
    }
    box-shadow: none;

    // style to remove expansion animation
    .MuiAccordionSummary-content.Mui-expanded {
        margin-bottom: 12px;
        margin-top: 12px;
        min-height: 48px;
    }
}

.edit-lang-switch-container {
    padding-right: 15px;
}
#message-id {
    font-family: 'Montserrat', sans-serif;
}
.upload-program-container {
    .uploaded-file {
        margin-top: 16px;
        width: 308px;
        a {
            display: flex;
            align-items: center;
        }
        p {
            height: 20px;
            text-transform: uppercase;
            font-family: $fontFamily, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $dark;
            margin: 0;
        }
        .uploaded-file-name {
            color: $secondary;
            text-transform: lowercase;
            width: 170px;
            margin-left: 3px;
        }
    }
    .remove-file {
        background: none;
        border: none;
        cursor: pointer;
        padding-right: 0;
    }
}

.organizer-dashboard-page {
    background-color: #f5f5f5;
    padding: 110px 32px 0;
    color: $dark;
    min-height: 100vh;

    .organizer-branding-homepage .accordion-title-details .title {
        word-break: break-all;
        width: fit-content;
    }

    .dashboard-with-side-nav {
        .side-navigation {
            position: fixed;
            width: 290px;

            @media screen and (max-height: 850px) {
                position: static;
            }
        }

        .aside-dashboard-container {
            margin-left: 320px;

            @media screen and (max-height: 850px) {
                margin-left: 24px;
            }
        }
    }
    &.not-sticky {
        .side-navigation {
            position: static;
        }
        .aside-dashboard-container {
            margin-left: 24px;
        }
    }
}
.organizer-dashboard-page.has-side-nav {
    padding-left: 0;
}
.organizer-dashboard-page form {
    max-width: initial;
    width: 100%;
    padding: 0;
    margin: 0;
}

/* Navigation styles */
.inner-dashboard-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.organizer-navigation-container {
    height: 40px;
}
.organizer-navigation-container .inner-dashboard-navigation,
.organizer-navigation-container h4 {
    display: none;
}

.organizer-navigation-container {
    margin: 0 auto;
    position: fixed;
    background-color: #fff;
    z-index: 999;
    width: 100%;
    top: 69px;
    overflow-y: hidden;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: rgb(137, 137, 137) #fff0;
    &::-webkit-scrollbar {
        height: 4px;
    }
}
.organizer-navigation-container .dashboard-navigation a {
    padding: 5px;
    padding-bottom: 0;
    span {
        white-space: pre;
    }
}
.organizer-navigation-container .dashboard-navigation a.active .MuiButton-root {
    border-radius: 6px 6px 0 0;
    border-bottom: 2px solid $primary;
}

.organizer-navigation-container .dashboard-navigation a.active button span {
    color: $primary;
}
.organizer-navigation-container .dashboard-navigation a.active button:active span {
    color: white;
}

.organizer-dashboard-page .advanced-options-title {
    margin: 16px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.organizer-dashboard-page .advanced-options-container {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px !important;
    background-color: #fff;
    margin-bottom: 24px;
    padding: 24px 16px 16px;
    .device-title {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 20px;
        margin-top: 40px;
    }
    & > div {
        position: relative;
        & > svg {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
.organizer-dashboard-page .advanced-options-title,
.organizer-dashboard-page .advanced-options-container,
.organizer-dashboard-page .actions-container,
.organizer-dashboard-page.statistics-dashboard .statistics {
    max-width: 1112px;
}
.actions-container-portrait {
    margin-bottom: 20px;
    button,
    label {
        margin-right: 20px;
    }
}
.organizer-dashboard-page .advanced-options-container .options-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: auto;
    &.tablet {
        .cover-img-container {
            max-width: 420px;
            height: 315px;
        }
    }
    &.mobile {
        .cover-img-container {
            max-width: 310px;
            height: 310px;
        }
    }
    &.color-picker {
        align-items: flex-start;
        .input-description-alt {
            padding-top: 0;
        }
    }
}
.organizer-dashboard-page .advanced-options-container .options-container-lang {
    display: flex;
    align-items: center;
    margin: 24px 0;
    width: auto;
}
.organizer-dashboard-page .advanced-options-container .options-container-lang .single-option-container {
    flex: 0 0 520px;
}
.organizer-dashboard-page .advanced-options-container .options-container-lang .single-option-container.switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px 0 30px;
    position: relative;
}
.organizer-dashboard-page .advanced-options-container .options-container.parent-link {
    margin-bottom: 32px;
}
.editor-wrapper {
    position: relative;
}
.organizer-dashboard-page .advanced-options-container .options-container .single-option-container {
    flex: 0 0 520px;
}
.organizer-dashboard-page {
    .single-option-container {
        textarea {
            ~ svg {
                position: relative;
                top: -85px;
            }
        }
    }
}
.branding-participant-registration,
.branding-exhibitor-registration,
.branding-lobby,
.branding-scholar-registration {
    .single-option-container {
        textarea {
            ~ svg {
                position: relative;
                top: -50px;
            }
        }
    }
}
.organizer-dashboard-page .overlay-color-picker {
    width: 100% !important;
    box-shadow: none !important;
    border: 0.5px solid $grey;
    border-radius: 8px !important;
}
.organizer-dashboard-page .overlay-color-picker > div {
    border-radius: 8px 8px 0 0 !important;
}
.organizer-dashboard-page .advanced-options-container .options-container .single-option-container .setting-input {
    margin: 0;
}
.event-dates {
    .MuiOutlinedInput-adornedEnd {
        padding: 0 !important;
    }
    .MuiInputAdornment-root {
        margin-right: 2px;
    }
}
.random-banners-container {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: #fff;
    padding: 16px;
    max-width: 1112px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    margin-bottom: 1.5rem;

    .MuiSvgIcon-root {
        position: relative !important;
        margin-left: 10px;
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.organizer-dashboard-page .advanced-options-container .options-container .input-description {
    font-size: 16px;
    line-height: 24px;
    color: $grey-dark;
    padding-left: 25px;
    .timezone-tooltip-container {
        display: flex;
        align-items: center;
    }
}
//I wrote this code for a long time ago. It breaks the line height of DASHBOARD>SETUP>EVENT MANAGEMENT.
//I commented it.
//If something goes wrong with any line-height, we will look here first. OZ.
// .input-description {
//     line-height: 18px !important;
// }
.input-description-portrait {
    font-size: 16px;
    line-height: 24px;
    color: $text-grey;
    padding-left: 25px;

    p {
        margin: 0;
    }
}
.organizer-dashboard-page .advanced-options-container .options-container .input-description-alt {
    padding-top: 52px;
    font-size: 16px;
    line-height: 24px;
    color: $text-grey;
    padding-left: 25px;
}
.organizer-dashboard-page .advanced-options-container .options-container .input-description-auditorium {
    padding-top: 60px;
    font-size: 16px;
    line-height: 24px;
    color: $text-grey;
    padding-left: 25px;
    p {
        margin: 0;
    }
}
.organizer-dashboard-page .advanced-options-container .options-container .input-description p {
    margin: 0;
}

.organizer-dashboard-page .advanced-options-container .options-container .single-option-container.event-dates {
    display: flex;
    justify-content: space-between;
}
.organizer-dashboard-page
    .advanced-options-container
    .options-container
    .single-option-container.event-dates
    .date-input {
    width: calc(50% - 12.5px);
}
.organizer-dashboard-page .advanced-options-container .options-container.event-url input {
    color: $secondary;
}
.organizer-dashboard-page .advanced-options-container .options-container.event-url p {
    cursor: pointer;
    color: $dark;
    display: flex;
    align-items: center;

    span {
        padding-left: 10px;
    }
}
.clipboard-input-hidden {
    display: none !important;
}
.organizer-dashboard-page .advanced-options-container .inner-options-title {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $text-grey;
    text-transform: uppercase;
    margin-top: 0;
}
.organizer-dashboard-page .advanced-options-container .editor-wrapper {
    border-radius: 4px;
    max-width: 520px;

    .rdw-editor-main {
        padding: 0 14px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
            }
        }
    }
}
.organizer-dashboard-page .advanced-options-container.predefined-fields-container .inner-options-title {
    padding-bottom: 24px;
}

.manage-partner-logo {
    margin-top: 20px;
}
.manage-partner-logo-portrait {
    display: flex;
    flex-direction: row;
}
.organizer-dashboard-page .advanced-options-container .options-container .single-option-container.switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px 0 30px;
    position: relative;
}
.organizer-dashboard-page .child-link {
    .graphic-container {
        width: 16px;
        height: 58px;
        border-left: 2px solid $disabled-text;
        border-bottom: 2px solid $disabled-text;
        position: absolute;
        bottom: 15px;
    }

    .setting-label {
        padding-left: 24px;
    }
}

/* Setup page*/

.organizer-dashboard-page .dashboard-with-side-nav {
    display: flex;
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation {
    flex: 0 0 290px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    min-height: calc(100vh - 116px);
    border-right: 1px solid $disabled-text;
}
.multi-lang-edit-container {
    top: -5px;
    background-color: $white;
    position: absolute;
    width: 250px;
    display: flex;
    left: -5px;
    padding: 4px 4px 4px 8px;
    border: 1px solid $primary;
    border-radius: 4px;
    align-items: center;
}

.organizer-dashboard-page .dashboard-with-side-nav .side-navigation .select-language-container {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
        position: relative;
    }

    svg {
        width: 20px;
        height: 20px;
        margin-inline-end: 10px;
    }

    .selected-language {
        display: flex;
        align-items: center;
        padding: 12px 18px;
        border: 1px solid $disabled-text;
        border-radius: 6px;
        background: #ffffff;
        cursor: pointer;

        svg {
            margin: 0;

            path {
                fill: $dark;
            }
        }
    }

    .languages-list-container {
        position: absolute;
        top: 0;
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid $disabled-text;
        box-shadow: 0 14px 24px rgba(32, 15, 54, 0.25);
        z-index: 1;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                padding: 12px 18px;
                cursor: pointer;

                &:first-of-type {
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }

                &:last-of-type {
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                }

                &.active {
                    background: $primary;
                    color: #ffffff;
                }
            }
        }
    }
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation .select-language-container .MuiSelect-outlined {
    padding: 8px 32px 8px 8px;
    font-size: 14px;
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation .select-language-container span {
    padding-right: 10px;
    display: flex;
    align-items: center;
}
.MuiAccordionSummary-root {
    transition: none !important;
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation .accordion {
    background-color: #f5f5f5;
    &.active {
        background-color: #fff;
        margin: 0;

        .summary {
            border-left: 4px solid $primary;
            min-height: 48px;
            //use fix-height class for normal (max 2 rows) selected menus to have the same height (active with the rest)
            &.fix-height {
                height: 48px;
                max-height: 48px;
            }
        }

        p {
            overflow-wrap: anywhere;
            color: $primary;
            margin-left: -4px;
        }
    }
}

.organizer-dashboard-page .dashboard-with-side-nav .side-navigation p {
    margin: 0;
    padding-left: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
    .help-icon {
        margin-inline-end: 10px;
    }
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation p.disabled .label {
    opacity: 0.5;
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation p.disabled .help-icon {
    color: $primary;
    font-size: 20px;
}
.organizer-dashboard-page .aside-dashboard-container {
    flex: 1;
    margin-left: 24px;
    position: relative;
    max-width: calc(100vw - 320px);
}

.organizer-dashboard-page .subtabs {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 32px;
    margin: 0;
    width: 100%;

    a {
        padding: 16px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        word-break: break-word;
        color: inherit;

        &.active {
            color: $primary;
        }

        .bullet {
            flex: 0 0 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $primary;
            margin-right: 8px;
        }
    }
}

.organizer-dashboard-page .branding-homepage-description {
    form {
        margin-top: 30px;
    }

    .MuiSvgIcon-root {
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }
}
.custom-renders-wrapper {
    display: flex;
    flex-direction: row;

    .render-save-btn {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .MuiFormControl-root {
        min-width: 320px;
        margin-right: 80px;
    }

    .lobby-section .options-container {
        flex-direction: column;
        margin-right: 60px;

        .single-option-container {
            display: flex;
            flex-direction: column-reverse !important;
            margin-top: -220px;

            .actions-container {
                margin: 0 0 12px 0;
            }
        }

        .input-description {
            padding: 0 !important;

            @media all and (max-width: 1250px) {
                padding: 25px 0 0 0 !important;
            }
        }
    }

    .lobby-section .preview-only-image {
        z-index: 1;
        margin-top: 14px;
        margin-right: 60px;
        margin-bottom: 70px;

        .preview-only-btn {
            position: absolute;
            bottom: -50px;
            right: 0;
        }
    }

    .lobby-section .cover-img-container {
        min-width: 620px;
        @media all and (max-width: 1410px) {
            min-width: 520px;
        }
        @media all and (max-width: 1310px) {
            min-width: 420px;
        }
        @media all and (max-width: 1210px) {
            min-width: 320px;
        }
        @media all and (max-width: 1120px) {
            min-width: 100%;
        }
    }

    .auditorium-section .options-container {
        flex-direction: column;
        margin-right: 60px;

        .single-option-container {
            display: flex;
            flex-direction: column-reverse !important;
            margin-top: -220px;

            .actions-container {
                margin: 0 0 12px 0;
            }
        }

        .input-description {
            padding: 0 !important;

            @media all and (max-width: 1250px) {
                padding: 25px 0 0 0 !important;
            }
        }
    }

    .auditorium-section .preview-only-image {
        margin-top: 14px;
        margin-right: 60px;
        margin-bottom: 70px;

        .preview-only-btn {
            position: absolute;
            bottom: -50px;
            right: 0;
        }
    }

    .auditorium-section .cover-img-container {
        min-width: 620px;
        @media all and (max-width: 1410px) {
            min-width: 520px;
        }
        @media all and (max-width: 1310px) {
            min-width: 420px;
        }
        @media all and (max-width: 1210px) {
            min-width: 320px;
        }
        @media all and (max-width: 1120px) {
            min-width: 100%;
        }
    }
}
.organizer-dashboard-page .advanced-options-container .branding-background-image-container .options-container {
    align-items: flex-start;
}
.organizer-dashboard-page
    .advanced-options-container
    .branding-background-image-container
    .options-container
    .sublabel {
    display: block;
    margin-top: 20px;
}
.branding-background-image-container .actions-container {
    margin-top: 20px;
    height: 40px;
    display: flex;
    align-items: center;
}
.branding-background-image-container .cover-img-container {
    max-width: 100%;
    height: 195px;
}
.branding-background-image-container .cover-img-container-portrait {
    width: 179px;
    height: 341px;
}
.branding-background-image-container .cover-img-container > img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    border-radius: 8px;
}
.branding-background-image-container .cover-img-container-portrait > img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    border-radius: 8px;
}
.organizer-dashboard-page
    .advanced-options-container
    .branding-background-image-container
    .options-container
    .input-description {
    padding-top: 87px;
}
p.input-description-text {
    display: flex;
    svg {
        margin-inline-end: 10px;
    }
}
.branding-background-image-container .image-status-container {
    margin-top: 20px !important;
    color: $dark;
}
.branding-background-image-container .uploaded-file .file-name {
    color: $secondary;
}
.organizer-dashboard-page .aside-dashboard-container .advanced-options-title .archived-event-name {
    word-break: break-word;
}
.organizer-dashboard-page .actions-container {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 50px;
}
.organizer-dashboard-page .aside-dashboard-container .list-wrapper h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.organizer-dashboard-page .aside-dashboard-container .list-wrapper .single-banner-h3 {
    width: 100%;
    .actions-container {
        padding: 0;
    }
}
.single-banner-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    button {
        margin-top: 10px;
    }
}
.banner-container-500 {
    min-width: 500px;
}
.no-bottom-margin {
    margin-top: 8px;
}
.single-banner-save {
    margin-top: 16px !important;
    padding: 10px 16px !important;
    border-radius: 6px !important;
}
.drag-and-drop-container {
    flex: 0 0 308px !important;
    max-width: 308px;
    .or-wrapper {
        text-align: center;
        padding-bottom: 16px;
        font-weight: 600;
        font-family: $fontFamily, sans-serif;
    }
}
.single-banner-image,
.no-bottom-margin {
    margin-bottom: 0 !important;
    min-width: 297px;
}
.single-banner-input {
    min-width: 297px !important;
}
.organizer-dashboard-page .branding-background-image-container .actions-container {
    justify-content: flex-start;
    padding: 0;
    button,
    label {
        margin-right: 20px;
    }
}
.organizer-dashboard-page .branding-background-image-container .actions-container-noP {
    button,
    label {
        margin-right: 20px;
    }
}
.organizer-dashboard-page .program-entry {
    padding: 0;
    margin: 0;
    border: none;
    width: 100%;
}
.organizer-dashboard-page .aside-dashboard-container .participants-list-wrapper {
    background-color: #fff;
}

.timeslot-accordion {
    padding: 18px 16px !important;
}
.timeslot-accordion .summary {
    padding: 0;
}
.organizer-dashboard-page .timeslot-title-details,
.organizer-dashboard-page .field-title-details {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}
.organizer-dashboard-page .timeslot-title-details p,
.organizer-dashboard-page .field-title-details p {
    margin: 0;
}
.organizer-dashboard-page .timeslot-title-details p.title,
.organizer-dashboard-page .field-title-details p.title {
    color: $text-grey;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    flex: 0 0 50%;
    word-break: break-word;
}

.organizer-dashboard-page .timeslot-title-details .hours {
    display: flex;
}
.organizer-dashboard-page .timeslot-title-details .hours p {
    display: flex;
    flex-flow: column;
    margin: 0 10px;
}
.organizer-dashboard-page .timeslot-title-details .hours span {
    color: black;
    font-size: 16px;
}
.organizer-dashboard-page .timeslot-title-details .hours .label {
    color: $text-grey;
    font-weight: 500;
    font-size: 14px;
}
.organizer-dashboard-page .timeslot-title-details .expand-label,
.organizer-dashboard-page .field-title-details .expand-label {
    display: flex;
    align-items: center;
}
.branding-participant-registration .field-wrapper .switch-container,
.branding-scholar-registration .field-wrapper .switch-container,
.branding-exhibitor-registration .field-wrapper .switch-container {
    padding-right: 12px;
}
.organizer-dashboard-page .timeslot-wrapper,
.organizer-dashboard-page .field-wrapper {
    flex: 1;
}
.organizer-dashboard-page .field-wrapper .custom-fields-container {
    padding-left: 30px;
}
.organizer-dashboard-page .advanced-options-container .speakers-container .inner-options-title,
.organizer-dashboard-page .advanced-options-container .branding-background-image-container .inner-options-title {
    padding: 50px 0 30px 0;
}
.organizer-dashboard-page button.add-speaker,
.organizer-dashboard-page button.remove-item {
    display: flex;
    align-items: center;
    color: $dark !important;
    text-transform: uppercase;
}
.organizer-dashboard-page.statistics-dashboard .statistics {
    padding-bottom: 40px;
    position: relative;
    .table-statistics-container {
        display: flex;
        flex: 0 0 50%;
        flex-direction: column;
    }
    &.old-statistics {
        padding-top: 24px;
        padding-bottom: 0;
        .statistics-title {
            width: 92%;
        }
        .statistics-table {
            flex: 0 0 100%;
        }
    }
    &.archived-statistics {
        &.is-loading {
            .advanced-options-container {
                max-height: calc(100vh - 200px);
            }
        }
        .advanced-options-container {
            position: relative;
            .spinner {
                position: absolute;
                margin: 0;
            }
        }
    }
}
.organizer-dashboard-page .statistics .statistics-title {
    display: flex;
    justify-content: space-between;
    width: 46%;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 16px 0;
}
@media all and (max-width: 1320px) {
    .organizer-dashboard-page .statistics .statistics-title {
        width: 45%;
    }
}
.organizer-dashboard-page .statistics .statistics-title .button {
    cursor: pointer;
}
.organizer-dashboard-page .statistics .statistics-part {
    display: flex;
    justify-content: space-between;
    margin: 0;
    &.enhanced-statistics {
        justify-content: flex-start;
        .statistics-details {
            width: 50%;
            .statistics-table {
                margin-top: 0;
            }
        }
        .statistics-chart {
            margin-top: 24px;
            flex: initial;
        }
    }
    .statistic-column {
        display: flex;
        flex-flow: column;
        flex: 0 0 50%;
        .statistics-chart {
            flex: auto;
            margin-bottom: 32px;
        }
    }
}
.statistics-part .statistics-table {
    flex: 0 0 50%;
    padding: 0 12px;
}
.statistics-part .statistics-table ul {
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}
.organizer-dashboard-page .statistics .statistics-part .statistics-header {
    background-color: $light-grey;
}
.organizer-dashboard-page .statistics .statistics-part li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
    color: #2b2b2b;
    font-weight: 600;
    font-size: 14px;
    min-height: 50px;
}
.organizer-dashboard-page .statistics .StatisticUsers__userList li {
    padding-top: 4px;
    padding-bottom: 0px;
}
.organizer-dashboard-page .statistics .statistics-chart {
    flex: 0 0 50%;
    max-height: 330px;
    padding: 0 12px;
}
.organizer-dashboard-page .statistics .statistics-chart .chart {
    position: relative;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 16px;
    height: 100%;
    max-width: 532px;
    canvas {
        height: 100% !important;
        width: auto !important;
        margin: 0 auto;
    }
}

.organizer-dashboard-page .smallContainer,
.program-dashboard.organizer-dashboard-page .smallContainer[data-empty='true'] {
    max-width: 100%;
    min-height: calc(100vh - 130px);
}
.program-dashboard.organizer-dashboard-page .smallContainer[data-empty='false'] {
    height: auto;
}
.program-dashboard.organizer-dashboard-page .smallContainer[data-empty='false'] .dashboard-wrapper,
.program-dashboard.organizer-dashboard-page .smallContainer[data-empty='true'] .dashboard-wrapper {
    height: auto;
}
.organizer-dashboard-page .smallContainer[data-empty='false'] .program-section,
.organizer-dashboard-page .smallContainer[data-empty='true'] .program-section {
    padding-bottom: 30px;
    margin: 0;
}
.organizer-dashboard-container .aside-dashboard-container .spinner {
    position: fixed;
    width: 100vw;
    height: 100vh;
}
.navigation-container {
    padding-left: 77px;
}
.organizer-dashboard-page .advanced-options-container .action-container {
    margin: 20px 0;
    text-align: left;
}
.settings-dashboard .advanced-options-container .action-container {
    margin: 20px 0 0;
}
.organizer-dashboard-page .field-wrapper .action-container,
.organizer-dashboard-page .advanced-options-container.predefined-fields-container .action-container {
    margin: 20px 0 0 0;
}
.organizer-dashboard-page {
    .branding-section {
        .single-option-container {
            max-width: 800px;
            margin: 30px auto 0;
        }
    }
}
.dashboard-navigation {
    display: flex;
    align-items: end;
    height: 40px;
}

.speakers-dashboard .speakers-list .scrollbar-container .speaker > div p:first-of-type {
    font-size: 16px;
    margin-bottom: 3px;
}
.showfloor-dashboard .box > div {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.showfloor-dashboard .box > div > p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.showfloor-dashboard .box > div button,
.remove-representative,
.remove-item,
.notify-speaker,
.add-speaker-button,
.add-to-booth,
.cancel-button {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    padding: 0;
    margin-left: 30px;
}
.showfloor-dashboard .box > div > div {
    flex: 1;
    display: inline-flex;
    justify-content: space-between;
    margin-left: 40px;
}

.showfloor-dashboard .boothCompany,
.showfloor-dashboard .boothEmail {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    span {
        margin-inline-end: 15px;
    }
}
.select-showfloor label {
    background-color: #fff;
    padding: 0 5px 0 3px;
}
.showfloor-dashboard .boothEmail a {
    color: $secondary;
    font-size: 14px;
    font-weight: bold;
    word-break: break-all;
}
.buttons-exhibitor .remove-exhibitor,
.remove-representative,
.remove-item {
    color: $error-light;
}
.buttons-exhibitor .change-exhibitor,
.notify-speaker {
    color: $primary;
}
.notify-speaker-disabled {
    color: grey;
    cursor: default;
}
.add-exhibitor,
.add-speaker-button,
.add-to-booth {
    color: $secondary;
}
.showfloor-dashboard .box > div:last-of-type {
    padding-bottom: 0;
}
.program-dashboard.organizer-dashboard-page {
    height: auto;
    .program-section {
        &.is-loading {
            min-height: 200px;
            .spinner {
                margin-top: 0;
                //& > div {
                //    margin-top: 20%;
                //}
            }
        }
    }
}
.organizer-dashboard-page .top-container {
    position: fixed;
}
.program-dashboard.organizer-dashboard-page .smallContainer {
    height: auto;
}
.program-dashboard.organizer-dashboard-page .program-section h3,
.program-dashboard.organizer-dashboard-page .program-section h2 {
    padding-bottom: 30px;
    margin: 0;
    color: grey;
    font-family: $fontFamily, sans-serif;
    font-size: 22px;
    letter-spacing: 2px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.program-dashboard.organizer-dashboard-page .program-section h2 {
    font-size: 32px;
    padding-top: 25px;
    align-items: center;
    padding-bottom: 25px;
    padding-right: 60px;
}
.program-dashboard.organizer-dashboard-page.display-program .auditorium-details {
    flex-flow: row;
}
.program-dashboard.organizer-dashboard-page.display-program .program-section h2 {
    padding-right: 30px;
    flex: 1;
}
.program-dashboard.organizer-dashboard-page.display-program .import-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.program-dashboard.organizer-dashboard-page .program-section h2 .auditorium-navigation-button {
    padding: 7px;
    font-size: 0;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px;
}
.program-dashboard.organizer-dashboard-page .program-section h2 .auditorium-navigation-button:hover {
    border-color: black;
}
.program-dashboard.organizer-dashboard-page .program-section h2 .auditorium-navigation-button.next {
    transform: rotate(180deg);
}
.program-dashboard.organizer-dashboard-page .program-section h3 > div {
    margin: 0;
}
.speakers-list ul,
.representatives-list ul,
.showfloor-list {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
}
.speakers-list ul li,
.representatives-list ul li,
.showfloor-list ul li {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.speakers-list ul li > div {
    flex: 1;
}
.speakers-list ul li button:first-of-type {
    margin-right: 10px;
}
.representatives-list > p,
.speakers-list > p {
    font-weight: 600;
    font-size: 16px;
    color: #000;
}
.representatives-list ul,
.speakers-list ul {
    margin: 15px 0;
}
.representatives-list ul li,
.speakers-list ul li {
    justify-content: space-between;
}
.representatives-list ul li > div,
.speakers-list ul li > div {
    flex: 1;
    margin-right: 20px;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}
.speakers-list ul li.speaker div > p {
    margin: 0;
}
.add-speaker-button {
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
    font-family: $fontFamily, sans-serif;
}
#add-exhibitor-dialog #form-dialog-title,
#add-speaker-dialog #form-dialog-title,
#showfloor-position #form-dialog-title {
    background-color: $primary;
    padding: 0;
}
#add-exhibitor-dialog #form-dialog-title h2,
#add-speaker-dialog #form-dialog-title h2,
#showfloor-position #form-dialog-title h2 {
    color: #fff;
    text-transform: uppercase;
    padding: 10px 20px;
    text-align: center;
}
.add-exhibitors-list {
    padding-top: 25px;
}
.add-exhibitors-list > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 0;
}
.add-exhibitors-list > div:last-of-type {
    padding-bottom: 0;
}
#add-exhibitor-dialog .cancel-button,
#add-speaker-dialog .cancel-button,
#showfloor-position .cancel-button {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: $text-grey;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 0;
    font-family: $fontFamily, sans-serif;
}
#showfloor-position > div > div {
    max-width: initial;
}

#add-speaker-dialog #standard-search {
    padding-bottom: 10px;
}
.participants-search-wrapper.representatives-list-wrapper {
    height: 100px;
    padding-top: 10px;
    padding-bottom: 0;
    width: 520px;
}
.corg-wrapper {
    .timeslot-title-details {
        .user-container {
            flex: 0 0 40%;
            .participant-avatar {
                margin: auto 0;
                width: 36px;
                height: 36px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                box-shadow: none;
                box-sizing: border-box;
                border-radius: 50%;
                border: 0.5px solid #ffffff;
                position: relative;
                -webkit-margin-end: 10px;
                margin-inline-end: 10px;
                filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
                -webkit-filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
                -ms-flex-item-align: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
            }
            .user-details {
                border: none;
                padding: 0;
                .user-name {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .user-name-top {
                        margin: 0;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        text-transform: capitalize;
                        line-height: 95.9%;
                        color: $dark;
                        overflow-wrap: break-word;
                        text-align: start;
                    }
                    p:not(.user-name-top) {
                        margin: 4px 0 0 0;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 95.9%;
                        word-break: break-word;
                        word-wrap: break-word;
                        color: $grey-dark;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .timeslot-title-details .user-container {
            flex: 0 0 36%;
        }
    }
    @media screen and (max-width: 1200px) {
        .timeslot-title-details .user-container {
            flex: 0 0 24%;
        }
    }
    #align-labels {
        width: auto;
    }
    .MuiPaper-root {
        border-radius: 6px !important;
        border: 1px solid #cacbd0 !important;
        background-color: $white-variant;
        &:before {
            background-color: transparent;
        }
    }
    .MuiPaper-elevation1 {
        box-shadow: none;
    }
    .switch-container {
        white-space: nowrap;
    }
    @media screen and (max-width: 1400px) {
        .switch-container:last-of-type {
            margin-right: 20px;
        }
    }
    .MuiButtonBase-root:is(.MuiAccordionSummary-root) {
        cursor: default !important;
        padding: 0px 8px;
    }
    .MuiAccordionDetails-root {
        padding: 0px 8px 14px;
    }
    .MuiAccordionSummary-content {
        margin: 7px 0;
    }
    .MuiButton-root {
        padding: 8px 12px;
    }
    .acces-tables-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .access-table-wrapper:first-child {
            margin-right: 24px;
        }
        .access-table-wrapper {
            width: 100%;
            max-width: 512px;
            border-radius: 6px;
            background-color: $light-grey;
            border: 1px solid $disabled-text;
            box-sizing: border-box;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            .access-table-header {
                width: 100%;
                padding: 14px 34px 14px 14px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $disabled-text;
                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.4px;
                    color: $grey-dark;
                }
            }
            .access-table-option {
                width: 100%;
                padding: 3px 28px 3px 14px;
                background-color: $white;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $disabled-text;
                .dashboard-label {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 95.9%;
                    color: $dark;
                }
                @media (hover: hover) {
                    &:focus {
                        outline: none;
                    }
                    &.focus-visible {
                        outline: none;
                        background-color: $secondary-light;
                    }
                }
            }
            .access-table-option:last-child {
                border-bottom-right-radius: 6px;
                border-bottom-left-radius: 6px;
            }
            .access-table-option.unavailable {
                background-color: $light-grey;
                .dashboard-label {
                    cursor: default;
                    color: $muiDisabled;
                }
            }
        }
    }
    .user-component-closed:not(.MuiAccordion-root.Mui-expanded) {
        margin-bottom: 14px;
    }
}
.participants-search-wrapper.corg-list-wrapper {
    z-index: 999;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    border: 1px solid $disabled-text;
    border-top-width: 0;
    position: absolute;
    height: 218px;
    padding: 0;
    width: 515px;
    left: 2.5px;
    padding: 0 0 0 4px;
    overflow-y: hidden;
    em {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 95.9%;
        color: $grey-dark;
        margin-left: 2px;
    }
    .friends-list {
        padding-top: 10px;
    }
}
.MuiFormControl-marginNormal.corg-search {
    margin: 0;
}

.audience-list-search-wrapper {
    height: 460px;
}
.audience-list-search-wrapper .audience-list {
    list-style-type: none;
    padding: 0;
}
.audience-list-search-wrapper .audience-list .info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.audience-list-search-wrapper .audience-list .info-container .user-avatar {
    flex: 0 0 35px;
}
.audience-list-search-wrapper .audience-list .info-container .info-wrapper {
    flex: 1;
}
.showfloor-dashboard.dashboard-wrapper .see-position-booths {
    text-align: center;
    display: block;
    cursor: pointer;
    padding-top: 10px;
    color: $text-grey;
}
.positioning-image-container {
    width: 95vh;
    height: 50vh;
    padding-top: 24px;
}
.positioning-image-container img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
.showfloor-list li,
.showfloor-dashboard .list-header {
    padding: 0 20px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.showfloor-dashboard .list-header {
    padding: 20px;
    margin: 0;
    justify-content: flex-start;
}
.showfloor-list li > p {
    width: 150px;
}
.showfloor-list li:last-of-type {
    padding-bottom: 0;
}
.showfloor-list li > div,
.showfloor-dashboard .list-header .buttons-exhibitor,
.showfloor-dashboard .boothCompany,
.showfloor-dashboard .boothEmail,
.showfloor-dashboard .select-showfloor {
    flex: 1 1;
    display: inline-flex;
    justify-content: space-between;
    width: 33.3%;
}
.showfloor-dashboard {
    .button-col {
        justify-content: flex-end;
        max-width: 260px;
    }
    .button-col.select-box-helper {
        justify-content: flex-start;
    }
    .list-header {
        .buttons-exhibitor {
            span {
                padding-left: 12px;
            }
        }
    }
}
.showfloor-list .exhibitor button {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    padding: 0;
    /*
    margin-left: 30px;
    */
}
.showfloor-list {
    position: relative;
}
.showfloor-list .spinner {
    background-color: rgba(255, 255, 255, 0.6);
}
.showfloor-list .exhibitor.custom-booth {
    position: relative;
    border: 1px solid #ccc;
    padding: 20px 20px 150px;
}
.showfloor-list .exhibitor.custom-booth .spinner {
    justify-content: center;
}
.showfloor-list .exhibitor.custom-booth .error-text {
    position: absolute;
    width: auto;
    left: 20px;
    bottom: 5px;
}
.showfloor-list .exhibitor.custom-booth .upload-custom-booth-container {
    position: absolute;
    bottom: 30px;
    display: flex;
    justify-content: space-between;
    width: 600px;
}
.showfloor-list .exhibitor.custom-booth .upload-custom-booth-container label {
    width: 170px;
    height: 100px;
    background-color: #eee;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $secondary !important;
}

.showfloor-list .exhibitor.custom-booth .upload-custom-booth-container img {
    height: 100px;
    width: auto;
    margin-left: 80px;
}
.organizer-dashboard-page.statistics-dashboard .building-options-container {
    max-width: 522px;
    padding: 16px 0 0;
}
.organizer-dashboard-page .statistics .name-action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0 30px;
    h2 {
        word-break: break-word;
    }
}
.organizer-dashboard-page .statistics .navigation-button {
    padding: 7px;
    font-size: 0;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
}
.organizer-dashboard-page .statistics .navigation-button.next {
    transform: rotate(180deg);
}
.organizer-dashboard-page .statistic-title {
    margin-top: 30px;
    padding-left: 15px;
}
.organizer-dashboard-page .statistics-part ul {
    width: 100%;
    padding-left: 0;
}
.organizer-dashboard-page .inner-statistic-container {
    flex-flow: column;
    align-items: flex-start;
    margin-top: 8px;
}
.organizer-dashboard-page .inner-statistic-container .statistics-part {
    width: 100%;
}
.organizer-dashboard-page.statistics-dashboard .online-users-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 522px;
}
.organizer-dashboard-page.statistics-dashboard .refresh-button {
    display: flex;
    align-items: center;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $secondary;
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    padding: 0;
}
.organizer-dashboard-page.statistics-dashboard .refresh-button svg {
    margin-right: 10px;
}
.organizer-dashboard-page.statistics-dashboard .online-users-container .users-number {
    font-size: 20px;
    padding: 0 10px;
    color: $primary;
    font-weight: bold;
    letter-spacing: 1px;
}
.program-dashboard.organizer-dashboard-page .auditorium-details {
    display: flex;
    flex-flow: column;
    position: relative;
}
.program-dashboard.organizer-dashboard-page .auditorium-details .edit-auditorium-details-button {
    position: absolute;
    right: 0;
    top: 38px;
    cursor: pointer;
}
.program-dashboard.organizer-dashboard-page .auditorium-details .image-container {
    width: 300px;
    height: 180px;
    border-radius: 15px;
    padding: 10px;
    background-color: #d3d3d35e;
    margin-top: 15px;
}
.program-dashboard.organizer-dashboard-page .auditorium-details .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.program-dashboard.organizer-dashboard-page .auditorium-details .manage-booth-banners {
    padding: 0;
    margin-top: 20px;
}
.program-dashboard.organizer-dashboard-page .auditorium-details .manage-booth-banners button,
.organizer-dashboard-page .branding-section .manage-partner-logo button,
.organizer-dashboard-page .branding-section .manage-exhibitor-logo button,
.organizer-dashboard-page .branding-section .manage-lobby-logo button {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #3f51b5;
    padding-right: 0;
    text-transform: uppercase;
}
.branding-homepage-partners .single-previous-event-container {
    h3 .previous-event-name {
        word-break: break-word;
    }
    div p {
        word-break: break-word;
    }
}
.program-dashboard.organizer-dashboard-page .auditorium-details .manage-booth-banners .remove-file,
.organizer-dashboard-page .branding-section .manage-partner-logo .remove-file,
.organizer-dashboard-page .branding-section .manage-exhibitor-logo .remove-file {
    color: $error-light;
}
.branding-section .partners-container,
.branding-section .exhibitors-container,
.branding-section .banners-container,
.branding-section .previous-events-container {
    margin-top: 50px;
}
.branding-section .single-partner-container h3,
.branding-section .single-exhibitor-container h3,
.branding-section .single-banner-container h3,
.branding-section .single-previous-event-container h3 {
    max-width: 75%;
    word-break: break-word;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    margin-bottom: 30px;
}
.branding-section .single-partner-container,
.branding-section .single-exhibitor-container,
.branding-section .single-banner-container,
.branding-section .single-previous-event-container {
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 2px solid #ccc;
}
.branding-section .single-partner-container p,
.branding-section .single-exhibitor-container p {
    margin-bottom: 10px;
}
.logos-list-container .lobby-logos-container .single-lobby-logo-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 50px;

    .remove-file {
        color: $error-light;
    }

    .cover-img-container {
        margin-bottom: 0;
        flex: 1;
    }

    .cover-img-container-portrait {
        margin-bottom: 0;
        flex: 1;
    }
}
.manage-lobby-logo {
    margin-top: 50px;
}
.branding-section .single-partner-container p > span.label,
.branding-section .single-exhibitor-container p > span.label,
.branding-section .single-previous-event-container p > span.label {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #3f51b5;
    padding-right: 10px;
    min-width: 110px;
    display: inline-block;
}
.organizer-dashboard-page .branding-section {
    max-width: 800px;
    padding: 20px;
    margin: 0 auto;
}
.organizer-dashboard-page .branding-section .inner-tabs {
    margin-top: 50px;
}
.organizer-dashboard-page .branding-section form {
    max-width: initial;
    padding: 0;
    margin-bottom: 50px;
}
.branding-section .manage-partner-logo .cover-img-container,
.branding-section .manage-exhibitor-logo .cover-img-container,
.branding-section .manage-lobby-logo .cover-img-container {
    margin-bottom: 0;
    width: 300px;
    height: 200px;
}
.branding-section .manage-partner-logo .cover-img-container-portrait,
.branding-section .manage-exhibitor-logo .cover-img-container-portrait,
.branding-section .manage-lobby-logo .cover-img-container-portrait {
    margin-bottom: 0;
    width: 300px;
    height: 200px;
}
.branding-section .manage-partner-logo .image-wrapper,
.branding-section .manage-exhibitor-logo .image-wrapper,
.branding-section .manage-lobby-logo .image-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto !important;
}
.branding-section .manage-partner-logo .image-wrapper {
    flex-flow: column;
    align-items: flex-start;
}
.branding-section .manage-partner-logo .image-wrapper button {
    line-height: 36px;
}
.branding-section .manage-partner-logo .cover-img-container {
    margin: 20px 0;
}
.branding-section .manage-partner-logo .cover-img-container-portrait {
    margin: 20px 0;
}
.branding-section .sublabel {
    font-size: 12px;
    display: block;
    color: $text-grey;
}
.branding-section .background-image-container {
    display: flex;
    justify-content: space-between;
}
.branding-section .color-picker {
    display: flex;
    flex-flow: column;
}
.branding-section .color-picker .chrome-picker {
    margin: 20px 0;
    max-width: 182px;
}
.branding-section .color-picker p {
    line-height: 24px;
}
.organizer-dashboard-page p.list-empty-text {
    margin: 20px;
}
.participants-table-wrapper {
    margin-bottom: 30px;
    border: 1px solid $disabled-text;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
    border-radius: 0 0 6px 6px;
}
.filters-wrapper-general {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 15px;
    background-color: #fff;
    margin: 30px 0;
    border: 1px solid #ccc;
    border-radius: 8px;

    div {
        margin-right: 1rem;
    }

    .MuiSelect-root {
        margin: 0;
    }
}
.participants-list-header > div {
    margin: 0 10px;
    padding: 20px 0;
    text-transform: uppercase;
    font-size: 12px;
    color: $text-grey;
}
.participants-list-wrapper .single-participant-row .name {
    width: 19.7%;
}
.participants-list-wrapper .single-participant-row .company {
    width: 18%;
}
.participants-list-wrapper .single-participant-row .email {
    width: 19.7%;
}
.participants-list-wrapper .single-participant-row .phone {
    width: 8%;
}
.participants-list-wrapper .single-participant-row .registration-date {
    width: 14.18%;
}
.participants-list-wrapper .single-participant-row .role,
.participants-list-wrapper .single-participant-row .registration-date {
    margin: 0 14px !important;
}
.participants-list-wrapper .single-participant-row .invitation-code {
    margin: 0 5px !important;
}
.participants-list-wrapper .single-participant-row .registration-type {
    width: 8%;
}
.participants-list-wrapper .single-participant-row .access-package {
    padding: 20px 0;
    width: 11%;
}
.participants-list-wrapper .single-participant-row .invitation-code {
    width: 7.5%;
}
.participants-list-wrapper .single-participant-row .role {
    width: 17.19%;
}
.participants-list-wrapper .single-participant-row .delete {
    width: 73px;
    text-align: center;
    margin-left: auto;
}
.participants-list-wrapper .single-participant-row .access-package .MuiInput-underline:before,
.participants-list-wrapper .single-participant-row .access-package .MuiInput-underline:after {
    display: none !important;
}
.participants-list-wrapper .single-participant-row .access-package .MuiSelect-select:focus {
    background-color: transparent !important;
}
.participants-list-wrapper .single-participant-row .access-package .MuiInputBase-root {
    max-width: 100%;
}
.participants-list-wrapper .single-participant-row .access-package .MuiSelect-selectMenu {
    font-family: $fontFamily, sans-serif;
}
.snackbar div {
    margin-left: auto;
    margin-right: auto;
}
.snackbar-success-message {
    display: block;
    width: 100%;
    text-align: center;
}
.settings-dashboard .advanced-options-container .big-centered-button {
    width: 100%;
    max-width: 440px;
    height: 48px;
    margin: 0 auto 10px;
    display: block;
}
.program-dashboard.organizer-dashboard-page .archived-event-videos-container .single-video-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}
.program-dashboard.organizer-dashboard-page .archived-event-videos-container .single-video-container .timeslot-details {
    padding-right: 30px;
}
.program-dashboard.organizer-dashboard-page .archived-event-videos-container .single-video-container .small-label {
    display: block;
}
.program-dashboard.organizer-dashboard-page
    .archived-event-videos-container
    .single-video-container
    .timeslot-details
    p {
    margin-bottom: 10px;
}
.program-dashboard.organizer-dashboard-page
    .archived-event-videos-container
    .single-video-container
    .speakers-container
    .speaker {
    display: inline-block;
    padding-right: 5px;
}
.program-dashboard.organizer-dashboard-page
    .archived-event-videos-container
    .single-video-container
    .image-preview-video {
    width: 250px;
}
.program-dashboard.organizer-dashboard-page
    .archived-event-videos-container
    .single-video-container
    .image-preview-video
    img {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
}
.program-dashboard.organizer-dashboard-page
    .archived-event-videos-container
    .single-video-container
    .image-preview-video
    .actions-container {
    display: flex;
    justify-content: space-between;
}
.invitations-wrapper {
    max-width: 1112px;
}
.invitation-codes-action-wrapper {
    margin: 30px 0;
}
.invitation-codes-action-wrapper button {
    margin-right: 30px;
}
.invitation-codes-list {
    list-style: none;
    padding: 0;
    border: 1px solid $disabled-text;
    border-radius: 6px;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
}
.invitation-codes-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10.5px 0 16px 10.5px;
    border-bottom: 1px solid $disabled-text;
    background-color: #ffffff;

    &:first-of-type {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    &:last-of-type {
        border: 0;
    }
}
.invitation-codes-list li:hover {
    background-color: $light-grey;
}
.invitation-codes-list li.list-header {
    text-transform: uppercase;
    color: $text-grey;
    background: $light-grey;
    padding: 12.5px 0 16px 12.5px;

    svg {
        width: 17px;
        height: 9px;
        margin-inline-start: 8px;
    }
}
.invitation-codes-list li.list-header:hover {
    background: $light-grey;
}
.invitation-codes-list li .invitation-details {
    display: flex;
    width: 100%;

    #light {
        font-family: $fontFamily, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.4px;
    }
}
.invitation-codes-list li .invitation-details p {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 95.9%;
    text-align: right;
    width: 8%;
    margin: 0;
    min-height: 19px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:first-of-type {
        justify-content: flex-start;
    }
}
.invitation-codes-list li .invitation-details p.code {
    margin-left: 7px;
    width: 66%;
}
.ReactVirtualized__Table__rowColumn {
    margin: 0 !important;
}
.virtualized-list-paper {
    width: 100%;
    height: 51.3vh;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: $disabled-text;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    direction: rtl;
    overflow: hidden;
    // Chrome and others
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(137, 137, 137);
        border-radius: 10px;
    }
    // Firefox specific
    scrollbar-color: rgb(137, 137, 137) #f5f5f5;
}
.resize-triggers,
.resize-triggers > div,
.contract-trigger:before {
    direction: ltr;
    writing-mode: horizontal-tb;
}
.invitation-spinner-container {
    height: 57vh;
    .spinner {
        max-width: 1112px;
        background-color: transparent;
    }
}
.MuiTableCell-alignRight {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
}
.add-tab-button {
    margin-top: 10px;
}
.invitation-actions {
    margin-left: 1.3vw;

    @media all and (min-width: 1700px) {
        margin-left: 1vw;
    }
    @media all and (max-width: 1300px) {
        margin-left: 0.9vw;
    }
    @media all and (max-width: 1290px) {
        margin-left: 1vw;
    }
    @media all and (max-width: 1200px) {
        margin-left: 0.5vw;
    }
    @media all and (max-width: 1100px) {
        margin-left: 0.2vw;
    }
    @media all and (max-width: 1024px) {
        margin-left: 0;
    }

    &:hover {
        svg {
            path:not(:last-child) {
                fill: $error-light;
            }
        }
    }

    &:disabled {
        cursor: default;
        svg {
            path {
                fill: $grey;
            }
        }
    }
}
.ReactVirtualized__Table__Grid:focus {
    outline: none;
}
.audiences-list-wrapper .audiences-actions,
.campaigns-list-wrapper .campaigns-actions {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.audiences-list,
.campaigns-list {
    max-width: 1112px;
    font-weight: 500;
    margin-bottom: 30px;
    background-color: white;
    border: 1px solid $disabled-text;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
    border-radius: 6px 6px 6px 6px;
}

.campaigns-list-wrapper {
    margin-bottom: 25px;
}
.single-audience-row,
.audiences-list-header,
.single-campaign-row,
.campaigns-list-header {
    display: flex;
    font-size: 14px;
    align-items: center;
}
.single-campaign-row {
    align-items: initial;
}
.single-audience-row:hover,
.single-campaign-row:hover {
    background-color: #eee;
}
.audiences-list-header .name,
.single-audience-row .name,
.campaigns-list-header .subject,
.single-campaign-row .subject {
    width: 30%;
}
.audiences-list-header .users-count,
.single-audience-row .users-count,
.campaigns-list-header .audience-name,
.single-campaign-row .audience-name {
    width: 20%;
    text-align: center;
    justify-content: center;
}
.audiences-list-header .users-count,
.single-audience-row .users-count,
.campaigns-list-header .sent-on,
.single-campaign-row .sent-on {
    width: 15%;
    text-align: center;
    justify-content: center;
}
.audiences-list-header .assigned-to,
.single-audience-row .assigned-to {
    width: 20%;
    text-align: center;
    justify-content: center;
}
.campaigns-list-header .preview,
.single-campaign-row .preview {
    width: 10%;
    text-align: center;
    justify-content: center;
}
.campaigns-list-header .wide,
.single-campaign-row .wide {
    width: 60%;
}
.campaigns-list-header .wide > span,
.single-campaign-row .wide > span {
    text-transform: none;
}
.audiences-list-header .actions,
.single-audience-row .actions {
    width: 20%;
    text-align: center;
    justify-content: space-around;
    padding: 7.5px 0 !important;
}
.audiences-list-header .actions {
    width: 20%;
    text-align: center;
    justify-content: space-around;
    padding: 20px 0 !important;
}
.single-campaign-row .actions,
.campaigns-list-header .actions {
    width: 30%;
    text-align: center;
    justify-content: space-around;
}
.single-audience-row .actions,
.single-campaign-row .actions,
.single-campaign-row .preview {
    padding: 0;
}
.audiences-list-header > div,
.campaigns-list-header > div {
    margin: 0 10px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 20px 0;
    text-transform: uppercase;
}
.campaigns-list-header > div {
    font-weight: 600;
}
.single-audience-row > div,
.single-campaign-row > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    margin: 0 10px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 15px 0;
    min-height: 19px;
    display: flex;
    align-items: center;
}
.rdw-link-modal {
    max-height: 180px;
}

.rdw-link-modal-target-option {
    display: none;
}
.superadmin-dashboard {
    .rdw-link-modal-target-option {
        display: block;
    }

    .rdw-link-modal {
        max-height: initial;
        height: auto;
    }

    .gdpr-dashboard {
        .options-container {
            .single-option-container {
                width: 100%;

                div {
                    width: 100%;
                    height: 98%;
                }
            }
            .rdw-editor-main {
                max-height: initial;
            }
        }
        .action-container {
            button {
                margin-inline-start: 10px;
            }
        }
    }
}
.speaker-main-info-wrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
}
.confirm-event-privacy-policy {
    margin-top: 20px;
}
.confirm-event-privacy-policy label {
    margin: 0 !important;

    &:first-of-type {
        padding-bottom: 15px;
    }
}
.confirm-text p {
    color: $text-grey;
}
.confirm-event-privacy-policy .confirm-event-privacy-policy-text {
    margin-left: 20px;
    display: block;
    color: rgba(0, 0, 0, 0.54);
}
.event-payment-information ul li span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 450px;
}
.organizer-dashboard-page .payment-setup {
    color: $text-grey;
}
.organizer-dashboard-page .payment-setup .stripe-link {
    padding: 10px 20px;
    border: 1px solid #3f51b5;
    border-radius: 10px;
    color: #3f51b5;
    transition: all 0.2s;

    &:hover {
        background-color: #3f51b5;
        color: white;
        transition: all 0.2s;
    }

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 4px 10px $secondary;
            background-color: #3f51b5;
            color: white;
            transition: all 0.2s;
        }
    }
}
.commisions a {
    color: $secondary;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            font-weight: bold;
            border-bottom: 1px solid $secondary;
        }
    }
}
.organizer-dashboard-page .payment-setup .connection-details,
.organizer-dashboard-page .payment-setup .currency {
    padding: 15px;
    background-color: #eee;
    margin: 30px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
}
.organizer-dashboard-page .payment-setup .connection-details {
    margin-top: 0;
}
.organizer-dashboard-page .payment-setup .connection-details h4 {
    margin-bottom: 20px;
    margin-top: 0;
}
.organizer-dashboard-page .payment-setup .connection-details p span {
    font-style: italic;
    color: black;
}
.organizer-dashboard-page .payment-setup .currency-container {
    display: flex;
    align-items: center;
    .MuiSelect-root {
        background: none;
    }
    .currency-info-icon {
        width: 16px;
        height: 16px;
        fill: $primary;
        margin-inline-start: 8px;
        margin-inline-end: 30px;
    }
}
.organizer-dashboard-page .payment-setup .currency .helper-text {
    padding-top: 10px;
    font-size: 13px;
    font-style: italic;
}
.organizer-dashboard-page .access-packages-setup .packages-list {
    /*   columns: 2 475px;*/
}
// don't delete the double width, else the above layout effect will break
.access-package-container {
    display: inline-block;
    width: 50%;
    /*
    width: 100%;
    */
    height: 100%;
    padding: 15px 7.5px;
}
.organizer-dashboard-page .access-packages-setup {
    max-width: 1112px;
}
.organizer-dashboard-page .access-packages-setup .package {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 315px;

    div {
        .package-header {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .name {
                width: 320px;
                height: auto;
                word-wrap: break-word;
            }
        }
    }
}
.organizer-dashboard-page .access-packages-setup .package .package-description {
    color: $text-grey;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    word-wrap: break-word;
    min-height: 100px;
}
.organizer-dashboard-page .access-packages-setup .package.disabled {
    background-color: #fcfcfc;
}
.organizer-dashboard-page .access-packages-setup .access-package-container .label {
    font-style: italic;
    color: $text-grey;
}
.access-packages-setup .access-package-container .package-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}
.access-packages-setup .access-package-container .package-description .label {
    display: block;
}
.access-packages-setup .access-package-container .package-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
}
.access-packages-setup .access-package-container .package-header .package-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    min-width: 76px;
    margin-left: 16px;
}
.access-packages-setup .access-package-container .price-container {
    margin-top: 50px;
    .price {
        margin-top: 50px;
        color: $primary;
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
        white-space: nowrap;
    }
}
.access-packages-setup .access-package-container .package-actions .edit-container button {
    margin-left: 20px;
}
.access-packages-setup .access-package-container .package-buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;
}
#align-labels {
    width: 100px;
}
.access-management .access-management-table {
    background-color: #eee;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    .access-types {
        flex: 0 0 200px;
        width: 200px;
        div {
            text-align: left !important;
            font-weight: bold;
            &.subsection-title {
                font-weight: normal;
                padding-left: 32px;
            }
        }
    }
    .packages-columns {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        flex: 1;
        .column {
            flex: 0 0 120px;
            display: flex;
            flex-flow: column;
            align-items: center;
        }
    }
    .column {
        & > div {
            height: 40px;
            line-height: 40px;
            text-align: center;
            padding: 0 8px;
        }
        & > div.title {
            line-height: 20px;
            word-break: break-word;
        }
    }
}
.organizer-dashboard-page .timeslot-resources-container {
    .add-link-form-container {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .action-container {
            margin: 0;
        }
    }

    .editor-wrapper {
        ul {
            padding-left: 24px;
        }
        ol {
            padding: 0;
            margin: 0;
        }
        ul li {
            display: list-item;
            list-style: disc;
        }
    }

    .add-resource-container.uploadFile {
        justify-content: flex-start;
    }
    .add-resource-container {
        display: flex;
        flex: 1;
        justify-content: space-between;
        margin: 0 -15px;
        margin-right: 0 !important;
        align-items: flex-start;

        .options-container {
            margin-bottom: 0;
            flex: 0 0 50%;
            padding: 0 15px;

            .action-container {
                width: 100%;
                overflow: hidden;
            }
        }

        .single-option-container {
            flex: auto;
        }
    }
}
.organizer-dashboard-page .account-not-approved-container {
    flex-flow: column;
    padding-top: 48px;

    p {
        margin: 0 0 24px;
    }
}
.organizer-dashboard-page .statistics-table {
    &.actionable {
        li {
            &:hover {
                cursor: pointer;
            }
        }
        .statistics-header span {
            font-size: 12px;
        }
        .title {
            width: 80%;
            display: flex;
            align-items: center;
            &.attendees {
                svg {
                    color: $primary;
                    margin-inline-end: 10px;
                }
            }
            &.title-item {
                span {
                    font-weight: 500;
                    word-break: break-all;
                }
            }
        }
        .action {
            width: 30%;
            text-align: center;
        }
        .counter {
            width: 20%;
            text-align: right;
            padding-inline-end: 0;
        }
    }
}
.organizer-dashboard-page .statistics {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    .statistics-header {
        padding: 12px 16px;
        display: flex;
        display: -webkit-flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(137, 137, 137, 0.5);
        .StatisticUsers__title {
            flex: 1;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}
.organizer-dashboard-page .statistics .StatisticUsers {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    .statistics-header {
        padding: 6px 14px;
        display: flex;
        display: -webkit-flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(137, 137, 137, 0.5);
        min-height: 46px;
        .StatisticUsers__title {
            flex: 1;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: normal;
        }
    }
    &__content {
        width: 100%;
        display: flex;
        display: -webkit-flex;
        flex: 1 1 auto;
        flex-direction: column;
    }
    &__userListHolder {
        width: 100%;
        display: flex;
        display: -webkit-flex;
        flex: 1;
        min-height: 250px;
        ul {
            box-shadow: none;
            border-radius: 0;
            li {
                border-bottom: none;
                .user-container {
                    width: 100%;
                    .participant-avatar {
                        width: 36px;
                        height: 36px;
                    }
                    .user-details {
                        width: 100%;
                        border-bottom: none;
                        .actions {
                            min-height: auto;
                        }
                    }
                }
            }
        }
    }
}
.package-filters {
    margin-bottom: 30px;
    p {
        margin-top: 0;
    }

    & > div {
        width: 150px;

        .MuiFormLabel-root {
            font-family: $fontFamily, sans-serif;
        }
    }

    .MuiFormControl-root {
        margin-left: 12px;
    }
}
#menu-filterPackage li {
    font-family: $fontFamily, sans-serif;
}
.branding-lobby {
    .custom-renders-wrapper {
        .link-focus {
            @media (hover: hover) {
                &:focus {
                    outline: none;
                }
                &.focus-visible {
                    outline: none;
                    border-bottom: 1px solid $secondary;
                }
            }
        }
        .MuiFormControl-root {
            margin-bottom: 80px;

            label {
                margin: 0 0 0 10px;
                border-bottom: 1px solid #ccc;
                padding: 5px 0;
            }
        }
    }
}
.organizer-dashboard-page.buildings-dashboard {
    // Setup Tab with all buildings
    .buildings-setup {
        .buildings-render-container {
            filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
            border-radius: 8px;
            margin-bottom: 40px;
            max-width: 1112px;
            img {
                width: 100%;
                height: auto;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin-top: 16px;
            background: #f5f5f5;
            text-transform: uppercase;
            border: 1px solid $disabled-text;
            box-sizing: border-box;
            filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
            border-radius: 6px;
            padding-bottom: 104px;
            margin-bottom: 104px;
            li {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                border-bottom: 1px solid #ccc;
                font-weight: 500;
                font-size: 14px;
                line-height: 32px;
                background-color: #fff;
                &.list-header {
                    color: $text-grey;
                    font-weight: 500;
                    font-size: 12px;
                    background-color: transparent;
                }
                &:last-child {
                    border-bottom: none;
                }
                &.selected {
                    background-color: #e6e6e6;
                }
                .building-index-container {
                    flex: 1;
                }
                .building-name-container {
                    flex: 1;
                }
                .building-type-container {
                    flex: 1;
                    position: relative;
                    cursor: pointer;
                    span {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .building-type-selection {
                        position: absolute;
                        background-color: #fff;
                        z-index: 2;
                        cursor: initial;
                        padding: 0;
                        border-radius: 6px;
                        margin-top: 0;
                        li {
                            cursor: pointer;
                            &.active {
                                color: var(--theme-primary-color);
                            }
                        }
                    }
                }
            }
        }
    }
    // main side navigation with all buildings
    .buildings-list {
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        list-style: none;
        background-color: #f5f5f5;
        border-right: 1px solid #ccc;
        flex: 0 0 290px;
        width: 290px;
        li {
            padding: 0 16px 0 24px;
            border-left: 4px solid transparent;
            transition: all 0.2s;
            cursor: pointer;
            display: flex;
            flex-flow: column;
            &.setup-option {
                padding: 16px 16px 16px 24px;
            }
            &.active {
                background-color: #fff;
                color: var(--theme-primary-color);
                border-left-color: var(--theme-primary-color);
                transition: all 0.2s;
            }
            .building-name-container {
                padding: 16px 0 16px 16px;
            }
        }
    }
    // When building is active, dropdown building navigation
    .building-navigation {
        padding-left: 32px;
        font-size: 16px;
        line-height: 36px;
        color: #211e16;
        &-tab {
            padding: 8px 0;
            white-space: nowrap;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.active {
                color: var(--theme-primary-color);
            }
        }
    }
    // When building is active, second side navigation
    .single-building-dashboard {
        display: flex;
        flex: 1;
        .side-navigation {
            p {
                padding-left: 20px;
            }
            .accordion.active .summary {
                border-left-color: transparent;
            }
            .MuiAccordionSummary-root {
                min-height: 52px !important;
            }
            .MuiAccordion-root:before {
                display: none !important;
            }
            .MuiAccordionSummary-content {
                margin: 12px 0 !important;
            }
            ul.subtabs {
                padding: 0 0 0 24px;
                .bullet {
                    opacity: 0;
                    transition: all 0.2s;
                }
                .active {
                    .bullet {
                        opacity: 1;
                        transition: all 0.2s;
                    }
                }
            }
        }
    }
}
.gdpr-dashboard {
    height: calc(100vh - 116px);

    .gdpr-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
        &.has-language-selector {
            justify-content: flex-start;
        }

        p {
            font-weight: 600;
        }

        h4 {
            margin: 0;
        }

        .select-language-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            margin-right: 16px;
            & > div {
                position: relative;
            }
            svg {
                width: 20px;
                height: 20px;
                margin-inline-end: 10px;
            }

            .selected-language {
                display: flex;
                align-items: center;
                padding: 12px 18px;
                border: 1px solid $disabled-text;
                border-radius: 6px;
                background: #ffffff;
                cursor: pointer;

                svg {
                    margin: 0;

                    path {
                        fill: $dark;
                    }
                }
            }
            span {
                padding-right: 10px;
                display: flex;
                align-items: center;
            }
            .languages-list-container {
                position: absolute;
                top: 0;
                width: 100%;
                background: #ffffff;
                border-radius: 6px;
                border: 1px solid $disabled-text;
                box-shadow: 0 14px 24px rgba(32, 15, 54, 0.25);
                z-index: 5;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    li {
                        padding: 12px 18px;
                        cursor: pointer;

                        &:first-of-type {
                            border-top-left-radius: 6px;
                            border-top-right-radius: 6px;
                        }

                        &:last-of-type {
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 6px;
                        }

                        &.active {
                            background: $primary;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }

    .action-container {
        display: flex;
        align-items: center;

        button {
            position: relative;
            left: 0;
        }

        & > div {
            display: flex;
            align-items: center;
            font-size: 14px;

            .MuiSwitch-colorSecondary.Mui-checked {
                color: $primary;
            }

            .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
                background-color: $primary;
            }

            & > svg {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
    }

    form {
        height: 100%;
    }

    .options-container {
        height: calc(100% - 142px);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px !important;
        background-color: #fff;
        padding: 24px 16px 16px;
        position: relative;

        &.event-page-dashboard {
            display: flex;
            flex-direction: column;

            & > div {
                &:first-of-type {
                    display: flex;
                    flex: 1;
                    height: calc(100% - 142px);
                }
            }

            .single-option-container {
                width: 60%;

                div {
                    width: 100%;
                    height: 100%;
                }
            }

            .input-description {
                display: flex;
                flex: 1;
                align-items: center;
                padding-left: 20px;
                color: $text-grey;
            }

            .action-container {
                margin-top: 30px;
                justify-content: flex-start;
            }
        }

        .editor-wrapper {
            height: 100%;
            border: 0;

            .rdw-editor-wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            .rdw-editor-main {
                height: 100%;
            }
        }

        .single-option-container {
            height: 100%;
            display: flex;

            .MuiOutlinedInput-multiline {
                height: 100%;
                padding: 18.5px 14px 18.5px 14px;

                textarea {
                    height: 100%;
                }
            }
        }
    }

    .advanced-options-container {
        box-shadow: none;
        padding: 0;
        max-width: initial;
    }
}

.event-settings .switch-container {
    width: 160px;
    outline: 0;
    * {
        outline: 0;
    }
}

#disclaimer-text {
    & > div {
        font-size: 12px;
        font-family: $fontFamily, sans-serif;
    }
}

.empty-statistics-users {
    min-height: 200px;
    display: flex;
    align-items: center;
    & > div {
        margin: 0 30px;
        text-align: center;
        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $text-grey;
            margin-bottom: 0;
        }
    }
}

.color-picker {
    .actions-container {
        justify-content: flex-start;
        button {
            margin-right: 20px;
        }
    }
}
.branding-colors {
    .advanced-options-container {
        & > div {
            &:first-of-type {
                margin-bottom: 80px;
            }
        }
    }
}
#info-img {
    .MuiTooltip-tooltip {
        max-width: initial;
        background-color: white;
        padding: 0;
    }
    img {
        max-width: 300px;
    }
}
.img-container-preview {
    position: absolute !important;
    top: 0 !important;
    left: -100px;
}
/* new redesign partners and exhibitors */
.new-accordion-design {
    .advanced-options-container {
        padding: 30px 16px;
        .list-empty-text {
            margin: 0;
            font-weight: 500;
            font-size: 20px;
            color: $grey-dark;
        }
    }
}
.accordion-title-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.accordion-wrapper-details {
    width: 100%;
}
.accordion-container {
    &.new-role-container {
        margin-bottom: 24px;
        & > div {
            padding: 26px 16px;
        }
        .accordion-wrapper-details {
            & > p {
                margin: 0;
                padding-top: 24px;
                font-size: 14px;
                font-weight: 600;
                color: $grey;
            }
        }
        .accordion-title-details {
            .title {
                margin: 0;
                font-weight: 500;
                font-size: 20px;
                text-transform: uppercase;
                color: $grey-dark;
            }
        }
        .MuiAccordionSummary-root {
            min-height: initial;
            padding: 0;
            &.Mui-expanded {
                min-height: initial;
            }
        }
        .MuiAccordionSummary-content {
            margin: 0;
            &.Mui-expanded {
                margin: 0;
            }
        }
        input {
            font-family: $fontFamily, sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.87);
            padding-inline-end: 10px;
        }
        legend {
            font-size: 12px;
        }
        form {
            width: 50%;
            button {
                margin-top: 16px;
            }
        }
        h4 {
            font-weight: 500;
            font-size: 20px;
            text-transform: uppercase;
            color: $grey;
            margin: 0;
            padding-top: 40px;
        }
        .actions-container {
            justify-content: flex-start;
            margin-top: 24px;
            padding-bottom: 30px;
            button,
            label {
                margin-right: 20px;
            }
        }
        .options-container {
            margin: 0;
            align-items: flex-start;
            .input-description {
                padding-top: 93px;
            }
        }
        .manage-partner-logo {
            margin: 0;
        }
        .cover-img-container {
            width: 100%;
            max-width: initial;
            height: 264px;
            border-radius: 8px;
            margin-bottom: 0;
            display: flex;
        }
        .dialog-btn-uppercase {
            .button-content {
                color: $dark;
                display: flex;
                align-items: center;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}

.single-banner-link {
    all: unset;
    cursor: pointer;
    color: $dark;
}
.textarea-wrapper {
    position: relative;
    textarea {
        min-height: 150px;
    }
    .MuiOutlinedInput-multiline {
        padding-bottom: 24px;
    }
}
.branding-disable-login {
    .advanced-options-container {
        .action-container {
            text-align: left;
        }
    }
}
// remove arabic styles from online user box - statistics
html {
    &.lang-rtl {
        .organizer-dashboard-page {
            .is-offline {
                .participant-avatar {
                    &:after {
                        left: initial;
                        right: 0;
                    }
                }
            }
            .participant-button {
                &.open-chat {
                    left: initial;
                    right: 0;
                }
            }
            .StatisticUsers__userListHolder {
                .scrollbar,
                .box {
                    direction: ltr;
                }
                .box {
                    margin-right: -17px !important;
                    margin-left: 0 !important;
                }
            }
        }
    }
}
.create-session-tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
}
.corg-gdpr-width {
    max-width: 900px !important;
}
// Platform custom scrollbars apply to
// react-wysiwyg editor textarea, all other textareas and
// ACCESS > Invitation Codes > Virtualized Table
.rdw-editor-main,
textarea,
.ReactVirtualized__Grid {
    // Rest of the browsers
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* color of the tracking area */
    }
    &::-webkit-scrollbar-thumb {
        background: $grey; /* color of the scroll thumb */
        border-radius: 10px;
    }
    // Firefox
    scrollbar-color: $grey transparent;
    scrollbar-width: thin;
}
.core-copy-button {
    padding-right: 10px;
    padding-bottom: 8px;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.subtab-settings-link {
    padding: 16px 6px !important;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0px 0px 10px $secondary;
        }
    }
}
.display-timezone-wrapper {
    display: inline-block;
    position: relative;
    border-radius: 6px;
    padding-left: 6px;
    left: -6px;

    .MuiSvgIcon-root {
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 8px $secondary;
        }
    }
}
@keyframes uparrow {
    0% {
        -webkit-transform: translateY(0);
        opacity: 0.4;
    }
    100% {
        -webkit-transform: translateY(-0.2em);
        opacity: 0.9;
    }
}
@keyframes downarrow {
    0% {
        -webkit-transform: translateY(0);
        opacity: 0.4;
    }
    100% {
        -webkit-transform: translateY(0.2em);
        opacity: 0.9;
    }
}
.upload-label.banner-upload {
    padding: 6px;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
            svg {
                animation: uparrow 0.6s infinite alternate ease-in-out;
                -webkit-animation: uparrow 0.6s infinite alternate ease-in-out;
                -o-animation: uparrow 0.6s infinite alternate ease-in-out;
                -moz-animation: uparrow 0.6s infinite alternate ease-in-out;
            }
        }
    }
}
.edit-label.banner-upload {
    padding: 3px 6px;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.branding-lobby-content {
    .advanced-options-container {
        .lobby-link-container {
            .options-container {
                align-items: flex-start;

                .input-description {
                    margin-top: 18px;
                }
            }
        }
    }

    .MuiSvgIcon-root {
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }
}
.timeslot-resources-container .link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    a {
        color: $secondary;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                font-weight: bold;
                border-bottom: 1px solid $secondary;
            }
        }
    }
}
.add-sub-tab {
    display: flex;
    flex-direction: row;
    left: -13px;
    justify-content: flex-start;
    width: fit-content;
}
.auditorium-text-switch {
    display: inline-block;
    padding: 6px 6px 0 6px;
    border-radius: 6px;
    margin-bottom: 8px;
    .MuiSwitch-root {
        margin-bottom: 4px;
    }
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.switch-section {
    display: flex;
    flex: 0 0 520px;
    align-items: center;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            & > div {
                border-radius: 6px;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }
}
.booth-switch-wrapper {
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.switch-wrapper .MuiFormControlLabel-root {
    padding-right: 12px;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.statistics-wcag-li {
    .MuiSvgIcon-root {
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            background-color: $secondary-light;
        }
    }
}
.info-timeslot {
    svg {
        position: relative;
        margin-left: 10px;
        top: 3px;
        cursor: pointer;
    }

    .MuiSvgIcon-root {
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }
}
#download-info svg {
    width: 30px;
    height: 20px;
    padding: 3px;
    border-radius: 50%;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 6px 8px $secondary;
        }
    }
}
.editor-wrapper .rdw-editor-main {
    padding: 0 14px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
    }
}
.single-filter-container.wcag-emails,
.single-option-container.wcag-emails {
    margin-top: 8px;
    display: inline-block;
    padding-right: 12px;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.editAudiences-backBtn {
    padding: 12px 12px 6px 12px;
    border-radius: 6px;
    margin-right: 48px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
input[type='number'] {
    -moz-appearance: textfield; /*For FireFox*/
    &::-webkit-inner-spin-button {
        /*For Webkits like Chrome and Safari*/
        -webkit-appearance: none;
        margin: 0;
    }
}
.image-explain {
    font-size: 16px;
    line-height: 24px;
    color: $grey-dark;
    margin-bottom: 10px !important;
}
.access-wcag-focus:last-child {
    margin-bottom: 12px;
}
.access-wcag-focus {
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}

.matching-setup-dashboard {
    .spinner {
        margin-top: 150px !important;
    }
    .advanced-options-container {
        margin-bottom: 0;
    }
    .options-container {
        margin-bottom: 30px !important;
    }
    .action-container {
        margin: 30px 0 0 0 !important;
    }
    .matching-actions-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .questions-container {
        max-width: 1112px;
        .spinner {
            position: relative;
            margin-top: 100px !important;
            background-color: transparent;
            width: 100%;
            height: auto;
        }
    }
    .bottom-actions {
        width: 100%;
        max-width: 1112px;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 50px;
        .create-question {
            &:active,
            &:active:focus {
                background: $secondary !important;
                box-shadow: none !important;
                .MuiButton-label {
                    color: $white !important;
                }
            }
            @media (hover: hover) {
                &:hover {
                    background: $secondary-light !important;
                    box-shadow: none !important;
                    .MuiButton-label {
                        color: $secondary;
                    }
                }
                &:focus {
                    background: $white;
                    box-shadow: 0px 4px 10px $secondary;
                }
            }
        }
    }
    .matching-form-title {
        margin: 0 0 30px 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $dark;
    }
    .description-input {
        padding: 18.5px 3px 18.5px 14px;
    }
    .timeslot-title-details {
        @media screen and (max-width: 1280px) {
            display: block;
            .matching-actions-wrapper {
                position: relative;
                left: -4px;
                margin-top: 30px;
                justify-content: flex-start;
            }
        }
        .matching-actions-left {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 20px;
            svg {
                margin-right: 20px;
            }
            .matching-question {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: $dark;
                text-transform: uppercase;
                word-break: break-all;
            }
        }
        .matching-actions-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .question-controls-container {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            @media screen and (max-width: 1152px) {
                flex-wrap: wrap;
            }
            .advanced-options-closed {
                white-space: nowrap;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.04em;
                color: $grey-dark;
                margin-right: 20px;
            }
        }
    }

    .MuiPaper-root {
        border-radius: 6px !important;
        .MuiAccordionSummary-root {
            cursor: default !important;
            padding: 7px 16px;
        }
    }
    .MuiAccordion-root.Mui-expanded {
        margin: 0 0 40px 0;
    }
    .MuiAccordion-root::before {
        background-color: transparent;
    }
    .MuiAccordionDetails-root {
        flex-direction: column;
    }
}
.uploadfile-prop {
    margin-left: 10px;
}
.removefile-prop {
    margin-left: 10px !important;
}
.MuiButton-label.dialog-btn-uppercase.removefile-button {
    padding: 6px 8px !important;
    div {
        margin-right: 8px !important;
    }
}
.scroll-helper-gdpr {
    position: relative !important;
    border-radius: 10px;
    svg {
        position: absolute !important;
        top: 20px !important;
        right: 20px !important;
    }
    textarea {
        padding-right: 50px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
        padding-right: 0 !important;
    }
}

.program-upload-file-button-container button {
    width: 100%;
}
.timezones-select-container {
    width: 520px;
}

.assign-to-hall-select {
    width: 229px;
}

#homepage-description-tooltip {
    .MuiTooltip-tooltip {
        word-break: normal;
    }
}

.custom-render-radio {
    .custom-info-icon {
        margin-inline-start: 10px;
    }
}

#custom-render-tooltip {
    max-width: 255px;
    font-size: 14px;
    line-height: 16px;
    .MuiTooltip-tooltip {
        background-position: 14px 12px;
        border: 1px solid #efeef1;
        border-radius: 12px;
    }
}

.window-view-container {
    .MuiAccordionSummary-root {
        padding: 0;
    }
    .MuiAccordionDetails-root {
        padding: 0;
    }
    .accordion-title-details {
        .title {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            /* identical to box height, or 150% */
            margin: 0;
            letter-spacing: 0.0015em;
            text-transform: uppercase;

            /* MO/Text/Mono 400 */

            color: #777081;
        }
    }

    .window-view-upload-container {
        display: flex;
        width: 100%;
        .upload-window-container {
            display: flex;
            flex-direction: row;
            flex: 1;
        }
        .left-image-wrapper {
            padding-inline-end: 11px;
            border-right: 1px solid #cacbd0;
        }
        .right-upload-window {
            padding-inline-start: 11px;
            padding-inline-end: 24px;
        }
        .upload-window {
            flex: 1;
            .window-img-container {
                width: 244px;
                height: 244px;
                background: #f5f5f5;
                border: 1px solid #777081;
                border-radius: 6px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .upload-label {
                    cursor: pointer;
                }
                img {
                    width: 100% !important;
                    height: 100% !important;
                    aspect-ratio: 1/1;
                    border-radius: 6px;
                }
            }
        }
        .upload-window p {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
        .window-view-upload-details {
            .recommended-description {
                margin-bottom: 24px;
            }
            .window-view-tip {
                font-weight: 600;
                color: #2b2b2b;
            }
        }
        .window-view-upload-details p {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #777081;
        }
        .pictures-names .picture-left-name {
            margin-bottom: 24px;
        }

        .actions-container-window-view {
            //.remove-window-view-btn {
            //    margin-inline-start: 15px;
            //}
            margin-bottom: 16px;
            button,
            label {
                margin-right: 18px;
            }
        }
    }
}

.left-window-preview {
    padding-bottom: 10%;
    padding-right: 24%;
}

.right-window-preview {
    padding-bottom: 23%;
    padding-left: 19%;
}

.full-lobby-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 95%;
    .full-preview-left {
        position: absolute;
        margin: 0;
        background: transparent;
        aspect-ratio: 1/1;
        object-fit: contain;
        left: 2.5%;
        width: 31.4%;
        img {
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
            object-position: center;
            padding-bottom: 25%;
            padding-right: 15%;
        }
    }
    .full-preview-right {
        position: absolute;
        margin: 0;
        background: transparent;
        aspect-ratio: 1/1;
        object-fit: contain;
        right: 2.5%;
        width: 31.4%;
        z-index: -1;
        img {
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
            object-position: center;
            padding-bottom: 22%;
            padding-left: 15%;
        }
    }
    .full-left-window-preview {
        object-position: center;
        width: 31% !important;
        object-fit: cover !important;
        padding-bottom: 4%;
        padding-top: 3.4%;
        right: 16.5%;
        z-index: -11;
    }

    .full-right-window-preview {
        object-position: center;
        width: 31% !important;
        object-fit: cover !important;
        padding-bottom: 4%;
        left: 16.5%;
        z-index: -11;
    }
}

.small-preview-window-view {
    position: absolute;
    font-size: 0;
    margin: 0;
    background: transparent;
    aspect-ratio: 1/1;
    height: 100vh;
    z-index: -4;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.left-side-position {
    left: 0;
    .left-window-preview {
        width: 100%;
        height: 100%;
    }
}

.right-side-position {
    right: 0;
    .right-window-preview {
        width: 100%;
        height: 100%;
    }
}
