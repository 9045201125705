@import '../../CSS/variables.module';
.drag-file-wrapper-active {
    opacity: 0.5;
}
.upload-program-drag {
    background: $white-variant;
    width: 308px;
    height: 175px;
    border: 1px dashed $secondary;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    .dropzone {
        width: 308px;
        height: 175px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .drag-text {
        cursor: pointer;
        user-select: none;
        font-family: $fontFamily, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        width: 248px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        color: $secondary;
        /* Inside Auto Layout */

        flex: none;
        order: 3;
        flex-grow: 1;
        margin: 0px 8px;
    }
}
