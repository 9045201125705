@import 'variables.module';
@import './hpColors';

.not-found-page{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 1301;
}
.homepage .event-container,
.login-page .event-container {
    background-color: $secondary;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 100vh;
    position: relative;
}
.homepage .event-container .single-event-container,
.login-page .event-container .single-event-container {
    position: relative;
    padding-inline-end: 550px;
    width: 100%;
}
.register-participant .single-event-container {
    padding-inline-end: 0 !important;
}
.header.homepage {
    width: 100%;
    position: absolute;
    background-color: rgba(256, 256, 256, 0.5);
    z-index: 1;
}
.header.homepage button {
    background-color: transparent;
    border: 0;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 33px !important;
    outline: 0;
    cursor: pointer;
}
.homepage h1,
.login-page h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    margin: 0;
    line-height: 1.3;
}
.homepage .single-event-container p.event-description {
    font-size: 16px;
    line-height: 20px;
    min-height: 350px;
}
.homepage .single-event-container .organizer-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
}
.homepage-overlay {
    height: 100%;
    background-color: rgba(102, 36, 209, 0.5);
    position: absolute;
    width: 100%;
    top: 0;
}
.homepage .smallContainer,
.login-page .smallContainer {
    max-width: 1587px;
}
.homepage .event-container .smallContainer,
.login-page .event-container .smallContainer {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 10px;
    position: relative;
    padding-top: 70px !important;
    height: calc(100% - 70px);
}
.homepage #show-video-homepage {
    position: absolute;
    width: 459px;
    height: 215px;
    right: 0;
    top: 0;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}
#show-video-homepage > div {
    height: 100%;
    position: relative;
}
#show-video-homepage .video-overlay {
    background: linear-gradient(
        119.29deg,
        rgba(50, 35, 105, 0.4) 0.46%,
        rgba(84, 47, 217, 0.356) 52.7%,
        rgba(52, 18, 172, 0) 98.37%
    );
    height: 100%;
}
.video-overlay .play-button {
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
}
#random-banner {
    position: absolute;
    right: 0;
    width: 273px;
    height: 210px;
    bottom: 0;
}
#random-banner a {
    height: 100%;
}
#random-banner > div {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
#random-banner > div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right;
}
.homepage .event-container .actions-container,
.login-page .event-container .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 35px;
}
.homepage .event-container .actions-container p {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
}
.homepage .event-container .actions-container button {
    margin: 0;
    background-color: $primary;
    border: 0;
    padding: 14px 100px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    outline: 0;
    cursor: pointer;
}
.homepage .auditoriums-program {
    padding-top: 50px;
}
.homepage .auditoriums-program h2,
.guests-container h3 {
    font-weight: 800;
    font-size: 38px;
    line-height: 46px;
    color: $secondary;
    max-width: 495px;
    text-align: right;
    margin-left: auto;
}
.homepage .auditoriums-program ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.homepage .auditoriums-program ul li {
    text-align: center;
    padding: 20px 0;
    width: 33.3%;
}
.homepage .auditoriums-program ul li p {
    margin: 0;
}
.homepage .speaker-avatar {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 148px;
    height: 148px;
    border: 1px solid #ffffff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    margin: 0 auto;
}
.homepage .speaker-information {
    padding-top: 15px;
}
.homepage .speaker-information > div:first-child {
    max-width: 300px;
    margin: 0 auto;
}
.homepage .speaker-information > div p {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: $dark;
}
.homepage .speaker > div p:last-of-type {
    font-size: 18px;
    color: $dark;
}
.homepage .speaker > div p[data-empty='true'] {
    padding: 0;
}
.homepage .social-media-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}
.homepage .social-media-wrapper > div {
    display: inline-flex;
    font-size: 0;
    margin-left: 4px;
}
.homepage .speaker .social-media-wrapper > p {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 24px;
    color: $text-grey !important;
}
.homepage .social-media-wrapper .social-button {
    margin-left: 6px;
}
.speakers-section-details {
    position: relative;
    padding-top: 60px;
}
.speakers-section-details p {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $dark;
    max-width: 900px;
    text-align: center;
    margin: 0 auto;
    padding: 0 15px;
}
.speakers-section-details img {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
}
.program-wrapper .image-wrapper-top {
    min-height: 336px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.program-wrapper .image-wrapper-bottom {
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.homepage .program-container {
    background-color: $secondary;
}
.homepage .program-list-container {
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 40px 30px 0;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
}
.program-list-container .program-entry-description {
    padding-bottom: 8px;
    word-break: break-word;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    letter-spacing: 0.005em;

    color: #2B2B2B;
}
.program-list-container .program-entry {
    padding: 0;
}
.program-list-container .program-entry h4 {
    margin: 0;
    padding-bottom: 10px;
    color: $grey-dark;
    padding-top: 2px;
    font-weight: 600;
}
.program-list-container p {
    margin: 0;
    overflow-wrap: break-word;
}
.program-list-container span {
    word-break: break-word;

    ul, ol {
        position: relative;
        left: -23px;
    }

    ul li {
        display: list-item;
        list-style: disc;
    }
}
.program-list-container .note {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $text-grey;
}
.program-list-container .program-item {
    padding-top: 30px;
}
.program-list-container .left-program-container {
    width: 45%;
    float: left;
}
.program-list-container .right-program-container {
    width: 45%;
    float: right;
}
.clearfix {
    clear: both;
}
.homepage .auditorium-program {
    position: relative;
}
.homepage .auditorium-program:after {
    content: '';
    position: absolute;
    height: 80px;
    background-color: #fff;
    width: 100%;
}
.homepage .auditorium-program h3,
.homepage .event-name {
    margin-top: 0;
    font-weight: 800;
    font-size: 38px;
    line-height: 46px;
    text-align: right;
    color: #fff;
}
.homepage .auditorium-program .auditorium-name {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: $secondary;
    margin-top: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.homepage .program-entry {
    border: 0;
    margin: 0;
    padding-bottom: 30px;
}
.homepage .program-entry ul {
    margin: 0;
}
.homepage .program-entry ul li {
    text-align: left;
    width: 50%;
    padding: 0;
}
.homepage .program-entry .program-time {
    font-weight: bold;
    color: $secondary;
    text-align: start;
    font-size: 18px;
}
.guests-container {
    padding-top: 180px;
}
.guests-container .slick-arrow {
    display: none !important;
}
.guests-container .slider-carousel {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 113px;
}
.guests-container h3 {
    margin-bottom: 16px;
}
.guests-container p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: $dark;
}
.guests-container .react-tabs__tab-list {
    border: 0;
    margin-bottom: 80px;
}
.guests-container .react-tabs__tab {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    padding: 7px 27px;
    text-transform: uppercase;
    border: 0;
    color: $text-grey;
}
.guests-container .react-tabs__tab:focus {
    outline: 0;
    box-shadow: 0 0 5px transparent;
    border-color: transparent;
}
.guests-container .react-tabs__tab--selected {
    color: #fff;
    background: $secondary;
    border-radius: 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    cursor: initial;
}
.slider1 .testimonials-container {
    padding-bottom: 165px;
    text-align: left;
    padding-top: 125px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.slider1 .testimonials-container img {
    float: right;
}
.slider1 .testimonials-container h2 {
    font-weight: 900;
    font-size: 40px;
    line-height: 47px;
    color: $dark;
    margin: 0;
    text-transform: uppercase;
    padding-bottom: 50px;
}
.slider1 .testimonials-container h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $text-grey;
    margin: 0;
    padding-bottom: 25px;
}
.slider1 .testimonials-container .testimonial-description {
    max-width: 900px;
    padding-left: 28px;
    border-left: 2px solid $secondary;
}
.slider1 .testimonial-description p {
    line-height: 24px;
    margin-bottom: 16px;
}
.slider1 .slider-image-container {
    width: 100% !important;
}
.slider2 img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.slider2 img:hover {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.slider2 img.slick-arrow {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    width: 30.5px;
    height: 61px;
}
.slick-active.slick-current img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.partner-logo-container {
    width: 300px;
    height: 250px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
}
.partners-social-buttons {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    flex-wrap: wrap;
}
.partners-social-buttons > div {
    flex: 1 0 100%;
    text-align: center;
    margin-top: 20px;
}
.partners-social-buttons > div a {
    color: $text-grey;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.partners-social-buttons a {
    margin: 0 15px;
}
footer {
    background-color: var(--secondary-color);
    padding: 50px 0 100px;
}
.footer-container {
    max-width: 1224px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
}
.footer-container .links-container {
    display: flex;
    justify-content: space-between;
}
.footer-container .links-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer-container .links-container .social-buttons-wrapper p {
    margin-top: 0;
    color: $disabled-text;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 15px;
}
.footer-container .links-container .social-buttons-wrapper ul {
    display: flex;
    align-items: center;
}
.footer-container .links-container .social-buttons-wrapper ul li {
    padding-inline-start: 40px;
}
.footer-container .links-container .social-buttons-wrapper ul li:first-child {
    padding-inline-start: 0;
}
.footer-container .links-container .social-buttons-wrapper ul li svg {
    width: 36px;
    height: 36px;
}
.footer-container .links-container .social-buttons-wrapper ul li:hover svg {
    stroke: var(--primary-color);
}
.footer-container .links-container .social-buttons-wrapper ul li:focus {
    outline: 0;
    svg {
        stroke: var(--primary-color);
    }
}
.login-page {
    height: 100vh;
}
.single-login-event-page {
    .login-page {
        .header-spacing-container {
            @media screen and (min-width: 768px) and (max-width: 1199.9px) {
                padding-bottom: 80px;
            }
        }
    }
}
.footer-container .links-container .inner-links li {
    margin-bottom: 20px;
}
.footer-container .links-container .inner-links li:last-child {
    margin-bottom: 0;
}
.footer-container .links-container .inner-links a {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
}
.footer-container .links-container .inner-links a:hover {
    @media screen and (min-width: 1199.9px) {
        color: var(--primary-color);
        padding-bottom: 5px;
        border-bottom: 2px solid var(--primary-color);
    }
}
footer .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 100px;
}
footer .logo-wrapper a {
    font-size: 0;
    margin-inline-end: 10px;
}
footer .logo-wrapper span {
    font-weight: 600;
}
.login-page #signup-text p,
.login-page.ansa .single-event-container > p {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
}
.login-page.register-participant h1 {
    margin-bottom: 20px;
}
.login-page.register-participant #signup-text p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 10px;
}
.login-page.register-participant #signup-text a {
    color: $text-grey;
}
.login-page.register-participant #signup-text p:last-child {
    margin-bottom: 30px;
}
.login-page #signup-text a {
    color: #fff;
    font-weight: bold;
}
.login-page .event-container {
    height: 100%;
}
.login-popup {
    background-color: #ffffff;
    right: 0;
    padding: 30px;
    color: $text-grey;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.login-page h1 {
    text-transform: uppercase;
}
.login-page .single-event-container > p {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    max-width: 600px;
}
.login-page .event-container .smallContainer {
    position: relative;
    justify-content: center;
}
.social-btn {
    background: #fff;
    color: $text-grey !important;
    text-transform: none;
    width: 100%;
    margin-bottom: 16px;
    position: relative;
    font-weight: 600;
    font-size: 14px;
    &:active {
        color: white;
        background: #fff !important;
    }
    &:hover {
        background-color: #fff;
    }
    .MuiButton-startIcon  {
        align-items: center;
        img {
            width: 26px;
            position: absolute;
            left: 16px;
        }
    }
}
.social-btn.facebook-button {
    background: #3c5a99;
    color: #fff !important;
    &:active {
        background: #3c5a99 !important;
    }
}
.social-btn.twitter-button {
    background: #1da1f2;
    color: #fff !important;
    &:active {
        background: #1da1f2 !important;
    }
}
.login-page form {
    padding: 0;
}
.unregister-in-progress-error{
    padding-bottom: 40px;
}
.login-page form > p,
.register-form-wrapper .agree-terms + .error-text {
    color: $error-light !important;
    font-size: 14px !important;
    font-weight: bold !important;
    display: block !important;
    margin: 16px 0 !important;
    white-space: pre-line;
    text-align: center;
    & > span {
        display: block;
    }
    span {
        &.error-clickable {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
.login-page form > p.invitation-code-information {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $text-grey !important;
    margin-top: 10px;
    margin-bottom: 16px;
}
.login-page .email-wrapper,
.login-page .password-wrapper {
    position: relative;
    margin: 0;
}
.login-page .email-wrapper .MuiInputBase-input,
.login-page .password-wrapper .MuiInputBase-input {
    padding-inline-end: 50px;
}
.login-page .email-wrapper svg,
.login-page .password-wrapper svg {
    position: absolute;
    right: 16px;
    top: 17px;
    path {
        fill: $grey;
    }
}
.recover-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    font-size: 0;
    flex-wrap: wrap;
    & > div {
        width: 47%;
        text-align: left;
        &:last-of-type {
            text-align: right;
        }
        span {
            font-family: $fontFamily, sans-serif;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            white-space: pre-line;
        }
        button {
            font-family: $fontFamily, sans-serif;
            font-weight: 600;
            font-size: 14px;
            background-color: transparent;
            border: 0;
            cursor: pointer;
            color: #ffffff;
            text-transform: uppercase;
            outline: 0;
            text-align: right;
            line-height: 1.5;
            white-space: pre-line;
            &:disabled {
                cursor: initial;
            }
        }
    }
}
.recover-wrapper > p.server-message {
    flex: 0 0 100%;
    text-align: left;
    font-size: 12px;
    color: $primary;
    font-weight: bold;
}
.recover-wrapper .remember-me:checked,
.register-form-wrapper .agree-terms input:checked {
    background-color: $primary;
    position: relative;
    appearance: none;
}
.recover-wrapper .remember-me:checked:before,
.register-form-wrapper .agree-terms input:checked:before {
    content: '';
    left: 3px;
    top: 0;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
}
.login-submit-button,
.register-button,
.buttons-wrapper button {
    color: #fff;
    font-family: $fontFamily, sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    border: 0;
    background-color: var(--primary-color);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    border-radius: 8px 8px 0px 8px;
    width: 100%;
    padding: 11px 0;
    margin-top: 0;
    margin-bottom: 27px;
    outline: 0;
    text-transform: uppercase;
    cursor: pointer;
}
.login-submit-button.disabled,
.register-button.disabled {
    cursor: not-allowed;
}
.register-form-wrapper.step-2 .buttons-wrapper button {
    width: 100%;
    font-size: 16px;
}
.login-popup p {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
}
.login-popup p span > a {
    font-weight: 600;
    color: $secondary;
    margin-inline-start: 8px;
}
.login-popup .social-btn p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    padding-inline-start: 30px;
}
.register-participant .smallContainer {
    justify-content: flex-end;
}
.register-participant .single-event-container {
    width: 80%;
    max-width: 80%;
    display: flex;
    flex-direction: row;
    background: rgba(245, 245, 245, 0.9);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding-right: 0 !important;
}
.single-event-container .left-form {
    padding: 20px;
    width: 318px;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.single-event-container .left-form h1 {
    color: #7e57aa;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 50px;
    font-size: 50px;
}
.single-event-container .left-form p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $text-grey;
}
.single-event-container .right-form {
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
}
.single-event-container .right-form > p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $secondary;
    text-align: center;
    margin-top: 37px;
}
.register-participant .only-login {
    display: none;
}
.register-participant .or-text {
    display: none;
}
/*.register-form-wrapper fieldset,*/
/*.edit-user-information fieldset {*/
/*border: 0.5px solid $grey;*/
/*!*border-color: $grey !important;*!*/
/*border-radius: 8px;*/
/*background-color: #fff;*/
/*}*/
.register-form-wrapper label + div:hover,
.edit-user-information label + div:hover {
    border: 0 !important;
}
/*.register-form-wrapper input,*/
/*.edit-user-information input{*/
/*position: relative;*/
/*color: #847878 !important;*/
/*z-index: 1;*/
/*}*/
.edit-user-information .single-option-container input {
    position: absolute;
}
.register-participant .register-form-wrapper label.error-label {
    position: relative;
}
.register-participant .register-form-wrapper label > p {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $text-grey;
    margin: 0;
}
.register-participant .register-form-wrapper label > p.error-text,
.register-participant .register-form-wrapper p.error-text {
    position: absolute;
    bottom: -15px;
    font-size: 10px;
    color: $error-light;
    font-weight: 400;
}
.register-participant .register-form-wrapper p.error-text {
    position: initial;
    margin: 0;
    padding-bottom: 15px;
    font-size: 12px;
}
.register-form-wrapper form > label {
    cursor: pointer;
    display: flex;
}
.register-form-wrapper .agree-terms {
    margin-top: 16px;
    margin-bottom: 24px;
    color: #ffffff;
    label {
        margin: 0;
        cursor: initial;
    }
    span {
        font-family: $fontFamily, sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        a {
            color: #ffffff;
            &:hover {
                color: #ffffff !important;
            }
            span {
                font-weight: bold;
            }
        }
    }
}
.register-participant .register-form-wrapper > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    padding-bottom: 20px;
}
.register-participant .register-form-wrapper > p a {
    color: $primary;
    margin-left: 8px;
    font-weight: 600;
}
.register-participant .register-form-wrapper form > div > p {
    margin: 0;
    line-height: 1;
    margin-top: 5px;
}
.register-form-wrapper .imgPreview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #ffffff;
    margin-top: 16px;
    margin-bottom: 16px;
}
.register-form-wrapper .imgPreview > div {
    order: 2;
    display: inline-flex;
    font-size: 0;
    flex: 1;
    justify-content: space-between;
}
.register-form-wrapper .imgPreview > div span {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}
.register-form-wrapper .imgPreview > div button {
    background-color: transparent;
    border: 0;
    font-family: $fontFamily, sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: $error-light;
    outline: 0;
    text-transform: uppercase;
    cursor: pointer;
}
.register-form-wrapper .imgPreview > img {
    order: 1;
    margin-inline-end: 16px;
}
.register-form-wrapper.step-2 {
    padding-top: 32px;
    max-width: 320px;
}
.register-form-wrapper.step-2 .error-input fieldset {
    border-color: $error-light !important;
}
.buttons-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}
.buttons-wrapper button {
    width: 35%;
    cursor: pointer;
}
.buttons-wrapper .skip-button {
    background-color: $secondary;
}
.right-form form .form-footer .register-button {
    opacity: 1;
    max-width: 376px;
    margin: 30px auto 22px auto;
    display: block;
}
.right-form form .form-footer .register-button:disabled {
    opacity: 0.7;
    cursor: initial;
}
.form-footer .imgPreview[data-empty='false'] {
    display: none;
}
.form-footer .imgPreview {
    padding-bottom: 20px;
}
.form-footer .agree-terms {
    text-align: left;
    font-size: 0;
    cursor: pointer;
}
.form-left h2,
.form-right h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $secondary;
}
.smallContainer .register-section {
    padding-top: 110px;
}
.register-section .actions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 620px;
    align-items: center;
    margin: 0 auto;
}
.register-section .actions-container p {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    color: $text-grey;
}
.register-section .register-button {
    color: #fff;
    text-transform: uppercase;
    background-color: $primary;
    min-width: 307.65px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
}
.slider-image-container {
    max-width: 200px;
    width: 100%;
    height: 150px;
    cursor: pointer;
}
.slider2 .slick-slide {
    padding: 0 20px;
    box-sizing: border-box;
}
.slider-image-container img {
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
}
.guests-container .slick-initialized .slick-slide {
    text-align: center;
}
.register-participant .register-form-wrapper label > p.error-text {
    padding: 0;
    margin: 0;
    bottom: -3px;
    line-height: 1;
}
.register-form-wrapper .agree-terms + .error-text {
    text-align: center;
    white-space: pre-line;
}
.register-form-wrapper form > label.invitation-code-container {
    justify-content: flex-start;
}
.register-form-wrapper form .invitation-message,
.register-form-wrapper .agree-terms .agree-terms-message {
    display: block;
}
.register-form-wrapper .agree-terms .agree-terms-message {
    padding-inline-start: 10px;
}
.logo-placeholder {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 24px !important;
}
.CookieDeclaration {
    display: none;
}
@media all and (max-width: 1650px) {
    .homepage .smallContainer,
    .login-page .smallContainer {
        max-width: 1400px;
    }
    .homepage .guests-container .smallContainer,
    .homepage .program-container .smallContainer {
        max-width: 1300px;
    }
    .slider1 .testimonials-container .testimonial-description {
        max-width: 700px;
    }
}
@media all and (max-width: 1466px) {
    .guests-container .smallContainer,
    .footer-container .smallContainer,
    .homepage .program-container .smallContainer {
        max-width: 1300px;
    }
}
@media all and (max-width: 1450px) {
    .homepage .smallContainer {
        max-width: initial;
        padding: 0;
        width: 100%;
        margin: 0;
    }
    .login-page .single-event-container > p {
        font-size: 20px;
        line-height: 24px;
    }
    .login-page .event-container .single-event-container {
        padding-right: 450px;
    }
    .homepage .event-container .single-event-container {
        padding-right: 300px;
    }
    .homepage .single-event-container p.event-description {
        min-height: 250px;
    }
    .homepage .single-event-container p.event-description {
        font-size: 15px;
        line-height: 18px;
    }
    .homepage .event-container .actions-container {
        padding-top: 0;
    }
    .homepage.register-participant .single-event-container {
        padding: 0;
        margin-right: 15px;
    }
    .homepage .guests-container .smallContainer,
    .footer-container .smallContainer,
    .homepage .program-container .smallContainer {
        max-width: 1300px;
        margin: 0 auto;
    }
    .homepage .single-event-container,
    .login-page .single-event-container {
        padding-left: 25px;
    }
    .homepage .event-container .smallContainer,
    .login-page .event-container .smallContainer {
        padding: 0;
    }
    .homepage #show-video-homepage {
        width: 240px;
        height: 120px;
        right: 25px;
        top: 50px;
    }
    #random-banner {
        width: 240px;
        height: 120px;
        right: 25px;
        bottom: 65px;
    }
    .homepage .auditoriums-program {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media all and (max-width: 1370px) {
    .homepage .single-event-container h1,
    .login-page .single-event-container h1 {
        font-size: 40px;
    }
    .homepage .guests-container .smallContainer,
    .footer-container .smallContainer,
    .homepage .program-container .smallContainer {
        max-width: 1100px;
        margin: 0 auto;
    }
}
@media screen and (max-height: 800px) {
    .login-submit-button,
    .register-button,
    .buttons-wrapper button {
        margin-top: 10px;
        padding: 10px 0;
    }
    .social-btn {
        height: 40px;
        font-size: 15px;
    }
    .single-event-container .right-form > p {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .register-participant .register-form-wrapper > p {
        padding-bottom: 0;
        margin: 10px 0;
    }
    .register-form-wrapper form > div {
        margin-bottom: 5px;
    }
    .register-form-wrapper form .form-footer > label .upload-image {
        width: 40px;
        height: 40px;
    }
    .right-form form .form-footer .register-button {
        margin: 15px 0;
    }
    .form-left h2,
    .form-right h2 {
        margin-top: 0;
    }
}

@media all and (max-width: 1200px) {
    .homepage.register-participant .single-event-container {
        padding: 0;
        margin: auto;
        left: initial;
        right: initial;
    }
}

@media all and (max-width: 800px) {
    .not-found-page {
        height: 100vh !important;
    }
    .homepage-overlay {
        position: static !important;
    }
    .homepage-spinner-container .homepage-overlay {
        position: absolute !important;
    }
    .homepage.register-participant .single-event-container {
        flex-flow: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-top: 0 !important;
    }
    .register-participant .single-event-container {
        max-width: 500px;
        position: static;
        margin: 100px auto !important;
    }
    .main-menu-container .actions-container button {
        display: none;
    }
    .single-event-container .left-form {
        border-radius: 8px;
        max-width: 318px;
        margin-top: 30px;
        width: auto;
    }
    .single-event-container .right-form > p {
        margin-top: 0;
    }
    .single-event-container .right-form {
        margin-top: 30px;
    }
}

@media all and (max-height: 700px) {
    .homepage .single-event-container h1 {
        font-size: 32px;
    }
    .homepage .single-event-container p.event-description {
        font-size: 14px;
        line-height: 16px;
    }
    .homepage .event-container .actions-container {
        padding-top: 0;
    }
}

@media all and (max-width: 1283.9px) {
    .footer-container {
        max-width: 600px;
    }
}
@media all and (max-width: 767.9px) {
    footer {
        padding-bottom: 150px;
    }
    .footer-container .links-container {
        flex-flow: column;
    }
    .footer-container .links-container .inner-links li {
        margin-bottom: 16px;
    }
    .footer-container .links-container .social-buttons-wrapper p {
        margin-top: 60px;
    }
    footer .logo-wrapper {
        margin-top: 50px;
    }
}
@media screen and (max-width: 480px) {
    .login-popup .social-btn {
        padding-left: 14px;
        padding-right: 14px;
    }
    .login-popup .social-btn img {
        left: 14px;
    }
    .login-popup .social-btn p {
        font-size: 14px;
    }
}
@media screen and (max-width: 365px) {
    .login-popup .social-btn {
        padding-left: 10px;
        padding-right: 10px;
    }
    .login-popup .social-btn img {
        left: 10px;
        width: 20px;
    }
    .login-popup .social-btn p {
        padding-left: 20px;
    }
}
@media screen and (max-width: 340px) {
    .login-popup .social-btn p {
        font-size: 13px;
    }
}
@media screen and (max-width: 325px) {
    .login-popup .social-btn p {
        font-size: 11px;
    }
    .login-page .or-text:before,
    .login-page .or-text:after {
        width: 100px;
    }
}
