@import '../../../CSS/variables.module';
@import '../../../CSS/hpColors';

div.main {
    padding: 60px 212px;
    .speakers-container {
        width: 100%;
        row-gap: 8%;
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-wrap: wrap;
        max-width: none !important;

        div.speakers-item {
            width: 19%;
            min-height: 205px;

            .person-short {
                text-align: center;

                .person-img:hover:focus:active {
                    //filter: drop-shadow(0px 4px 10px var(--secondary-color)) !important;
                    box-shadow: 0px 4px 10px var(--secondary-color) !important;
                    border: 0;
                    outline: 0;
                }

                .person-img:focus-visible {
                    box-shadow: 0px 4px 10px var(--secondary-color) !important;
                    border: 0;
                    outline: 0;
                }

                .person-img:hover {
                    border: 3px solid var(--secondary-color);
                    filter: none !important;
                    outline: 0;
                }
                // .person-img:active {
                //     box-shadow: none !important;
                //     border: 3px solid var(--secondary-color) !important;
                // }

                div.person-img.no-image.no-hover-no-image:hover:focus:active {
                    filter: none !important;
                    background-position: center !important;
                    background-repeat: no-repeat !important;
                    outline: 0;
                    box-shadow: none !important;
                }
                .person-img.no-image.active-person-img.no-image-hover:hover:focus:active {
                    background: url(../../../Images/no-img-hover.png) !important;
                    background-position: center !important;
                    background-repeat: no-repeat !important;
                }
                div.person-img.no-image.no-hover-no-image:hover {
                    border: 3px solid $grey !important;
                    background: url(../../../Images/no-img.png) !important;
                    background-position: center !important;
                    background-repeat: no-repeat !important;
                    cursor: default !important;
                    outline: 0;
                }
                div.person-img.no-image {
                    border: 3px solid $grey;
                    background-repeat: no-repeat !important;
                    background-size: 68% !important;
                    box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                }

                div.person-img.no-image:hover:focus:active {
                    background-color: $white !important;
                    border: 3px solid $grey;

                    //filter: drop-shadow(0px 4px 10px var(--secondary-color)) !important;
                    box-shadow: 0px 4px 10px var(--secondary-color) !important;
                    outline: 0;
                    background: url(../../../Images/no-img.png) !important;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                }
                div.person-img.no-image:hover {
                    border: 3px solid var(--secondary-color);

                    background: url(../../../Images/no-img-hover.png) !important;
                    background-size: 68% !important;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                    outline: 0;
                }
                div.person-img.person-img.no-image-hover {
                    background-size: 80% !important;

                    border: 3px solid var(--secondary-color);
                    outline: 0;
                }
                div.person-img.no-hover {
                    cursor: auto;
                    outline: 0;
                    border: 3px solid $white !important;
                }
                div.person-img.no-hover:hover:focus:active {
                    filter: none !important;
                    outline: 0;
                    box-shadow: none !important;
                }
                div.person-img.no-hover:hover {
                    cursor: auto;
                    outline: 0;
                    border: 0;
                }
                .person-img:focus-visible {
                    box-shadow: 0 0 30px $black;
                    outline: 0;
                }
                .person-img {
                    transition: border 0.25s ease-out;
                    width: 100px;
                    outline: 0;
                    height: 100px;
                    margin: auto auto 5px auto;
                    border-radius: 180px;
                    box-shadow: none !important;
                    cursor: pointer;
                    background-size: cover !important;
                    background-position: center !important;
                    border: 3px solid $white;
                }
                .person-img.active-person-img {
                    width: 140px;
                    height: 140px;
                    border: 3px solid var(--secondary-color);
                    margin-bottom: 45px;
                    transition: width 0.3s, height 0.3s;
                    outline: 0;
                }
                .active-speaker-bottom {
                    background: linear-gradient(156.39deg, var(--secondary-color) 48.1%, $secondaryDarken 100%);
                    width: 100%;
                    height: 8px;
                    border-radius: 4px 4px 0 0;
                }
                ul.person-short-info {
                    word-break: break-word;
                    width: 100%;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li {
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        text-align: center;
                    }
                    li.person-name {
                        font-size: 14px;
                        color: $dark;
                        font-weight: 500;
                    }
                    li.person-profession {
                        font-size: 12px;
                        font-weight: normal;
                        color: $grey-dark;
                    }
                    li.person-social {
                        display: flex;
                        width: 100%;
                        gap: 8px;
                        justify-content: center;
                        align-items: center;
                        margin-top: 15px;
                    }
                }
            }

            .person-long {
                width: 100%;
                min-height: 296px;
                display: flex;
                position: absolute;
                right: 0;
                padding: 40px 212px;
                background-color: $white-variant;
                gap: 78px;
                overflow: hidden;
                button.close-button {
                    transition: background-color 0.5s ease;
                    background: none;
                    border: 0;
                    display: flex;
                    flex-direction: column;
                    height: 55px;
                    align-items: center;
                    gap: 12.5px;
                    padding: 4px 12px;
                    cursor: pointer;
                    width: 71px;
                    span.close-button-text {
                        font-family: $fontFamily, sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 120%;
                        color: var(--secondary-color);
                        display: none;
                    }
                }
                .close-button.visited-speaker {
                    svg {
                        path {
                            fill: var(--secondary-color);
                        }
                    }
                }
                button.close-button:hover:focus {
                    transition: background-color 0.5s ease;
                    background-color: $white;
                    box-shadow: 0 4px 10px var(--secondary-color);
                    border-radius: 6px;
                    svg {
                        path {
                            fill: var(--secondary-color);
                        }
                    }
                    span.close-button-text {
                        display: inline-flex;
                    }
                }

                button.close-button:hover {
                    svg {
                        path {
                            fill: var(--secondary-color);
                        }
                    }
                    span.close-button-text {
                        display: inline-flex;
                    }
                }
                button.close-button:active {
                    svg {
                        path {
                            fill: var(--secondary-color);
                        }
                    }
                }
                .person-introduce {
                    width: 20%;
                    h2 {
                        word-break: break-word;
                        font-size: 24px;
                        font-weight: 600;
                        color: var(--secondary-color);
                        margin-top: 0;
                    }
                    h3 {
                        word-break: break-word;
                        font-size: 14px;
                        font-weight: 500;
                        color: $dark;
                    }
                    ul.social-icon-container {
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        display: flex;
                        justify-content: flex-start;
                        gap: 12px;
                        height: 42px;
                        li {
                            height: 42px;
                        }
                    }
                }
                .person-description {
                    width: 75%;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                }
            }
        }
    }
}
button.mobile-close-button {
    background: transparent;
    border: 0;
    width: 200px;
    display: flex;
    align-items: center;
}
button.mobile-close-button:hover {
    h4 {
        color: var(--secondary-color);
    }
    svg.mobile-go-back-arrow {
        path {
            fill: var(--secondary-color);
        }
    }
}
h2.mobile-title {
    font-weight: bold;
    font-size: 42px;
    line-height: 42px;
    color: $dark;
    margin-top: 0;
    margin-bottom: 24px;
    display: none;
    margin-left: 2.5rem;
    margin-bottom: 2rem;
}
.main {
    h2.speakers-mobile-title-ml {
        font-size: 30px;
    }
}
//MOBILE
.isMobile {
    display: none !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center !important;
}
.go-back-bar {
    width: 100%;
    height: 75px;
    padding: 0 22px;
    background-color: $white;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    display: none;
    position: absolute;
    top: 0;
    left: 0;

    h4 {
        font-family: $fontFamily, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.04em;
        color: $dark;
    }
}
.description-text {
    word-break: break-word;
    width: 82%;
}

@media only screen and (max-width: 1025px) {
    svg.default-user.active-person-svg.mobile-prop-speaker-pic {
        display: block !important;
    }
    .close-dialog-speaker {
        display: none !important;
    }
    .no-scroll-speaker {
        position: fixed;
    }
    .close-button.visited-speaker {
        display: none !important;
    }
    h2.mobile-title {
        display: block !important;
        margin-left: 0.5rem;
    }
    .speaker-tab {
        display: block !important;
    }
    .go-back-bar h4 {
        margin-left: 18px !important;
    }
}
@media only screen and (max-width: 810px) {
    svg.default-user.active-person-svg.mobile-prop-speaker-pic {
        display: block !important;
    }
    .close-dialog-speaker {
        display: none !important;
    }
    .no-scroll-speaker {
        position: fixed;
    }
    .close-button.visited-speaker {
        display: none !important;
    }
    .go-back-bar h4 {
        margin-left: 18px !important;
    }
    .description-text {
        width: 100%;
    }
    div.main .speakers-container div.speakers-item .person-short ul.person-short-info li.person-social {
        margin-top: 0;
    }
    .go-back-bar {
        display: flex;
    }
    .isMobile {
        display: block !important;
    }
    div.main {
        padding: 0 64px;
    }
    div.main .speakers-container {
        row-gap: 36px;
    }
    div.main .speakers-container div.speakers-item {
        width: 29%;
        margin-bottom: 20px;
    }

    div.main .speakers-container div.speakers-item .person-long {
        top: 0;
        gap: 29px;
        flex-direction: column;
        padding: 120px 28px 28px 28px;
        z-index: 999;
        position: fixed;
        height: 100vh;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce,
    div.main .speakers-container div.speakers-item .person-long .person-description {
        width: 100%;
    }
    div.main .speakers-container div.speakers-item .person-long .person-description {
        overflow-y: auto;
        padding-right: 56px;
        padding-left: 56px;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .person-img.active-person-img.isMobile {
        width: 140px;
        height: 140px;
        border: 3px solid var(--secondary-color);
        margin-bottom: 12px;
        transition: width 0.3s, height 0.3s;
        border-radius: 180px;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce h2 {
        margin-bottom: 5px;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce h3 {
        margin: 0 0 16px 0;
        padding: 0;
    }
    .close-button {
        display: none;
    }
}
.speaker-tab {
    display: none;
}
//IPAD PRO
@media only screen and (max-width: 1080px) {
    svg.default-user.active-person-svg.mobile-prop-speaker-pic {
        display: block !important;
    }
    .close-dialog-speaker {
        display: none !important;
    }
    .no-scroll-speaker {
        position: fixed;
    }
    .speaker-add-prop {
        overflow-y: auto !important;
        padding-right: 10px !important;
        max-height: 304px !important;
    }
    .close-button.visited-speaker {
        display: none !important;
    }
    .mobile-hide-close.close-button {
        display: none !important;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1080px) {
    svg.default-user.active-person-svg.mobile-prop-speaker-pic {
        display: block !important;
    }
    .close-dialog-speaker {
        display: none !important;
    }
    .no-scroll-speaker {
        position: fixed;
    }
    .mobile-hide-close.close-button {
        display: none !important;
    }
    .description-text {
        width: 100%;
    }
    div.main .speakers-container div.speakers-item .person-short ul.person-short-info li.person-social {
        margin-top: 0;
    }
    .go-back-bar {
        display: flex;
    }
    .isMobile {
        display: block !important;
    }
    div.main {
        padding: 0 64px;
    }
    div.main .speakers-container {
        row-gap: 36px;
    }
    div.main .speakers-container div.speakers-item .person-long {
        top: 0;
        gap: 29px;
        flex-direction: column;
        padding: 120px 28px 28px 28px;
        z-index: 999;
        position: fixed;
        height: 100vh;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce,
    div.main .speakers-container div.speakers-item .person-long .person-description {
        width: 100%;
    }
    div.main .speakers-container div.speakers-item .person-long .person-description {
        overflow-y: auto;
        padding-right: 56px;
        padding-left: 56px;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .person-img.active-person-img.isMobile {
        width: 140px;
        height: 140px;
        border: 3px solid var(--secondary-color);
        margin-bottom: 12px;
        transition: width 0.3s, height 0.3s;
        border-radius: 180px;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce h2 {
        margin-bottom: 5px;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce h3 {
        margin: 0 0 16px 0;
        padding: 0;
    }
    .close-button {
        display: none;
    }
    //---
    div.main {
        padding: 60px 28px !important;
    }
    div.main .speakers-container div.speakers-item {
        width: 22% !important;
    }
    .go-back-bar h4 {
        margin-left: 0 !important;
    }
    .close-button.visited-speaker {
        display: none !important;
    }
}
@media only screen and (min-width: 810px) and (max-width: 1023px) {
    svg.default-user.active-person-svg.mobile-prop-speaker-pic {
        display: block !important;
    }
    .close-dialog-speaker {
        display: none !important;
    }
    .no-scroll-speaker {
        position: fixed;
    }
    .mobile-hide-close.close-button {
        display: none !important;
    }
    .close-button.visited-speaker {
        display: none !important;
    }
    .go-back-bar h4 {
        margin-left: 0 !important;
    }
    .description-text {
        width: 100%;
    }
    div.main .speakers-container div.speakers-item .person-short ul.person-short-info li.person-social {
        margin-top: 0;
    }
    .go-back-bar {
        display: flex;
    }
    .isMobile {
        display: block !important;
    }
    div.main {
        padding: 0 64px;
    }
    div.main .speakers-container {
        row-gap: 36px;
    }
    div.main .speakers-container div.speakers-item .person-long {
        top: 0;
        gap: 29px;
        flex-direction: column;
        padding: 120px 28px 28px 28px;
        z-index: 999;
        position: fixed;
        height: 100vh;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce,
    div.main .speakers-container div.speakers-item .person-long .person-description {
        width: 100%;
    }
    div.main .speakers-container div.speakers-item .person-long .person-description {
        overflow-y: auto;
        padding-right: 56px;
        padding-left: 56px;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .person-img.active-person-img.isMobile {
        width: 140px;
        height: 140px;
        border: 3px solid var(--secondary-color);
        margin-bottom: 12px;
        transition: width 0.3s, height 0.3s;
        border-radius: 180px;
        outline: 0 !important;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce h2 {
        margin-bottom: 5px;
    }
    div.main .speakers-container div.speakers-item .person-long .person-introduce h3 {
        margin: 0 0 16px 0;
        padding: 0;
    }
    .close-button {
        display: none;
    }
    //---
    div.main {
        padding: 60px 28px !important;
    }
    div.main .speakers-container div.speakers-item {
        width: 33% !important;
    }
}
//-END MOBILE

svg.svg-with-linkedin {
    outline: none;
    border: 0;
    border-radius: 90px;
}
svg.svg-with-linkedin:hover {
    rect {
        fill: $white !important;
    }
    path {
        fill: var(--secondary-color) !important;
    }
    outline: none;
    border: 0;
}
svg.svg-with-linkedin:hover:focus:active {
    box-shadow: 0px 4px 10px var(--secondary-color) !important;
    rect {
        fill: var(--secondary-color) !important;
    }
    path {
        fill: $white !important;
    }
    outline: none;
    border: 0;
}
//--
svg.svg-with-twitter {
    outline: none;
    border: 0;
    border-radius: 90px;
}
svg.svg-with-twitter:hover {
    rect {
        fill: $white !important;
    }
    path {
        fill: var(--secondary-color) !important;
    }
    outline: none;
    border: 0;
}
svg.svg-with-twitter:hover:focus:active {
    box-shadow: 0 4px 10px var(--secondary-color) !important;
    rect {
        fill: var(--secondary-color) !important;
    }
    path {
        fill: $white !important;
    }
    outline: none;
    border: 0;
}

//---website
svg.svg-with-world {
    outline: none;
    border: 0;
    border-radius: 90px;
}

svg.svg-with-world:hover {
    rect {
        fill: var(--secondary-color);
    }
    path {
        fill: $white;
    }
    outline: none;
    border: 0;
}

svg.svg-with-world:hover:focus:active {
    box-shadow: 0 4px 10px var(--secondary-color);
    rect {
        fill: $white;
    }
    path {
        fill: var(--secondary-color);
    }
    outline: none;
    border: 0;
}
//---end
svg.svg-with-fb:hover {
    path.fb-bg,
    ellipse {
        fill: var(--secondary-color);
    }
    path {
        fill: $white;
    }
}
svg.svg-with-fb {
    outline: none;
    border: 0;
    border-radius: 90px;
}
svg.svg-with-fb:hover:focus:active {
    box-shadow: 0 4px 10px var(--secondary-color) !important;
    path.fb-bg,
    ellipse {
        fill: $white;
    }
    path {
        fill: var(--secondary-color);
    }
    outline: none;
    border: 0;
}
h3.form-h.speaker-image {
    margin-top: 34px;
}
@media only screen and (max-width: 468px) {
    svg.default-user.active-person-svg.mobile-prop-speaker-pic {
        display: block !important;
    }
    .close-dialog-speaker {
        display: none !important;
    }
    .no-scroll-speaker {
        position: fixed;
    }
    .mobile-hide-close.close-button {
        display: none !important;
    }
    ul.social-icon-container {
        margin-bottom: 15px !important;
    }
    .close-button.visited-speaker {
        display: none !important;
    }
    .go-back-bar h4 {
        margin-left: 18px !important;
    }
    .description-text {
        width: 100%;
    }
    div.main .speakers-container div.speakers-item {
        width: 45% !important;
    }
    div.main .speakers-container {
        row-gap: 36px;
        justify-content: center !important;
    }
    div.main .speakers-container div.speakers-item .person-long .person-description {
        overflow-y: auto;
        padding-right: 10px !important;
        padding-left: 0 !important;
    }
    div.main {
        padding: 0 !important;
    }
    div.main .speakers-container div.speakers-item {
        width: 30%;
    }
    h2.mobile-title {
        margin-left: 30px !important;
        word-break: break-word;
    }
}

//speaker icons
.icons-common {
    background-color: $white;
    border-radius: 90px;
    background-position: center center;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    outline: 0 !important;
}

.speaker-facebook {
    background-image: url(./Helpers/Icons/png/facebook-default.png);
    background-size: 96%;
}
.speaker-facebook:hover {
    background-image: url('./Helpers/Icons/png/facebook-hover.png'),
        linear-gradient(156.39deg, var(--secondary-color) 48.1%, #9321c8 105%);
    background-color: $secondary;
}
.speaker-facebook:hover:active:focus {
    box-shadow: 0 4px 10px var(--secondary-color);
    background-image: url('./Helpers/Icons/png/facebook-default.png');
    background-color: $white;
    -webkit-transition: background-color 200ms ease-out;
    -ms-transition: background-color 200ms ease-out;
    transition: background-color 200ms ease-out;
}
.speaker-twitter {
    background-image: url(./Helpers/Icons/png/twitter-default.png);
    background-size: 89%;
}

.speaker-twitter:hover {
    background-image: url('./Helpers/Icons/png/twitter-hover.png'),
        linear-gradient(156.39deg, var(--secondary-color) 48.1%, #9321c8 105%);
    background-color: var(--secondary-color);
}
.speaker-twitter:hover:active:focus {
    box-shadow: 0 4px 10px var(--secondary-color);
    background-image: url('./Helpers/Icons/png/twitter-default.png');
    background-color: $white;
    -webkit-transition: background-color 200ms ease-out;
    -ms-transition: background-color 200ms ease-out;
    transition: background-color 200ms ease-out;
}
.speaker-linkedin {
    background-image: url(./Helpers/Icons/png/linkedin-default.png);
    background-size: 81%;
}
.speaker-linkedin:hover {
    background-image: url('./Helpers/Icons/png/linkedin-hover.png'),
        linear-gradient(156.39deg, var(--secondary-color) 48.1%, #9321c8 105%);
    background-color: var(--secondary-color);
}
.speaker-linkedin:hover:active:focus {
    box-shadow: 0 4px 10px var(--secondary-color);
    background-image: url('./Helpers/Icons/png/linkedin-default.png');
    background-color: $white;
    -webkit-transition: background-color 200ms ease-out;
    -ms-transition: background-color 200ms ease-out;
    transition: background-color 200ms ease-out;
}
.speaker-globe {
    background-image: url(./Helpers/Icons/png/globe-default.png);
}
.speaker-globe:hover {
    background-image: url('./Helpers/Icons/png/globe-hover.png'),
        linear-gradient(156.39deg, var(--secondary-color) 48.1%, #9321c8 105%);
    background-color: var(--secondary-color);
}
.speaker-globe:hover:active:focus {
    box-shadow: 0 4px 10px var(--secondary-color);
    background-image: url('./Helpers/Icons/png/globe-default.png');
    background-color: $white;
    -webkit-transition: background-color 200ms ease-out;
    -ms-transition: background-color 200ms ease-out;
    transition: background-color 200ms ease-out;
}
.mobile-go-back-arrow {
    width: 12px;
    height: 20px;
    margin-right: 10px;
}
.visited-speaker-mobile {
    h4 {
        color: var(--secondary-color);
    }
    svg.mobile-go-back-arrow {
        path {
            fill: var(--secondary-color);
        }
    }
}
.close-dialog-speaker.visited-speaker {
    .close-dialog-icon {
        background-image: url('./Helpers/Icons/png/close-dialog-hover.png');
    }
}
.close-dialog-speaker {
    outline: 0;
    cursor: pointer;
    background: none;
    border: 0;
    height: 48px;
    margin: 0;
    width: 76px;
    align-items: center;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    .close-dialog-text {
        font-family: $fontFamily, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: var(--secondary-color);
        margin-top: 12px;

        display: none;
    }
}
.close-dialog-icon {
    background-image: url('./Helpers/Icons/png/close-dialog-default.png');
    background-repeat: no-repeat;
    width: 18px;
    height: 10px;
}

.close-dialog-speaker:hover:focus:active {
    background: $white;
    box-shadow: 0 4px 10px var(--secondary-color);
    border-radius: 6px;
    .close-dialog-text {
        display: block;
    }
    .close-dialog-icon {
        background-image: url('./Helpers/Icons/png/close-dialog-hover.png');
    }
    svg.close-dialog-up {
        path {
            fill: var(--secondary-color);
        }
    }
}
.close-dialog-speaker:focus-visible {
    background: $white;
    box-shadow: 0 4px 10px var(--secondary-color);
    border-radius: 6px;
    .close-dialog-text {
        display: block;
    }
    .close-dialog-icon {
        background-image: url('./Helpers/Icons/png/close-dialog-hover.png');
    }
    svg.close-dialog-up {
        path {
            fill: var(--secondary-color);
        }
    }
}
.close-dialog-speaker:hover {
    .close-dialog-text {
        display: block;
    }
    .close-dialog-icon {
        background-image: url('./Helpers/Icons/png/close-dialog-hover.png');
    }
}
.person-img:focus-visible {
    box-shadow: 0px 4px 10px var(--secondary-color) !important;
    border: 0;
    outline: 0;
}
// .no-img-cover-hover {
//     display: flex;
//     width: 100%;
//     height: 100px;
//     border: 2px solid white !important;
// }
// .no-img-cover-hover:hover {
//     border: 0;
// }
.active-speaker-social {
    svg.facebook,
    svg.webpage,
    svg.twitter,
    svg.linkedin {
        outline: 0;
        border-radius: 180px;
    }
    svg.linkedin:hover {
        outline: 0;
        rect {
            fill: $white;
        }

        path {
            fill: var(--secondary-color);
        }
    }
    svg.linkedin:hover:focus:active {
        box-shadow: 0px 4px 10px var(--secondary-color);
        outline: 0;
        rect {
            fill: var(--secondary-color);
            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }

        path {
            fill: $white;
            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }
    }
    svg.linkedin:focus-visible {
        box-shadow: 0px 4px 10px var(--secondary-color);
        outline: 0;
        rect {
            fill: var(--secondary-color);
            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }

        path {
            fill: $white;
            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }
    }
    svg.twitter:hover {
        outline: 0;
        rect {
            fill: $white;
        }

        path {
            fill: var(--secondary-color);
        }
    }
    svg.twitter:hover:focus:active {
        box-shadow: 0px 4px 10px var(--secondary-color);
        outline: 0;
        rect {
            fill: var(--secondary-color);
            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }

        path {
            fill: white;
            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }
    }
    svg.twitter:focus-visible {
        box-shadow: 0px 4px 10px var(--secondary-color);
        outline: 0;
        rect {
            fill: var(--secondary-color);
            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }

        path {
            fill: $white;
            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }
    }
    svg.webpage:hover {
        outline: 0;
        rect {
            fill: var(--secondary-color);
        }
        g {
            path {
                fill: $white;
            }
        }
    }
    svg.webpage:hover:focus:active {
        box-shadow: 0px 4px 10px var(--secondary-color);
        outline: 0;
        rect {
            fill: $white;
            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }
        g {
            path {
                fill: var(--secondary-color);
                -webkit-transition: fill 200ms ease-out;
                -ms-transition: fill 200ms ease-out;
                transition: fill 200ms ease-out;
            }
        }
    }
    svg.webpage:focus-visible {
        box-shadow: 0px 4px 10px var(--secondary-color);
        outline: 0;
        rect {
            fill: $white;
            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }
        g {
            path {
                fill: var(--secondary-color);
                -webkit-transition: fill 200ms ease-out;
                -ms-transition: fill 200ms ease-out;
                transition: fill 200ms ease-out;
            }
        }
    }
    svg.facebook:hover {
        outline: 0;
        circle {
            fill: var(--secondary-color);
        }

        g {
            path {
                fill: $white;
            }
        }
        box-shadow: none;
    }
    svg.facebook:hover:focus:active {
        box-shadow: 0px 4px 10px var(--secondary-color);
        outline: 0;
        //***********
        circle {
            fill: $white;

            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }

        g {
            path {
                fill: var(--secondary-color);
                -webkit-transition: fill 200ms ease-out;
                -ms-transition: fill 200ms ease-out;
                transition: fill 200ms ease-out;
            }
        }
    }
    svg.facebook:focus-visible {
        box-shadow: 0px 4px 10px var(--secondary-color);
        outline: 0;
        //***********
        circle {
            fill: $white;

            -webkit-transition: fill 200ms ease-out;
            -ms-transition: fill 200ms ease-out;
            transition: fill 200ms ease-out;
        }

        g {
            path {
                fill: var(--secondary-color);
                -webkit-transition: fill 200ms ease-out;
                -ms-transition: fill 200ms ease-out;
                transition: fill 200ms ease-out;
            }
        }
    }
}
svg.close-dialog-up {
    outline: 0;
}
.mobile-hide-close:hover,
.mobile-hide-close.visited-speaker {
    svg.close-dialog-up {
        path {
            fill: var(--secondary-color);
        }
    }
}
/* iPhone 6 in portrait & landscape */
@media only screen and (min-device-height: 630px) and (max-device-height: 667px) {
    .description-text.speaker-add-prop {
        max-height: 184px !important;
    }
}

/* iPhone 6 in landscape */
@media only screen and (min-device-height: 630px) and (max-device-height: 667px) and (orientation: landscape) {
    .description-text.speaker-add-prop {
        max-height: 184px !important;
    }
}

/* iPhone 6 in portrait */
@media only screen and (min-device-height: 630px) and (max-device-height: 667px) and (orientation: portrait) {
    .description-text.speaker-add-prop {
        max-height: 184px !important;
    }
}

@media only screen and (min-device-height: 730px) and (max-device-height: 736px) {
    .description-text.speaker-add-prop {
        max-height: 184px !important;
    }
}
svg.default-user {
    border-radius: 180px;
    outline: 0;
    cursor: pointer;
    rect {
        outline: 0;
        stroke-width: 2px !important;
    }
}
svg.default-user:hover {
    rect.circle-rect {
        outline: 0;
        stroke-width: 3px !important;
        stroke: var(--secondary-color);
        -webkit-transition: stroke 200ms ease-out;
        -ms-transition: stroke 200ms ease-out;
        transition: stroke 200ms ease-out;
        -webkit-transition: stroke-width 200ms ease-out;
        -ms-transition: stroke-width 200ms ease-out;
        transition: stroke-width 200ms ease-out;
    }
    path.person-speaker-bg {
        fill: var(--secondary-color);
        -webkit-transition: fill 200ms ease-out;
        -ms-transition: fill 200ms ease-out;
        transition: fill 200ms ease-out;
    }
}
svg.default-user:hover:focus:active {
    box-shadow: 0px 4px 10px var(--secondary-color) !important;
    rect.circle-rect {
        outline: 0;
        stroke-width: 2px !important;
        stroke: $grey;
        -webkit-transition: stroke 200ms ease-out;
        -ms-transition: stroke 200ms ease-out;
        transition: stroke 200ms ease-out;
        -webkit-transition: stroke-width 200ms ease-out;
        -ms-transition: stroke-width 200ms ease-out;
        transition: stroke-width 200ms ease-out;
    }
    path.person-speaker-bg {
        fill: $grey;
        -webkit-transition: fill 200ms ease-out;
        -ms-transition: fill 200ms ease-out;
        transition: fill 200ms ease-out;
    }
}
svg.default-user:focus-visible {
    box-shadow: 0px 4px 10px var(--secondary-color) !important;
    rect.circle-rect {
        outline: 0;
        stroke-width: 2px !important;
        stroke: $grey;
        -webkit-transition: stroke 200ms ease-out;
        -ms-transition: stroke 200ms ease-out;
        transition: stroke 200ms ease-out;
        -webkit-transition: stroke-width 200ms ease-out;
        -ms-transition: stroke-width 200ms ease-out;
        transition: stroke-width 200ms ease-out;
    }
    path.person-speaker-bg {
        fill: $grey;
        -webkit-transition: fill 200ms ease-out;
        -ms-transition: fill 200ms ease-out;
        transition: fill 200ms ease-out;
    }
}
svg.default-user.active-person-svg {
    transition: width 0.3s, height 0.3s;
    width: 134px;
    height: 134px;
    margin-bottom: 45px;
    rect.circle-rect {
        outline: 0;
        stroke-width: 3px !important;
        stroke: var(--secondary-color);
        -webkit-transition: stroke 200ms ease-out;
        -ms-transition: stroke 200ms ease-out;
        transition: stroke 200ms ease-out;
        -webkit-transition: stroke-width 200ms ease-out;
        -ms-transition: stroke-width 200ms ease-out;
        transition: stroke-width 200ms ease-out;
    }
    path.person-speaker-bg {
        fill: var(--secondary-color);
        -webkit-transition: fill 200ms ease-out;
        -ms-transition: fill 200ms ease-out;
        transition: fill 200ms ease-out;
    }
}
svg.outline-0 {
    outline: 0 !important;
}
svg.default-user.active-person-svg.mobile-prop-speaker-pic {
    margin-bottom: 12px !important;
}
svg.default-user.active-person-svg.mobile-prop-speaker-pic {
    display: none;
}
.focus-black-white-unselected:focus-visible {
    border-color: $grey !important;
}
div.main
    .speakers-container
    div.speakers-item
    .person-short
    div.person-img.no-hover.focus-black-white-unselected:focus-visible {
    border-color: $grey !important;
}
@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        div.main .speakers-container div.speakers-item .person-short div.person-img.no-hover:focus {
            border-color: transparent !important;
        }
        .person-img:hover {
            border: 3px solid var(--secondary-color) !important;
            filter: none !important;
            outline: 0 !important;
            box-shadow: none !important;
        }

        .person-img:focus {
            box-shadow: 0px 4px 10px var(--secondary-color) !important;
            border: 0 !important;
            outline: 0 !important;
        }

        .person-img.active-person-img,
        .person-img.active-person-img:focus {
            box-shadow: none !important;
            border: 3px solid var(--secondary-color) !important;
        }
        // .person-img:hover:focus:active {
        //     border: 3px solid var(--secondary-color) !important;
        //     filter: none !important;
        //     outline: 0 !important;
        //     box-shadow: none !important;
        // }
        .person-img:focus:not(:focus-visible) {
            border: 3px solid var(--secondary-color) !important;
            filter: none !important;
            outline: 0 !important;
            box-shadow: none !important;
        }
    }
}

div.main .speakers-container div.speakers-item .person-short div.person-img.no-hover:focus-visible {
    border-color: transparent !important;
}
