@media only screen and (min-width: 768px) {
    .auditoriumOverlayPlayer.desktop-helper {
        width: 96%;
        left: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .booth-video-container.mobile-helper {
        padding: 0;
    }
    #form-dialog-title.mobile-helper.MuiDialogTitle-root {
        margin-bottom: 0 !important;
    }
}

.mobile-helper.booth-video-actions-container {
    button.fullscreen-button {
        svg {
            fill: black !important;
        }
    }
}

.react-player.full-screen-all {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
}

.booth-video-actions-container.full-screen-all {
    padding: 10px 20px !important;
    z-index: 9999999;
    background-color: #ffffffad;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    width: 80%;
    border-radius: 9px;
    bottom: 20px;
}
.fullscreen-bg {
    background-color: black !important;
}

@media only screen and (max-width: 900px) and (min-width: 667px) and (orientation: landscape) and (display-mode: browser) {
    .booth-video-container.mobile-helper,
    .homepage .booth-video-container {
        height: auto;
        width: 100%;
    }
    .booth-video-actions-container.mobile-helper {
        margin-top: -75px;
        position: relative;
        z-index: 99999999999999;
        padding: 5px 11px;
        background: #ffffff9e;
    }
    .booth-video-actions-container.mobile-helper.full-screen-all {
        margin: 33px auto auto auto !important;
    }
    .MuiDialogContent-root.booth-dialog-content.booth-video-dialog-container {
        overflow: hidden;
    }
}
// .vjs-fade-out {
//     display: none ;
// }
