@import 'CSS/variables.module';


.user-consent-collection-updates-page{
    width: 80%;
    word-break: break-word;
    @media screen and (max-width: 1024px){
        width: 100%;
    }
    .user-consent-collection-updates-page-background-image{
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        object-fit: cover;
        width: 20%;
        @media screen and (max-width: 1024px){
            display: none;
        }
    }
    .user-consent-collection-updates-page--header{
        position: fixed;
        width: 80%;
        padding: 16px 40px;
        background-color: white;
        z-index: 2;

        @media screen and (max-width: 1024px){
            width: 100%;
        }
        @media screen and (max-width: 768px){
            padding: 16px 14px 0 28px;
            margin-bottom: 19px;
        }
    }
    .user-consent-collection-updates-list{
        max-width: 880px;
        margin: 0 auto;
        padding: 150px 40px;
        color: $dark;
        .single-privacy-policy-notification{
            margin-bottom: 34px;
            @media screen and (max-width: 768px){
                &:last-child{
                    margin-bottom: 80px;
                }
            }
            h3{
                font-weight: 700;
                font-size: 32px;
                line-height: 42px;
                margin: 0;
            }
            .updated-text{
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: $grey-dark;
                margin: 0;
                padding-bottom: 28px;
            }
            .single-privacy-policy-description-text{
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                word-break: break-word;
            }
            .single-privacy-policy-link{
                display: inline-block;
                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                color: $primary;
                margin-top: 48px;
                text-transform: uppercase;
            }
        }
        @media screen and (max-width: 768px){
            padding: 80px 16px 80px;
        }
    }

    .accept-privacy-policy-updates-button-container{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 80%;
        padding-top: 20px;
        padding-bottom: 30px;
        text-align: center;
        box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
        background-color: white;
        .MuiButton-root{
            padding: 12px 84px;
            .MuiButton-label{
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                letter-spacing: 0.0125em;
                text-transform: uppercase;
            }
        }

        @media screen and (max-width: 1024px){
            width: 100%;
        }
        @media screen and (max-width: 768px){
            .accept-privacy-policy-updates-button-container{
                width: 100%;
                right: 0;
                padding: 16px 12px;
                button{
                    width: 100%;
                }
            }
        }
    }
}



.user-consent-collection-updates-page--header--menu{
    padding: 0;
    .MuiPaper-root.MuiMenu-paper .MuiMenu-list .MuiMenuItem-root{
        padding: 0;
        .MuiListItemText-root{
            margin: 0;
            span{
                padding: 10px 12px;
            }
        }
    }
}


.lang-rtl{
    .user-consent-collection-updates-page-background-image{
        left: 0 !important;
        right: auto !important;
    }
}