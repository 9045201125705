// @import 'CSS/variables.module';
@import '../CSS/variables.module';
@media screen and (max-width: 1080px) {
    .sort-container.mobile-helper {
        border-top: 1px solid gray;
        padding-top: 1.5px;
    }
}
.search-icon-container.ar-helper {
    margin-right: 0;
    margin-left: 20px;
}
.network-filters__reset-btn.ar-lang-reset {
    margin: 0 0 0 20px !important;
}
/* input[type='search'] {
    background-color: red !important;
} */
.search-user-container:hover {
    box-shadow: 0 5px 9px #00000029;
    z-index: 999999;
    position: relative;

    // .cursor-pointer.network-filters-show-btn {
    //     svg {
    //         path {
    //             fill: $secondary;
    //         }
    //     }
    // }
}
// #standard-search::-webkit-search-cancel-button{
//     position:relative;
//     right:20px;

//     -webkit-appearance: none;
//     height: 20px;
//     width: 20px;
//     border-radius:10px;
//     background: red;
// }
.cursor-pointer.network-filters-show-btn {
    outline: 0;
}

// .network-filters.network-filters--show{
//     .cursor-pointer.network-filters-show-btn{
//         background: red;
//     }
// }
.filled-cancel {
    right: 53px;
    display: none;
    cursor: pointer;
}
.search-container-helper {
    display: flex;
    width: 87%;
    margin-right: 12px;
    .search-input-container {
        align-items: center;
    }
    input#standard-search {
        background-color: $white-variant;
    }
}
::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
.search-container-helper:hover {
    input#standard-search {
        color: $secondary !important;
    }
    input#standard-search::placeholder {
        color: $secondary !important;
    }
    .search-icon-container {
        svg {
            // color: rgb(119, 112, 129);
            // box-shadow: 1px 1px 3px #00000063;
            // border-radius: 6px;
            fill: $secondary;
        }
    }

    input#standard-search:focus-visible,
    input#standard-search:focus,
    input#standard-search::placeholder:focus-visible,
    input#standard-search::placeholder:focus {
        color: $dark !important;
    }
}
span.cursor-pointer.network-filters-show-btn.filters-active {
    width: 25px;
    display: block;
    background: $secondary !important;
    padding: 0 3px;
    border-radius: 6px;
    svg {
        path {
            fill: $white;
        }
    }
}
span.cursor-pointer.network-filters-show-btn:hover.filters-active {
    svg {
        path {
            fill: $white !important;
        }
    }
}
span.cursor-pointer.network-filters-show-btn:hover:active:focus.filters-active {
    box-shadow: 0px 4px 10px #6926d3;
}

.search-user-container.add-helper {
    visibility: hidden;
    // border-bottom: 1px solid black;
}
.network-filters.network-filters--show {
    background-color: $white-variant;
}
.search-icon-container:focus:active,
.search-icon-container:focus-visible:active {
    padding: 0px 1px;
    box-shadow: 0px 4px 10px #6926d3;
    border-radius: 6px;
}
.search-user-container:focus:active,
.search-user-container:focus:active {
    box-shadow: 0px 4px 10px #6926d3;
}
@media screen and (max-height: 400px) {
    .empty-artwork {
        display: none;
    }
}
