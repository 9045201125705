@import 'variables.module';

.notifications-page.dropdown-component {
    max-width: 323px;
    overflow: initial;
    background-color: #f5f5f5;
    height: auto;
    top: 43px;
    left: auto;
    right: 0;
}
.notifications-page .notifications-container {
    overflow: auto;
    padding: 20px 10px;
    background-color: #fff;
    min-height: 255px;
    position: relative;
}
.notifications-page .notifications-container.active-scrollbar {
    height: 235px;
}
.notifications-page .notifications-container.active {
    max-height: calc(100vh - 185px);
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
}
.notifications-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.notifications-list.active {
    height: auto;
    overflow-y: visible;
}
.notifications-list li {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $dark;
}
.single-notification-text {
    display: flex;
    cursor: pointer;
    padding-bottom: 3px;
    margin-bottom: 8px;
}
.notifications-list li:last-of-type .single-notification-text {
    margin: 0;
}
.contacts-wrapper {
    flex: 1;
    text-align: start;
}
.contacts-wrapper .user-name {
    word-break: break-word;
    margin: 0;
    color: $secondary;
}
.contacts-wrapper span:last-of-type {
    margin-inline-start: 3px;
}
.date-time {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: $text-grey;
    margin-left: 20px;
}
.notifications-page .see-all-notifications {
    margin-top: 0;outline: 0;

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            background-color: $secondary-light;
        }
    }
}
.see-all-notifications p {
    padding: 13px 0;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: $secondary;
    margin: 0;
    text-align: center;
    cursor: pointer;
}
/* Notifications mobile */
.notifications-page.notifications-mobile {
    max-width: initial;
    width: 100%;
    top: 56px;
}
.notifications-mobile .notifications-container.active {
    max-height: calc(100vh - 315px);
}
.notifications-mobile .page-title {
    height: 48px;
    line-height: 48px;
    text-align: left;
    padding-left: 16px;
}
.notifications-mobile .page-title h3 {
    font-weight: 600;
    font-size: 16px;
}
.notifications-mobile .notifications-list li {
    font-size: 14px;
}
// @media only screen and (max-width: 1025px) {.notifications-mobile .notifications-list li:last-child{margin-bottom: 90px;}}

.notifications-mobile .notifications-list li .date-time {
    font-size: 12px;
}
@media screen and (min-width: 1025px) {
    .notifications-page.dropdown-component {
        z-index: 6;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
}
