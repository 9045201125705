.secondary{
    //date input
    .MuiPickersToolbar-toolbar,
    .MuiPickersDay-daySelected{
        background-color: #208918;
    }
    .MuiPickersDay-current{
        color: #208918;
    }
    .MuiButton-textPrimary{
        color: #208918;
        &:active{
            background: #208918;
            color: white
        }
    }

    // time input
    .MuiPickersClock-pin,
    .MuiPickersClockPointer-pointer,
    .MuiPickersClockPointer-noPoint{
        background-color: #208918;
    }
    .MuiPickersClockPointer-thumb{
        border-color: #208918;
    }
}
.MuiPickersTimePickerToolbar-hourMinuteLabel {
    align-items: center;
}
.MuiPickersDay-daySelected{
    color: #fff !important;
}
.MuiPickersTimePickerToolbar-hourMinuteLabel {
    width: 215px;
    min-width: 215px;
}
.MuiPickersTimePickerToolbar-ampmSelection {
    margin-right: 0;
    margin-left: 0;
}