@import 'variables.module';

#account-drop-mobile {
    max-width: initial;
    width: 100%;
    top: 56px;
    z-index: 2;
    background-color: #ffffff;
}

#account-drop-mobile .page-title {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
}
#account-drop-mobile .page-title h3 {
    font-weight: 600;
    font-size: 16px;
}
#account-drop-mobile .myaccount-container {
    padding: 0 16px;
    max-height: 340px;
    overflow: auto;
}
.create-event-wrapper {
    display: flex;
    align-items: center;
}
.create-event-wrapper span {
    color: #ffffff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    margin-inline-start: 10px;
}
#account-drop-mobile.my-account-component.dropdown-component .row {
    font-size: 16px;
    font-weight: bold;
    color: $text-grey;
    border: 0;
    padding-bottom: 22px;
    margin: 0;
}
#account-drop-mobile.my-account-component.dropdown-component .row:last-of-type {
    padding: 0;
}
