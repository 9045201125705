// variants:
// -color "primary"
// -color "secondary"
// -color "red" (using className "is-sharing")



.MuiSwitch-root{
    width: 60px;
    height: 40px;
    padding: 13px;
}
.MuiSwitch-switchBase{
    padding: 10px;
    &:hover {
        background-color: rgba(119, 112, 129, 0.1); // #777081 with 0.1 opacity;
    }
}
.MuiSwitch-thumb{
    background: #777081;
}
.MuiSwitch-track{
    background: #BBB8C0;
    opacity: 1;
}



// color="primary"
.MuiSwitch-colorPrimary{
    // checked
    &.Mui-checked{
        &:hover{
            background-color: rgba(102, 36, 209, 0.1); // #6624D1 with 0.1 opacity
        }
        .MuiSwitch-thumb{
            background: #6624D1;
        }
        + .MuiSwitch-track{
            background: #B291E8;
            opacity: 1;
        }
    }

    // disabled
    &.Mui-disabled{
        .MuiSwitch-thumb{
            background: #BBB8C0;
        }
        + .MuiSwitch-track{
            background: #DDDBDF;
            opacity: 1;
        }

        // disabled && checked
        &.Mui-checked{
            .MuiSwitch-thumb{
                background: #B291E8;
            }
            + .MuiSwitch-track{
                background: #D9C8F3;
                opacity: 1;
            }
        }
    }
}

// color="secondary"
.MuiSwitch-colorSecondary{
    // checked
    &.Mui-checked{
        &:hover{
            background-color: rgba(32, 137, 24, 0.1); // #208918 with 0.1 opacity
        }
        .MuiSwitch-thumb{
            background: #208918;
        }
        + .MuiSwitch-track{
            background: #7EB27A;
            opacity: 0.5;
        }
    }

    // disabled
    &.Mui-disabled{
        .MuiSwitch-thumb{
            background: #BBB8C0;
        }
        + .MuiSwitch-track{
            background: #DDDBDF;
            opacity: 1;
        }

        // disabled && checked
        &.Mui-checked{
            .MuiSwitch-thumb{
                background: #7EB27A;

            }
            + .MuiSwitch-track{
                background: #BED8BC;
                opacity: 0.5;
            }
        }
    }
}


// for additional colors, we need to add a class to the <Switch />
.MuiSwitch-root.is-sharing {
    .Mui-checked{
        &:hover{
            background-color: rgba(216, 42, 42, 0.1); // #D82A2A with 0.1 opacity
        }
        .MuiSwitch-thumb{
            background: #D82A2A;
        }
        + .MuiSwitch-track{
            background: #EB9494;
            opacity: 1;
        }
    }

    // disabled
    .Mui-disabled{
        .MuiSwitch-thumb{
            background: #BBB8C0;
        }
        + .MuiSwitch-track{
            background: #DDDBDF;
            opacity: 1;
        }

        // disabled && checked
        &.Mui-checked{
            .MuiSwitch-thumb{
                background: #EB9494;
            }
            + .MuiSwitch-track{
                background: #F5CACA;
                opacity: 1;
            }
        }
    }
}