.organizer-dashboard-page ul.subtabs.showfloor-helper {
    padding: 0;
    li a {
        border: 1px solid black;
        padding: 5px;
        margin-bottom: 10px;
        background-color: white !important;
        .dragDrop-element {
            margin-top: -5px !important;
            margin-right: 15px;
            margin-left: 5px;
        }
    }
    li {
        list-style: none;
    }
    li.add-new-button a {
        border: 0;
    }
}
.program-tab-setup {
    height: 48px;
    padding-left: 5px;
}
.program-tab-setup.active {
    padding-left: 0;
    color: #208918;
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation p {
    padding-left: 5px;
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation .accordion.active p.space-between {
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
}
.add-day-btn {
    &:hover {
        background: transparent;
    }
}
