@import 'CSS/variables.module';
.vote-banner-container.enabled {
    opacity: 1;
    top: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: all;
    visibility: visible;
    z-index: 9;
}
.vote-banner-container {
    background: rgba(43, 43, 43, 0.9);
    display: flex;
    width: 100%;
    padding: 5px 32px;
    position: absolute;
    top: -40px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    left: 0;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    transition: all 0.5s ease-in-out;
    pointer-events: none;

    span.message {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 99.6%;
        color: $white;
    }

    div.vote-actions {
        display: flex;
        align-items: center;
        gap: 12px;
        min-width: 210px;
        justify-content: flex-end;
        cursor: pointer;
    }
    .vote-action {
        height: 37px;
        background: $white;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 10px;
        position: relative;
        span.MuiCheckbox-root {
            padding: 0;
            margin: 0;
            .MuiSvgIcon-root {
                width: 16px;
            }
        }
        span.MuiCheckbox-root:hover {
            background: transparent;
        }
        span.vote-text {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            text-transform: uppercase;
            color: $black-variant;
        }
    }
    @media screen and (max-width: 567px) {
        .vote-action {
            gap: 4px;
        }
    }
}
.vote-banner-container.mobile {
    position: relative;
    padding: 10px 16px;
    align-items: flex-start;
}
.vote-number {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 9px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 110%;
    color: $online-for-black-bg;
    text-transform: lowercase;
}
.message-container.mobile {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    span.message {
        font-size: 14px;
    }
    .vote-number {
        font-size: 10px;
    }
    @media screen and (max-width: 567px) {
        .vote-number {
            font-size: 12px;
        }
    }
}
.with-vote {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: row;
}
.spinner-container-vote.vote-banner-top {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 6px;
    z-index: 999;
    width: 100%;
    height: 37px;
    background: #ffffffbd;
    .spinner {
        background: transparent;
    }

    svg {
        width: 20px !important;
        height: 20px;
    }
    .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate {
        width: 20px !important;
        height: 20px !important;
    }
}
.vote-action.disabled {
    .vote-text,
    .MuiIconButton-root {
        color: $grey !important;
    }
}
