.video-stream-buttons {
    display: flex;
    justify-content: space-around;
    position: absolute;
    width: 100%;
    z-index: 100;
    top: 0;
    background-color: rgba(#000, 0.5);
    .button {
        color: #fff !important;
        font-size: 70px !important;
        cursor: pointer;
    }
}
