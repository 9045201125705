.user-edit-container-form {
    .Mui-focused {
        &.MuiFormLabel-root {
            color: #6624d1;
            &.Mui-error {
                color: #d82a2a !important;
                margin-bottom: 5px;
                margin-top: 0;
            }
        }
        &.MuiFormLabel-colorSecondary {
            color: #208918;
        }
        .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
            border-color: #6624d1 !important;
        }
    }
    .Mui-error {
        margin-bottom: 5px;
        margin-top: 0;
    }
    .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled.Mui-focused {
        display: flex;
    }
    .Mui-error fieldset {
        border: 1px solid #e73131 !important;
    }
}
