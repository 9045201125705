@import 'variables.module';

.other-pages #header {
    background-color: $secondary;
    position: initial;
}
.other-pages .smallContainer {
    max-width: 1192px;
    padding-left: 15px;
    padding-right: 15px;
}
.privacy-policy-container {
    padding-bottom: 130px;
    padding-top: 35px;
}
.alpha-list {
    list-style: lower-alpha;
}
.other-pages h2 {
    margin-top: 40px;
}
.other-pages h3 {
    margin-top: 30px;
}
ol ul {
    list-style-type: initial;
}
.other-pages ul li p {
    margin-top: 0;
    margin-bottom: 7.5px;
}
.other-pages ul li {
    margin-bottom: 15px;
}
.bold-words {
    font-weight: 700;
}
.alphabetical-list {
    list-style-type: lower-alpha;
}
.round-type-list {
    list-style-type: disc;
}
.none-type {
    list-style-type: none;
}
.bold-numbered-list > li {
    font-weight: bold;
}
.bold-numbered-list .font-normal {
    font-weight: normal;
}
.unsubscribe-page .register-form-wrapper{
    display: flex;
    justify-content: center;
    align-items: baseline;
}
