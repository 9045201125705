@import 'variables.module';

#form-dialog-title h2 {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    color: $dark;
    text-align: start;
}
#form-dialog-secondary {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.005em;
    color: $dark;
}
.form-upload-picture {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $dark;
}

.dialog-btn-uppercase {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
    &.button-type {
        color: $black;
        padding: 5px;
        display: inline-block;
        margin-bottom: 32px;
    }

    img {
        margin-right: 8px;
        filter: invert(1) saturate(0) contrast(400%);
    }

    .img-color {
        margin-right: 8px;
        filter: none;
    }

    div {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .add-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        margin-top: -4px;
    }

    svg {
        margin-inline-end: 8px;
    }
}
.dialog-btn-uppercase-white {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
    color: $white;

    img {
        margin-right: 8px;
        filter: invert(1) saturate(0) contrast(400%);
    }

    .img-color {
        margin-right: 8px;
        filter: none;
    }

    div {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    svg {
        margin-right: 8px;
    }
}
.dialog-btn-custom {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
    svg {
        margin-right: 8px;
        margin-bottom: 8px;
    }
}
.dialog-btn-uppercase-light {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    text-transform: uppercase;

    img {
        margin-right: 8px;
        filter: invert(1) saturate(0) contrast(400%);
    }

    .img-color {
        margin-right: 8px;
        filter: none;
    }

    div {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    svg {
        margin-right: 8px;
    }
}
.img-review-dialog {
    display: contents !important;
    img {
        width: 95%;
    }
}
.double-option-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .MuiFormControl-root:first-child {
        margin-right: 24px;
    }
    .MuiFormControl-root:last-child {
        margin-left: 24px;
    }
    .MuiInputAdornment-positionEnd {
        margin-right: -12px;
    }
}
.create-new-archived-event .MuiInputAdornment-positionEnd {
    margin-right: -12px;
}
.expand-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 5px 0 10px;
}
.MuiDialogContent-root p {
    direction: ltr;
}
.confirm-event-privacy-policy label {
    pointer-events: none;

    .MuiCheckbox-root {
        pointer-events: visible;
    }
}
.corg-terms-checkbox {
    padding: 0 !important;
    margin-right: 8px;
}
.exhibitor-representative-dialog {
    &.avatar-dialog,
    &.medalions {
        .MuiDialog-paper {
            max-width: 1120px;
        }
        .dialog-container {
            display: flex;
            & > div {
                width: 50%;
                &:first-of-type {
                    border-right: 1px solid $disabled-text;
                    margin-inline-end: 24px;
                    padding-inline-end: 24px;
                }
            }
            .upload-avatar-wrapper {
                padding-bottom: 10px;
                p {
                    font-size: 14px;
                }
                a {
                    color: $secondary;
                }
            }
            .or-wrapper {
                padding-top: 5px;
                padding-bottom: 20px;
            }
            .search-here {
                label {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 18px;
                    font-family: $fontFamily, sans-serif;
                    &.MuiFormLabel-filled {
                        & ~ div {
                            &:before {
                                border-color: $secondary;
                            }
                        }
                    }
                }
            }
            .current-selected-representative {
                & > div {
                    &:nth-child(2) {
                        flex: 1;
                    }
                }
                .remove-representative {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: $dark;
                    svg {
                        path {
                            fill: $dark;
                        }
                    }
                    span {
                        margin-inline-start: 9px;
                    }
                }
                p {
                    &:first-of-type {
                        font-weight: 600;
                        color: $dark;
                    }
                }
            }
        }
        .buttons-container {
            background: #f5f5f5;
            border-top: 1px solid $disabled-text;
            padding-top: 15px;
            padding-bottom: 15px;
            button {
                &:last-of-type {
                    margin-inline-end: 16px;
                }
            }
        }
        .participants-search-wrapper {
            max-height: 355px;
            height: initial;
            padding: 0;
            &.scholar {
                max-height: 500px;
            }
            & > div {
                padding-inline-start: 6px;
            }
            .friends-list {
                li {
                    margin-bottom: 16px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
                .info-container {
                    .user-avatar {
                        width: 40px;
                        height: 40px;
                    }
                    p {
                        color: $dark;
                        &:first-of-type {
                            font-weight: 600;
                        }
                        &:last-of-type {
                            font-size: 16px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
    .MuiDialog-paper {
        margin: 0;
    }
    #form-dialog-title {
        h2 {
            font-weight: 600;
            font-size: 24px;
            color: $dark;
            padding-top: 20px;
            padding-bottom: 15px;
        }
    }
    h3 {
        font-weight: 500;
        font-size: 20px;
        color: $primary;
        text-transform: uppercase;
        font-family: $fontFamily, sans-serif;
        margin-top: 0;
        margin-bottom: 12px;
    }
    .dialog-container {
        p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            margin: 0;
        }
    }
    .search-here {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 14px;
        span {
            color: $text-grey;
            font-family: $fontFamily, sans-serif;
            text-transform: uppercase;
            margin-inline-start: 8px;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
        }

        & > div {
            &:last-of-type {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        .hide-button {
            opacity: 0;
            transition: opacity 0.5s ease-in;
        }
    }

    .MuiGrid-root {
        &.avatarSelection {
            margin: 18px 0;

            &.scholar {
                & > div {
                    padding-bottom: 15px;
                }
                .avatar-details-container {
                    height: 176px;
                    padding: 0;

                    &.active {
                        background: #ffffff;
                        border-width: 1px;
                    }

                    .predefinedAvatar {
                        width: 100%;
                        height: 100%;
                        box-shadow: none;
                    }
                    div {
                        display: none;
                    }
                }
            }
        }
    }

    .buttons-container {
        padding-bottom: 16px;
        button {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: $dark;

            &:disabled {
                color: $text-grey;
            }

            &:last-of-type {
                margin-inline-start: 32px;
                margin-inline-end: 32px;
            }
        }
    }

    .or-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        padding: 20px 0 30px;
    }
    &.medalions {
        .dialog-container {
            overflow-x: hidden;
            flex-direction: column;
            & > div {
                width: 100%;
                &:first-of-type {
                    padding-inline-end: 0;
                    border-right: 0;
                    margin-inline-end: 0;
                }
            }
        }
        .search-here {
            min-width: 500px;
            @media screen and (max-width: 650px) {
                min-width: 250px;
            }
        }
        .participants-search-wrapper {
            max-height: 355px;
            &[data-empty='true'] {
                height: auto;
                padding: 0;
            }
        }
    }
    .remove-picture-representative {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: $error-light;
    }
}
.exhibitor-representative-dialog p {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: rgba(43, 43, 43, 0.9);
    margin-bottom: 10px;
    text-align: start;
}
.exhibitor-representative-dialog .avatarSelection {
    width: 100%;
    margin: 0;
}
.exhibitor-representative-dialog .avatar-details-container {
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px 30px;
    height: 119px;
    font-size: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.exhibitor-representative-dialog .avatar-details-container.active {
    background: #f5f5f5;
    border: 0.5px solid rgba(90, 180, 83, 0.5);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.exhibitor-representative-dialog .avatar-details-container .predefinedAvatar {
    border-radius: 50%;
    width: 83px;
    height: 83px;
    border: 0.5px solid #ffffff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}
.exhibitor-representative-dialog .avatar-details-container div {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding-top: 12px;
}
.exhibitor-representative-dialog .current-selected-representative {
    display: flex;
    align-items: center;
    padding: 22px 0;

    p {
        line-height: 18px;
        overflow-wrap: anywhere;
    }
}
.exhibitor-representative-dialog .current-selected-representative .user-avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.exhibitor-representative-dialog .upload-avatar-wrapper {
    position: relative;
}
.exhibitor-representative-dialog .upload-avatar-wrapper .error-text {
    padding-bottom: 5px;
}
.exhibitor-representative-dialog .upload-avatar-container {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    & > div {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
            margin-right: 5px;
        }

        // Couldn't find for what was this used, but it broke the remove button
        //&:last-of-type {
        //    span {
        //        color: $text-grey;
        //        font-family: $fontFamily, sans-serif;
        //        text-transform: uppercase;
        //        margin-inline-start: 8px;
        //        font-weight: bold;
        //        font-size: 16px;
        //    }
        //}

        &.hide-button {
            opacity: 0;
            transition: opacity 0.5s ease-in;
        }
    }
}
.choose-file {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    color: $primary;
}
.exhibitor-representative-dialog .upload-avatar-container img {
    margin-inline-end: 10px;
}
.exhibitor-representative-dialog .upload-avatar-container .secondary-color {
    padding-left: 15px;
    font-size: 12px;
    max-width: 350px;
    width: auto;
    color: rgba(137, 137, 137, 0.9);
}
.exhibitor-representative-dialog .friends-list,
.representatives-list-wrapper .friends-list,
.corg-list-wrapper .friends-list {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;
}
.exhibitor-representative-dialog .friends-list li,
.representatives-list-wrapper .friends-list li,
.corg-list-wrapper .friends-list li {
    margin-bottom: 10px;
}
.exhibitor-representative-dialog .friends-list li:last-of-type {
    margin-bottom: 0;
}
.exhibitor-representative-dialog .friends-list .info-container,
.representatives-list-wrapper .friends-list .info-container,
.corg-list-wrapper .friends-list .info-container {
    display: flex;
    flex: 1;
    align-items: center;
}
.exhibitor-representative-dialog .friends-list .info-container .info-wrapper,
.representatives-list-wrapper .friends-list .info-container .info-wrapper,
.corg-list-wrapper .friends-list .info-container .info-wrapper {
    flex: 1;
    text-align: start;
}
.exhibitor-representative-dialog .friends-list .info-container .info-content {
    max-width: 345px;
    overflow-wrap: break-word;
}
.search-here > div {
    margin: 0;
}
.exhibitor-representative-dialog .save-file {
    color: $primary;
    margin-left: 37px;
}
.buttons-container {
    padding-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
}
.participants-search-wrapper[data-empty='true'] {
    height: 180px;
    padding: 20px 0;
    & > p {
        text-align: left;
    }
}
.campaign-preview-text {
    width: 550px;

    a {
        color: $secondary;
    }
}
.add-edit-campaign-dialog, .edit-registration-email-dialog {
    .MuiDialogTitle-root {
        width: 550px;
        margin-left: 24px;
        margin-right: 24px;
        padding-left: 0;
        padding-right: 0;
    }
    form {
        padding: 0;
    }
    .input-container {
        width: 550px;
    }
    .MuiDialogContent-root {
        overflow-x: hidden;
    }
}
.editor-wrapper {
    border: 1px solid #ccc;
    height: 250px;
}
form.create-single-invitation-code,
form.create-multiple-invitation-codes {
    padding: 0;
}
form.create-single-invitation-code .input-container,
form.create-multiple-invitation-codes .input-container {
    margin-bottom: 15px;
}
form.create-multiple-invitation-codes .input-container-double {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .input-container:last-child {
        position: relative;
        left: 14px;
    }
}
form.create-single-invitation-code .input-container.small,
form.create-multiple-invitation-codes .input-container.small {
    width: 120px;
}
form.create-single-invitation-code .input-container.medium,
form.create-multiple-invitation-codes .input-container.medium {
    width: 200px;
    margin-bottom: 5px;
}
form.create-multiple-invitation-codes .limit-label {
    color: $text-grey;
    font-weight: 100;
    margin: 0;
}
form.create-multiple-invitation-codes .range-container {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}
form.create-multiple-invitation-codes .buttons-actions.no-range {
    padding-top: 71px;
}
.add-archived-video {
    width: 500px;
    .MuiDialogContent-root {
        height: 250px;
    }
}
.select-campaign-container {
    word-break: break-word;
    display: flex;
    align-items: center;
    height: 30px;
}
.editor-wrapper .rdw-editor-main {
    max-height: 160px;
    overflow-y: auto;
}
.add-edit-access-package-dialog input:disabled {
    opacity: 0;
}
.add-edit-access-package-dialog form {
    padding: 0;
}
.add-edit-access-package-dialog #form-dialog-title h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: $dark;
}
.add-edit-access-package-dialog p.description {
    color: $dark;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    max-width: 500px;
}
.add-edit-access-package-dialog.free-event .price,
.add-edit-access-package-dialog.free-event .single-option-container {
    display: none;
}

.user-info-dialog p {
    color: $dark;
    margin: 0;
}
.user-info-dialog #header-container {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.2);
}
.user-info-dialog .title {
    margin-top: 6px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
}
.user-info-dialog .subtitle {
    font-size: 18px;
    line-height: 27px;
}
.user-info-dialog ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.user-info-dialog .user-data-container {
    height: 40vh;
}
.user-info-dialog ul li {
    border-bottom: 1px solid $disabled-text;
    display: flex;
    align-items: center;
}
.user-info-dialog ul li .label {
    color: $text-grey;
    padding: 16px;
    flex: 0 0 150px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}
.user-info-dialog ul li .value {
    padding: 16px;
    font-weight: 500;
    font-size: 14px;
    color: $dark;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}
.user-info-dialog .actions-container {
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
}
.edit-registration-email {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.import-invitation-codes {
    .invitation-popup-dialog {
        width: 100%;
        .create-multiple-invitation-codes {
            padding-bottom: 8px;
        }
    }

    #form-dialog-title {
        padding-bottom: 24px;
        padding-top: 24px;

        h2 {
            font-weight: 600;
            font-size: 24px;
            color: $dark;
            text-transform: unset;
        }
    }
    .download-container {
        background: $light-grey;
        padding-bottom: 20px;
        overflow: hidden;

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            .download-button {
                color: $dark;
                font-weight: bold;
                font-size: 16px;
                text-transform: uppercase;
                font-family: $fontFamily, sans-serif;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 1px solid transparent;
                background-color: transparent;
                border-radius: 6px;
                padding: 11px 10px;
                cursor: pointer;

                input {
                    &[type='file'] {
                        display: none;
                    }
                }

                span {
                    padding-inline-start: 8px;
                }

                &:focus {
                    outline: none;
                }

                &:hover {
                    border: 1px solid $secondary;
                    background-color: $secondary-light;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                }

                &:active {
                    color: #ffffff;
                    background-color: $secondary;
                    box-shadow: none;

                    svg {
                        path {
                            fill: #ffffff;
                        }
                    }
                }

                &:disabled {
                    color: $dark;
                    border: 1px solid transparent;
                    cursor: default;

                    svg {
                        path {
                            fill: $grey;
                        }
                    }

                    &:hover {
                        background-color: transparent;
                        box-shadow: none;
                    }

                    &:active {
                        color: $text-grey;
                        background-color: #fff;
                        box-shadow: none;

                        svg {
                            path {
                                fill: $grey;
                            }
                        }
                    }
                }
            }
        }
    }

    .MuiDialogContent-root {
        overflow: hidden;
    }

    p {
        margin: 0;
        font-size: 18px;
        line-height: 27px;

        &.Mui-error {
            font-size: 12px;
            font-family: $fontFamily, sans-serif;
            font-weight: 500;
        }

        &.error {
            color: $error;
        }
    }

    h3 {
        font-weight: 500;
        font-size: 20px;
        text-transform: uppercase;
        padding-inline-start: 30px;
        position: relative;

        svg {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .upload-button-wrapper {
        text-align: center;
        padding-top: 8px;
        padding-bottom: 20px;

        .upload-button input {
            &[type='file'] {
                display: none;
            }
        }
    }

    .uploaded-file-wrapper {
        font-weight: 600;
        font-size: 16px;
        color: $secondary;

        span {
            color: $dark;
            text-transform: uppercase;
        }
    }

    .create-multiple-invitation-codes {
        .buttons-actions {
            margin-top: 20px;
            margin-bottom: 0;

            .update-button {
                margin-right: 0;
            }
        }
    }
}
.booth-pdf-dialog {
    .MuiDialog-paperWidthFalse {
        border-radius: 6px;
        @media screen and (max-width: 1024px) {
            margin: 0;
            min-width: 100%;
            max-height: initial;
            height: (calc(var(--vh, 1vh) * 100));
            border-radius: 0;
        }
    }
    .MuiDialogContent-root {
        padding: 0;
        &:first-child {
            padding: 0;
        }
    }
    .MuiDialogActions-root {
        background: #f5f5f5;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.13);
        @media screen and (max-width: 1024px) {
            display: none;
        }
        button {
            color: $error-light;
            font-size: 16px;
            line-height: 20px;
            font-weight: bold;
            font-family: $fontFamily, sans-serif;
        }
    }
    .booth-dialog-content {
        overflow: hidden;
        #form-dialog-title {
            height: 48px !important;
            position: relative;
            margin: 0;
            h2 {
                font-size: 16px;
                line-height: 20px;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    left: 22px;
                    bottom: 12px;

                    @media screen and (min-width: 1025px) {
                        display: none;
                    }
                }
            }
        }
        .content-container {
            @media screen and (max-width: 1024px) {
                .react-pdf__Document .react-pdf__Page .react-pdf__Page__canvas {
                    margin: 0 auto;
                    width: 100% !important;
                    height: auto !important;
                }
            }
            & > div {
                overflow-y: auto;
                max-height: calc(100vh - 200px);
                overflow-x: hidden;
                scrollbar-width: thin;
                scrollbar-color: rgb(137, 137, 137) $disabled-text;

                @media screen and (max-width: 1024px) {
                    max-height: initial;
                    height: calc(calc(var(--vh, 1vh) * 100) - 48px);
                }

                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: transparent;
                }
                &::-webkit-scrollbar-thumb {
                    background: rgb(137, 137, 137);
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }
    }
}
.delete-post {
    .MuiDialogContent-root {
        min-width: 511px;
        @media screen and (max-width: 650px) {
            min-width: 250px;
        }
    }
}
.edit-user-information {
    .MuiDialogContent-root {
        @media screen and (min-width: 1025px) {
            min-width: 570px;
        }
    }
}
.exhibit-hall-organizer {
    .scroll-left-container {
        padding-top: 0;
        padding-bottom: 0;
        p {
            direction: ltr;
            &.agree-container {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
            }
        }
    }
}
.closed-doors {
    .MuiDialogContent-root {
        min-width: 511px;
        @media screen and (max-width: 650px) {
            min-width: 250px;
        }
    }
}

.MuiDialogActions-root.dialog-actions-space-between {
    justify-content: space-between;
}

@media all and (max-height: 859px) {
    .exhibitor-representative-dialog .avatar-details-container {
        height: auto;
        padding: 5px 15px;
    }
    .exhibitor-representative-dialog .avatar-details-container .predefinedAvatar {
        width: 52px;
        height: 52px;
    }
    .exhibitor-representative-dialog .upload-avatar-container {
        padding-bottom: 32px;
    }
    .exhibitor-representative-dialog .buttons-container {
        padding-bottom: 7px;
    }
    .exhibitor-representative-dialog .dialog-container {
        padding-bottom: 0;
    }
    .participants-search-wrapper[data-empty='true'] {
        height: 118px;
        padding: 5px 0;
    }
    .exhibitor-representative-dialog .friends-list li {
        margin-bottom: 5px;
    }
    .exhibitor-representative-dialog.medalions .participants-search-wrapper[data-empty='true'] {
        height: 200px;
    }
    .exhibitor-representative-dialog .avatar-details-container div {
        padding-top: 5px;
    }
}

@media screen and (max-width: 479px) {
    .stripe-payment-dialog .MuiDialog-paper {
        max-width: initial;
        margin: 16px;
    }
    .stripe-payment-dialog .title h2 {
        padding-left: 16px;
        padding-right: 16px;
        align-items: initial;
    }
}
@media screen and (max-width: 340px) {
    .stripe-payment-dialog .title h2 {
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;
    }
    .stripe-payment-dialog .title h2 a svg {
        height: 24px;
    }
    .stripe-payment-dialog .title h2 .secure-locket svg {
        height: 15px;
    }
}
.wrapper-event-deleted {
    display: flex;
    align-items: center;
    justify-content: center;
}
.unregister-dialog {
    word-break: break-word;
}

.delete-archived-event-dialog #form-dialog-secondary {
    word-break: break-word;
}

.booth-links-dialog-container .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dialog-max-width {
    .MuiDialog-paperWidthSm {
        max-width: 575px;
    }
}