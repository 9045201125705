.text-align-left {
    text-align: left;
}

.align-helper {
    .MuiFormControlLabel-root {
        display: block;
        span {
            display: block;
            float: left;
        }
        span.MuiTypography-root {
            margin-top: 9px;
        }
    }
    .MuiFormLabel-root {
        text-align: left;
    }
    .MuiSelect-select {
        text-align: left;
    }
}
.align-helper.mT-10 {
    margin-top: 10px;
}

#select-country {
    top: 0px !important;
}