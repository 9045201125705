@import 'variables.module';

.my-account-page {
    padding-top: 69px;
    padding-bottom: 80px;
    .matching-loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.my-account-component.dropdown-component {
    max-width: 323px;
    overflow: initial;
    top: 56px;
    right: 0;
    left: initial;
}
.my-account-component .myaccount-container {
    padding: 20px 10px;
    background-color: #fff;

    .row:not(:last-of-type) {
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0px 10px 10px -10px $secondary;
            }
        }
    }

    .logout-row {
        // padding: 4px 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                span {
                    font-weight: bold;
                    color: $error;
                }
                .profile-image-container svg {
                    fill: $error;
                    path {
                        fill: $error;
                    }
                }
            }
        }
    }
}
.my-account-component.dropdown-component .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
}
.my-account-component.dropdown-component .row a {
    color: $secondary;
    padding-top: 5px;
    font-weight: 600;
}
.my-account-component.dropdown-component .row button {
    padding: 0;
}
.my-account-component.dropdown-component .row span {
    cursor: pointer;
    overflow-wrap: anywhere;
}
.profile-image-container {
    flex-basis: 25%;
}
.profile-image-container.logout-icon {
    cursor: pointer;
}
.profile-image-container > img {
    margin-right: 30px;
}
.my-account-component.dropdown-component .row:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}
.my-account-component.dropdown-component .row > div:last-of-type {
    flex: 1;
    text-align: start;
}
.my-account-page h3 {
    font-family: $fontFamily, sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color: $grey-dark;
    margin: 0;
    padding-inline-end: 30px;
}
.edit-section-container {
    max-width: 630px;
    position: relative;
    margin: 40px auto 0;
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 0.5px solid rgba(137, 137, 137, 0.5);
    // MATCHING
    &.event-matching {
        padding: 16px;
        border-radius: 4px;
        filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
        .matching-required-error {
            min-width: max-content;
            min-height: max-content;
            margin-left: 16px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.4px;
            color: $error;
        }
        .matching-questions-container {
            min-width: 485px;
            min-height: max-content;
            height: max-content;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 32px;
            .MuiDivider-root {
                width: 100%;
                min-height: max-content;
                margin: 0 0 24px;
                background-color: $disabled-text;
            }
            .question:last-child {
                margin: 0;
            }
            .question {
                width: 100%;
                min-width: 100%;
                min-height: max-content;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 0 0 24px;
                .question-requirement {
                    min-height: max-content;
                    height: max-content;
                    margin: 0;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.4px;
                    color: $grey-dark;
                }
                .question-title {
                    text-align: left;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 30px;
                    letter-spacing: 0.0015em;
                    color: $dark;
                    padding: 0 0 16px;
                }
                section {
                    width: 100%;
                    min-height: max-content;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .form-control-outlined {
                        padding: 8px 20px;
                        border: 2px solid $primary;
                        border-radius: 4px;
                        legend {
                            width: max-content;
                            font-family: $fontFamily, sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: -0.4px;
                            color: $primary;
                            padding: 0 4px;
                            text-align: left;
                        }
                        .MuiFormControlLabel-root {
                            margin-left: -4px;
                            margin-right: 0;
                            word-break: break-all;
                        }
                        .radio-fix .MuiTypography-root.MuiFormControlLabel-label {
                            text-align: left;
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }
}
.edit-section-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}
.matching-section-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}
.edit-user-personal-info-container {
    display: flex;
    align-items: center;
}
.edit-user-personal-info-container .avatar {
    width: 150px;
    height: 150px;
    margin-inline-end: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.my-account-page ul {
    list-style: none;
    padding-inline-start: 0;
}

// Comented because EventProfileItem refactor
// Please double check the page if you un-comment !

// .my-account-page ul li p span:first-of-type {
//     text-transform: capitalize;
//     padding-inline-end: 5px;
//     min-width: auto;
//     word-break: initial;
// }
.my-account-page .edit-user-personal-info-container .image-container {
    position: relative;
    margin-inline-end: 30px;
}
.my-account-page .edit-user-personal-info-container .image-container .select-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: 2;
}
.my-account-page .image-error {
    display: contents;
    width: 300px;
    color: $error-light;
    font-size: 12px;
    font-weight: bold;
    margin-top: 25px;
}
.my-account-page .edit-user-personal-info-container .image-container button {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
}
.user-profile-information > span {
    font-family: $fontFamily, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 14px;
    color: $dark;
    word-break: break-word;
}
button.change-password-btn {
    margin-top: 10px;
    padding: 5px 8px;
    font-size: 12px;
    margin-inline-end: 10px;
}
.user-profile-information p,
.edit-participant-info-container p {
    font-family: $fontFamily, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    padding-top: 10px;
}
// Comented because EventProfileItem refactor
// Please double check the page if you un-comment !

// .user-profile-information p > span,
// .edit-participant-info-container p {
//     span {
//         color: $text-grey;
//         max-width: 40%;
//     }
  
//     > span{
//         min-width: fit-content;
//         @media screen and (max-width: 451px) {
//             width: min-content;
//         }
//     }   
// }

.upload-text-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    color: #fff;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.image-container .select-image:hover + .upload-text-container {
    visibility: visible;
}
.edit-user-information {
    .update-button {
        &:not(.create-multiple-invitation-codes) {
            color: $primary;
        }

        &.Mui-disabled {
            color: $disabled-text;
        }
    }
    form {
        padding: 0 0 4px 0;
    }
    #select-country {
        font-size: unset;
        top: 17px;
        color: #726f79;
        opacity: 0.5;
    }
    fieldset {
        background-color: transparent;
    }
    fieldset {
        background-color: transparent;
    }
    .buttons-actions {
        padding-bottom: 10px;
    }
    .input-wrapper {
        position: relative;
        .tooltip {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s ease-in-out;
            position: absolute;
            width: 100%;
            background-color: #555;
            bottom: calc(100% + 10px);
            border-radius: 6px;
            color: $white;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

            div {
                padding: 10px;
                position: relative;
                &::after {
                    content: '';
                    width: 0;
                    height: 0;
                    border: 0 solid transparent;
                    border-left-width: 10px;
                    border-right-width: 10px;
                    border-top: 10px solid #555;
                    position: absolute;
                    left: 10px;
                    bottom: -10px;
                }
            }

            p {
                margin: 0;
                font-size: 14px;
            }
        }
        &.show-tooltip {
            &:hover {
                @media screen and (min-width: 1025px) {
                    .tooltip {
                        word-break: break-word;
                        opacity: 1;
                        visibility: visible;
                        z-index: 1;
                    }
                }
            }
        }
        &.show-tooltip-mobile {
            .tooltip {
                opacity: 1;
                visibility: visible;
                z-index: 1;
            }
        }
        .field-container {
            input {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}
@media all and (max-width: 1024px) {
    .change-password-form {
        padding-bottom: 20px !important;
    }
}
.change-password-form > div {
    margin-bottom: 20px;
}
.my-account-page .gdpr-links {
    margin-top: 25px;
}
.my-account-page .gdpr-links p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: $dark;
    font-family: $fontFamily, sans-serif;
}
.my-account-page .gdpr-links ul {
    margin: 0;
}
.my-account-page .gdpr-links a {
    color: $secondary;
    font-size: 14px;
    line-height: 25px;
}
.my-account-page .select-language-container {
    width: 100%;
    margin-top: 25px;
}
.my-account-page .select-language-container span {
    padding-inline-end: 20px;
    min-width: 150px;
    display: inline-block;
}
.edit-section-container.event-profile {
    margin-bottom: 80px;
}
.edit-section-container.event-profile ul {
    margin: 0;
}
// Comented because EventProfileItem refactor
// Please double check the page if you un-comment !

// .edit-section-container.event-profile ul p {
//     padding: 0;
//     line-height: 25px;
//     display: flex;
//     word-break: break-word;
// }
.edit-section-container.event-profile .gdpr-links {
    margin-top: 30px;
    margin-bottom: 30px;
}
/* My account dropdown - mobile */
.my-account-component.dropdown-component.my-account-mobile {
    max-width: initial;
    width: 100%;
    top: 56px;
}
.my-account-mobile .page-title {
    height: 48px;
    line-height: 48px;
    text-align: left;
    padding-left: 16px;
}
.my-account-mobile .page-title h3 {
    font-weight: 600;
    font-size: 16px;
}
.my-account-mobile .myaccount-container {
    padding: 0;
}
.my-account-mobile .profile-image-container {
    flex-basis: auto;
}
.my-account-mobile .profile-image-container > img {
    margin-right: 16px;
}
.my-account-mobile .row > div:last-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.my-account-mobile.dropdown-component .row a {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    padding: 0;
}
.my-account-mobile.dropdown-component .row span {
    padding-right: 16px;
    display: inline-flex;
    flex: 1;
}
.my-account-mobile.dropdown-component .myaccount-container > .row {
    padding: 10px 16px;
    min-height: 60px;
    margin: 0;
}
.my-account-mobile .myaccount-container .row:last-of-type div:last-of-type p {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}
.my-account-mobile .myaccount-container .row:last-of-type .profile-image-container img {
    margin: 0;
}

@media screen and (min-width: 1025px) {
    .my-account-component.dropdown-component {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
}
/* Mobile -start */
@media screen and (max-width: 1024px) {
    .my-account-page {
        padding-top: 56px;
    }
}
@media screen and (max-width: 479px) {
    .my-account-mobile.dropdown-component .row a {
        font-size: 14px;
        flex-flow: row-reverse;
    }
}
@media screen and (max-width: 390px) {
    .my-account-mobile.dropdown-component .row a {
        flex: 1;
    }
}
@media screen and (max-width: 330px) {
    .my-account-mobile.dropdown-component .row span {
        flex: 1;
    }
}

.long-event-name {
    overflow-wrap: anywhere;
}