@import '../../CSS/variables.module';


.info-tooltip {
    min-width: 225px;
    margin: 0 5px;
    @media screen and (max-width: 500px) {
        min-width: 175px;
    }
    .info-tooltip-inner-container{
        position: relative;
    }
    .tooltip-arrow {
        display: flex;
        justify-content: center;
        margin: auto;
        .arrow{
          width: 13px;
          height: 13px;
          background: linear-gradient(137.73deg, $gradientMenu1 -15.66%, $gradientMenu2 43.77%, $gradientMenu3 100.28%);
          transform: rotate(45deg);
          bottom: -6.5px;
          position: relative;
            &.arrow-top{
                bottom: initial;
                top: -6.5px
            }
        }
    }
    .text-container {
        padding: 15px 12px;
        background: #FFFFFF;
        border: 1px solid $disabled-text;
        border-radius: 12px;
        color: $dark;
        text-transform: none;
        text-align: start;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        position: relative;
        z-index: 1;
        svg path{
            fill: #5AB453 !important;
        }
        p{
            margin: 0;
            padding-inline-start: 14px;
        }
    }
}

.mobile-menu-wrapper{
    .info-tooltip {
        .tooltip-arrow {
            width: 175px;
            margin-right: 0;
            margin-left: auto;
        }
    }
}