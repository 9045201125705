.mobile-menu-wrapper .bottom-links-container {
    justify-content: flex-end;
}
@media all and (max-width: 766px) {
    .outside-event-header .menu-wrapper {
        justify-content: center;
        padding-top: 30px;
        -webkit-padding-start: 50px;
        padding-inline-start: 50px;
        -webkit-padding-end: 0 !important;
        padding-inline-end: 0 !important;
    }
}
.light.homepage-logout-button.mobile-white-btn {
    background: white;
    color: #1a1a1a;
}
.mobile-container-btns {
    display: flex;
    gap: 20px;
    flex-direction: row;
    width: 100%;
    button {
        width: 50%;
    }
}
