@import 'CSS/variables.module';

.announcements-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background-color: $secondary;
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  animation-duration: 1s;
  animation-iteration-count: 1;
  max-height: 110px;

  &.full-screen {
    animation-name: slideIn;

    &.banner-exit {
      animation-name: slideOut;
    }
  }

  &.side-navigation-opened {
    right: 323px;
    // because of wall-and-networking-container
    width: calc(100% - 323px);
    animation-name: slideInWithWall;

    &.banner-exit {
      animation-name: slideOutWithWall;
    }
  }

  .collapse-icon {
    padding: 30px;
    align-items: center;
    display: flex;

    svg:hover {
      cursor: pointer;
    }

    &.not-ready {
      visibility: hidden;
    }
  }

  svg {
    fill: white;
  }

  .announcements-slider-control {
    height: 36px;
    display: flex;
    align-items: center;
  }

  .announcement {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    overflow: hidden;

    .announcement-content {
      width: 100%;
      padding-inline-end: 10px;
      padding-top: 8px;
      padding-bottom: 8px;
      overflow: hidden;

      .announcement-title {
        padding-bottom: 4px;
        overflow: hidden;
      }
    }

    .call-to-action-button {
      flex: none;
      margin-inline-end: 10px;
      overflow: hidden;
    }
  }

  .arrow {
    svg {
      height: 20px;
      fill: none;
    }

    &.next {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    // the remaining without the close button
    width: calc(100% - 74px);

    &.not-ready {
      display: none;
    }
  }
}

.announcement-title {
  word-break: break-word;
}

.announcements-icon {
  z-index: 2;
  width: 40px;
  height: 74px;
  border-radius: 8px 0 0 8px;
  background: linear-gradient(156.39deg, $secondary 48.1%, $secondaryDarken 105%);
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: white;
  }

  &.side-navigation-opened {
    // because of wall-and-networking-container
    right: 323px;
  }

  :hover {
    cursor: pointer;
  }
}

.announcements-banner-mobile {
  z-index: 999;
  position: fixed;
  // because of header-mobile
  top: 56px;
  width: 100%;
  background-color: $secondary;
  color: white;
  padding-top: 12px;
  padding-bottom: 8px;
  padding-inline-end: 12px;

  &.single-announcement-banner {
    padding-inline-start: 16px;
  }

  svg {
    fill: white;
  }

  .top-area {
    display: flex;
    align-items: center;

    .close-icon {
      padding-left: 17px;
      padding-right: 17px;
    }

    .announcements-slider {
      // the remaining without the close button
      width: calc(100% - 48px);
    }
  }

  .bottom-menus {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .call-to-action-button {
      height: 37px;
      flex: none;
      color: white;
      border: 1px solid white;
      margin-inline-end: 10px;

      .MuiButton-label {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .announcements-slider-control {
      padding-inline-start: 5px;
    }
  }

  .arrow {
    svg {
      height: 20px;
      fill: none;
    }

    &.next {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.announcement-title {
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 4px;
}

.announcement-description {
  word-break: break-word;
  font-weight: 500;
  font-size: 14px;
}

.announcements-slider-control {
  display: flex;
  justify-content: center;

  .announcement-number {
    font-size: 14px;
    font-weight: 500;
  }

  div+div {
    margin-inline-start: 5px;
  }

  .arrow {
    svg:hover {
      cursor: pointer;
    }
  }
}

.announcements-slider {
  width: 100%;
  display: flex;
}

@keyframes slideIn {
  from {
    margin-left: 100%;
    width: 0;
    overflow: hidden;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slideInWithWall {
  from {
    margin-left: 100%;
    width: 0;
    overflow: hidden;
  }

  to {
    margin-left: 0%;
    width: calc(100% - 323px);
  }
}

@keyframes slideOut {
  from {
    margin-left: 0%;
    width: 100%;
  }

  to {
    margin-left: 100%;
    width: 0;
    overflow: hidden;
  }
}

@keyframes slideOutWithWall {
  from {
    margin-left: 0%;
    width: calc(100% - 323px);
  }

  to {
    margin-left: 100%;
    width: 0;
    overflow: hidden;
  }
}