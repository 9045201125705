@import 'CSS/variables.module';

.event-info {
  overflow: scroll;
  height: 90vh;

  &.spinner-container {
    height: 100vh;
    display: flex;
    align-items: center;
    width: 323px;
    justify-content: center;
  }

  &.mobile {
    margin-inline-start: 16px;
    margin-inline-end: 16px;
  }

  .resource-container {
    h3 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $primary;
      text-align: justify;
      padding: 24px 16px 0 16px;
      margin-top: 0;
    }
    .description-resource {
      padding-inline-start: 16px;
      padding-inline-end: 16px;
      text-align: left;
      word-break: break-word;
      font-size: 16px;
    }

    .resource a {
      color: $dark;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 20px 16px;
      border-bottom: 1px solid $disabled-text;

      &:hover {
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid $secondary;
      }

      &:active {
        background-color: $secondary;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.2);

        svg, span {
          color: white;
        }
      }

      .resource-title {
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        max-width: 85%;
      }

      svg {
        color: $secondary;
      }

    }
  }
}