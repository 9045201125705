

.over-helper {
    position: relative;
    .overlay-color-picker-helper{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 999999999999;
        display: none;
    }
}