.MuiCheckbox-root{
    padding: 8px;
    color: #2B2B2B;

    &:hover{
        color: #2B2B2B;
        background-color: #F5F5F5;
    }

    &.Mui-disabled{
        color: #898989;
        &:hover{
            background-color: transparent;
        }
    }



    &.Mui-checked{
        &.MuiCheckbox-colorPrimary:hover{
            color: #6624d1;
            background-color: rgba(102, 36, 209, 0.1);
        }
        &.MuiCheckbox-colorSecondary:hover{
            color: #208918;
            background-color: rgba(32, 137, 24, 0.1);
        }
    }
}

.white-variant.MuiCheckbox-root{
    color: #FFFFFF;
    &:hover{
        color: #FFFFFF;
        background-color: #312644;
    }
    &.Mui-checked{
        &:hover{
            color: #FFFFFF;
            background-color: #312644;
        }
    }
}