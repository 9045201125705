.ar-lang-helper {
    #name-label,
    #search-label {
        right: 20px;
        left: auto;
        direction: rtl;
    }
    legend {
        text-align: right !important;
    }

    #search-label.Mui-focused {
        right: 0px;
    }
    #name-label.Mui-focused {
        right: -4px;
    }
}
.ar-lang-helper-p {
    text-align: right;
}
