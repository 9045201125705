.StatisticUsers__userList {
    .participant-avatar {
        margin-inline-end: 8px;
    }
}

.announcements-statistics .statistics-details {
    flex: 0 0 50%;
    .statistics-table {
        height: 100%;
        margin-top: 0;
        .StatisticUsers__content{
            height: 355px;
        }
    }
}
