.IconContainer {
    display: inline-block;
    height: 24px !important;
    width: 24px !important;
    padding-right: 0 !important;

    vertical-align: top;
}

.IconContainer--size-medium {
}

.IconContainer--size-small {
    height: 18px !important;
    width: 18px !important;
}

.IconContainer--size-large {
    height: 32px !important;
    width: 32px !important;
}

.IconContainer--size-xlarge {
    height: 40px !important;
    width: 40px !important;
}

.IconContainer--size-inline {
    height: 1em !important;
    width: 1em !important;
}
