.session-self-preview-container{
    position: absolute;
    width: calc(100vw - 323px);
    height: calc((100vh - 168px));
    @media screen and (max-width: 1365px){
        height: calc((100vh - 159px));
    }
    @media screen and (max-height: 859px){
        width: 78vw;
    }
    top: calc(-50vh + 34px);
    left: -50vw;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: #ffffff;
    font-size: 14px;
    padding: 32px;

    .self-preview{
        width: 400px !important;
        height: 360px !important;
        border-radius: 6px;
        overflow: hidden;
        background-color: #000;
        margin-bottom: 24px;
        position: relative;
        .cam-icon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 50px;
            color: #ffffff;
        }
        .session-user-playback{
            width: 100% !important;
            height: 100% !important;
            video{
                width: 100%;
                height: 100%;
            }
        }
    }

    .input-devices{
        width: 100%;
        max-width: 650px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;
        .setting-name{
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.0015em;
            color: #2B2B2B;
        }
        .select-input-device{
            max-width: 150px;
            font-weight: 500;
            font-size: 14px;
            line-height: 95.9%;
            padding: 0;
            margin: 0;
            padding-right: 16px;
        }
        .MuiInput-underline:after,
        .MuiInput-underline:before{
            display: none;
        }
        .MuiSelect-icon{
            top: -5px;
            right: 0;
            width: 22px;
            height: 22px;

        }
    }
}

.mic-volume{
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg{
        font-size: 24px;
        margin-right: 10px;
    }
}
.mic-input-volume{
    width: 200px;
    height: 4px;
    border-radius: 50px;
    background-color: #CACBD0;
    #meter{
        height: 100%;
        background-color: #6624D1;
        width: 0;
    }
}
