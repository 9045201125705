@import 'variables.module';

.largeContainer {
    position: relative;
    max-width: 1430px;
    margin: 0 auto;
    height: calc(100vh - 70px);
    overflow: hidden;
}
.largeContainer h5 {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    padding-top: 20px;
    padding-bottom: 14px;
    margin: 0;
}
.left-container {
    width: 436px;
    height: calc(100vh - 254px);
    max-height: 683px;
    margin-inline-end: 12px;
    background: #ffffff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
.left-container .booth-title-wrapper,
.booth-view-header,
.booth-visitors-container > div:first-child,
.exhibitor-booth-view-page .statistics > div {
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    border-bottom: 0.5px solid rgba(137, 137, 137, 0.5);
}
.left-container .booth-title-wrapper {
    max-height: 47px;
}
.left-container .booth-title-wrapper p {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    padding-inline-end: 16px;
}
.left-container .booth-title-wrapper a {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: $secondary;
    text-transform: uppercase;
    text-align: right;
}
.left-container .booth-title-wrapper a:active {
    color: $secondary;
}
.left-container .booth-title-wrapper a:focus {
    outline: 0;
}
.booth-preview-container {
    width: 100%;
    flex: 1 1;
}
.booth-preview-container.no-exhibitors {
    height: 100%;
    opacity: 0.33;
}
.no-exhibitors-wrapper {
    height: 80%;
}
.no-content-box-container {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 100px;
    max-width: 1112px;
}
.no-content-box-container p {
    padding-top: 60px;
    color: $text-grey;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    max-width: 320px;
}
.no-content-box-container .direction-arrow {
    position: absolute;
    left: 50%;
    transform: translateX(150px);
    top: -30px;
}
p.no-exhibitors-text {
    height: 20%;
    display: flex;
    margin: 0;
    justify-content: flex-end;
    align-items: center;
    font-size: 26px;
    text-transform: uppercase;
    color: $secondary;
    flex-flow: column;
}
p.no-exhibitors-text span {
    display: block;
    padding: 5px 30px;
    text-align: center;

    &:last-of-type {
        padding-bottom: 0;
    }
}
.booth-preview-container img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-position: center center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.single-booth-participant-view .booth-preview-container img {
    border-radius: 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}
.booth-manage {
    display: flex;
    flex-direction: row;
    max-height: calc(100% - 47px);
    flex: 1;

    textarea {
        scrollbar-width: thin;
        scrollbar-color: rgb(137, 137, 137) #fff0;

        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: rgb(137, 137, 137);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}
.tab-navigation {
    display: flex;
    flex-flow: column;
    //min-width: 100px;
    max-width: 105px;
    margin-right: 30px;
    .tab-navigation-button {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.07em;
        margin: 0;
        padding-top: 6px;
        text-transform: uppercase;
        display: block;
        white-space: normal;
    }
}
.tab-navigation button {
    flex-grow: 1;
    background: #f5f5f5;
    border: 0.5px solid rgba(137, 137, 137, 0.5);
    outline: 0;
    border-bottom: 0;
    cursor: pointer;
    margin: 0;
}
.tab-navigation button:first-of-type {
    border-top: 0;
}
.tab-navigation button.active {
    border-left: 4px solid $primary;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    padding-inline-start: 2px;
    svg path {
        fill: $primary;
    }
    p {
        color: $primary;
    }
}
.tab-navigation button p {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.07em;
    margin: 0;
    padding-top: 6px;
    text-transform: uppercase;
}
.booth-management-container .tab-navigation {
    margin-right: 0;
}
.booth-management-container .tab-content {
    width: 337px;
    height: 100%;
    position: relative;
    flex: 1;
}
.booth-management-container .booth-preview-container {
    max-height: 520px;
}
.booth-manage .manage-booth-style {
    padding-top: 0;
}
.radio-fix {
    &::before {
        right: 3px !important;
    }
    &::after {
        right: 0 !important;
    }
}
.manage-booth-video {
    h2 {
        font-weight: 800;
        font-size: 16px;
        text-transform: uppercase;
        color: $text-grey;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }
    button {
        transition: opacity 0.5s;
        &:disabled {
            opacity: 0;
            height: 0;
        }
    }
}
.manage-booth-style fieldset,
.manage-booth-documents > div,
.manage-booth-video > div {
    width: 100%;
    min-width: 293px;
}
.manage-booth-documents .scrollbar .box {
    margin-bottom: 0 !important;
}
.manage-booth-video form {
    padding: 0;
}
.manage-booth-banners {
    padding-top: 24px;
}
// .manage-booth-documents div p,
.manage-booth-banners .logo-container p {
    text-transform: uppercase;
}
.manage-booth-documents {
    overflow-x: hidden !important;
}
.cover-img-container {
    max-width: 297px;
    height: 196px;
    position: relative;
    background-color: #eee;
    border-radius: 8px;
    border: 0.5px solid $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;
}
.cover-img-container .spinner {
    left: -0.5px;
    bottom: -0.5px;
}
.cover-img-container > img:not(.upload-icon) {
    width: 70%;
    height: 90%;
    object-fit: contain;
    object-position: center;
}
.cover-img-container .edit-label {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.cover-img-container .upload-label {
    cursor: pointer;
}
.cover-img-container-portrait {
    max-width: 297px;
    height: 196px;
    position: relative;
    background-color: #eee;
    border-radius: 8px;
    border: 0.5px solid $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;
}
.cover-img-container-portrait .spinner {
    left: -0.5px;
    bottom: -0.5px;
}
.cover-img-container-portrait > img:not(.upload-icon) {
    width: 70%;
    height: 90%;
    object-fit: contain;
    object-position: center;
}
.cover-img-container-portrait .edit-label {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.cover-img-container-portrait .upload-label {
    cursor: pointer;
}
.cover-img-container-portrait span {
    display: block;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding-top: 5px;
}
.manage-booth-banners p,
.manage-partner-logo p,
.manage-exhibitor-logo p,
.manage-lobby-logo p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 14px;
}
.manage-booth-banners p > span {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: $text-grey;
}
.manage-booth-banners p > span .no-file,
.manage-booth-banners p > span .uploaded-file {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
}
.manage-booth-video .MuiFormHelperText-root.Mui-error {
    white-space: pre-line;
}
.manage-booth-video button.remove-file {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}
.manage-booth-representatives h2 {
    display: flex;
    justify-content: space-between;
    font-weight: 800;
    font-size: 16px;
    text-transform: uppercase;
    color: $text-grey;
    span {
        display: flex;
        align-items: center;
    }
}
.social-links-container .actions-wrapper h2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.manage-booth-style,
.manage-booth-banners,
.manage-booth-documents,
.manage-booth-links,
.manage-booth-representatives,
.manage-booth-video {
    padding: 24px 20px 0 20px;
}
.manage-study-details {
    padding: 20px;
}
.manage-booth-documents p,
.social-links-container > p,
.manage-booth-representatives > div > p,
.medalion-representative-wrapper,
.manage-booth-video form > p,
.manage-booth-video .logo-container p,
.manage-study-details {
    .actions-wrapper {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        h2 {
            margin-left: 0;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 20px;
            color: $text-grey;
            text-transform: uppercase;
        }
        button {
            transition: opacity 0.5s;
            &:disabled {
                opacity: 0;
                height: 0;
            }
        }
    }
}
.manage-booth-video .current-banner-container,
.manage-booth-video .current-logo-container {
    margin-top: 16px;
}
.manage-booth-video .logo-container {
    margin-top: 30px;
}
.manage-booth-video .image-title-and-button {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: wrap;
    .additional-info-line {
        display: flex;
        align-items: center;
    }
}
.manage-booth-video form > p.title,
.manage-booth-video .logo-container p.title {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}
.manage-booth-representatives > div > p:first-child,
.manage-booth-representatives .medalion-representative-wrapper {
    text-transform: uppercase;
}
.additional-info-line {
    padding: 0;
    font-size: 12px !important;
    font-weight: normal !important;
    margin-top: 0 !important;
}
.manage-booth-documents p {
    margin-bottom: 10px;
}
.manage-booth-links form {
    padding: 0;
}
.current-uploaded-documents {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;
}

.manage-booth-documents .document-name {
    margin-top: 5px;
}

.current-uploaded-documents li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-inline-start: 10px;
}
.current-uploaded-documents li:last-of-type {
    margin-bottom: 0;
}
.current-uploaded-documents li.spinner-container {
    position: relative;
    height: 50px;
}
.current-uploaded-documents li a {
    color: black;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    flex: 1;
    word-break: break-word;
    margin-right: 10px;
}
.manage-booth-documents label {
    color: #000 !important;
}
.document-name > div {
    margin-top: 0;
}
.manage-booth-documents .edit-label {
    display: inline-flex;
    align-items: center;
    font-size: 0;
    cursor: pointer;
}
.manage-booth-documents .edit-label span {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $primary;
    margin-inline-start: 6px;
}
.booth-manage {
    .list-style {
        list-style: none;
        margin-top: 3px;
        margin-bottom: 0;
        padding: 0;
        li {
            padding: 12px 32px 12px 16px;
            background: $light-grey;
            margin-bottom: 8px;
            border: 1px solid $disabled-text;
            position: relative;
            display: flex;
            flex-direction: column;
            font-weight: 500;
            font-size: 16px;
            border-radius: 3px;

            span {
                word-break: break-word;
            }

            a {
                margin-top: 8px;
                color: $secondary;
                word-break: break-word;
            }

            button {
                position: absolute;
                top: 10px;
                right: 10px;
                margin: 0;

                svg {
                    path {
                        fill: $grey;
                    }
                }
            }
        }
    }
}

.manage-booth-documents .document-title {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: rgba(137, 137, 137, 0.9);
    margin-top: 2px;
}
.manage-booth-links .link-container button {
    margin-inline-start: 25px;
    margin-top: 20px;
    text-align: right;
}
.manage-booth-links .link-container button span {
    text-transform: none;
}

.social-links-container > div > div {
    margin: 0;
}
.manage-booth-links label + div:hover:before {
    border-bottom: 1px solid $grey !important;
}
.manage-booth-links .link-container {
    display: flex;
    margin-bottom: 5px;
    align-items: flex-start;
}
.manage-booth-links .link-container > div {
    flex: 1;
    margin: 0;
}
.manage-booth-documents .link-container label,
.search-here label,
.visitors label {
    color: $text-grey !important;
    bottom: 30px;
    display: flex;
    flex-direction: column-reverse;
}
.agree-terms label span a:hover {
    color: $secondary !important;
}
.agree-terms label input {
    cursor: pointer;
}
.manage-booth-links .link-container input,
.visitors input,
#add-representative-dialog #standard-search,
#add-speaker-dialog #standard-search {
    padding-top: 0;
    padding-bottom: 5px;
}
.manage-booth-links label + div:after,
.search-here label + div:after,
.visitors label + div:after,
.register-form-wrapper label + div:after,
#add-representative-dialog label + div:after,
#add-speaker-dialog label + div:after {
    border-bottom: 1px solid $secondary !important;
}
.manage-booth-links .link-container label[data-shrink='true'],
.manage-booth-documents .link-container label[data-shrink='true'],
.search-here label[data-shrink='true'],
.visitors label[data-shrink='true'],
.booth-manage #add-representative-dialog label[data-shrink='true'],
#add-speaker-dialog label[data-shrink='true'] {
    font-size: 12px;
    color: $secondary !important;
}
.manage-booth-links .social-links-container .link-container button {
    margin-bottom: 10px;
    margin-inline-start: 15px;
    padding: 0;
}
.social-links-container .link-container button span.remove-file {
    color: $error-light;
}
.social-links-container .link-container button:disabled span {
    color: $text-grey;
    cursor: initial;
}
.social-links-container .upload-document {
    font-size: 12px;
    color: $secondary;
}
.manage-booth-links .link-container .inputs-container {
    padding-inline-end: 10px;
    border-inline-end: 1px solid $dark;
}
.manage-booth-links .link-container .inputs-container .MuiTextField-root {
    margin-top: 4px;
}
.new-link-container > div {
    margin: 0;
}
.manage-booth-representatives .exhibitor-representatives-list {
    margin: 5px 0;
    padding-inline-start: 0;
    list-style-type: none;
}
.manage-booth-representatives .exhibitor-representatives-list li {
    padding-bottom: 7px;
}
.manage-booth-representatives .representative-container .info-container {
    display: flex;
    align-items: center;
}
.manage-booth-representatives .representative-container .info-container .avatar {
    margin-right: 10px;
    display: flex;
    align-self: center;
}
.representative-container .info-container .info-wrapper {
    flex: 1;
}
.representative-container button {
    min-width: 24px;
    padding: 0;
}
.info-wrapper p {
    margin: 0;
}
.info-wrapper .info-title {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $dark;
    overflow-wrap: anywhere;
}
.info-wrapper p:last-of-type {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: $text-grey;
}
.manage-booth-representatives .positioning-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $dark;
    margin: 0;
    padding: 12px 0;
}
.medalion-representative-wrapper {
    padding-top: 25px;
}
.manage-booth-representatives .new-avatar-button {
    padding: 0 !important;
    display: flex;
    align-items: center;
    padding-top: 7px;
    width: 100%;
    justify-content: space-between;
    &:active {
        background: none;
    }
    cursor: initial;
    .add-text-from-button {
        cursor: pointer;
    }
    .MuiButton-label {
        margin-left: 0;
    }
    img {
        width: 36px;
        height: 36px;
    }
}

.new-avatar-button div {
    display: contents;
}
.add-button-wrapper .representative-container > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.manage-booth-video .button-container {
    margin-top: 5px;
    text-align: right;
}
.booth-management-container {
    @media screen and (max-width: 1299px) {
        padding: 0 20px;
        .event-name-wrapper {
            max-width: 1028px;
            margin: 0 auto;
            width: 100%;
        }
    }
    .boothOverlay {
        position: relative;
        height: 100%;
    }
}
.booth-management-container .boothOverlay div.preview {
    display: none;
}
.booth-management-container .boothOverlay div.preview p {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.booth-management-container .boothOverlay:hover div.preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}
.booth-preview-container #highlight-banner,
.booth-preview-container #highlight-files,
.booth-preview-container #highlight-study-details,
.booth-preview-container #highlight-links,
.booth-preview-container #highlight-representatives,
.booth-preview-container #highlight-video {
    box-shadow: 0 0 15px 5px rgba(31, 164, 233, 1);
    position: absolute;
}
.booth-preview-container #highlight-banner {
    width: 107px;
    height: 60px;
    left: 321px;
    bottom: 80px;
}
.booth-preview-container #highlight-files {
    width: 107px;
    height: 233px;
    left: 582px;
    bottom: 59px;
}
.scholar .booth-preview-container #highlight-files {
    width: 380px;
    height: 300px;
    left: 188px;
    bottom: 145px;
    @media (max-width: 1530px) {
        width: 320px;
        height: 260px;
        left: 162px;
        bottom: 126px;
    }
    @media (max-width: 1300px) {
        width: 250px;
        height: 200px;
        left: 100px;
        bottom: 93px;
    }
}
.scholar .booth-preview-container #highlight-study-details {
    position: absolute;
    width: 707px;
    height: 80px;
    left: 188px;
    bottom: 25px;
    @media (max-width: 1530px) {
        width: 607px;
        height: 70px;
        left: 162px;
        bottom: 26px;
    }
    @media (max-width: 1300px) {
        width: 466px;
        height: 50px;
        left: 100px;
        bottom: 13px;
    }
}
.exhibitor-dashboard-page {
    &.scholar {
        .info-container {
            .user-avatar {
                background-size: contain;
            }
        }
    }
    h5 {
        word-break: break-word;
        text-transform: uppercase;
        font-weight: 600;
        @media screen and (min-width: 1025px) and (max-width: 1430px) {
            padding-left: 20px;
        }
    }
}
.booth-preview-container #highlight-links {
    width: 81px;
    height: 47px;
    left: 420px;
    bottom: 183px;
}
.scholar .booth-preview-container #highlight-links {
    width: 140px;
    height: 80px;
    left: 637px;
    bottom: 170px;
    @media (max-width: 1530px) {
        width: 100px;
        height: 60px;
        left: 553px;
        bottom: 150px;
    }
    @media (max-width: 1300px) {
        width: 90px;
        height: 50px;
        left: 393px;
        bottom: 110px;
    }
}
.booth-preview-container #highlight-representatives {
    width: 379px;
    height: 284px;
    left: 21px;
    bottom: 123px;
    box-shadow: none;
}
.booth-preview-container #highlight-representatives img {
    box-shadow: none;
    border-radius: 0;
}
.scholar .booth-preview-container #highlight-presentation-representatives {
    position: absolute;
    width: 120px;
    height: 310px;
    left: 30px;
    bottom: 20px;
    img {
        border: none;
        box-shadow: none;
    }
    @media (max-width: 1530px) {
        width: 100px;
        height: 260px;
        left: 20px;
        bottom: 10px;
    }
    @media (max-width: 1300px) {
        width: 70px;
        height: 200px;
        left: 5px;
        bottom: 10px;
    }
}
.scholar .booth-preview-container #highlight-medalion-representatives {
    position: absolute;
    width: 80px;
    height: 290px;
    left: 820px;
    bottom: 150px;
    img {
        border: none;
        box-shadow: none;
    }
    @media (max-width: 1530px) {
        width: 60px;
        height: 260px;
        left: 710px;
        bottom: 127px;
    }
    @media (max-width: 1300px) {
        width: 50px;
        height: 190px;
        left: 518px;
        bottom: 97px;
    }
}
.booth-preview-container #highlight-video {
    width: 175px;
    height: 97px;
    left: 725px;
    bottom: 292px;
}
.scholar .booth-preview-container #highlight-video {
    width: 140px;
    height: 80px;
    left: 637px;
    bottom: 340px;
    @media (max-width: 1530px) {
        width: 100px;
        height: 60px;
        left: 553px;
        bottom: 300px;
    }
    @media (max-width: 1300px) {
        width: 90px;
        height: 50px;
        left: 393px;
        bottom: 210px;
    }
}
.links-container {
    padding: 20px;
    .MuiTextField-root {
        &.Mui-error {
            margin-bottom: 0;
        }

        input {
            padding-right: 14px;
        }
    }

    h2 {
        margin: 0;
        font-weight: 800;
        font-size: 16px;
        text-transform: uppercase;
        color: $text-grey;
    }

    & > div {
        .MuiTextField-root {
            margin: 14px 0;
        }
    }

    .study-highlights-container {
        padding-top: 19px;
        .actions-wrapper {
            word-break: break-word;
        }
    }

    .new-author-container {
        padding-top: 21px;

        form {
            padding: 0;
        }

        .setting-input {
            &:first-of-type {
                margin-bottom: 0;
            }
        }
    }

    .actions-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.authors,
        &.company-links {
            position: relative;
            flex-direction: column;

            & ~ div {
                &.add-button-wrapper {
                    padding: 0;
                }
            }

            h2 {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }

            p {
                margin: 0;
                font-weight: 500;
                font-size: 14px;
                color: $text-grey;
                text-transform: none;
                padding-top: 15px;
            }
        }

        &.company-links {
            align-items: flex-start;
            p {
                padding-bottom: 15px;
            }
        }
    }

    .add-items-list {
        .inputs-container {
            & > div {
                &:first-of-type {
                    .MuiFormControl-root {
                        margin-bottom: 4px;
                        margin-top: 0;
                    }
                }
            }
        }
        .actions-wrapper {
            padding-bottom: 18px;
            padding-top: 18px;
        }
    }

    .representative-container {
        .user-avatar {
            width: 40px;
            height: 40px;
            margin-inline-end: 12px;
        }
        .info-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
        svg {
            path {
                fill: $error;
            }
        }
    }

    .co-authors {
        padding-top: 50px;

        .exhibitor-representatives-list {
            margin: 0;
            padding-top: 26px;
        }
    }

    .authors-tab {
        &.show-border {
            &:after {
                position: relative;
                left: 0;
                content: ' ';
                display: block;
                border-bottom: 1px solid $disabled-text;
                margin-top: 10px;
                width: 100%;
            }
            .authors {
                padding-bottom: 26px;
            }
        }
    }
}
.manage-booth-documents {
    label + .MuiInput-formControl {
        margin-top: 30px;
    }
    &.scholar {
        h2 {
            margin: 0;
            font-weight: 800;
            font-size: 16px;
            text-transform: uppercase;
            color: $text-grey;
        }

        p {
            font-weight: 500;
            font-size: 14px;
            color: $text-grey;
            padding-top: 8px;
        }

        .current-uploaded-documents {
            li {
                padding-inline-start: 0;
                justify-content: flex-start;

                span {
                    margin-inline-end: 5px;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 16px;
                }

                & > div {
                    display: flex;
                    align-items: center;
                    flex: 1;

                    a {
                        color: $secondary;
                        display: block;
                        display: -webkit-box;
                        max-width: 100%;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        text-align: start;
                    }

                    svg {
                        width: 20px;
                        height: 25px;

                        path {
                            fill: $secondary;
                        }
                    }
                }
            }
        }
        .drag-file-wrapper {
            background: #f5f5f5;
            height: 175px;
            border: 1px dashed $secondary;
            border-radius: 6px;
            cursor: pointer;

            .dropzone {
                outline: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                & > div {
                    display: flex;
                    align-items: center;
                    padding: 0 5px;
                    p {
                        margin: 0;
                        padding: 0;
                        font-weight: 600;
                        font-size: 14px;
                        color: $secondary;
                        text-transform: uppercase;
                        flex: 1;
                    }
                    svg {
                        margin-inline-end: 12px;
                        height: 24px;
                    }
                }
            }
        }
        .or-wrapper {
            padding: 16px 0;
            text-align: center;
            span {
                font-weight: 600;
                font-size: 16px;
                text-transform: uppercase;
            }
        }
        .upload-study-pdf-presentation {
            display: flex;
            align-items: center;
            padding: 10px 40px;
            cursor: pointer;
            width: 291px;
            justify-content: center;
            span {
                padding: 0;
                color: #ffffff;
            }
            svg {
                position: absolute;
                left: 12px;
                path {
                    fill: #ffffff;
                }
            }
        }
    }
}
.manage-study-details .authors-list .author {
    margin-bottom: 0;
    margin-top: 8px;
}
.manage-booth-video {
    .remove-file {
        svg {
            margin-right: 11px;
            margin-bottom: -1px;
        }
    }

    &.scholar-container {
        .actions-wrapper {
            h2 {
                p {
                    padding-top: 5px;
                    padding-bottom: 15px;
                }
            }
            &.authors {
                align-items: flex-start;
                p {
                    padding-top: 5px;
                    padding-bottom: 15px;
                }
            }
        }
        .logo-container {
            position: relative;
            .additional-info-line {
                margin: 0 0 0 2px;
                font-weight: 500 !important;
                color: $text-grey;
                text-transform: none;
            }
        }
        .remove-file {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-family: $fontFamily, sans-serif;
            position: absolute;
            right: 0;
            top: -11px;

            span {
                margin-inline-start: 11px;
            }

            svg {
                margin-right: 0;
                margin-bottom: 0;
                path {
                    fill: $error-light;
                }
            }
        }
    }
}
.add-representative {
    display: flex;
    justify-content: space-between;
}
.new-author-container svg.colored-end-icon {
    fill: $secondary;
    height: 24px;
    width: 24px;
}
@media (min-width: 1200px) {
    @supports (-ms-ime-align: auto) {
        .current-uploaded-documents li a {
            word-break: break-all;
        }
    }
}
@media all and (max-height: 859px) {
    .booth-management-container .left-container {
        height: calc(100vh - 150px);
    }
    .booth-preview-container {
        height: calc(100vh - 150px);
    }
    .manage-booth-links {
        padding-top: 14px;
    }
    .manage-booth-representatives {
        padding-top: 14px;
    }
}

@media all and (max-height: 700px) {
    .manage-booth-video .current-banner-container {
        height: 120px;
        margin-bottom: 10px;
    }
}

@media all and (max-width: 1530px) {
    .booth-management-container {
        .booth-preview-container {
            max-width: 840px;
        }
        .boothOverlay {
            max-height: 450px;
        }
    }
    .booth-preview-container #highlight-banner {
        width: 91px;
        height: 51px;
        left: 274px;
        bottom: 70px;
    }
    .booth-preview-container #highlight-files {
        width: 94px;
        height: 206px;
        left: 498px;
        bottom: 48px;
    }
    .booth-preview-container #highlight-links {
        width: 71px;
        height: 40px;
        left: 358px;
        bottom: 159px;
    }
    .booth-preview-container #highlight-representatives {
        width: 332px;
        height: 243px;
        left: 30px;
        bottom: 105px;
    }
    .booth-preview-container #highlight-video {
        width: 151px;
        height: 83px;
        left: 623px;
        bottom: 253px;
    }
}

@media all and (max-width: 1300px) {
    .booth-management-container {
        .booth-preview-container {
            max-width: 600px;
        }
        .boothOverlay {
            max-height: 340px;
        }
    }
    .booth-preview-container #highlight-banner {
        width: 71px;
        height: 40px;
        left: 189px;
        bottom: 52px;
    }
    .booth-preview-container #highlight-files {
        width: 69px;
        height: 153px;
        left: 360px;
        bottom: 38px;
    }
    .booth-preview-container #highlight-links {
        width: 54px;
        height: 30px;
        left: 253px;
        bottom: 121px;
    }
    .booth-preview-container #highlight-representatives {
        width: 225px;
        height: 160px;
        left: 7px;
        bottom: 86px;
    }
    .booth-preview-container #highlight-video {
        width: 113px;
        height: 63px;
        left: 454px;
        bottom: 191px;
    }
}
@media all and (max-width: 1024px) {
    .no-exhibitors-wrapper {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    .no-exhibitors-wrapper p {
        padding-top: 60px;
        color: $text-grey;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        max-width: 280px;
    }
}
@media all and (max-width: 1081px) {
    .booth-preview-container #highlight-representatives {
        left: calc(20% - 100px);
    }
    .booth-preview-container #highlight-video {
        left: auto;
        right: calc(30% - 186px);
    }
    .booth-preview-container #highlight-banner {
        left: calc(50% - 111px);
    }
    .booth-preview-container #highlight-files {
        left: auto;
        right: calc(50% - 130px);
    }
    .booth-preview-container #highlight-links {
        left: calc(50% - 47px);
    }
    .scholar .booth-preview-container #highlight-study-details {
        left: auto;
        right: calc(10% - 10px);
    }
    .scholar .booth-preview-container #highlight-files {
        left: calc(6% - 10px);
    }
    .scholar .booth-preview-container #highlight-links,
    .scholar .booth-preview-container #highlight-video {
        left: calc(80% - 68px);
    }
}
