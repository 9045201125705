@import 'variables.module';
.superadmin-dashboard .menu-wrapper {
    background: linear-gradient(0deg, rgba(102, 36, 209, 0.5), rgba(102, 36, 209, 0.5)), #000000;
    padding-top: 10px;
    padding-bottom: 10px;
}
thead th {
    position: sticky;
    top: 0;
}
.mr-sticky {
    overflow-y: auto;
    height: 500px;
}
.superadmin-dashboard {
    .top-section {
        padding-top: 115px;

        .organizers-list-wrapper {
            margin-top: 16px;
            .spinner {
                top: 60px;
            }
            & > p {
                text-align: center;
            }
        }
    }
    .organizer-navigation-container {
        display: flex;
        justify-content: space-between;
        .online-now-wrapper {
            display: flex;
            align-items: center;
            position: relative;
            font-weight: 500;
            padding-right: 18px;
            svg {
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }
    .container {
        max-width: 1400px;
        padding: 0 20px;
    }
    .events-list {
        &.is-loading {
            position: relative;
            min-height: 400px;
        }
        .events-table {
            .MuiTableCell-root {
                font-family: $fontFamily, sans-serif !important;
            }
            .event-name {
                width: 15%;
            }
            .cell-with-switch {
                width: 10%;
            }
        }
    }
}

.single-organizer-row,
.organizers-list-header,
.single-event-row,
.events-list-header {
    display: flex;
    font-size: 14px;
    align-items: center;
}
.single-organizer-row:hover,
.single-event-row:hover {
    background-color: #eee;
}
.single-organizer-row .actions,
.organizers-list-header .actions {
    width: 10%;
    text-align: center;
    justify-content: space-around;
}
.single-organizer-row .actions,
.single-event-row .actions {
    padding: 9.5px;
}
.organizers-list-header > div,
.events-list-header > div {
    margin: 0 10px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 20px 0;
    text-transform: uppercase;
    min-height: 100px;
    display: flex;
    align-items: center;
}
.organizers-list-header {
    & > div {
        font-weight: 600;
        text-align: center;
        div {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        &.label-image {
            padding: 17px 0;
            button {
                padding: 0;
                flex: 1;
                justify-content: space-between;
                &:hover {
                    background-color: transparent;
                }
                span {
                    font-weight: 600;
                }
            }
        }
    }
}
.single-organizer-row > div {
    font-size: 12px;
    font-weight: 400;
    margin: 0 10px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 17px 0;
    min-height: 19px;
    display: flex;
    align-items: center;
}
.organizer-first-name {
    width: 15%;
    margin-left: 0;
}
.organizer-last-name {
    width: 15%;
}
.organizer-email {
    width: 25%;
}
.organizer-timestamp {
    width: 15%;
}
.organizer-events {
    width: 10%;
}
.organizer-events a {
    width: 100%;
    text-align: center;
}
.organizer-status {
    width: 10%;
}
.events-list-wrapper {
    padding-bottom: 50px;
}
.admin-sidebar {
    flex: 0 0 256px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    min-height: calc(100vh - 116px);
    border-right: 1px solid $disabled-text;
    position: fixed;
    width: 256px;
    .accordion {
        background-color: #f5f5f5;
        &.active {
            background-color: #fff;
            margin: 0;
            p {
                color: $primary;
                margin-left: -4px;
            }
            .summary {
                border-left: 4px solid $primary;
            }
        }
        p {
            margin: 0;
            padding-left: 12px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.admin-content-container {
    flex: 1 1;
    position: relative;
    margin-left: 280px;
    padding-right: 32px;
    h1 {
        margin: 0;
    }
    .organizers-admin-container > div,
    & > div:not(.organizers-admin-container) {
        padding-right: 24px;
        max-width: 1370px;
        &.header-search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 16px;
            padding-bottom: 16px;
            & > div {
                max-width: 50%;
            }
        }
        &.admin-statistics-container {
            position: relative;
        }
        &.spinner {
            max-width: initial;
            padding: 0;
        }
    }
    .all-events-container {
        .admin-download-header {
            max-width: 1370px;
            padding-bottom: 16px;
            .search-wrapper {
                display: flex;
                max-width: 50%;
                flex: 1;
                align-items: center;
                label {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-right: 70px;
                    width: 100%;
                    &[data-shrink='true'] {
                        padding-right: 0;
                    }
                }
                & > button {
                    height: 40px;
                    margin-left: 30px;
                    background-color: $primary;
                    border: 0;
                    text-transform: uppercase;
                    color: #ffffff;
                    font-family: $fontFamily, sans-serif;
                    border-radius: 6px;
                    padding: 0 15px;
                    letter-spacing: 0.5px;
                    outline: 0;
                    cursor: pointer;
                    &:disabled {
                        background-color: #898989;
                        cursor: initial;
                    }
                }
            }
            .pagination {
                padding: 0;
            }
        }
        .event-name-header {
            width: 15%;
        }
        .event-date-header {
            width: 15%;
        }
        .event-organizer-header {
            width: 15%;
        }
        .event-no-participants-header {
            width: 18%;
        }
        .event-no-exhibitors-header {
            width: 13%;
        }
        .single-organizer-row {
            & > div {
                display: block;
                text-align: center;
                a {
                    color: $secondary;
                }
            }
        }
    }
}
.admin-statistics-container {
    h1 {
        text-transform: uppercase;
    }
}
.admin-download-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
}
.table-statistics {
    display: flex;
    width: 50%;
    ul {
        list-style-type: none;
        background-color: #fff;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        width: 100%;
        padding-left: 0;
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 16px;
            border-bottom: 1px solid rgba(137, 137, 137, 0.5);
            font-size: 14px;
            font-weight: 500;
        }
    }
    .header-statistics {
        background-color: #efeef1;
        span {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 400;
        }
    }
}
.pagination {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    .button-pag-wrapper {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $secondary;
        cursor: pointer;
        &:hover {
            background-color: #fff;
            transition: background-color 0.5s linear;
            svg {
                fill: $secondary;
            }
        }
        a {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            svg {
                fill: #fff;
            }
        }
    }
    .page-count {
        display: flex;
        align-items: center;
        padding: 0 20px;
    }
}
.rdw-link-modal .rdw-link-modal-target-option {
    display: none;
}
.statistics-details {
    width: 40%;
    padding-inline-start: 20px;
    flex-direction: column;
    display: flex;
    .statistics-table {
        margin-top: -65px;
        flex: 0 0 50%;
        height: 50%;
    }
    .statistics-chart {
        margin-top: 24px;
        height: 50%;
        .chart {
            position: relative;
            background-color: #fff;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            padding: 16px;
            height: 100%;
            canvas {
                height: 100% !important;
                width: auto !important;
                margin: 0 auto;
            }
        }
    }
}
.loading-section {
    height: calc(100vh - 115px);
    overflow: hidden;
}
