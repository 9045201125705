@import 'CSS/variables.module';

.item-list-style-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item-list-styles-container {
        display: flex;
        flex-direction: row;
        gap: 23px;
        margin-right: 8px;
        min-width: 57px;
        svg {
            cursor: pointer;
            path {
                fill: $grey-variant;
            }
        }
        svg:hover,
        svg.active {
            path {
                fill: $primary;
            }
        }
    }
}
.lists {
    .column-element {
        width: 32.7%;
        flex: 1;
    }
    display: flex;
    flex-direction: row;
    gap: 9px;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 9px;
        width: 100%;
        li {
            border-left: 1px solid $black-variant;
            background: $white-variant;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 7px 0 7px 5px;
            overflow: hidden;
            position: relative;
            .item-cover {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                cursor: pointer;
            }
            .timeslot-actions {
                width: 102%;
                bottom: 0;
                display: none;

                align-items: center;
                justify-content: space-between;
                height: 32px;
                background: $secondary;
                margin: 5px 0 -7px -5px;
            }
        }

        li:hover,
        li.active {
            .item-cover {
                height: calc(100% - 32px);
            }
            .timeslot-actions {
                display: flex;
            }
            background: $primary-variant-light;
            h3 {
                color: $secondary;
            }
        }
        li.live {
            border-left: 1px solid $primary;
        }
    }
}
.auditorium-numbers {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $dark;
    min-width: 185px;
}
.auditoriums-pager {
    .column-element {
        border-right: 1px solid $white;
        margin-right: -5px;
        width: 33.3333333333%;
        padding: 0 44px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.03em;
        color: $secondary;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
    .column-element.last-child {
        border: 0 !important;
    }
    gap: 9px;
    width: 100%;
    display: flex;
    height: 38px;
    background: $light-grey;
    margin-bottom: 9px;
    position: relative;
    .pager-prev,
    .pager-next {
        top: 7px;
        position: absolute;
        background: white;
        width: 24px;
        height: 24px;
        border-radius: 3px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            path {
                fill: $secondary;
            }
        }
    }
    .pager-prev.passive,
    .pager-next.passive {
        cursor: unset !important;
        svg {
            path {
                fill: $light-grey-variant;
            }
        }
    }
    .pager-prev {
        left: 10px;
    }
    .pager-next {
        right: 10px;
    }
}
.date-and-isLive {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        color: $black-variant;
    }
}
h3.timeslot-title-calendar {
    margin: 5px 0 12px 0;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.03em;
    color: $dark;
}
span.timeslot-speakers-calendar {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: $dark;
}
.isLiveBall {
    background: $primary;
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 90px;
    top: 1px;
}
.isLiveBall.setLeft {
    left: 1px;
}
.isLiveBall.setRight {
    right: 1px;
}
.open-auditorium-btn {
    background: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.4px;
    text-transform: uppercase;
    color: $white;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    flex-direction: row;
    cursor: pointer;
    padding-right: 0;
}
.action-list-btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
    margin-right: 10px;
    button {
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0;
        cursor: pointer;
    }
    svg {
        cursor: pointer;
        path {
            fill: $white;
        }
    }
}
.separator-actions {
    width: 1px;
    height: 16px;
    background: $white;
    margin: 0 4px 0 10px;
}
.auditorium-numbers.single-auditorium-padding {
    margin-left: -23px;
}
.add-to-my-agenda-btn-calendar.single-auditorium-padding {
    padding-right: 10px;
}

.add-to-my-agenda-btn-calendar {
    margin-top: 2px !important;
    svg {
        path {
            fill: $white;
        }
    }
}
.single-calendar-dialog-content {
    min-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.date-and-isLive-dialog {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 12px;
    span {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 12px;
        letter-spacing: -0.4px;
        color: $dark;
    }
}
.dialog-title-calendar-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.dialog-title-calendar {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: $dark;
    margin: 0;
    padding: 0;
}
.btn-cover {
    height: 40px;
    max-width: 250px;
    display: flex;
    button {
        height: 40px !important;
        min-width: 200px !important;
    }
}
.speakers-list-calendar-list {
    margin-top: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: $dark;
    text-transform: uppercase;

    span {
        text-transform: none;
        font-weight: 500;
    }
}
.dialog-description-calendar {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: $dark;
    border-top: 1px solid $light-grey-v2;
}
.bottom-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    svg {
        path {
            fill: $secondary;
        }
    }
}
.sticky-calendar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9;
    background: $white;
    padding-bottom: 1px;
}
.item-list-styles-container.sticky-support {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9;
    background: $white;
    padding-bottom: 1px;
}
.date-calendar {
    min-width: 176px;
}
