.type-container-dnd {
    display: flex;
    min-width: 320px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    div.type-dnd,
    div.length-dnd {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;
        span:first-child {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.4px;
            color: #808080;
        }
        span:last-child {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #2b2b2b;
        }
    }
}
.tooltip-container-custom-fields {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
}
.organizer-dashboard-page .advanced-options-container .options-container .input-description.tooltip-support {
    padding-left: 10px !important;
}
