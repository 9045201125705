@import 'CSS/variables.module';

.program-dropdown-wrapper {
    position: absolute;
    top: 70px;
    left: 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    background-color: $white;
    z-index: -1;
    .program-dropdown-container {
        @media screen and (max-width: 1024px) {
            height: calc(100% - 55px);
            .program-container {
                height: 100%;
            }
        }
        position: relative;
        height: calc(100vh - 70px);
        display: flex;
    }
    .program-container {
        display: flex;
        flex-flow: column;
        max-width: 700px;
        width: 50vw;
    }
    .timeslot-details-container {
        max-width: 340px;
        @media screen and (min-width: 1279px) {
            min-width: 340px;
        }
        width: 20vw;
        border-inline-start: 1px solid $disabled-text;
        padding-left: 2px;
        margin-top: 12px;
        margin-bottom: 12px;
        color: $dark;
        .timeslot-details {
            padding: 0 16px;
            overflow: auto;
            .date-container {
                display: flex;
                align-items: center;
                .timespan {
                    padding-left: 13px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 140%;
                    margin: 0;
                    text-transform: capitalize;
                }
            }
            .auditorium-name {
                color: $secondary;
                font-weight: 500;
                font-size: 14px;
                line-height: 95.9%;
                margin: 0;
                padding-top: 8px;
                a {
                    color: $secondary;
                    text-decoration: none;
                }
            }
            .timeslot-title {
                margin: 0;
                padding: 24px 0;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                width: 100%;
            }
            .timeslot-description {
                width: 100%;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: $black;
                margin: 0;
                word-break: break-word;
            }
            .add-to-calendar-button-container {
                margin-top: 20px;
            }
            .speakers-container {
                padding-top: 32px;
                .speakers-title {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 120%;
                    padding-bottom: 8px;
                    border-bottom: 1px solid $disabled-text;
                    margin: 0;
                }
                .speakers-list {
                    list-style: none;
                    margin: 0;
                    padding: 20px 0 0;

                    li {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 95.9%;
                        padding-bottom: 16px;
                    }
                }
            }
        }
    }
    .action-button {
        cursor: pointer;
        z-index: 1;
        background-color: $grey;
        border-radius: 0 8px 8px 0;
        color: $white;
        font-size: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 46px;
        transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:hover {
            color: $error-light;
        }
    }
    .close {
        position: absolute;
        top: 0;
        left: 100%;
    }
    .collapse {
        position: absolute;
        top: 50px;
        left: 100%;
        svg {
            transform: rotate(90deg);
        }
    }
    .title {
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: $secondary;
        margin: 0;
        padding: 12px 0;
        border-bottom: 2px solid $secondary;
    }
    .single-day-program {
        color: $primary;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 12px 24px;
    }
    .topbar {
        margin: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        min-height: 48px;
        form {
            padding: 0;
        }
        .search-input-container {
            width: 0;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            .input {
                flex: 1;
                margin-right: 8px;
            }
        }
        .search-button,
        .actions-container,
        .select-timezone-container {
            transition: all 0.5s;
        }
        .select-timezone-wrapper {
            .MuiSelect-root {
                padding: 8px;
            }
            .MuiSelect-root:hover {
                background: $white-variant;
                border-radius: 6px;
                padding: 8px;
            }
        }

        &.is-searching {
            .search-button,
            .actions-container,
            .select-timezone-container {
                opacity: 0;
            }

            .search-input-container {
                width: 100%;
                z-index: 2;
                overflow: auto;
            }
        }
        .close-icon-mobile {
            .MuiIconButton-label {
                svg {
                    font-size: 22.5px;
                }
            }
        }
    }
    .display-number-of-search-result {
        margin: 8px 0 0;
        margin-inline-start: 22px;
        font-size: 14px;
        line-height: 95%;
        color: $grey-dark;
        width: 110px;
    }
    .empty-program-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        .empty-program-search {
            text-align: center;
            max-width: 279px;
            width: 100%;
            svg {
                margin-bottom: 32px;
            }
            p {
                margin: 0;
                line-height: 20px;
                font-size: 16px;
                font-weight: 600;
                color: $grey-dark;
            }
        }
    }
    .search-button {
        font-size: 0;
        width: 52px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-variant;
        border-radius: 6px;
        cursor: pointer;
    }
    .actions-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .select-timezone-container {
        transition: all 0.3s;
        .MuiInput-underline:before,
        .MuiInput-underline:after {
            display: none;
        }
        .selected-timezone-value {
            display: flex;
            align-items: center;
            color: $black-variant;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            svg {
                padding-inline-end: 2px;
            }
        }
        .selected-timezone-value-container-mobile {
            svg {
                font-size: 16px;
                z-index: 1;
            }
        }
        .MuiSelect-select {
            padding-inline-end: 24px !important;
            &[aria-expanded='true'] {
                .MuiSvgIcon-root {
                    color: $primary;
                }
            }
        }
        .MuiSelect-icon {
            color: $dark;
        }
        .MuiSelect-iconOpen {
            color: $primary;
        }
    }
    .select-timezone-container-mobile {
        .MuiSelect-icon {
            font-size: 22px;
        }
        .MuiFormControl-root {
            top: -5px;
        }
    }
    .program-details-container {
        padding: 0 8px 12px 8px;
        flex: 1;
        display: flex;
        overflow: hidden;
        .days-list {
            margin-inline-start: 13px;
            overflow: auto;
            padding-left: 1px;
            overflow-x: hidden;
            .month-name {
                margin-top: 14px;
                margin-bottom: 4px;
                margin-inline-start: 13px;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: $grey-dark;
                text-transform: capitalize;
                //text-align: center;
                &:first-child {
                    margin-top: 0;
                }
            }
            .year {
                display: block;
            }
            .day-container {
                margin: 0 0 2px;
                width: 80px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $white-variant;
                cursor: pointer;
                &:hover {
                    color: $primary;
                }
                &.active {
                    background-color: $primary;
                    color: $white;
                }
                .day {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 0;
                    text-transform: capitalize;
                }
                &.first-day {
                    border-top-right-radius: 6px;
                }
                &.last-day {
                    border-bottom-right-radius: 6px;
                }
            }
        }

        .timeslots-list {
            flex: 1;
            overflow: auto;
            padding-inline-end: 6px;
            p,
            h5 {
                margin: 0;
            }
            .date-title {
                color: $primary;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 12px;
                &.date-title-tablet {
                    margin-bottom: 16px;
                }
                @media screen and (max-width: 767px) {
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: -0.03em;
                    margin-bottom: 14px;
                }
            }
            .timeslot:not(:last-child) {
                border-bottom: 1px solid $light-grey-v2;
            }
            .timeslot {
                display: flex;
                margin-bottom: 12px;
                cursor: pointer;

                padding: 8px;

                border-radius: 0;
                &:last-of-type {
                    margin-bottom: 0;
                }
                &.active {
                    background-color: $secondary-background;
                }
                &:hover {
                    background-color: $white-variant;
                    border-radius: 6px;
                    border-bottom: 0;
                }
                .timeslot-details {
                    padding-inline-start: 8px;
                    border-inline-start: 2px solid $disabled-text;
                    flex: 1;
                    padding-inline-end: 8px;
                    color: $dark;
                    overflow: hidden;
                    .auditorium-name {
                        width: -webkit-fill-available;
                        color: $secondary;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        a {
                            color: $secondary;
                            text-decoration: none;
                        }
                    }
                    .timeslot-title {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        padding-bottom: 4px;
                        width: 100%;
                    }
                    .timeslot-description {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        width: 100%;
                    }
                }
                .timeslot-time {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 140%;
                    color: $dark;
                    margin-right: 8px;
                    text-align: right;
                    width: 52px;
                    flex: 0 0 52px;
                    margin-top: 3px;
                }
                .add-to-calendar-button-container {
                    cursor: pointer;
                }
                .add-timeslot-to-calendar {
                    svg:hover {
                        color: $secondary;
                    }
                }
            }
        }
    }
}

.loading-timeslots {
    margin: 12px;
    overflow: hidden;
    p {
        margin: 0;
        background: $light-grey-v2;
    }
    .placeholder-day {
        width: 91px;
        height: 15px;
        background: $light-grey-v2;
    }
    .placeholder-timeslots-list {
        margin-top: 12px;
        .placeholder-timeslot {
            padding: 8px;
            border-radius: 6px;
            margin-bottom: 12px;
            display: flex;
            &:nth-child(2n + 1) {
                background: $white-variant;
            }
            .placeholder-left-container {
                width: 89px;
                padding-right: 13px;
                border-right: 2px solid $disabled-text;
                display: flex;
                flex-flow: column;
                align-items: end;
                p {
                    width: 41px;
                    height: 10px;
                    margin: 4px 0;
                }
            }
            .placeholder-right-container {
                padding-left: 8px;
                flex: 1;
                .placeholder-timeslot-info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .name {
                        width: 153px;
                        height: 10px;
                    }
                    .button {
                        margin-top: 2px;
                        width: 16px;
                        height: 16px;
                    }
                }
                .placeholder-timeslot-content {
                    margin-top: 18px;
                    width: 100%;
                    max-width: 432px;
                    height: 22px;
                    margin-bottom: 4px;
                }
                .placeholder-timeslot-footer {
                    width: 100%;
                    max-width: 432px;
                    height: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .program-dropdown-wrapper.expanded {
        width: 100%;
    }
    .program-dropdown-wrapper {
        top: 56px;
        height: calc(calc(var(--vh, 1vh) * 100) - 56px);
        .date-title-mobile {
            color: $primary;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 2px;
        }
        .program-container {
            width: 100%;
            max-width: 100%;
            height: calc(100vh - 111px);
            position: absolute;
        }
        .container-tablet-helper {
            padding-inline-start: 10px;
        }
    }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
    .select-timezone-container {
        margin-inline-end: 10px;
    }
}

.timeslot-description p:not(:last-of-type) {
    display: block;
    margin-block-end: 1em !important;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-block-start: 0 !important;
}
//.program-details-container {
//    .timeslots-list {
//        .accordion-container {
//            .timeslot-description-on-search {
//                max-width: 520px;
//                width: 100%;
//            }
//        }
//    }
//}

.accordion-container {
    .timeslot-description-on-search {
        max-width: 520px;
        width: 100%;
    }
    a {
        color: $secondary;
    }
    .speakers-container {
        padding-top: 24px;
        .speakers-list {
            list-style: none;
            margin: 0;
            padding: 28px 0 0;
            li {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.03em;
            }
            li:not(:last-of-type) {
                padding-bottom: 16px;
            }
        }
    }
    .speakers-title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $dark;
    }

    .Mui-expanded {
        margin: 0;
        .accordion-timeslot-speakers {
            display: none;
        }
    }
}

.timeslot-title-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
}

.time-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date-container-mobile-and-tablet {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.03em;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    color: $primary;
}

.selected-timezone-value-mobile {
    position: absolute;
    top: 28px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.03em;
    color: $black-variant;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    left: -10px;
}

.program-details-container {
    .timeslots-list {
        .accordion-container {
            width: 100%;
            margin-bottom: 30px;
            .w-100 {
                width: 100%;
            }
        }
        .accordion-summary-mobile {
            .MuiAccordionSummary-content {
                margin: 0;
            }
        }

        .accordion-summary.MuiAccordionSummary-root {
            padding-inline-start: 0;
            padding-inline-end: 4px;
        }
        .accordion-details.MuiAccordionDetails-root {
            padding-inline-start: 0;
        }

        .timeslot-time-sm {
            line-height: 17px;
            font-weight: 600;
            font-size: 14px;
            font-family: 'Montserrat', sans-serif;
            color: $dark;
            &.timeslot-time-tablet {
                width: 20%;
                margin-inline-end: 14px;
            }
            &.timeslot-time-mobile {
                width: 100%;
            }
        }

        .auditorium-title {
            color: $secondary;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.03em;
            &.auditorium-title-tablet {
                margin-top: 8px !important;
            }
            &.auditorium-title-mobile {
                margin-top: 5px !important;
            }
        }

        .timeslot-details-tablet {
            width: 72%;
            margin-inline-end: 6px;
        }

        .actions-container-tablet {
            width: 5%;
            display: flex;
            flex-direction: column;
            align-items: end;
        }

        .timeslot-title-sm {
            color: $dark;
            font-size: 16px;
            line-height: 19.5px;
            font-weight: 600;
            font-family: Montserrat, sans-serif;
        }

        .timeslot-description-sm {
            font-size: 12px;
            font-weight: 500;
            font-family: Montserrat, sans-serif;
            line-height: 16px;
            margin-top: 4px !important;
        }

        .column {
            width: 78%;
        }

        .column-mobile {
            width: 328px;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
        }

        .empty-div {
            width: 20%;
            margin-right: 8px;
        }

        .event-icon {
            margin-top: 10px;
        }
        .add-to-calendar-button-container-sm {
            margin-top: 24px;
        }
    }
}

#active-timezone-area-mobile {
    background: $white-variant;
    height: 48px;
    width: 77px;
    position: absolute;
    right: calc(-2% - 1px);
    top: calc(-2% - 1px);
    border-radius: 6px;
    display: none;
}

.select-timezone-container-mobile {
    .MuiSelect-root:hover #active-timezone-area-mobile {
        display: block;
    }
}

.live-label {
    width: 49px;
    height: 18px;
    border-radius: 6px;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 49px;
    margin-top: -3px;
    .live-label-bullet {
        width: 8px;
        height: 8px;
        background-color: $white;
        border-radius: 8px;
        margin-inline-end: 4px;
    }
    .live-label-text {
        color: $white;
        font-size: 12px;
        font-weight: 500;
        line-height: 11.5px;
        font-family: Montserrat, sans-serif;
    }
}

.live-label-desktop-ml {
    margin-inline-end: 11px;
}

.live-label-tablet-mt {
    margin-top: 3px;
}

.live-timeslot-container {
    display: flex;
    justify-content: space-between;
    height: 30px;
    align-items: center;
}

.live-label-container-mobile {
    display: flex;
    align-items: center;
    .live-label {
        margin-inline-end: 18px;
    }
}

.live-label-margin-helper {
    .live-label {
        margin-inline-end: 38px;
    }
}

.live-label-container-tablet {
    display: flex;
    justify-content: space-between;
}

.svg-container-mobile {
    line-height: 0;
    font-size: 0;
    font-weight: 0;
}

/*Agenda*/
.program-titles {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    button {
        cursor: pointer;
        text-align: center;
        border: 0;
        width: 50%;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active-slot {
            background: $secondary-background;
        }
    }
}

span.meeting-requests-number {
    display: inline-flex;
    width: 16px;
    height: 16px;
    background: $secondary;
    font-style: normal;
    font-weight: 500;
    color: $white;
    border-radius: 9px;
    font-size: 11px;
    line-height: 12px;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    span {
        position: relative;
        left: -0.5px;
    }
}
.add-to-my-agenda-btn {
    padding: 0;
    border: none;
    background: transparent;
    z-index: 999;
    cursor: pointer;
    svg.discard {
        path {
            fill: $secondary;
        }
    }
}
.add-to-my-agenda-btn:hover {
    svg {
        path {
            fill: $secondary;
        }
    }
}
.container-support-addtomyagenda {
    display: flex;
    align-items: center;
    height: 25px;
    flex-direction: row;
    gap: 19px;
}
.timearea.mobile-support {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;
}
.accordion-container.mobile-support {
    padding-bottom: 5px;
}
.accordion-container.mobile-support:not(:last-child) {
    border-bottom: 1px solid $disabled-text;
}
.timearea.mobile-support {
    margin-right: 10px !important;
}
.accordion-container.tblt {
    border-bottom: 1px solid $disabled-text;
    margin-bottom: 20px !important;
}
.calendar-list-style {
    width: 66%;
    .program-container {
        width: 100%;
        max-width: 100%;
    }
}
.content-filler {
    display: block;
    height: 20px;
    width: -webkit-fill-available;
}
