@import './CSS/muiOverrides/muiOverrides';
@import 'CSS/variables.module';
@import './CSS/layout';
@import './CSS/typography';

body {
    margin: 0;
    padding: 0;
    font-family: $fontFamily, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    -moz-osx-font-smoothing: grayscale;
    height: calc(100vh - env(safe-area-inset-bottom, 0));
    position: relative;
    padding-right: 0 !important;
}
html {
    box-sizing: border-box;
    direction: ltr;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
img,
svg {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.top-container {
    position: relative;
    z-index: 999;
    width: 100%;
}
.purple-background {
    background-color: $secondary;
}
.purple-color {
    color: $secondary;
}
.green-color {
    color: $primary;
}
#breadcrumbs {
    position: absolute;
    z-index: 2;
    width: 100%;
    left: 0;
    top: 0;
    padding: 8px 0 8px 43px;
    background-color: rgba(255, 255, 255, 0.5);
    nav {
        font-weight: 500;
        font-size: 14px;
        color: $secondary;
        li {
            a {
                color: inherit;
                font-family: $fontFamily, sans-serif;
                font-weight: 500;
            }
            span {
                cursor: default;
                color: #211e16;
                font-family: $fontFamily, sans-serif;
                font-weight: 500;
            }
        }
    }
    &.white {
        background-color: rgba(43, 43, 43, 0.7);
        nav {
            color: #fff;
            a {
                color: #fff;
            }
            span {
                color: var(--theme-primary-color);
            }
        }
    }
}
.scroll-left-container {
    height: 100%;
    overflow: auto;
    direction: rtl;
    scrollbar-width: thin;
    scrollbar-color: rgb(137, 137, 137) #fff0;
    margin-inline-end: 4px;
    // background: #f5f5f5;

    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(137, 137, 137);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    & > div {
        direction: ltr;
    }
}
@media screen and (max-width: 1024px) {
    .App.event-page {
        display: flex;
        justify-content: normal;
        align-items: normal;
        flex: initial;
        flex-direction: column;
        height: 100% !important;
        max-height: 100% !important;
        width: 100vw !important;
        max-width: 100vw !important;
        position: fixed !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
    }
    .position-background {
        position: initial;
        overflow: initial;
        height: auto;
        font-size: 16px;
        overflow: auto;
    }
    .mobile-page-container {
        position: initial;
        overflow: auto;
        height: calc(100vh - 56px);
        height: calc(calc(var(--vh, 1vh) * 100) - 56px);
        min-height: calc(100vh - 56px);
        min-height: calc(calc(var(--vh, 1vh) * 100) - 56px);
        padding-bottom: 56px;
    }
    .mobile-page-container.auditorium-page {
        height: auto;
    }
    .mobile-page-container.auditorium-page.wall-tab-active {
        height: calc(100vh - 56px);
        height: calc(calc(var(--vh, 1vh) * 100) - 56px);
    }
    .mobile-page-container.auditorium-page.wall-tab-active.h100vh {
        height: 100vh !important
    }
    .centerOfPage {
        position: initial;
        top: initial;
        left: initial;
        width: auto;
        height: auto;
    }
    .relativeCenter {
        position: static;
    }
    #breadcrumbs {
        display: none;
    }
}
@media screen and (max-width: 480px) {
    .platform-not-available-page {
        &.private-page {
            position: absolute;
            height: 100%;
        }
    }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

/* Handle */
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded::-webkit-scrollbar-thumb {
    background: $grey;
    border-radius: 10px;
}

/* Handle on hover */
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded::-webkit-scrollbar-thumb:hover {
    background: $grey;
}

.overflow-hidden.MuiDialogContent-root {
    overflow-y: unset !important;
}
