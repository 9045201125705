.MuiSelect-select.MuiSelect-select{
    padding-inline-end: 50px !important;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    background: white;
    .MuiChip-root{
        margin-top: 2px;
        margin-bottom: 2px;
        margin-right: 8px;
        max-width: 30%;
    }
}
.MuiInputBase-root svg.material-icons {
    scale: 1.5;
}

.select-label{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 12px);
    padding-inline-end: 50px;
    &.MuiInputLabel-shrink{
        padding-inline-end: 0;
        max-width: calc(100% + 12px);
    }
}

.color-secondary{
    .MuiPaper-root.MuiMenu-paper, .MuiAutocomplete-paper{
        .MuiMenu-list, .MuiAutocomplete-listbox{
            .MuiMenuItem-root, .MuiAutocomplete-option{
                &.Mui-selected{
                    color: #208918;
                    background-color: white;
                }
            }
            .MuiListItem-button:hover, .MuiAutocomplete-option:hover{
                background-color: #208918;
                color: white;
            }
        }
    }
}
//.MuiPaper-root.MuiMenu-paper {
//    max-width: 0; // fix for menu width too big for long items
//}

.MuiPaper-root.MuiMenu-paper, .MuiAutocomplete-paper{
    max-height: 250px;
    margin-top: 12px;
    direction: rtl;
    .MuiMenu-list, .MuiAutocomplete-listbox{
        direction: ltr;
        padding-top: 4px;
        padding-bottom: 4px;
        .MuiMenuItem-root, .MuiAutocomplete-option{
            padding: 10px 20px;
            font-weight: 600;
            font-size: 16px;
            font-family: "Montserrat", sans-serif;
            line-height: 20px;
            color: #2B2B2B;
            &.Mui-selected{
                color: #6624D1;
                background-color: white;
            }
            .MuiListItemText-root span{
                padding: 10px;
                font-weight: 600;
                font-size: 16px;
                font-family: "Montserrat", sans-serif;
                line-height: 20px;
            }
            .MuiListItemIcon-root {
                color: inherit;
            }
        }
        .MuiListItem-button:hover, .MuiAutocomplete-option:hover{
            background-color: #6624D1;
            color: white;
        }
    }
    &::-webkit-scrollbar-thumb {
        width: 6px;
        background: #898989;
        border: 1px solid #FFFFFF;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }
}

.selected-chips-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: -4px;
    margin-bottom: -4px;
}

.MuiAutocomplete-endAdornment {
    right: 7px;
    .MuiSvgIcon-root {
        color: #0000008a;
    }
}