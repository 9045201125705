@import 'CSS/variables.module';

.info-tab-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  white-space: pre;
  padding-right: 12px;
  padding-left: 12px;
  cursor: pointer;
  text-transform: uppercase;
  background: white;
  color: $primary;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    border-color: $primary;
  }
  &:focus {
    color: $dark;
    background: $secondary-background;
    border: 2px dashed $secondary;
  }
  &:active {
    color: white;
    background: $primary;
    border-color: $primary;
  }

}