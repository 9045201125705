.mail-contianer-user-profile {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    line-height: 25px !important;
    &.ar-helper {
        justify-content: flex-start;
    }
}
.mail-contianer-user-profile {
    width: 100%;
    justify-content: space-between;
    .user-mail-helper {
        width: 170px;
    }
    .ar-helper.user-mail-helper {
        max-width: 270px;
    }
}
.change-email-btn {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid var(--mo-background-secondary-400, #208918);
    background: white;
    min-width: 170px;
    min-height: 32px;
    color: #208918;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-transform: uppercase;
}
.control-password-form {
    padding: 0;
}
.password-control-container-width {
    .MuiDialog-paperWidthSm {
        min-width: 580px;
    }
}
.password-control-container-width.mobile-support {
    .MuiDialog-paperWidthSm {
        min-width: auto !important;
    }
}
.password-control-actions {
    padding: 0;
    direction: ltr;
    margin-top: 12px;
    margin-bottom: 4px;
    button {
        color: #2b2b2b;
    }
}

/*------email change*/

.email-change-container {
    width: 100vw;
    display: flex;
    border-top: 1px solid #cacbd0;
    border-bottom: 1px solid #cacbd0;
    margin: 40px -30px;
    padding: 30px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
}
.mobile-support.email-change-container {
    margin: 0;
    padding: 20px;
    background: white;
}

.error-text-span {
    display: flex;
    margin-right: auto;
    direction: ltr;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
    color: #d82a2a;
    margin-top: 5px;
}
.error-text-field {
    label {
        color: #d82a2a;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: #d82a2a;
        border-width: 2px;
    }
    input {
        color: #d82a2a;
    }
}
.email-change-header {
    h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0;
        text-align: left;
    }
}
.user-email-container {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
}
.user-profile-information {
    width: 100%;
}
.arabic-support {
}

@media (max-width: 768px) {
    .email-change-validation-head p span {
        display: block;
        width: 100%;
        max-width: 100%;
    }
    .email-change-container {
        width: 100%;
        height: calc(100vh - 217px);
    }
}
.success-light {
    transition: all 0.5s ease-in-out;
    box-shadow: 0 0 10px 2px #208918;
}

.scroll-left-container.arabic-support {
    input,
    fieldset,
    div.MuiInputBase-root,
    legend {
        direction: rtl !important;
        text-align: right !important;
    }
}
.arabic-support {
    .MuiOutlinedInput-adornedEnd {
        padding-left: 12px;
    }
    .MuiInputLabel-outlined {
        transform: translate(-20px, 18px) scale(1);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(-18px, -6px) scale(0.75);
    }
    direction: rtl !important;
    text-align: right !important;

    * {
        direction: rtl !important;
        text-align: right !important;
    }
    label {
        right: 0;
        transform-origin: top right;
    }
}
