.meeting-create-title {
    font-weight: bold;
    padding: 0 20px;
    margin: 10px 0 0 0;
    font-size: 24px;
    line-height: 40px;
}
.user-container-create-meeting {
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    .avatar {
        margin: 0;
        img {
            border-radius: 50%;
        }
    }
}
