.react-player.fullscreen-ios {
    padding: 0 !important;
   
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9999999999999;
    background-color: #000 !important;
    background: #000 !important;
    height: 100vh !important;
    pointer-events: none !important;
    .video-stream.html5-main-video {
        width: 100% !important;
    }
    @media screen and (min-width: 480px) {
        .booth-video-actions-container {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            background: #00000070;
            bottom: 0;
            display: flex;
            align-items: center;
            padding: 10px 0;
            width: fit-content;
            border-radius: 9px;
        }
    }

    svg {
        path {
            fill: white;
        }
    }
}

.video-actions-container.fullscreen-ios-controls {
    z-index: 99999999999999999999 !important;
    background-color: transparent !important; 
        //    background-color: #000 !important;

    align-items: baseline !important;
    position: fixed !important;
    bottom: 21% !important;
}
.video-ended-text {
    // exclude arrows
    max-width: calc(100% - 100px);
}
@media screen and (orientation: landscape) {
    .video-actions-container.fullscreen-ios-controls {
        bottom: 20px !important;
        width: 300px !important;
        align-items: center !important;
        background-color: #000000b0 !important;
        border-radius: 6px !important;
    }
  
}

#js-auditorium-page.mobile-page-container.auditorium-page.wall-tab-active.h100vh,
.h100vh {
    height: 100vh !important;
}

body.fullscreen-bg {
    background-color: black !important;
}


@supports (-webkit-touch-callout: none) {
    .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom{
        padding-left: 40px;
    }
  }
  .bg-black{background-color: black !important;}