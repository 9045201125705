@import 'CSS/variables.module';

.single-exhibitor.active {
    background: $white;
}
.vote-action {
    height: 37px;
    background: $light-grey;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px;
    position: relative;
    span.MuiCheckbox-root {
        padding: 0;
        margin: 0;
        .MuiSvgIcon-root {
            width: 18px !important;
        }
    }
    span.MuiCheckbox-root:hover {
        background: transparent;
    }
    span.vote-text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.4px;
        text-transform: uppercase;
        color: $black-variant;
    }
}

.vote-number.mobile {
    color: $primary;
    /*
    margin-top: 17px;
    */
    svg path {
        fill: $primary;
    }
}
.single-exhibitor.active {
    .vote-number.mobile {
        color: $white;
        /*   margin-top: 17px;*/
        svg path {
            fill: $white;
        }
    }
}
.showfloors-list.scroll-additional-helper {
    margin-bottom: 10px;
}

.showfloors-list.scroll-additional-helper::-webkit-scrollbar {
    height: 4px;
    border: 1px solid #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}
.ellipsis-second-line.min-width-250 {
    min-width: 250px;
}
.ellipsis-second-line.vote-support {
    height: 40px;
    display: -webkit-box;
    align-items: center;
    max-width: 250px;
}
