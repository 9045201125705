@import 'src/CSS/hpColors';
@import 'src/CSS/variables.module';

.homepage {
    .tab-name {
        -webkit-tap-highlight-color: transparent;
        text-align: center;
        position: relative;
        cursor: pointer;
    }
    .program-section {
        .tab-name {
            padding: 11px 0;
            min-width: 200px;

            &.active {
                background-color: $white;
                border-radius: 8px 8px 0 0;
                margin-top: -5px;
                padding-top: 16px;

                /*    &:before {
          content: '';
          position: absolute;
          height: 8px;
          width: 100%;
          bottom: 100%;
          left: 0;
          background-color: $white;
          border-radius: 8px 8px 0 0;
        }*/

                &:after {
                    content: '';
                    position: absolute;
                    height: 5px;
                    width: 100%;
                    top: 100%;
                    left: 0;
                    background-color: $white;
                }

                span {
                    color: var(--secondary-color);
                }
            }

            span {
                color: $white;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.0125em;
            }
        }

        .secondary-colors .tab-name.active span {
            color: var(--primary-color);
        }
    }

    .partners-section {
        .tab-name {
            min-width: 144px;
            padding: 11px 0;

            &.sponsors {
                @media screen and (max-width: 480px) {
                    display: none;
                }
            }

            &.active {
                background-color: $white;

                &:before {
                    content: '';
                    position: absolute;
                    height: 8px;
                    width: calc(100% + 4px);
                    top: -8px;
                    left: -2px;
                    background-color: $white;
                    border-top: 2px solid var(--secondary-color);
                    border-left: 2px solid var(--secondary-color);
                    border-right: 2px solid var(--secondary-color);
                    border-radius: 8px 8px 0 0;
                }

                &:after {
                    content: '';
                    position: absolute;
                    height: 5px;
                    width: 100%;
                    top: 100%;
                    left: 0;
                    background-color: $white;
                }

                span {
                    color: var(--secondary-color);
                }
            }

            span {
                color: $white;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.0125em;
            }
        }

        .secondary-colors .tab-name.active {
            &:before {
                border-top: 2px solid var(--primary-color);
                border-left: 2px solid var(--primary-color);
                border-right: 2px solid var(--primary-color);
            }

            span {
                color: var(--primary-color);
            }
        }
    }
}

@media all and (max-width: 767.9px) {
    .homepage .partners-section .tab-name {
        min-width: 144px;
    }
}
