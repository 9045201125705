@import '../../CSS/variables.module';

.event-profile-item {
    padding: 0;
    line-height: 25px;
    word-break: break-word;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    .event-profile-item-label {
        color: $text-grey;
        text-transform: capitalize;
        min-width: auto;
        word-break: break-word;
    }
    .event-profile-item-colon {
        color: $text-grey;
        padding-inline-end: 5px;
    }
}
