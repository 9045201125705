 @import 'variables.module';

.sort-buttons {
    justify-content: space-between;
    width: 100%;
}
.network-filters-wrapper {
    position: relative;
}

.selected-filters-count {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $primary;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $white;
    border-radius: 50%;
    top: -4px;
    right: -9px;
}
.network-filters-show-btn {
    position: relative;
    &:hover {
        svg {
            path {
                fill: $secondary !important;
            }
        }
    }
}
.search-user-container {
    height: 43px;
    background-color: $white-variant;
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-right: 19px;
    padding-bottom: 8px;
    padding-left: 16px;
    .standard-search {
        margin-right: 20px;
    }
    .search-input-container {
        flex: 90%;
        display: flex;
        align-items: flex-start;
        input {
            padding: 0 !important;
            width: 100%;
            background-color: $white-variant;
        }
    }
    .search-icon-container {
        flex: 5%;
        display: flex;
        // margin-right: 20px;
    }
    .search-filters-icon-container {
        flex: 5%;
    }
    &.no-filter-button{
        padding-right: 16px;
        .search-container-helper{
            width: 100%;
            margin-right: 0;
        }
    }
}
.no-results {
    background: #f5f5f5;
    .search-user-container {
        position: relative;
        z-index: 11;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.2);
        background: #f5f5f5;
        #standard-search {
            background: #f5f5f5;
            color: $text-grey;
            font-weight: 500;
        }
    }
}
.filter-users {
    display: none;
}
.participants-page .friends-list {
    padding: 0;
    margin: 0;
}
.participants-page .friends-list li {
    list-style-type: none;
    padding: 13px 0 0 0;
}
.participants-page .friends-list li:first-child {
    padding-top: 0;
}
.participants-page .friends-list li:last-of-type {
    padding-bottom: 13px;
}
.participants-page .friends-list li:last-of-type .user-details {
    border-bottom: 0;
}
.participants-page .friends-list li > div {
    text-align: left;
}
.custom-scroll-container.networking-wrapper {
    background-color: $white-variant;
    border-top: 1px solid $disabled-text;
}
.custom-scroll-container.networking-wrapper div,
.scrollbar,
.scrollbar div {
    outline: none !important;
}
.participant-avatar {
    width: 54px;
    height: 54px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: relative;
    margin-inline-end: -16px;
}
.networking-container .participant-avatar {
    flex: 0 0 54px;
}
.is-online .participant-avatar:after,
.is-online .avatar:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 12px;
    height: 12px;
    background-color: $primary;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
}
.is-offline .participant-avatar:after,
.is-offline .avatar:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 12px;
    height: 12px;
    background-color: $grey;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
}
.participant-avatar .online-button {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.participant-avatar .online-button:after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: $primary;
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    border: 0.7px solid #ffffff;
}
.participant-button {
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    line-height: 1;
}
// add some margin to the last user card
.ReactVirtualized__Grid__innerScrollContainer:last-child {
    margin-bottom: 10px;
    // Mobile
    @media all and (max-width: 1024px) and (max-height: 1366px) {
        margin-bottom: 0;
    }
}
// make virtualized list row (user card) height auto for long name / company / title
.Row {
    height: auto !important;
}


@supports (-webkit-touch-callout: none) {
    .search-user-container.networking-helper{
        height: 50px !important;
    
    }
    .search-container-helper .search-input-container{margin-top:-8px}
    .search-container-helper{padding-top: 8px;}
  }



// MATCHING USER CARD STYLES
.user-main-card {
    z-index: 3;
    width: 323px;
    min-height: 108px;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    padding: 8px;
    border-bottom: 1px solid $disabled-text;
    // Small desktop
    @media all and (max-width: 1280px) and (max-height: 800px) {
        width: 100%;
    }
    // Tablet
    @media all and (max-width: 1024px) and (max-height: 1366px) {
        width: 100%;
        padding-left: 10px;
    }
    .user-container {
        padding: 0 !important;
        display: flex;
        &.is-online {
            .participant-avatar {
                &:after {
                    background: linear-gradient(
                        137.73deg,
                        $gradientMenu1 -15.66%,
                        $gradientMenu2 43.77%,
                        $gradientMenu3 100.28%
                    );
                }
            }
        }
        &.is-offline {
            .participant-avatar {
                &:after {
                    background: $grey;
                }
            }
        }
        .participant-avatar {
            min-width: 55px;
            min-height: 55px;
            box-shadow: none;
            margin-inline-end: 8px;
            &:after {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 16px;
                height: 16px;
                background: linear-gradient(
                    137.73deg,
                    $gradientMenu1 -15.66%,
                    $gradientMenu2 43.77%,
                    $gradientMenu3 100.28%
                );
                border: 3.5px solid $white;
                border-radius: 50%;
                box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
            }
        }
        .match-level {
            z-index: 5;
            position: absolute;
            top: 74px;
            left: 5.5px;
            width: 100%;
            max-width: 60px;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.4px;
            color: $primary;
            text-transform: uppercase;
            margin-right: 7px;
            &.medium {
                color: $secondary;
                &.smaller-label {
                    font-size: 10px;
                    line-height: 10px;
                }
            }
            &.smaller-label {
                font-size: 10px;
                line-height: 10px;
            }
        }
        .user-details {
            flex: 1;
            display: flex;
            border: 0;
            padding-top: 5px;
            position: relative;
            text-align: start;
            justify-content: space-between;
            div {
                max-width: 175px;
                height: auto;
                padding-right: 5px;
            }
            .user-name {
                max-width: 175px;
                height: auto;
                .user-name-top {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 95.9%;
                    letter-spacing: -0.03em;
                    color: $dark;
                }
                .user-name-bottom {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: -0.4px;
                    color: $grey-dark;
                }
            }
            .participant-button-chat {
                width: 80px;
                height: 37px;
                min-height: 37px;
                border: 0;
                outline: 0;
                background-color: transparent;
                cursor: pointer;
                padding: 0;
                div {
                    height: 100%;
                    padding-right: 0;
                    width: 100%;
                    position: relative;
                    svg {
                        width: 100%;
                        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
                        @media all and (max-width: 1024px) and (max-height: 1366px) {
                            filter: unset;
                        }
                    }
                    span {
                        position: absolute;
                        left: 50%;
                        top: 12px;
                        transform: translateX(-50%);
                        text-transform: uppercase;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.0125em;
                        color: white;
                        font-weight: bold;
                        font-family: $fontFamily, sans-serif;
                    }
                }
                &:disabled,
                &.display-only {
                    cursor: initial;
                }
            }
        }
    }
    .bottom-row {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 8px;
        padding-right: 5px;
        padding-left: 72px;
        .social-add {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .match-social {
                display: flex;
                flex-direction: row;
                align-items: center;
                .single-social-link{
                    margin-right: 20px;
                    font-size: 0;
                    &:last-child {
                        margin: 0;
                    }
                }
                .social-button {
                    width: 24px;
                    height: 24px;
                }
            }
            .participant-button-add {
                border: 0;
                outline: 0;
                background-color: transparent;
                cursor: pointer;
                &:disabled,
                &.display-only {
                    cursor: initial;
                }
            }
        }
        &.sessions-actions {
            bottom: 45px;
            padding-bottom: 4px;
        }
    }
}

html.lang-rtl {
    .bottom-row {
        .social-add {
            .single-social-link:last-child {
                margin-right: 20px !important;
            }
        }
    }
}
#chat-tooltip, #facebook-tooltip, #scholar-tooltip,
#linkedin-tooltip, #research-tooltip, #twitter-tooltip,
#connect-tooltip, #new-chat-tooltip {
    .MuiTooltip-tooltip{
        padding: 10px 12px 10px 40px;
    }
}

#matching-tooltip {
    max-width: 215px;
    max-height: 90px;
    .MuiTooltip-tooltip {
        padding: 15px 12px 15px 48px;
        border-radius: 12px;
        border: 1px solid $disabled-text;
        background-position: 14px 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $dark;
        word-break: break-word;
    }
    // Tablet
    @media all and (max-width: 1024px) and (max-height: 1366px) {
        display: none;
    }
}
// END - MATCHING USER CARD STYLES
.user-container {
    display: flex;
    flex-wrap: wrap;
}
.networking-wrapper .user-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}
.already-contacts-wrapper .user-container .participant-button.open-chat {
    margin: 5% 0;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -webkit-filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -ms-flex-item-align: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
        }
    }
}
.contacts-page .box div:last-child .user-container .user-details {
    border-bottom: 0;
}
.user-container .user-details {
    flex: 1;
    display: flex;
    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
    padding-bottom: 0;
    position: relative;
    text-align: start;
}
.user-container .user-name p:last-child {
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    font-family: $fontFamily, sans-serif;
    margin-bottom: 34px;
    word-break: break-word;
    word-wrap: break-word;
}
.user-name .user-company-span {
    text-transform: none;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
}
.user-details > div:first-child {
    flex: 1;
    word-break: break-word;
}
.user-details > div:last-of-type {
    min-height: 58px;
}
.user-details .user-name {
    margin: 0;
}
.user-details .social-button {
    display: inline-block;
    line-height: 1;
    margin-inline-end: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            svg {
                fill: $secondary;
                path {
                    fill: $secondary;
                }
            }
        }
    }
}
.networking-wrapper .scrollbar > div:nth-child(3) {
    margin-bottom: 0;
}
.connection {
    height: 100%;
}
.participant-button.request-sent,
.participant-button.connect-button,
.participant-button.add-button {
    position: absolute;
    right: 0;
    cursor: initial;
    outline: 0;
    bottom: 5px;
    display: flex;
    align-items: center;
}
.participant-button.add-button span,
.participant-button.request-sent span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 10px;
}
.participant-button.request-sent span {
    color: $text-grey;
}
.participant-button.add-button:disabled {
    cursor: initial;
}
.participant-button.open-chat {
    outline: 0;
    background-color: transparent;
    div {
        position: relative;
        span {
            position: absolute;
            left: 50%;
            top: 12px;
            transform: translateX(-50%);
            text-transform: uppercase;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.0125em;
            color: white;
            font-weight: bold;
            font-family: $fontFamily, sans-serif;
        }
        svg {
            filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
            @media all and (max-width: 1024px) and (max-height: 1366px) {
                filter: unset;
            }
        }
    }
}
.networking-container .participant-button.open-chat {
    top: -5px;
    background-color: transparent;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -webkit-filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -ms-flex-item-align: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
        }
    }
}
.participant-button.connect-button,
.participant-button.add-button {
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -webkit-filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -ms-flex-item-align: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
        }
    }
}
.networking-container .participant-button.open-chat:disabled {
    cursor: initial;
    background-color: transparent;
}
.participant-button.add-button {
    cursor: pointer;
}
.networking-container {
    clear: both;
}
.networking-container .accept-request {
    display: none;
}
.networking-container .reject-request {
    display: none;
}
.networking-container .custom-scroll-container {
    height: calc(100vh - 209px);
}
.networking-container .empty-results {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #f5f5f5;
    height: calc(100vh - 202px);

    @media screen and (max-width: 1024px) {
        height: calc(calc(var(--vh, 1vh) * 100) - 250px);
    }

    .empty-artwork {
        padding: 0 22px;
        margin-top: -53px;

        &.move-artwork {
            margin-bottom: -150px;
        }
    }

    p {
        font-weight: 600;
        font-size: 16px;
        color: $text-grey;
        margin-bottom: 0;
    }
}
.networking-container .matching-spinner {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $white;
    height: calc(100vh - 202px);
    border-top: 1px solid rgba(137, 137, 137, 0.5);

    @media screen and (max-width: 1024px) {
        height: calc(calc(var(--vh, 1vh) * 100) - 250px);
    }

    p {
        font-size: 16px;
        line-height: 18px;
        color: $dark;
        padding: 4px 16px;
    }
}
.networking-wrapper .user-wrapper-separator {
    display: none;
}
@media screen and (max-width: 1024px) {
    .user-container .user-details {
        border: 0;
    }
    .auditorium-page .networking-container .empty-results {
        height: calc(calc(var(--vh, 1vh) * 100) - 290px);
    }
}
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .networking-wrapper .user-wrapper-separator {
        display: block;
    }
    .networking-container .user-container .user-details .social-links-container {
        margin-left: -50px;
    }
}
.participants-page .sort-container {
    min-height: 48px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #f5f5f5;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-transform: uppercase;
    padding: 0 8px;
    border-bottom: 1px solid $disabled-text;
    &.is-filtering{
        height: 48px;
    }
}
.participants-page .sort-container span {
    padding: 9.5px;
}
.participants-page .sort-container span:first-of-type {
    padding-left: 10px;
}
.participants-page .sort-container .button {
    border-radius: 6px;
    cursor: pointer;
    text-transform: uppercase;

    &.matching-tooltip-border {
        box-shadow: 0 0 0 1px $primary;
        // Tablet
        @media all and (max-width: 1024px) and (max-height: 1366px) {
            box-shadow: none;
        }
    }

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            color: $primary !important;
        }
    }
}
.participants-page .sort-container .button.active {
    color: $white;
    background-color: $secondary;
    &:focus {
        outline: none;
        outline-width: 0;
    }
    &.focus-visible {
        outline: none;
        outline-width: 0;
    }
}
@media screen and (max-width: 1024px) {
    .participants-page .sort-container span {
        font-weight: 500;
        font-size: 16px;
    }
    .participants-page .sort-container span.button {
        font-weight: bold;
    }
}
@media screen and (max-width: 374px) {
    .participants-page .sort-container span {
        font-size: 15px;
    }
}
@media screen and (max-width: 355px) {
    .participants-page .sort-container span {
        font-size: 14px;
    }
}
@media screen and (max-width: 330px) {
    .participants-page .sort-container span {
        font-size: 13px;
    }
}
