@import 'CSS/variables.module';
@import 'CSS/hpColors';

.d-flex-row {
    display: flex;
    flex-direction: row;
}
.d-flex-column {
    display: flex;
    flex-direction: column;
}
.login-registration-container {
    width: 100%;
    position: relative;
    min-height: 100%;
    align-content: flex-start;
    justify-content: flex-start;
    .login-registration-action {
        position: relative;
        display: flex;
        width: 80%;
        min-height: 100vh;
        padding: 29px 40px;
        .login-registration-header {
            min-height: 72px;
            max-height: 80px;
            gap: 11px;
        }
    }
    .login-registration-death-space {
        right: 0;
        width: 20%;
        position: fixed;
        top: 0;
        height: 100vh;
        align-items: flex-start;
        display: flex;
        background: url('../images/main-bg.webp') bottom center no-repeat;
        background-size: cover !important;
        .menu-wrapper {
            display: flex;
            align-items: center;
            margin-left: auto;
        }
    }
}

.one-step-back-btn-container {
    width: 100%;
    display: flex;
    background-color: $white;

    button {
        margin-left: -12px;

        span {
            display: flex;
            flex-direction: row;
            gap: 14px;
        }

        &:active {
            svg path {
                fill: $white;
            }
        }
    }
}
.registration-progress-bar {
    height: 8px;
    display: block;
    background: $light-grey;
    margin-left: -40px;
    margin-right: 0;
}
.children-container {
    margin: 46px auto 50px auto;
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.select-role-page.children-container {
    min-height: calc(100vh - 115px);
}
.login-page.children-container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    margin: auto !important;
    height: max-content;
    min-height: auto;
}
.page-headline {
    display: flex;
    text-align: center;
    flex-direction: column;
    gap: 12px;
    margin: auto;
    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        text-align: center;
        color: #211e16;
    }
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.0015em;
        color: $dark;
    }
    h2,
    p {
        margin: 0;
        padding: 0;
    }
}
.register-button:not(.create-event-btn),
.buttons-wrapper {
    padding-bottom: 0 !important;
    button {
        margin: 0 !important;
    }
}

ul.social-media-connections {
    display: flex;
    margin: 24px 0 0 0;
    padding: 0;
    width: 100%;
    list-style: none;
    justify-content: space-evenly;
    flex-direction: row;
    gap: 35px;
    li {
        padding: 0;
        margin: 0;
        button {
            padding: 16px;
            width: 72px;
            height: 72px;
            background: $white;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:active {
                background: $secondary;
                svg path {
                    fill: $white;
                }
            }
        }
    }
}
.or-separator {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    margin-top: 18px;
    margin-bottom: 18px;
    .left-line,
    .right-line {
        width: inherit;
        height: 1px;
        background: $grey;
    }
    .or-text {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: $grey-dark;
    }
}
.MuiInput-underline:after,
.MuiInput-underline:before {
    display: none !important;
}
.login-form-fc {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 32px;

    &::before,
    &::after {
        display: none !important;
    }

    div {
        width: 100%;
        padding: 0;
        margin: 0;

        label {
            top: -15px;
            left: 7px;
            padding: 5px;
        }

        label.Mui-focused {
            top: -12px;
            background: $white;
            color: var(--primary-color);
            z-index: 9;
        }

        label.MuiFormLabel-filled {
            top: -12px;
            z-index: 9;
            background: $white;
        }
    }

    div.submit-and-secondary-actions {
        margin-top: -10px;
    }

    input {
        width: 100%;
        height: 48px;
        border: 1px solid $disabled-text;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 0 45px 0 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: $dark;

        &:focus {
            border: 2px solid var(--primary-color);
        }

        &[type='password'],
        &[type='email'] {
            &:focus {
                border: 2px solid var(--primary-color);
            }
        }
    }
}

.Mui-focused {
    input[type='password'] {
        color: var(--primary-color);
        font-size: 25px;
    }
}

input[type='password'] {
    color: $grey;
}
.Mui-error {
    input[type='password'] {
        color: $error !important;
    }
}
.Mui-focused.Mui-error {
    input[type='password'] {
        color: var(--primary-color) !important;
    }
}
.withEndAdornment {
    .MuiInputBase-adornedEnd {
        position: relative;
        width: 100%;
    }
    .show-pass {
        cursor: pointer;
        position: absolute;
        right: 12px;
        top: 12px;
        width: fit-content;
    }
    .show-pass.email {
        cursor: default;
    }
    svg path {
        fill: $grey;
    }
}
.send-form-fc,
.send-form-fc:hover {
    background: var(--primary-color);
    span {
        color: $white;
    }
}
.send-form-fc.disabled {
    background: $white-variant;
    span {
        color: $grey-variant;
    }
}
.email-text-field.field-container.withEndAdornment.disabled {
    input {
        color: $disabled-text;
    }
}
.login-registration-field-login-container {
    position: relative;
    width: 100%;
    height: 48px;
}

.secondary-form-actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px !important;
    button:active {
        background: var(--primary-color);
    }
}

.submit-and-secondary-actions {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    button.send-form-fc {
        width: 100%;
    }
}
.error-pass {
    color: $error-light;
    font-size: 14px;
    font-weight: bold;
    display: block;
    white-space: pre-line;
    text-align: center;
    margin: 0 auto 15px;
}
@media only screen and (min-width: 768px) and (max-width: 1180px) and (orientation: portrait) {
    /*    .form-100p.tablet-helper {
        flex-direction: column;
    }
    .field-carrier.field-tablet-helper {
        width: 65%;
    }

    .field-carrier.password-container.field-tablet-helper {
        display: flex;
        flex-direction: row;

        width: 100%;
        div:first-child {
            width: 65% !important;
            display: flex;
            flex-direction: column;
            gap: 22px;
            div {
                width: 100% !important;
            }
        }
    }
    .validation-list {
        flex-wrap: nowrap !important;
        flex-direction: column !important;
    }
    .validation-list li {
        flex: auto !important;
    }
    .strong-password-container {
        display: flex;
        flex-direction: column;
        padding-left: 24px !important;
        width: 35% !important;
        gap: 22px !important;
    }*/
    /******/
    .login-registration-death-space {
        display: none !important;
    }

    .login-registration-container {
        .login-registration-action {
            width: 100%;
        }
    }
    .extra-fields-without-invitation-code {
        max-width: 100% !important;
    }
    .default-fields-and-invitation-code div {
        flex: 1 1 48% !important;
        max-width: 100% !important;
    }
    .access-packages-container {
        max-width: 100% !important;
    }
    .register-form-wrapper .agree-terms {
        padding: 21px 30px 30px !important;
    }
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
    .stripe-dialog-wrapper {
        z-index: 9999;
        .stripe-payment-dialog {
            width: 95% !important;
        }
    }
    .login-page.children-container {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
    .register-form-wrapper .agree-terms {
        position: unset !important;
        width: 106% !important;
        margin-left: -12px !important;
        margin-right: -12px !important;
        margin-bottom: 0 !important;
        .area-protect {
            flex-direction: column;
            gap: 16px;
            width: 100% !important;
        }
    }
    .area-protect {
        flex-direction: column;
        gap: 16px;
    }
    .page-headline h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }
    .page-headline p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    .login-registration-death-space {
        display: none !important;
    }
    .login-registration-container {
        .login-registration-action {
            width: 100%;
        }
    }

    .error-bar {
        top: 60px !important;
        justify-content: center !important;
        margin: 12px -12px 20px !important;
    }
    .single-package-headline-container .package-name {
        max-width: 275px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .login-registration-action {
        .login-registration-header {
            gap: 26px !important;
        }
    }
    .links-register-pages {
        display: none;
    }
    .agree-terms.small-margin-top .buttons-wrapper button {
        width: 100% !important;
    }
    .register-form-wrapper .agree-terms {
        display: flex;
        flex-direction: column !important;
        gap: 12px !important;
        /*
        margin: 0 !important;
        */
        left: 0;
        width: 100%;
        position: absolute;
        padding: 0 !important;
    }
    .agree-terms.small-margin-top {
        padding: 15px !important;
    }
    .agree-terms.small-margin-top label {
        width: 100% !important;
    }
    .container-btn-mobile {
        bottom: 0;
        display: flex;
        /*
        position: absolute;
        */
        align-items: center;
        margin-top: 40px;
        width: 107%;
        justify-content: center;
        margin-left: -15px;
        background: #ffffff;
        box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
        .continue-button-register {
            padding: 12px !important;
            width: 100% !important;
            margin: 0 !important;
        }
        padding: 15px 12px;
    }
    .login-registration-container .login-registration-action .login-registration-header {
        min-height: 36px !important;
    }
    .default-fields-and-invitation-code {
        gap: 32px !important;
    }
    .selectFieldV2-container div:first-child,
    .text-field-width div:first-child {
        width: 100% !important;
    }
    .default-fields-and-invitation-code div {
        flex: 1 1 100% !important;
        max-width: 100% !important;
    }

    .mobile-role-container {
        display: flex;
        flex-direction: column;
        div {
            display: flex;
            flex-direction: row;
            gap: 11px;
            margin-bottom: 8px;
        }
    }
    .roles-container li {
        height: auto !important;
        padding: 15px 16px !important;
    }
    .form-100p {
        flex-direction: column !important;
    }
    .login-registration-header.d-flex-column {
        margin: 0 !important;
        padding: 28px 12px 0 !important;
        margin: 0 -12px !important;
        background: white !important;
    }
    .login-registration-header.d-flex-column.select-role-mobile {
        padding: 28px 12px !important;
    }
    .page-headline {
        margin: 0 auto !important;
        max-width: 100% !important;
    }
    .register-button {
        padding-bottom: 10px !important;
    }
    .login-registration-header.d-flex-column.scroll-at-top {
        padding: 5px 12px 0 !important;
    }
    .logo-and-social-and-lang .menu-wrapper {
        display: none;
    }
    .login-registration-container {
        .login-registration-action {
            padding: 29px 12px 0;
        }
    }

    ul.social-media-connections {
        gap: normal;
        justify-content: space-evenly;

        li {
            button {
                width: 69px;
                height: 69px;
            }
        }
    }

    .children-container {
        width: 100% !important;
        margin-top: 7px !important;
        padding: 0 0 80px 0 !important;
    }
    .progress-container {
        width: 100% !important;
    }
    .registration-progress-bar {
        margin-left: -12px;
        margin-right: -12px;
    }
    .children-container {
        width: 100% !important;
        margin-top: 7px !important;
        padding: 0 0 0 0 !important;
        margin-bottom: 0 !important;
    }
    .register-form-wrapper.version-2 form {
        padding: 0 !important;
    }

    .progress-container {
        justify-content: space-around !important;
        width: 253px !important;
    }
    .step-line.left {
        left: 14% !important;
    }
    .step {
        width: 105px !important;
    }
    .step-line {
        width: 77px !important;
    }

    .step-line.right {
        right: 14% !important;
    }
    .step .step-text {
        display: none !important;
    }
    .step-line.left.two-steps {
        left: 20% !important;
    }
    .step-line.right.two-steps {
        right: 20% !important;
    }
    .step.two-steps {
        width: 180px !important;
    }

    /*    .agree-terms.small-margin-top {
        width: 50% !important;
    }*/
    //buraya gel

    .full-container-form {
        max-width: 98% !important;
        * {
            div:not(.MuiCircularProgress-indeterminate, .agree-terms.small-margin-top) {
                width: 100% !important;
            }
        }
    }
    /* .agree-terms.small-margin-top {
        display: none !important;
    }*/
    .agree-terms.small-margin-top .buttons-wrapper button {
        width: 100% !important;
        min-width: 100% !important;
    }
    .register-form-wrapper .agree-terms {
        width: 100vw !important;
        margin-left: -20px !important;
    }
    .form-100p.bottom-actions-new-registration.mobile-helper,
    .container-btn-mobile,
    .register-form-wrapper .agree-terms {
        z-index: 999 !important;
        position: sticky !important;
        bottom: 0 !important;
        background: white !important;
    }
}

.dots {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    span {
        font-size: 30px;
    }
    span:first-child {
        color: $secondary;
    }
    span:nth-child(2) {
        color: var(--primary-color);
    }
    span:last-child {
        color: $secondary;
    }
}
.login-form-fc.form-margin {
    margin-top: 72px;
}
.emailReceived {
    display: flex;
    flex-direction: column;
    margin: 72px auto 0;
    p {
        margin: 0 0 14px 0;
    }
}
.login-form-fc.mt-55 {
    margin-top: 55px;
}
h3.strong-password-title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 -16px 0;
    padding: 0;
    letter-spacing: -0.4px;
    color: $dark;
}
.validation-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    li {
        flex: 0 0 50%;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.4px;
        margin-bottom: 8px;
        color: $black-variant;
        display: flex;
        flex-direction: row;
        gap: 4px;
        padding-right: 5px;
    }
    li.password-validator.valid {
        color: var(--primary-color);
        circle {
            fill: var(--primary-color);
            stroke: var(--primary-color);
        }
    }
}
.agree-terms svg path {
    fill: var(--primary-color);
}
.form-50p button:active {
    background: var(--primary-color);
}
.area-protect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    flex-direction: row;
}
.send-form-fc.mt-70 {
    margin-top: 70px;
}
.form-100p {
    display: flex;

    gap: 17px !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .form-50p {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        border: 1px solid $light-grey;
        border-radius: 6px;
        height: 100%;
    }
}
.progress-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 12px auto 0 auto;

    position: relative;
    width: 389px;
}
.login-form-fc.gap-0 {
    margin-top: 32px;
    gap: 0 !important;
    max-width: 100%;
}
.step-line {
    width: 107px;
    height: 2px;
    background: black;
    position: absolute;
    z-index: 9;
}
.step-line.left {
    left: 74px;
    top: 14px;
}
.step-line.right {
    right: 74px;
    top: 14px;
}
.step-line.passive {
    background: $grey;
}
.step-line.active {
    background: var(--primary-color);
}
.step-line.completed {
    background: $dark;
}
.step-line.active.right {
    background: $dark;
}

.step {
    position: relative;
    z-index: 99;
    width: 122px;
    .step-ball {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: black;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.03em;
    }
}
.step.passive {
    .step-ball {
        background: $grey;
    }
    .step-text {
        color: $grey;
    }
}
.step.active {
    .step-ball {
        background: var(--primary-color);
    }
    .step-text {
        color: var(--primary-color);
    }
}
.step.completed {
    .step-ball {
        background: $dark;
    }
    .step-text {
        color: $dark;
    }
}
.step {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    .step-text {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.03em;
    }
}
.form-100p.bottom-actions-new-registration {
    .form-50p {
        border: 0;
    }
}
.agree-terms-message {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: $dark;
    a {
        color: var(--primary-color);
    }
}
.user-photo-container {
    display: flex;
    min-height: 116px;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    div.upload-photo-container {
        background-size: cover !important;
        cursor: pointer;
        background: #2b2b2b30;
        border-radius: 50%;
        width: 116px;
        height: 116px;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        padding: 0 10px;
        position: relative;
        .photo-field {
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
.upload-photo-container {
    .pulse-container {
        width: 16.57px;
        height: 16.97px;
        border-radius: 50%;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: $white;
    }
}
.login-form-fc input[type='file'] {
    width: 100%;
    padding: 0;
    height: 100%;
    margin: 0;
}
.keeper-container {
    display: none;
}
.photo-actions {
    width: 130px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -25px !important;
    svg {
        cursor: pointer;
    }
}
.login-form-fc div.error-text {
    margin-bottom: 0 !important;
    padding-bottom: 0;
}
.show-pass {
    .spinner {
        width: 20px !important;
        height: 20px !important;
        div {
            width: 20px !important;
            height: 20px !important;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}
.withEndAdornment .show-pass.loading-spinner {
    right: 30px;
    top: 14px;
}
.circle-svg {
    z-index: 1;
}
circle.count-down {
    fill: none;
    stroke: #d9d9d9;
    stroke-width: 2px;
}
.circle-main {
    position: relative;
    display: flex;
    margin: 0 auto;
    width: fit-content;
    .circle-countdown {
        position: absolute;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $grey-dark;
        margin: auto;
        padding: 0;
        top: 9px;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .circle-container {
        display: flex;
        flex-direction: column;
        transform: rotate(-90deg) scaleY(-1);
        position: relative;

        .circle-bg {
            width: 34px;
            height: 34px;
            border: 2px solid var(--primary-color);
            position: absolute;
            border-radius: 50%;
            left: 0;
            top: 0;
        }
    }
}
.select-role-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    align-items: center;
    color: $dark;
    width: inherit;
    margin-right: auto;
    margin-left: auto;
    display: block;
    max-width: 800px;
}
.roles-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    padding: 0;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    li {
        flex-direction: row;
        align-items: center;
        padding: 0 16px;
        gap: 0;
        width: 100%;
        height: 71px;
        justify-content: flex-start;
        background: $white;
        border: 1px solid #cacbd0;
        border-radius: 8px;
        display: inline-flex;
        cursor: pointer;
        .role-name-icon {
            margin-right: 30px;
            display: flex;
            align-items: center;
            gap: 11px;
            min-width: 180px;
            span {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.0015em;
                color: $dark;
            }
        }
        span.role-description {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: $dark;
        }
    }
    li:hover {
        border: 1px solid var(--primary-color);
    }
    li.selected {
        border: 1px solid var(--primary-color);
        background: #ecffeb;
    }
}
.event-information-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 16px 16px 60px;
    gap: 16px;
    width: 100%;
    max-width: 800px;
    background: $white-variant;
    border-radius: 6px;
    margin: 30px auto 0;
    position: relative;
    svg {
        position: absolute;
        left: 20px;
        top: 16px;
        path {
            fill: var(--primary-color);
        }
    }
    div {
        width: 100%;
        p,
        span {
            margin-top: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: $black;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }
}
.continue-button-register {
    width: 300px;
    margin: auto auto 0;
}
.extra-fields-and-invitation-code {
    margin-top: 40px;
}
.default-fields-and-invitation-code {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 40px 0;
    div {
        flex: 1 1 30%;
        max-width: 32.2%;
        .MuiFormControl-marginNormal {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
        div {
            flex: 1 1 100%;
            width: 100%;
            max-width: 100%;
        }
    }
}
.extra-padding {
    padding: 11px 0 !important;
}

.register-form-wrapper.version-2 {
    form {
        max-width: 100%;
        margin: 0;
        padding: 0 0 60px 0;
    }
}
.extra-fields-without-invitation-code {
    max-width: 530px;
    margin: 40px auto 0;
}
.break-word.registration-usage {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: $black-variant;
    padding: 16px 14px 22px;
}
.registration-radio-group.registration-usage.error-border {
    border: 1px solid $error !important;
}
.registration-radio-group.registration-usage {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2) !important;
    border-radius: 6px !important;
    border: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 16px !important;
}
.break-word.registration-usage.select-field-v2 {
    padding: 0 0 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0015em;
}
.register-form-wrapper .agree-terms span a {
    span {
        color: var(--primary-color);
    }
}
.selectFieldV2-container,
.text-field-width {
    width: 100%;
    display: flex;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2) !important;
    border-radius: 6px !important;
    border: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 16px !important;
    padding: 16px;
    div:first-child {
        width: 100%;
        margin: 0;
        div {
            width: 100%;
        }
        input,
        select {
            width: 100%;
        }
    }
}
.input-wrapper.text-field-width {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.access-packages-container {
    max-width: 530px;
    margin: 40px auto 0;
    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.0015em;
        color: $black-variant;
        padding: 16px 14px 22px;
        margin: 0;
    }
}
.login-registration-header.d-flex-column {
    position: sticky;
    top: 0;
    background: white;
    padding-top: 17px;
    left: 0;
    z-index: 999;
    padding-left: 40px;
    margin: -29px -40px 20px;
}
.scrolled-part {
    background: linear-gradient(137.73deg, $gradientMenu1 -15.66%, $gradientMenu2 43.77%, $gradientMenu3 100.28%);
    height: 100%;
    border-radius: 0 6px 6px 0;
}
.single-package-headline-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 18px;
    .package-name {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: $black-variant;
    }
    .package-price {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.0015em;
        color: $black-variant;
    }
}
.selected-package {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}
.choose-package .package .name-container {
    width: 100%;
}
.package-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 96px;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 0;
    background: $white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 16px;
    .package {
        width: 99%;
    }
}
.package-description {
    padding-left: 53px !important;
}
.choose-package .package {
    margin-bottom: 0 !important;
}
.name.ellipsis.single-package-headline-container.active-package-padding {
    gap: 8px;
}
.package-container.active-package {
    background: #ecffeb;
    box-shadow: none;
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    .package.selected {
        background-color: transparent !important;
    }
}
.choose-package .package-description {
    background-color: transparent !important;
}

.register-form-wrapper .agree-terms {
    display: flex;
    flex-direction: row;
    gap: 28px;
    background: white;
    z-index: 9;
    margin: 16px 0 24px;
    left: 0;
    justify-content: center;

    padding: 21px 0 30px;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: -25px;
    width: 80%;
}
.agree-terms.small-margin-top {
    label {
        width: 46%;
    }
    .buttons-wrapper {
        min-width: 32%;
        button {
            padding: 12px 0;
            gap: 8px;
            min-width: 360px;
            height: 48px;
            background: var(--primary-color);
            border-radius: 6px;
            &.disabled {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                align-items: center;
                color: $grey-variant;
                letter-spacing: 0.0125em;
                text-transform: uppercase;
                background: $white-variant;
                border-radius: 6px;
                box-shadow: none;
            }
        }
    }
}
.agree-terms.small-margin-top.lang-ar {
    right: 0;
    left: auto;
}
.agree-terms-message {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: $dark;
}
.MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled.Mui-focused {
    display: none;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-error.Mui-error.MuiFormLabel-filled.Mui-focused.Mui-focused {
    color: var(--primary-color);
}
.error-bar {
    background: $error;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 72px;
    flex-direction: row;
    padding: 0 32px;
    z-index: 9999;
    position: sticky;
    top: 0;
    margin: -27px -40px 20px;
    p {
        color: $white;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 95.9%;
        margin: 0;
        padding: 0;
    }
}

.stripe-dialog-wrapper .stripe-payment-dialog .title h2 {
    background: var(--primary-color) !important;
    border-radius: 6px 6px 0 0;
}
.stripe-dialog-wrapper .stripe-payment-dialog .content .stripe-form-container form .form-input-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.stripe-dialog-wrapper .stripe-payment-dialog .content .stripe-form-container .payment-actions-container button.submit {
    background: var(--primary-color) !important;
    border-radius: 6px;
}
.payment-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: auto !important;
    }
    button.submit {
        padding: 12px 0;
        gap: 8px;
        width: 270px !important;
        height: 48px;
        background: var(--primary-color);
        border-radius: 6px;
    }
    button.cancel {
        padding: 12px 0;
        gap: 8px;
        width: 106px !important;
        height: 48px;
        background: #f5f5f5;
        border-radius: 6px;
        color: #959595 !important;
    }
}
.stripe-dialog-wrapper .stripe-payment-dialog .content .stripe-form-container form .form-input-container .error {
    position: initial;
}
.login-registration-action.d-flex-column {
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: var(--primary-color) !important;
    }
    .Mui-focused.MuiFormLabel-root {
        color: var(--primary-color) !important;
    }
}
@media screen and (min-width: 1179px) {
    .agree-terms.small-margin-top.bottom-push {
        bottom: -23px !important;
    }
    .default-fields-and-invitation-code {
        width: 800px;
    }
}

.hide {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.show {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}
.d-block {
    display: block;
}
#menu-country {
    .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        min-width: auto !important;
        max-width: 256px !important;
    }
}
.disabled {
    cursor: default !important;
}
/*arabic lang*/
.login-registration-death-space.lang-ar {
    left: 0;
    right: auto;
}
.login-registration-header.d-flex-column.lang-ar {
    padding-left: 0;
    padding-right: 40px;
}
.login-form-fc.lang-ar {
    input {
        text-align: right;
        direction: rtl;
        padding: 0 16px 0 45px;
    }
    .withEndAdornment .show-pass {
        left: 12px;
        right: auto;
    }
    div label {
        text-align: right;
        direction: rtl;
        right: 7px;
        left: auto;
    }
}
.login-registration-action.d-flex-column.lang-ar {
    margin-left: auto;
}
.registration-progress-bar.lang-ar {
    margin-left: 0;
    margin-right: -40px;
    .scrolled-part {
        border-radius: 6px 0 0 6px;
    }
}
.w-100.d-flex.justify-content-center.align-items-center.lang-ar {
    .MuiFormControl-root.MuiTextField-root.email-text-field.field-container.withEndAdornment.MuiFormControl-marginNormal.MuiFormControl-fullWidth {
        direction: rtl;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    input {
        text-align: right;
        direction: rtl;
    }
    label {
        right: 30px;
        left: auto;
    }
    label.MuiFormLabel-filled {
        display: none !important;
    }
    label.Mui-focused {
        max-width: none !important;
        width: 100% !important;
        right: 0 !important;
        left: auto !important;
        display: none !important;
    }
    .MuiInputBase-root.Mui-focused.MuiInputBase-formControl {
        fieldset {
            legend {
                span {
                    visibility: visible !important;
                    color: var(--primary-color);
                    margin-top: -3.5px;
                    font-weight: 500;
                    line-height: 20px;
                }
            }
        }
    }
    .MuiInputBase-root.MuiInputBase-formControl {
        fieldset {
            legend {
                visibility: hidden !important;
                span {
                    right: 9px;
                    position: absolute;
                    background: white;
                    visibility: hidden !important;
                }
            }
        }
    }
    .input-wrapper {
        position: relative;
    }
    .ar-label {
        margin: 0;
        padding: 0 5px;
        position: absolute;
        background: white;
        z-index: 9;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        right: 10px;
        top: -7px;
    }
    /*end*/
}
.error {
    color: $error !important;
}
.marginBottomDesktop {
    margin-bottom: 20px !important;
}
.remove-error {
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-error.Mui-error.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd:not(.Mui-focused)
        input[type='password'],
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-error.Mui-error.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd:not(.Mui-focused)
        input[type='text'] {
        border: 1px solid #cacbd0 !important;
    }

    .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled {
        display: none !important;
    }
    .MuiFormLabel-root.Mui-error {
        color: $grey-dark !important;
    }
    .Mui-error input[type='password'],
    .Mui-error input[type='text'] {
        color: $grey-dark !important;
        border: 2px solid $grey-dark !important;
    }

    .Mui-focused.Mui-error {
        input[type='password'] {
            color: var(--primary-color) !important;
        }
    }
}
.withEndAdornment.error-helper {
    label:not(.Mui-focused) {
        color: $error !important;
    }
    div:not(.Mui-focused) {
        input {
            border: 2px solid $error !important;
            color: $error !important;
        }
    }
}
.there-error {
    .private-error {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: $error !important;
        margin-top: 5px;
        margin-left: 16px;
        display: block;
    }
}
.there-error {
    :focus .private-error {
        display: none;
    }
}
.full-container-form {
    margin: 0 auto;
    max-width: 800px;
}
.red-border:not(.Mui-focused) {
    input {
        border: 2px solid $error !important;
    }
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-error.Mui-error.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd:not(.Mui-focused) {
    input[type='password'],
    input[type='text'] {
        border: 2px solid $error !important;
    }
}
.event-information-container.lang-ar {
    svg {
        right: 20px;
        left: auto;
    }
    div {
        margin-right: 35px;
    }
}
.error-message.forgot-pass {
    color: $error !important;
    font-weight: 500 !important;
    a {
        font-weight: bolder;
        color: $error !important;
    }
}
.only-one-role-width {
    width: 121px;
}
.login-registration-container {
    .MuiInputLabel-outlined.Mui-focused {
        max-width: 100% !important;
        width: 100% !important;
    }
}

.user-edit.scroll-left-container {
    .MuiInputLabel-outlined.Mui-focused {
        max-width: calc(100% - 30px) !important;
        width: auto !important;
    }
}
.children-container {
    input[type='password'] {
        font-size: 16px !important;
    }
}

.form-100p.bottom-actions-new-registration.mobile-helper {
    gap: 10px !important;
    padding: 12px 12px 16px 12px !important;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
    margin-top: 25px;
    width: 100vw;
    margin-left: -12px;
    .form-50p {
        gap: 0;
        padding: 0 !important;
    }
}
.area-protect {
    svg {
        path {
            fill: var(--primary-color);
        }
    }
}
.social-media-connections,
.submit-and-secondary-actions {
    button:active {
        background: var(--primary-color) !important;
    }
}
.one-step-back-btn-container button:active {
    background: var(--primary-color) !important;
}
.input-wrapper.extra-padding {
    .MuiIconButton-label {
        svg {
            path {
                fill: var(--primary-color) !important;
            }
        }
    }
}

.MuiPaper-root.MuiMenu-paper .MuiMenu-list .MuiListItem-button:hover,
.MuiPaper-root.MuiMenu-paper .MuiMenu-list .MuiMenuItem-root.Mui-selected {
    background-color: var(--secondary-color) !important;
}

.MuiPaper-root.MuiMenu-paper .MuiMenu-list .MuiMenuItem-root.Mui-selected {
    color: $white !important;
}
.email-text-field.error {
    input {
        border: 2px solid $error !important;
    }
    label {
        color: $error !important;
    }
}
.single-name-field {
    .error-text {
        margin-left: 12px !important;
        color: #d82a2a !important;
    }
}
