.VideoContainer {
}

.VideoContainer__video {
    &::-webkit-media-text-track-display {
        padding: 0 16px !important;
    }
}
@media only screen and (max-width: 1080px) {
    .isNotDesktop {
        display: none !important;
    }
}
