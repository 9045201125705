@import '../../CSS/variables.module';

.stripe-dialog-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
    .stripe-payment-dialog {
        margin: 16px;
        .title {
            h2 {
                display: flex;
                background-color: #32364e;
                padding: 20px 30px;
                font-size: 0;
                justify-content: space-between;
                align-items: center;
                svg {
                    height: 13px;
                    width: auto;
                }
                .secure-locket {
                    padding-inline-end: 12px;
                    svg {
                        height: 20px;
                    }
                }
                a {
                    svg {
                        height: 26px;
                        width: auto;
                    }
                }
            }
        }
        .content {
            background-color: $white;
            .stripe-form-container {
                position: relative;
                .spinner {
                    display: flex;
                    flex-flow: column;
                    text-align: center;
                }
                form {
                    padding: 30px 0 0;
                    max-width: 450px;
                    .form-input-container {
                        position: relative;
                        padding: 0 30px 30px;
                        &:last-child {
                            padding-bottom: 0;
                        }
                        .StripeElement {
                            display: block;
                            padding: 12px 14px;
                            font-size: 1em;
                            font-family: $fontFamily;
                            border-radius: 8px;
                            border: 0.5px solid $text-grey;
                            outline: 0;
                            background: white;
                            &:hover {
                                border-color: rgba(0, 0, 0, 0.87);
                            }
                            &--invalid {
                                border: 1px solid #eb1c26;
                            }
                        }
                        .error {
                            font-size: 13px;
                            color: #eb1c26;
                            font-weight: 200;
                            position: absolute;
                            bottom: 10px;
                            margin: 0;
                        }
                    }
                    label {
                        display: block;
                        color: #6b7c93;
                        font-weight: 300;
                        letter-spacing: 0.025em;
                    }
                }
                .form-error-message-container {
                    font-size: 13px;
                    color: #f50057;
                    font-weight: 200;
                    max-width: 100%;
                    padding: 0 30px;
                    margin-bottom: 30px;
                }
                .stripe-info {
                    padding: 0 30px 30px 0;
                    font-size: 12px;
                    line-height: 18px;
                    margin: 0;
                    font-weight: 300;
                    color: $text-grey;
                    max-width: 450px;
                    a {
                        text-decoration: underline;
                        color: $text-grey;
                    }
                }
                .payment-actions-container {
                    display: flex;
                    justify-content: space-between;
                    button {
                        flex: 0 0 50%;
                        border: none;
                        padding: 0;
                        margin: 0;
                        font-size: 18px;
                        line-height: 60px;
                        color: #32364e;
                        background-color: #e5e5e5;
                        cursor: pointer;
                        font-family: $fontFamily, sans-serif;
                        font-weight: 600;
                        &.submit {
                            color: #fff;
                            background-color: #32364e;
                        }
                    }
                }
            }
        }
    }
}
h3.package-name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #2b2b2b;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 0;
    padding-top: 0;
    text-align: center;
}
.expiry-and-cvc {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    margin: auto;
    padding: 0 0 30px;
    .form-input-container {
        flex: 1 50%;
        padding: 0 !important;
    }
}
.stripe-dialog-wrapper .stripe-payment-dialog .content {
    border-radius: 0 0 6px 6px;
}
.stripe-dialog-wrapper .stripe-payment-dialog .content .stripe-form-container form {
    padding: 30px;
}
.card-styles {
    margin-left: auto;
    display: block;
    margin-top: -35px;
    margin-right: 11px;
}
@media screen and (min-width: 1180px) {
    .stripe-dialog-wrapper {
        width: 80%;
        z-index: 999;
    }
}
