@import '../../../CSS/variables.module';

.exhibitors-slider {
    height: 200px;
    position: relative;
    margin: auto;
    max-width: 75%;
    padding: 0 60px;
    &.no-exhibitors {
        height: 20%;
        .title-container {
            .subtitle {
                display: none;
            }
        }
    }
    .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 0;

        & > div {
            &:first-of-type {
                flex: 1;
                margin-inline-end: 15px;
            }
        }

        .title {
            margin: 0;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: $dark;
            padding-bottom: 4px;
        }
        .subtitle {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: $primary;
        }
        .showfloor-menu-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            border: 1px solid $secondary;
            border-radius: 6px;
            font-weight: 600;
            font-size: 14px;
            line-height: 120%;
            color: $secondary;
            cursor: pointer;

            svg {
                path {
                    fill: $secondary;
                }
            }

            &:hover {
                background: linear-gradient(156.39deg, $secondary 48.1%, $secondaryDarken 105%);
                color: #ffffff;

                svg {
                    path {
                        fill: #ffffff;
                    }
                }
            }

            p {
                margin: 0;
                padding-inline-start: 10px;
            }
        }
    }

    .slider-container {
        .slick-arrow {
            height: 41px;
            width: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:before {
                display: none;
            }
            img {
                display: block;
            }
            &:hover svg path {
                stroke: $primary;
            }
            &.slick-prev {
                left: -55px;
                right: initial;
            }
            &.slick-next {
                right: -55px;
                left: initial;
                svg {
                    margin-right: 0;
                    margin-left: auto;
                    display: block;
                    transform: rotate(180deg);
                }
            }
        }

        .slick-dots {
            bottom: -40px;
            li {
                button:before {
                    color: #c0c0c0;
                    opacity: 1;
                    font-size: 8px;
                }
                &.slick-active button:before {
                    color: $secondary;
                }
            }
        }

        .featured-exhibitors-container {
            padding: 8px;
            .exhibitor-container {
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                padding: 16px;
                .logo-container {
                    height: 70px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                p {
                    margin: 0;
                    padding-top: 22px;
                    font-size: 16px;
                    line-height: 20px;
                    color: $dark;
                    text-align: center;
                    height: 62px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    word-break: break-word;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
            &.active {
                .exhibitor-container {
                    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.2);
                    border-radius: 8px;
                    border: 1px solid $secondary;
                }
            }
        }
    }
}

#exhibitor-booth-name-tooltip {
    .MuiTooltip-tooltip {
        word-break: normal;
    }
}
