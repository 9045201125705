@import '../../../../CSS/variables.module';


.welcome-screen-dashboard{
    .device-title{
        color: $grey
    }
    .types-list{
        flex-flow: row;
    }
    .options-container.desktop .cover-img-container{
        height: 280px;
    }
    .options-container.mobile .cover-img-container{
        width: 186px;
        height: 284px !important;
    }
    .end-adornment{
        font-size: 12px;
    }
    .input-description{
        line-height: 24px !important;
    }
    .video-url-input-container{
        svg{
            fill: $grey
        }
    }
    .branding-background-image-container .input-description p{
        margin-bottom: 24px !important;
    }
}