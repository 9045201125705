.scroll-left-container {
    input,
    fieldset,
    div.MuiInputBase-root,
    legend {
        direction: ltr !important;
    }
}
.justify-flex-start {
    justify-content: flex-start !important;
}
.cancel-button.edit-participant {
    font-size: 16px !important;
    margin-right: 0 !important;
}
.custom-dropdown-label {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    margin: 0 0 -8px 0;
    font-size: 1rem;
    direction: ltr !important;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}
.align-helper {
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
        direction: ltr !important;
        background-color: white;
        &[data-shrink="true"] {
            padding: 0 5px;
        }
    }
    .select-validator {
        .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
            max-width: 95%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            &[data-shrink="true"] {
                max-width: initial;
            }
        }
        legend {
            max-width: 0;
        }
    }
}
