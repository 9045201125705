@import "../../DesignSystemComponents/switch";
@import "../../DesignSystemComponents/radio";
@import "../../DesignSystemComponents/checkbox";
@import "../../DesignSystemComponents/button";
@import "../../DesignSystemComponents/dateTimeInput";
@import "../../DesignSystemComponents/textfield";
@import "../../DesignSystemComponents/select";
@import "../../DesignSystemComponents/chips";
@import "../../DesignSystemComponents/tooltip";


// for radios and checkboxes with labels
.MuiFormControlLabel-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #2B2B2B;
    &.Mui-disabled {
        color: #777081;
    }
}

// need to add this class to switch labels
.switch-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 19.6px;
    text-transform: uppercase;
    &.Mui-disabled {
        color: #959595;
    }
}

.white-variant .MuiFormControlLabel-label {
    color: #FFFFFF;
}

html,
.MuiInputBase-root,
.MuiInputLabel-root{
    font-family: "Montserrat", sans-serif;
}

.Mui-error {
    font-family: "Montserrat", sans-serif;
}