#form-dialog-title h2.MuiTypography-root {
    margin: auto;
    padding: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 30px !important;
    /*
    word-break: break-word;
    */
}
.break-all {
    word-break: break-word;
}
