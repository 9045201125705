@import 'variables.module';
@import './hpColors';

.header-spacing-container {
    padding-top: 70px;
    flex: 1;
}
.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}
.d-flex {
    display: flex;
}
.justify-content-center {
    justify-content: center;
}
.align-items-center {
    align-items: center !important;
}
.p-relative {
    position: relative;
}
.p-absolute {
    position: absolute;
}
.p-fixed {
    position: fixed;
}
.full-background-container {
    min-height: 100vh;
}
.img-cover {
    object-fit: cover;
}
.full-background-overlay {
    background-color: $backGroundOverlay;
}
.full-background-img {
    z-index: 0;
}
.full-background-overlay {
    z-index: 1;
}
.header-spacing-container {
    z-index: 1;
}
.form-container {
    width: 100%;
    max-width: 720px;
    margin: 30px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    h1 {
        font-size: 40px;
        color: $secondary;
        font-weight: 800;
        line-height: 1;
        margin-bottom: 50px;
        margin-top: 0;
    }
    input {
        font-weight: 500;
        font-size: 16px;
        font-family: $fontFamily, sans-serif;
        color: #2b2b2b;
        overflow: hidden;
        white-space: nowrap;
        &[type='password'] {
            padding-inline-end: 50px;
        }
        &[name='password'] {
            padding-inline-end: 50px;
        }
        &[name='passwordConfirmation'] {
            padding-inline-end: 50px;
        }
        &::placeholder {
            font-weight: 500;
            font-size: 16px;
            color: $text-grey;
            font-family: $fontFamily, sans-serif;
            opacity: 1;
        }
    }
    .input-wrapper {
        max-width: 330px;
        position: relative;
        .tooltip {
            z-index: 999;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s ease-in-out;
            position: absolute;
            width: 100%;
            background-color: #555;
            bottom: calc(100% + 10px);
            border-radius: 6px;
            color: $white;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

            div {
                padding: 10px;
                position: relative;
                &::after {
                    content: '';
                    width: 0;
                    height: 0;
                    border: 0 solid transparent;
                    border-left-width: 10px;
                    border-right-width: 10px;
                    border-top: 10px solid #555;
                    position: absolute;
                    left: 10px;
                    bottom: -10px;
                }
            }

            p {
                margin: 0;
                font-size: 14px;
            }
        }
        &.show-tooltip {
            &:hover {
                @media screen and (min-width: 1025px) {
                    .tooltip {
                        opacity: 1;
                        visibility: visible;
                        z-index: 1;
                    }
                }
            }
        }
        &.show-tooltip-mobile {
            .tooltip {
                opacity: 1;
                visibility: visible;
                z-index: 1;
            }
        }
    }

    .select-validator {
        label {
            font-weight: 500;
            font-size: 16px;
            color: $text-grey;
            font-family: $fontFamily, sans-serif;
            opacity: 1;
            padding-inline-end: 40px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 95%;
        }
        .MuiSelect-outlined {
            font-weight: 500;
            font-size: 16px;
            font-family: $fontFamily, sans-serif;
            color: #2b2b2b;
        }
    }
    .form-header {
        border-top-right-radius: 6px;
        background-color: #2b2b2b;
        .form-title {
            font-weight: 600;
            font-size: 24px;
            text-transform: none;
            color: #2b2b2b;
            margin: 0;
            padding: 24px;
            &.banner-text {
                div {
                    position: relative;
                    display: inline-block;
                    padding: 5px;
                    svg {
                        position: absolute;
                        z-index: 1;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                    }
                    span {
                        position: relative;
                        z-index: 2;
                        color: #ffffff;
                    }
                }
            }
        }
        &.sign-up {
            background-color: #ffffff;
            svg {
                path {
                    fill: var(--secondary-color);
                }
            }
            .tab {
                h3 {
                    color: #2b2b2b;
                }
            }
        }
        &.log-in {
            .form-title {
                font-size: 34px;
                font-weight: bold;
                text-transform: uppercase;
                color: #ffffff;
                text-align: center;
            }
            span {
                color: var(--primary-color);
            }
        }
        &.left-log-in-description {
            background-color: #ffffff;
            border-bottom: 1px solid $disabled-text;
            border-top-left-radius: 6px;
            &.is-sign-up {
                .form-title {
                    color: var(--primary-color);
                    &.banner-text {
                        svg {
                            path {
                                fill: var(--primary-color);
                            }
                        }
                    }
                }
            }
            .form-title {
                font-weight: 600;
                font-size: 24px;
                color: var(--primary-color);
                padding-left: 50px;
                padding-right: 50px;
                text-align: center;
                text-transform: uppercase;
                &.banner-text {
                    & > span {
                        display: block;
                        padding-top: 16px;
                    }
                    svg {
                        path {
                            fill: var(--primary-color);
                        }
                    }
                }
            }
        }
    }
    .left-form {
        width: 360px;
        background-color: rgba(256, 256, 256, 0.9);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        &.sign-up {
            & > div {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                p {
                    flex: 1;
                }
                .steps-text {
                    font-weight: 600;
                    font-size: 16px;
                    padding-inline-start: 32px;
                    padding-bottom: 32px;
                }
            }
        }
        p {
            line-height: 20px;
            font-size: 18px;
            padding: 32px 16px;
            color: #2b2b2b;
            margin: 0;
        }
        .social-btn {
            p {
                font-size: 16px;
                padding-left: 30px;
                margin: 0;
                font-weight: 500;
            }
        }
    }
    .right-form {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        flex: 1 1;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        position: relative;
        &.is-sign-up {
            background-color: #2b2b2b;
            .register-form-wrapper {
                background: linear-gradient(
                        179.92deg,
                        rgba(17, 8, 31, 0.595) 20.1%,
                        rgba(27, 14, 48, 0.581) 31.41%,
                        rgba(36, 28, 48, 0.546) 53.72%,
                        rgba(31, 29, 34, 0.595) 69.25%,
                        rgba(24, 20, 30, 0.399) 99.93%
                    ),
                    linear-gradient(179.55deg, rgba(35, 5, 85, 0.576) 50.87%, rgba(23, 21, 26, 0.648) 105.07%);
                padding: 0 15px;
                border-bottom-right-radius: 6px;
                .field-wrapper {
                    position: relative;
                    & > div {
                        &:last-of-type {
                            svg {
                                position: absolute;
                                right: 16px;
                                top: 17px;
                                path {
                                    fill: $grey;
                                }
                            }
                        }
                    }
                }
                .social-buttons-container {
                    .social-title {
                        padding-bottom: 24px;
                    }
                    & > div {
                        display: flex;
                        justify-content: space-around;
                    }
                    button {
                        width: 54px;
                        height: 54px;
                        padding: 0;
                        margin-bottom: 0;
                        border-radius: 8px;
                        img {
                            position: initial;
                        }
                        p {
                            display: none;
                        }
                    }
                    .or-text {
                        margin-top: 16px;
                        margin-bottom: 24px;
                    }
                }
                label {
                    color: #ffffff;
                    margin: 0;
                    &.file {
                        border: 1px solid #ffffff;
                        border-radius: 6px;
                        padding: 12px 10px;
                        justify-content: center;
                        &:hover {
                            background-color: var(--secondary-color);
                        }
                    }
                    .upload-image {
                        display: flex;
                        align-items: center;
                        svg {
                            margin-inline-end: 12px;
                            path {
                                fill: #ffffff;
                            }
                        }
                        p {
                            margin: 0;
                            text-transform: uppercase;
                            font-weight: 600;
                            font-size: 14px;
                            flex: 1;
                            color: $white;
                        }
                    }
                }
                .register-button {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 8px;
                    background-color: var(--secondary-color);
                    &:disabled {
                        background-color: rgba(43, 43, 43, 0.5);
                        cursor: default;
                        color: $error;
                        border: 2px solid $error;
                    }
                }
            }
        }
        .tabs-container {
            h3 {
                margin: 0;
                font-weight: 600;
                font-size: 14px;
                color: #ffffff;
                text-transform: uppercase;
                padding-bottom: 15px;
            }
            & > div {
                display: flex;
                .tab {
                    width: 50%;
                    text-align: center;
                    cursor: pointer;
                    &:first-of-type {
                        &.active {
                            border-bottom: 2px solid var(--secondary-color);
                            h3 {
                                color: var(--secondary-color);
                            }
                        }
                    }
                    &:last-of-type {
                        &.active {
                            border-bottom: 2px solid var(--primary-color);
                            h3 {
                                color: var(--primary-color);
                            }
                        }
                    }
                    &.disabled {
                        cursor: initial;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        h3 {
                            color: $black-variant;
                            opacity: 0.5;
                        }
                        .info-tooltip {
                            display: none;
                            position: absolute;
                            top: 100%;
                            z-index: 4;
                        }
                        &:hover {
                            .info-tooltip {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        .login-tab {
            background-color: #2b2b2b;
            border-bottom-right-radius: 6px;
            .login-page {
                background: linear-gradient(
                        179.92deg,
                        rgba(17, 8, 31, 0.595) 20.1%,
                        rgba(27, 14, 48, 0.581) 31.41%,
                        rgba(36, 28, 48, 0.546) 53.72%,
                        rgba(31, 29, 34, 0.595) 69.25%,
                        rgba(24, 20, 30, 0.399) 99.93%
                    ),
                    linear-gradient(179.55deg, rgba(35, 5, 85, 0.576) 50.87%, rgba(23, 21, 26, 0.648) 105.07%);
                height: auto;
                border-bottom-right-radius: 6px;
                .login-container {
                    padding: 0 15px;
                    .login-submit-button {
                        margin-top: 40px;
                        background-color: var(--primary-color);
                        &.has-error {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .social-buttons-container {
            width: 100%;
            .social-title {
                font-weight: 600;
                font-size: 16px;
                color: #ffffff;
                text-align: center;
                width: 100%;
                margin: 0;
                padding: 24px 0 32px;
            }
            .or-text {
                text-align: center;
                position: relative;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                margin-top: 32px;
                margin-bottom: 32px;
                color: #ffffff;
                width: auto;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    height: 1.9px;
                    background-color: #ffffff;
                    width: 13%;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:after {
                    right: 10px;
                    left: initial;
                }
            }
            .or-text-middle {
                display: block;
                max-width: calc(100% - 26% - 40px);
                margin: auto;
            }
        }
    }
    form {
        .show-pass {
            cursor: pointer;
        }
    }
}
.register-participant-step-1 .form-container .left-form .social-btn.facebook-button p,
.register-participant-step-1 .form-container .left-form .social-btn.twitter-button p {
    color: #fff;
}
.register-participant-step-1 .form-container .right-form form,
.register-participant-step-2 .form-container .right-form form,
.login-page.platform-login .form-container .right-form form {
    padding: 0;
    max-width: 330px;
    .MuiInputBase-root {
        background-color: #ffffff;
        margin-bottom: 18px;
        .MuiInputAdornment-positionEnd {
            margin-right: -10px;
        }
        input:disabled {
            cursor: not-allowed;
        }
    }
    .input-wrapper {
        position: relative;
        .error-text,
        .MuiFormHelperText-root {
            position: absolute;
            bottom: -3px;
        }
    }
}
.register-participant-step-1 .form-container .right-form form .field-container,
.register-participant-step-2 .form-container .right-form form .field-container,
.login-page.platform-login .form-container .right-form form .field-container {
    margin-bottom: 0;
    margin-top: 0;
    border-radius: 3px;
    & > div {
        background-color: #ffffff;
        margin-bottom: 18px;
        &.Mui-error {
            margin-bottom: 0;
        }
    }
}
.field-container.hidden label,
.field-container.hidden > div {
    display: none !important;
}
.registration-radio-group {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 18px;
    border: 0.5px solid $grey;
    .input-wrapper {
        margin-inline-start: 15px;
    }
    .MuiTypography-body1 {
        max-width: 461px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .input-wrapper.extra-padding .MuiTypography-body1 {
        white-space: normal !important;
        overflow: visible !important;
        text-overflow: clip !important;
    }
}
.register-participant-step-1 .form-container .right-form .allready-have-account {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
}
.register-participant-step-1 .form-container .right-form .allready-have-account a {
    color: $primary;
    margin-inline-start: 8px;
    font-weight: 600;
}
.register-participant-step-1 .error-text {
    text-align: left;
}
.login-container {
    width: 100%;
    max-width: 1224px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    & > div {
        width: 100%;
    }
}
.register-participant-step-2,
.login-page.platform-login {
    .form-header {
        &.left-log-in-description {
            border-top-right-radius: 6px;
        }
    }
    .form-container {
        flex-direction: column;
        & > div:not(.form-header) {
            display: flex;
            flex-direction: row;
        }
    }
    .left-form {
        border-top-left-radius: 0;
        display: flex;
        background-color: rgba(255, 255, 255, 0.8);
        & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            p {
                flex: 1;
            }
        }
        .steps-text {
            font-weight: 600;
            font-size: 16px;
            padding-inline-start: 32px;
            padding-bottom: 32px;
        }
    }
    .right-form {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: transparent;
        .register-form-wrapper {
            padding: 40px 15px 0;
            background: linear-gradient(
                176.96deg,
                rgba(91, 85, 105, 0.455) 5.76%,
                rgba(30, 21, 53, 0.7) 26.92%,
                rgba(22, 6, 34, 0.7) 61.8%,
                rgba(34, 31, 37, 0.7) 98.45%
            );
            border-bottom-right-radius: 6px;
            form {
                label {
                    margin: 0;
                    &.checkbox-wrapper {
                        color: #ffffff;
                        margin-bottom: 16px;
                        span {
                            font-family: $fontFamily, sans-serif;
                        }
                    }
                }
                fieldset {
                    legend {
                        font-weight: 600;
                        font-size: 16px;
                        color: #ffffff;
                        font-family: $fontFamily, sans-serif;
                    }
                    label {
                        display: flex;
                        span {
                            &:first-of-type {
                                &.Mui-checked {
                                    color: var(--primary-color);
                                    svg {
                                        fill: var(--primary-color);
                                    }
                                }
                            }
                            &.MuiFormControlLabel-label {
                                font-weight: 500;
                                font-size: 16px;
                                font-family: $fontFamily, sans-serif;
                                padding-inline-end: 16px;
                            }
                        }
                        svg {
                            fill: #2b2b2b;
                        }
                    }
                }
            }
            .agree-terms {
                margin-top: 90px;
                &.small-margin-top {
                    margin-top: 16px;
                }
            }
            .buttons-wrapper {
                button {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 8px;
                    background-color: var(--secondary-color);
                }
            }
        }
    }
}
.login-page {
    &.platform-login {
        .form-header {
            &.left-log-in-description {
                &.is-sign-up {
                    .form-title {
                        svg {
                            path {
                                fill: var(--primary-color);
                            }
                        }
                    }
                }
            }
        }
        &.is-tab {
            height: auto;
        }
        .right-form {
            background: linear-gradient(
                    179.92deg,
                    rgba(17, 8, 31, 0.595) 20.1%,
                    rgba(27, 14, 48, 0.581) 31.41%,
                    rgba(36, 28, 48, 0.546) 53.72%,
                    rgba(31, 29, 34, 0.595) 69.25%,
                    rgba(24, 20, 30, 0.399) 99.93%
                ),
                linear-gradient(179.55deg, rgba(35, 5, 85, 0.576) 50.87%, rgba(23, 21, 26, 0.648) 105.07%);
            .register-form-wrapper {
                padding-top: 0;
                .login-submit-button {
                    background-color: var(--primary-color);
                    margin-top: 40px;
                    &.has-error {
                        margin-top: 0;
                    }
                    &.disabled {
                        cursor: initial;
                        background-color: $grey;
                    }
                }
            }
        }
    }
}
.register-participant-step-2 {
    .form-container {
        .right-form {
            .invitation-code-information {
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                color: $text-grey !important;
                margin-top: 10px;
                margin-bottom: 16px;
                min-height: 40px;
            }
            .invitation-code-wrapper {
                position: relative;
                .error-message {
                    position: absolute;
                    bottom: -15px;
                }
            }
            .buttons-wrapper {
                padding-bottom: 0;
                margin-bottom: 0;
                button {
                    width: 100%;
                    font-size: 16px;

                    &:disabled {
                        background-color: rgba(43, 43, 43, 0.5);
                        cursor: default;
                        color: $error;
                        border: 2px solid $error;
                    }
                }
            }
        }
    }
}

.gdpr-container {
    max-width: 1224px;
    margin: 0 auto;
    padding: 60px 30px;
    color: $dark;

    .translated-ai-info {
        display: flex;
        align-items: center;
        padding-bottom: 40px;

        svg {
            margin-inline-end: 16px;
        }

        span {
            font-size: 16px;
            line-height: 24px;
            flex: 1;
        }

        a {
            font-weight: bold;
        }
    }
}
.gdpr-page h1 {
    margin-top: 0;
}
.gdpr-page h2 {
    margin-top: 40px;
}
.gdpr-page p {
    margin-top: 10px;
    margin-bottom: 0;
}
.gdpr-page a {
    color: var(--secondary-color);
    word-break: break-word;
}

.not-found-page-info-screen {
    width: 100%;
    padding: 0 50px;
}
.not-found-page-info-screen p {
    font-size: 24px;
    line-height: 30px;
    margin: 30px 0;
}
.not-found-page-info-screen a {
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}
.not-found-page-info-screen a:hover {
    text-decoration: underline;
}
.choose-package {
    display: flex;
    flex-flow: column;
}
.choose-package .package-description {
    margin: 0 0 8px 0;
    background-color: #fff;
    padding: 16px 16px 0;
    /*    border: 1px solid $disabled-text;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;*/
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    color: $dark;
    white-space: pre-line;
}
.choose-package .title {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 14px;
    margin-bottom: 16px;
}
.choose-package .package {
    border-radius: 4px;
    margin-bottom: 8px;
    /*
    border: 1px solid $disabled-text;
    */
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    color: $dark;
    background-color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}
.choose-package .package .name-container {
    display: flex;
    align-items: center;
    padding: 5px 7px;
    overflow: hidden;
}
.choose-package .package .name-container .name {
    text-transform: uppercase;
    padding-inline-start: 7px;
}
.choose-package .package .price {
    padding: 7px;
    background: #f5f5f5;
    border-radius: 0 4px 4px 0;
    flex: 0 0 126px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid $disabled-text;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
}
.choose-package .package.selected {
    /* color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));*/
    margin-bottom: 0;
}
.choose-package .package.selected svg {
    color: var(--primary-color);
}
.choose-package .package .description {
    display: block;
}
.choose-package .package.selected .price {
    background-color: var(--secondary-color);
    color: white;
    border-left: 1px solid var(--secondary-color);
}
.confirm-event {
    p {
        margin-top: 0;
        color: $text-grey;
    }
}
.cookie-links {
    padding-top: 50px;
    h3 {
        text-transform: capitalize;
    }
    div {
        display: inline-flex;
        flex-direction: column;
    }
    a {
        margin-bottom: 10px;
    }
}
/* Cookie bot - start */
#CybotCookiebotDialog {
    font-family: $fontFamily, sans-serif !important;
}
#CybotCookiebotDialogHeader > a {
    display: none;
}
#CybotCookiebotDialogPoweredbyLink {
    background-image: url('../Images/svg/logo-cookie.svg') !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: center !important;
}
#CybotCookiebotDialog[data-template='popup'] #CybotCookiebotDialogPoweredbyLink {
    min-height: 50px;
    background-position: left !important;
}
#CybotCookiebotDialogPoweredbyLink img {
    display: none;
}
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper button {
    border-radius: 6px;
}
@media screen and (min-width: 1280px) {
    #CybotCookiebotDialog[data-template='slideup'] {
        border-radius: 0;
    }
}
@media screen and (max-width: 1279px) {
    #CybotCookiebotDialog[data-template='slideup'] #CybotCookiebotDialogPoweredbyLink {
        min-height: 50px;
        background-position: left !important;
    }
}
@media screen and (max-width: 479px) {
    .CybotCookiebotScrollContainer:not(.hide) {
        max-height: 10em !important;
        overflow-y: auto;
    }
    #CybotCookiebotDialogBodyLevelWrapper {
        max-height: 12em;
        overflow-y: auto;
    }
    .CybotCookiebotScrollContainer.active {
        max-height: initial !important;
    }
    #CybotCookiebotDialogDetailBody {
        max-height: calc(100vh - 400px);
        overflow-y: auto;
    }
}
/* Cookie bot - end */
@media all and (max-width: 767.9px) {
    .header-spacing-container {
        padding-top: 0;
        padding-bottom: 0;
    }
    .gdpr-page {
        .header-spacing-container {
            padding-top: 70px;
        }
    }
    .form-container {
        max-width: initial;
        margin: 0;
        min-height: 100vh;
        background-color: rgba(48, 48, 48, 0.5);
        .left-form {
            display: none;
        }
        .form-header {
            padding-top: 70px;
            border-radius: 0;
            &.left-log-in-description {
                padding: 0;
                background-color: transparent;
                border: 0;
                svg {
                    &.log-in {
                        path {
                            fill: var(--primary-color);
                        }
                    }
                }
                &.is-sign-up {
                    .form-title {
                        color: $white;
                        &.banner-text {
                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                        div {
                            span {
                                color: var(--secondary-color);
                            }
                        }
                    }
                }
                .form-title {
                    color: $white;
                    &.banner-text {
                        & > span {
                            padding-top: 0;
                            padding-bottom: 16px;
                        }
                    }
                }
                p {
                    padding: 0 15px;
                    font-size: 18px;
                    color: $white;
                    text-align: center;
                }
            }
            .form-title {
                text-align: center;
            }
        }
        .right-form {
            box-shadow: none;
            border: none;
            border-radius: 0;
            &.is-sign-up {
                background-color: transparent;
                .register-form-wrapper {
                    background: none;
                }
            }
            .social-buttons-container {
                & > div {
                    display: flex;
                    justify-content: center !important;
                    button {
                        width: 54px;
                        height: 54px;
                        padding: 0;
                        margin-bottom: 0;
                        border-radius: 8px;
                        margin-inline-end: 16px;
                        &:last-of-type {
                            margin-inline-end: 0;
                        }
                        p {
                            display: none;
                        }
                        img {
                            position: initial;
                        }
                    }
                }
                .or-text {
                    &:before,
                    &:after {
                        height: 1px;
                    }
                }
            }
            .login-tab {
                background-color: transparent;
                .login-page {
                    background: none;
                }
            }
            .title-description {
                color: $white;
                text-align: center;
                padding-top: 24px;
                max-width: 360px;
                margin: 0 auto;

                span {
                    word-break: break-word;
                }
            }
        }
    }
    .login-container {
        flex-flow: column;
        align-items: center;
        margin-bottom: 0;
        & > div {
            width: 100%;
        }
    }
    .login-container .login-popup {
        padding-left: 15px;
        padding-right: 15px;
    }
    .login-page .left-column {
        width: 100%;
        max-width: 380px;
        padding: 0;
        margin-bottom: 60px;
    }
    .login-page .left-column img {
        display: none;
    }
    .booth-dialog-content #form-dialog-title {
        min-width: initial !important;
    }
    .gdpr-container {
        padding: 30px 30px 0;
        word-break: break-word;
    }
    .gdpr-page h1 {
        margin-top: 0;
        font-size: 24px;
    }
    .gdpr-page h2 {
        margin-top: 30px;
        font-size: 20px;
    }
    .cookie-links {
        padding-bottom: 30px;
    }
    .login-page-spinner-container .homepage-overlay,
    .register-participant-step-1-spinner-container .homepage-overlay {
        position: absolute !important;
    }
    #cookie-banner .container-banner {
        flex-flow: column;
        padding: 20px 30px !important;
    }
    #cookie-banner .accept-button {
        width: 100%;
        margin: 0;
        padding: 8px 0 !important;
        text-align: center;
        margin-top: 20px;
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 375px;
    }
    .register-participant-step-2 {
        .form-container {
            .form-header {
                &.left-log-in-description {
                    padding-top: 70px;
                    background: #ffffff;
                    width: 100%;
                    max-width: inherit;
                    border-radius: 0;
                    .form-title {
                        &.banner-text {
                            svg {
                                path {
                                    fill: var(--secondary-color);
                                }
                            }
                            div {
                                span {
                                    color: $white;
                                }
                            }
                        }
                        & > span {
                            padding-top: 16px;
                            color: var(--secondary-color);
                        }
                    }
                }
            }
        }
        .right-form {
            .register-form-wrapper {
                background: none;
            }
        }
        &.create-event {
            .form-container {
                .form-header {
                    .form-title {
                        color: var(--secondary-color);
                    }
                }
            }
        }
    }
    .login-page {
        &.platform-login {
            .form-container {
                .title-description {
                    color: $white;
                    padding-top: 24px;
                }
                .form-header {
                    background-color: $white;
                    border-radius: 0;
                    padding-top: 70px;
                    .form-title {
                        &.banner-text {
                            svg {
                                path {
                                    fill: var(--primary-color);
                                }
                            }
                            span {
                                color: $white;
                            }
                        }
                    }
                }
                .left-form {
                    display: none;
                }
                .right-form {
                    background: none;
                    .register-form-wrapper {
                        background: none;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 600px) and (max-width: 767.9px) {
    .form-container {
        .tabs-container {
            max-width: 360px;
            margin: 0 auto;
        }
        .form-header {
            &.left-log-in-description {
                p {
                    max-width: 360px;
                    margin-inline-start: auto;
                    margin-inline-end: auto;
                }
            }
        }
        .login-tab {
            .login-page {
                .login-container {
                    max-width: 360px;
                    margin: 0 auto;
                }
            }
        }
        .right-form {
            & > .form-header {
                .form-title {
                    padding-top: 16px;
                }
            }
            &.is-sign-up {
                .register-form-wrapper {
                    .social-buttons-container,
                    form {
                        max-width: 360px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    .register-participant-step-2 {
        .form-container {
            .right-form {
                .register-form-wrapper {
                    max-width: 360px;
                    margin: 0 auto;
                }
            }
        }
    }
    .login-page {
        &.platform-login {
            .form-container {
                .right-form {
                    .register-form-wrapper {
                        max-width: 360px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
