@import 'CSS/variables.module';
.agenda-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: calc(100% - 46px);
}

.top-bar-my-agenda {
    width: 100%;
    padding: 16px 18px;
    display: flex;
    background: transparent;
    align-items: center;
    justify-content: space-between;

    .radio-groups-my-agenda {
        .MuiFormGroup-root {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            gap: 12px;
        }

        &.mobile-support-session {
            .MuiFormGroup-root {
                flex-direction: column;
                gap: 0;
            }
        }
    }
    .past-sessions-and-gmt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: revert;
    }
}
.accordion-keeper {
    width: 100%;
}
.agenda-meeting-requests {
    flex-direction: column;
    display: flex;
    width: 100%;
    background: $white-variant;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}
.mr-3px {
    margin-right: 3px;
}
.agenda-meeting-requests-mobile {
    flex-direction: column;
    display: flex;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
}
.element-control {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 85px;
    margin-bottom: 3px;
}
.headline-accordion {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $grey-dark;
        align-items: center;
        display: flex;
        text-transform: uppercase;
    }
}
.single-agenda-border-bottom {
    height: 1px;
    width: 89.5%;
    margin-left: auto;
    display: block;
    background: $light-grey-v2;
    &.set-for-mobile {
        margin: 16px auto !important;
        width: 100%;
    }
}
.cursor-pointer {
    cursor: pointer;
}
.single-agenda-item-container {
    margin-bottom: 4px;
    &.mobile {
        flex-direction: column;
        gap: 11px;
    }
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    padding: 4px;
    .information-block,
    .time-block {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding-right: 8px;
        .action-icon {
            font-size: 0;
            margin-bottom: 11px;
        }
    }
    .information-block.border-color-req {
        border-left: 2px solid $dark;
    }
    .information-block {
        align-items: flex-start;
        border-left: 2px solid $disabled-text;
        padding-left: 8px;
        width: 60%;
        min-height: 60px;
        a {
            line-height: 24px;
            margin-bottom: 11px;
        }
        .state-of-req-container {
            height: 24px;
            margin-bottom: 11px;
        }
        .state-of-req {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            border-radius: 6px;
            padding: 0 8px;
            position: relative;
            top: 2px;
            &.requested {
                color: $secondary;
                background: $secondary-background;
            }
            &.accepted {
                color: $white;
                background: $secondary;
            }
            &.live {
                color: $white;
                background: $primary;
            }
            &.past {
                background: $grey-dark;
                color: $white;
            }
        }

        .date-of-req {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.03em;
            color: $dark;
            margin-bottom: 3.5px;
        }
        .requested-by,
        .timeslot-name {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $dark;
            word-break: break-word;
        }
    }
    .time-block {
        align-items: flex-end;
        .start-time-of-req,
        .end-time-of-req {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 149%;
            color: $dark;
        }
    }
    .action-block {
        display: flex;
        flex-direction: row;
        gap: 8px;
        width: 30%;
        justify-content: flex-end;
        align-items: center;
        button {
            height: 38px;
        }
        .item-decline-button {
            border: 1px solid $secondary;
            border-radius: 6px;
            align-items: center;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            color: $secondary;
            padding: 6px 12px;
        }
        .item-accept-button {
            border: 1px solid $secondary;
            border-radius: 6px;
            align-items: center;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            color: $white;
            background: $secondary;
            padding: 6px 12px;
        }
    }
}
.single-agenda-item-container:hover {
    background: $white-variant;
}
.active-slot {
    background: #efe4ff;
}
.req-list-agenda {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}
.accept-all-button-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.d-column {
    flex-direction: column;
}
.item-decline-button.item-accept-all-meetings-button {
    color: $secondary;
    padding: 14px 0 0 0 !important;
    margin: 0 !important;
    &.mobile {
        padding: 0 !important;
    }
}
.item-decline-button.item-accept-all-meetings-button:hover,
.item-decline-button.item-accept-all-meetings-button:active,
.item-decline-button.item-accept-all-meetings-button:focus {
    background: transparent;
    color: $secondary;
}
.all-sessions-and-meetings {
    display: flex;
    flex-direction: column;
    width: 100%;

    .related-date-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 16px;
        &.mobile {
            margin-bottom: 0 !important;
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.04em;
            color: $primary;
            padding-left: 10px;
            pointer-events: none !important;
        }
        .item-list {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-left: 10px;
        }
    }
}
.items-main-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 8px);
    margin: 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    height: 100%;
    direction: rtl;
    padding-right: 16px;
    padding-left: 8px;

    * {
        direction: ltr;
    }
}
.items-main-container.ar-lang-all {
    direction: ltr !important;
    * {
        direction: rtl !important;
    }
    .x-decline-btn {
        right: unset;
        left: 10px;
    }
    .padding-add-action-block {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .action-block.session-button-support {
        padding-left: 10px;
    }
}
.ar-support {
    margin: 0 0 0 20px !important;
}
.auditorium-name-agenda {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.03em;
    color: $secondary;
    margin-top: 7px;
    margin-bottom: 11.5px;
}
.timeslot-name-agenda {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $dark;
    margin-bottom: 4px;
}

.speakers-list-agenda {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $dark;
}
.session-is-live.isPast {
    margin-top: 2px;

    background: $grey-dark;
    .white-ball {
        display: none;
    }
}

.session-is-live {
    background: $primary;
    color: white;
    padding: 5px 6px;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 95.9%;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    height: 18px;
}
.white-ball {
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 90px;
}
.action-block.session-button-support {
    height: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row;
    gap: 19px;
}
.small-action-buttons {
    height: 24px;
    background: transparent;
    cursor: pointer;
    border: none;
    padding: 0;
}
.header-time-status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    .time-of-meeting {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        text-transform: uppercase;
        color: $dark;
    }
    .status-mobile {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        border-radius: 6px;
        padding: 0 6px;
        display: flex;
        align-items: center;
        gap: 5px;
        &.requested {
            color: $secondary;
            background: #efe4ff;
        }
        &.accepted {
            color: $white;
            background: $secondary;
        }
        &.live {
            color: $white;
            background: $primary;
        }
        &.past {
            background: $grey-dark;
            color: $white;
        }
    }
}

h3.header-of-meeting {
    color: $dark !important;
    width: 100%;
    margin: 0;
    padding-left: 0 !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.mobile-action-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    .action-btn {
        width: 47%;
        margin: 0;
        background: transparent;
        border: 1px solid $secondary;
        border-radius: 6px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        text-transform: uppercase;
        color: $secondary;
        padding: 6px 0;
    }
    .action-btn:hover {
        color: $white;
        background: $secondary;
    }
}
.mobile-support-accordion-button,
.mobile-support-accordion-button:hover,
.mobile-support-accordion-button:active {
    background: transparent !important;
    margin-right: -24px;
    path {
        fill: $dark;
    }
}
.mobile-support-session.headline-accordion {
    align-items: flex-start;
    flex-direction: column;
    h4.session-name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.03em;
        color: $secondary;
        margin: 0 0 10px 0;
    }
    .time-and-status-bar {
        width: 100%;
        display: flex;
    }
    .header-of-meeting {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $dark;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0;
    }
}
.active-time-slot-agenda-mobile {
    background: $white-variant;
    border: 1px solid $light-grey-v2;
    border-radius: 6px;
}
.accordion-keeper.mobile-support-session {
    border-bottom: 1px solid $disabled-text;
    padding-bottom: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.event-desc-agenda-session {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $dark;
    margin: 0 0 0 -5px;
}
.speaker-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin-left: -5px;
    justify-content: flex-start;
    margin-top: 20px;

    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        text-align: right;
        text-transform: uppercase;
        margin: 0 0 20px 0 !important;
        padding: 0 !important;
        color: $dark !important;
    }
    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        gap: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.03em;
        color: $dark;
    }
}
.filter-button-agenda {
    width: 48px;
    height: 48px;
    display: flex;
    border: 1px solid $grey;
    border-radius: 6px;
    background: transparent;
    justify-content: center;
    align-items: center;
    svg {
        transition: all 0.2s ease-out;
    }
    &.filters-active-agenda {
        background: $primary;
        svg {
            transform: rotate(180deg);
            path {
                fill: $white;
            }
        }
    }
}
.mobile-filters-container {
    z-index: 999;
    position: relative;
    .filters-agenda {
        padding: 4px;
        position: absolute;
        width: 280px;
        min-height: 208px;
        background: $white;
        box-shadow: 0 14px 24px rgba(32, 15, 54, 0.25);
        border-radius: 6px;
        z-index: 1;
        top: 50px;
    }
}
.filters-agenda {
    .MuiFormControlLabel-root {
        margin: 0 !important ;
        .MuiFormControlLabel-label {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.04em;
            color: $black;
        }
    }
}
.radio-group-filters {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.4px;
        color: $dark;
        margin: 15px 0 15px 14px;
    }
}
.date-of-day {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.03em;
    color: $primary;
}

.x-decline-btn {
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
    display: none;
}

.x-decline-btn.session {
    display: block;
    position: relative;
}
.single-agenda-item-container:hover {
    .x-decline-btn {
        display: block;
    }
}
.accepted-upcoming {
    align-self: stretch;
    align-items: flex-start;
    margin-top: 10px;
}
.desktop-support,
.desktop-support:hover,
.desktop-support:active,
.desktop-support:focus {
    margin-right: -10px;
    color: $dark !important;
}

.padding-add-action-block {
    padding-right: 10px;
}

.empty-list-my-agenda {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    width: inherit;
    gap: 14px;
    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $grey-dark;
        margin-top: 32px;
        margin-bottom: 0;
    }
}

.small-action-buttons.discard-help:hover {
    svg {
        path {
            fill: $secondary;
        }
    }
}

.loading-timeslots {
    width: 96%;
}
.m-support-btn {
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    &.color-primary {
        svg path {
            fill: $secondary;
        }
    }
}
.mobile-support-action-btns.action-block {
    width: 100%;
    button {
        width: 50%;
    }
}
.x-decline-btn.mobile-support {
    margin-left: auto;
    padding-right: 10px;
}
.date-mobile-support {
    /*    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 80px auto auto auto;
    text-align: center;*/
}

@media screen and (max-width: 567px) {
    .accordion-keeper {
        width: 96% !important;
    }
    .items-main-container {
        padding-right: 8px;
    }
    .desktop-support,
    .desktop-support:hover,
    .desktop-support:active,
    .desktop-support:focus {
        margin-right: -20px;
    }
}
.small-action-buttons.pastslot-calender {
    padding-top: 4px;
}

.tablet-headline {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
}
.single-agenda-item-container .action-block .item-accept-button:hover {
    color: $secondary;
    border: 1px solid $secondary;
    background: transparent;
}
.item-decline-button.item-accept-all-meetings-button:hover {
    color: $dark;
}
/*.mobile-action-container.tablet-support-action-container {
    width: 32%;
    margin-left: auto;
    margin-top: -65px;
}*/
.item-accept-button.phone-support {
    margin-right: auto;
}
.mr10agenda {
    margin-right: 10px;
}
.transparent-action {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
}
.z10relative {
    position: relative;
    z-index: 10;
}
.single-agenda-item-container {
    position: relative;
}
.mrM2 {
    margin-right: -10px !important;
}
.element-control:last-child {
    .single-agenda-border-bottom {
        display: none;
    }
}
.req-list-agenda {
    .element-control:last-child {
        .single-agenda-border-bottom {
            display: block;
        }
    }
}
.req-list-agenda.req-c-1 {
    .element-control:last-child {
        .single-agenda-border-bottom {
            display: none;
        }
    }
}
.keeper-sub {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 40px;
    justify-content: center;
}
.requested-by.meeting-add {
    height: 40px;
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
}
.req-list-agenda .requested-by.meeting-add {
    height: auto;
    display: block;
    width: -webkit-fill-available;
}
.small-action-buttons:hover,
.x-decline-btn:hover,
.z10relative.small-action-buttons.discard-help.tblt {
    svg {
        path {
            fill: $secondary;
        }
    }
}
.head-keeping {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
}
.meeting-with {
    display: block;
    float: left;
    margin-right: 5px;
}
.max-w260 {
    /*    max-width: 260px;*/
}
.MuiAccordionDetails-root {
    padding: 8px 14px 14px;
}

.pastslot-color {
    color: $grey-dark !important;
    svg {
        path {
            fill: $grey-dark;
        }
    }
}
.single-agenda-item-container.pastslot-color {
    h3.header-of-meeting {
        color: $grey-dark !important;
    }
}
h3.mobile-first-date {
    padding-left: 10px;
    pointer-events: none !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: -0.03em !important;
    color: $primary !important;
}
@media screen and (max-width: 567px) {
    .all-sessions-and-meetings .related-date-items h3 {
        padding-left: 10px;
        pointer-events: none !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        letter-spacing: -0.03em !important;
    }
}

.lang-ar {
    direction: rtl !important;
    text-align: right;
}
.ar-sup {
    margin-right: -10px !important;
    margin-left: auto;
}

.ar-lang-desktop {
    margin-right: auto !important;
    margin-left: 10px !important;
}

.line-ar {
    border-right: 2px solid $disabled-text !important;
    padding-right: 8px !important;
    margin-right: 8px !important;
    border-left: 0 !important;
}
ul.listof-speakers {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.03em;
        padding-bottom: 16px;
    }
}
h3.speakers-title {
    padding: 0 !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: $dark !important;
}
.liveMeeting {
    margin-right: 5px;
    margin-top: 20px;
}
