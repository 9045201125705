@import 'variables.module';

html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
body.no-scroll {
    overflow: hidden;
}
body.no-scroll.show-all-page {
    overflow: auto;
}
body.no-scroll .sections-wrapper {
    height: 0;
    overflow: hidden;
}
body.no-scroll.show-all-page .sections-wrapper {
    height: auto;
    overflow: initial;
}
.green-rectangle-background,
.purple-rectangle-background,
.white-rectangle-background,
.highlight-rectangle-background {
    background-repeat: no-repeat;
    background-size: cover;
}
.white-rectangle-background {
    color: $primary !important;
    padding: 3px 5px;
}
.platform-homepage header {
    background-color: transparent;
    z-index: -1;
    opacity: 0;
}
.show-all-page.platform-homepage header {
    opacity: 1;
    z-index: 99;
    transition: opacity 1.5s;
}
.platform-homepage .container {
    max-width: 1284px;
    padding: 0 30px;
    margin: 0 auto;
}
.platform-homepage .animated-top-section {
    width: 100%;
    height: 100vh;
    position: relative;
}
.platform-homepage .top-section {
    width: 100%;
    height: 100vh;
    position: relative;
    display: none;
}
.platform-homepage .full-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.platform-homepage .animated-top-section .full-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.platform-homepage .animated-top-section .full-image.second {
    opacity: 0;
}
.platform-homepage .top-section .section-heading {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 72px;
    color: #fff;
}
.platform-homepage .top-section .image-gradient-overlay-purple {
    background: linear-gradient(
            142.16deg,
            rgba(104, 38, 209, 0.17) -2.53%,
            rgba(98, 39, 223, 0.48) 61.91%,
            rgba(88, 26, 188, 0.36) 90.11%,
            rgba(102, 36, 209, 0.51) 107.95%
        ),
        rgba(0, 0, 0, 0.6);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.platform-homepage .top-section .section-heading h1 {
    margin: 0;
    font-weight: 600;
    font-size: 72px;
    line-height: 93%;
}
.platform-homepage .top-section .section-heading h1 .purple-rectangle-background {
    padding: 0 20px;
}
.platform-homepage .top-section .section-heading h2 {
    margin: 10px 0 0 0;
    font-weight: 600;
    font-size: 72px;
    line-height: 93%;
}
.platform-homepage .top-section .section-heading h2 .green-rectangle-background {
    padding: 0 20px;
}

.platform-homepage .animated-top-section {
    overflow: hidden;
}
.platform-homepage .animated-top-section .full-image {
    filter: grayscale(100%);
}
.platform-homepage .animated-top-section .section-heading {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 72px;
    color: #fff;
    transition: background-color 1.5s;
    z-index: 2;
}
.platform-homepage .animated-top-section .image-gradient-overlay-black {
    background-color: #000000;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all 1.5s;
}
.platform-homepage .animated-top-section.animate .image-gradient-overlay-black {
    opacity: 0;
}
.platform-homepage .animated-top-section .image-gradient-overlay-purple {
    opacity: 0;
}
.platform-homepage .animated-top-section.animate .image-gradient-overlay-purple {
    background: linear-gradient(
            142.16deg,
            rgba(104, 38, 209, 0.085) -2.53%,
            rgba(98, 39, 223, 0.24) 61.91%,
            rgba(88, 26, 188, 0.18) 90.11%,
            rgba(102, 36, 209, 0.255) 107.95%
        ),
        #000000;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all 1.5s;
}
.platform-homepage .animated-top-section .section-heading .elements-container {
    width: 220px;
    height: 230px;
    padding-top: 30px;
    position: relative;
}
.platform-homepage .animated-top-section .logo-animation-container {
    position: relative;
}
.platform-homepage .animated-top-section .purple {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}
.platform-homepage .animated-top-section .full-logo {
    position: absolute;
    top: calc(50vh + 60px);
    left: 50%;
    transform: translate(-50%);
    z-index: -1;
    opacity: 0;
}
.platform-homepage .animated-top-section.animate .full-logo {
    top: calc(100% + 26px);
    z-index: 0;
    opacity: 1;
    transition: top 1.5s;
}
.platform-homepage .animated-top-section.animate .logo-animation-container {
    transition: all 1.5s;
}
.platform-homepage .animated-top-section.animate .logo-animation-container .green {
    opacity: 0;
    transform: rotate(180deg);
    transition: all 1.5s;
}
.platform-homepage .animated-top-section.animate .logo-animation-container .purple {
    opacity: 1;
    transform: rotate(180deg);
    transition: all 1.5s;
}
.platform-homepage .animated-top-section .start-button {
    box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
    border-radius: 8px 8px 8px 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
    justify-content: center;
    padding: 3px;
    background: linear-gradient(187.84deg, #2c14a1 2.02%, #6513eb 99.76%);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    z-index: -1;
    opacity: 0;
    cursor: pointer;
}
.platform-homepage .animated-top-section.second .start-button span {
    display: block;
    background-color: #170d2d;
    width: 100%;
    height: 100%;
    padding: 8px 15px;
    border-radius: 8px 8px 8px 0;
    transition: background-color 0.3s;
}
.platform-homepage .animated-top-section .start-button:hover span {
    background-color: transparent;
    transition: background-color 0.3s;
}
.platform-homepage .animated-top-section.second .start-button {
    z-index: 0;
    opacity: 1;
    transition: opacity 1.5s;
}
.platform-homepage .animated-top-section.second .section-heading .elements-container {
    padding-top: 0;
    transition: padding-top 1.5s;
}
.platform-homepage .animated-top-section.animate.second .logo-animation-container {
    transform: scale(0.6);
    transition: transform 1.5s;
}
.platform-homepage .animated-top-section.third .section-heading .elements-container {
    opacity: 0;
    transition: all 1.5s;
}
.platform-homepage .animated-top-section.animate.third .logo-animation-container .purple {
    transform: scale(20);
    transition: all 1.5s;
}
.platform-homepage .animated-top-section.third .full-image.first {
    opacity: 0;
    transition: 1.5s;
}
.platform-homepage .animated-top-section.third .full-image.second {
    opacity: 1;
    filter: grayscale(0);
    transition: all 1.5s;
}
.platform-homepage .animated-top-section.animate.third .image-gradient-overlay-purple {
    opacity: 0.6;
    background: linear-gradient(
            142.16deg,
            rgba(104, 38, 209, 0.17) -2.53%,
            rgba(98, 39, 223, 0.48) 61.91%,
            rgba(88, 26, 188, 0.36) 90.11%,
            rgba(102, 36, 209, 0.51) 107.95%
        ),
        rgba(0, 0, 0, 0.6);
    transition: all 1.5s;
}
.platform-homepage .animated-top-section .section-heading .titles-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: 100%;
}
.platform-homepage .animated-top-section .section-heading .titles-container h1,
.platform-homepage .animated-top-section .section-heading .titles-container h2 {
    margin: 0;
    font-weight: 600;
    font-size: 72px;
    line-height: 93%;
    transform: scale(0);
    opacity: 0;
}
.platform-homepage .animated-top-section.third.fourth .section-heading .elements-container {
    display: none;
}
.platform-homepage .animated-top-section.fourth .section-heading .titles-container h1,
.platform-homepage .animated-top-section.fourth .section-heading .titles-container h2 {
    transform: scale(1);
    opacity: 1;
    transition: all 1.5s;
}
.platform-homepage .animated-top-section .section-heading .titles-container .first-title,
.platform-homepage .animated-top-section .section-heading .titles-container .second-title {
    position: relative;
}
.platform-homepage .animated-top-section .section-heading .titles-container .first-title .img-container {
    position: absolute;
    left: calc(-50vw - 650px);
}

.platform-homepage .animated-top-section .section-heading .titles-container .second-title .img-container {
    position: absolute;
    right: calc(-50vw - 650px);
}

.platform-homepage .animated-top-section.fourth .section-heading .titles-container .first-title .img-container {
    left: -25px;
    transition: all 1.5s;
}
.platform-homepage .animated-top-section.fourth .section-heading .titles-container .second-title .img-container {
    right: -25px;
    transition: all 1.5s;
}

section.onvent-look {
    padding-top: 120px;
}
.platform-homepage h3 {
    font-weight: bold;
    font-size: 42px;
    line-height: 42px;
    margin: 0;
    padding: 0 0 60px 0;
}
.platform-homepage h3 span.highlight-rectangle-background {
    display: inline-block;
    color: #fff;
    padding: 15px 10px 5px 15px !important;
    margin-left: -15px;
}
.platform-homepage h3 span.before-highlight-text {
    color: $dark;
    padding-right: 15px;
}
.onvent-look-slider {
    padding-bottom: 220px;
}
.onvent-look-slider .onvent-look-slider-wrapper {
    position: relative;
}
.platform-homepage .arrow {
    z-index: 2;
    background: $disabled-text;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 75px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.platform-homepage .onvent-look-slider .arrow {
    top: 50%;
    transform: translateY(-50%);
}
.platform-homepage .arrow svg path {
    stroke: #fff;
}
.platform-homepage .arrow.previous {
    border-radius: 0 150px 150px 0;
    left: 0;
}
.platform-homepage .arrow.next {
    border-radius: 150px 0 0 150px;
    right: 0;
}
.platform-homepage .arrow.next svg {
    transform: rotate(180deg);
}
.onvent-look-slider .slider-image-container {
    width: 100%;
    max-width: 1182px;
    padding: 0 83px;
    margin: auto;
    height: auto;
    cursor: initial;
}
.onvent-look-slider .slider-image-container .image-container {
    width: 100%;
    height: 100%;
    position: relative;
}
.onvent-look-slider .slider-image-container .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(191, 191, 191, 0.5), rgba(191, 191, 191, 0.5));
    border: 1px solid $disabled-text;
    box-sizing: border-box;
    border-radius: 8px;
}
.onvent-look-slider .slider-image-container .image-overlay button {
    background: $secondary;
    color: white;
    width: 196px;
    height: 40px;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px 8px 8px 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
    justify-content: center;
    padding: 3px;
}
.fullscreen-slider-wrapper {
    height: 100%;
    width: 100%;
    background-color: #636363;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fullscreen-slider-container {
    display: block;
    overflow: hidden;
    padding: 44px 16px;
    position: relative;
    max-height: 100%;
    width: 100%;
}
.fullscreen-slider-container .slick-arrow {
    display: none !important;
}
.fullscreen-slider-container .close-slider-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
}
.fullscreen-slider-wrapper img {
    max-width: 100%;
    height: auto;
}
.fullscreen-slider-wrapper p {
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
}
.fullscreen-slider-wrapper.landscape p {
    display: none;
}
.landscape .fullscreen-slider-container {
    padding: 16px 120px 40px;
}
.landscape .fullscreen-slider-container .slick-arrow {
    display: block !important;
}
.landscape .fullscreen-slider-container .close-slider-button {
    top: 16px;
    right: 76px;
}
.landscape .fullscreen-slider-container .arrow {
    display: flex;
}
.fullscreen-slider-container .slick-arrow:before {
    display: none;
}
.fullscreen-slider-container .slick-next {
    right: -120px;
}
.fullscreen-slider-container .slick-prev {
    left: -120px;
}
.fullscreen-slider-container .arrow {
    background: $secondary !important;
}
.onvent-look-slider .slider-image-container img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}
.onvent-look-slider .presentation-container {
    display: flex;
    margin: 32px auto 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1182px;
    padding: 0 83px;
}
.onvent-look-slider .presentation-container h4 {
    margin: 0;
    font-size: 34px;
    line-height: 34px;
    color: $dark;
    font-weight: normal;
    width: 28%;
    padding-right: 30px;
}
.onvent-look-slider .presentation-container p {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.005em;
    color: $text-grey;
    margin: 0;
    padding-left: 24px;
    border-left: 1px solid $disabled-text;
    display: flex;
    flex: 1;
}
.platform-homepage .slick-dots li {
    margin: 0;
}

.platform-homepage .slick-dots li button:before {
    font-size: 8px;
    opacity: 1;
    color: $secondary;
}
.platform-homepage .slick-dots li.slick-active button:before {
    color: $primary;
    opacity: 1;
}
.onvent-look-slider .slick-dots {
    bottom: -40px;
}
section.onvent-video-presentation {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #f5f5f5 50%, #f5f5f5 50%, #f5f5f5 100%);
    position: relative;
    overflow: hidden;
}
section.onvent-video-presentation-title h3 {
    max-width: 900px;
}
section.onvent-video-presentation .container {
    z-index: 1;
    padding: 0 84px;
    position: relative;
}
section.onvent-video-presentation .video-container {
    width: 100%;
    margin: auto;
    max-width: 1016px;
    position: relative;
    cursor: pointer;
}
section.onvent-video-presentation .video-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border: 1px solid $disabled-text;
    box-sizing: border-box;
    border-radius: 8px;
}
section.onvent-video-presentation .video-container .zoom-effect {
    position: absolute;
    top: 0;
    left: 0;
}
section.onvent-video-presentation .video-container .play-icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
section.onvent-video-presentation .video-container .play-icon-container svg path {
    fill: rgb(137, 137, 137);
    transition: all 1s;
}
section.onvent-video-presentation .left-effect {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
    transition: all 1s;
}
section.onvent-video-presentation:hover .left-effect {
    transform: translate(0, -50%);
    transition: all 1s;
}
section.onvent-video-presentation .right-effect {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    transition: all 1s;
}
section.onvent-video-presentation:hover .right-effect {
    transform: translate(0, -50%);
    transition: all 1s;
}
section.onvent-video-presentation .left-effect svg,
section.onvent-video-presentation .right-effect svg {
    height: 100%;
    width: auto;
}
section.onvent-testimonials {
    padding-top: 180px;
}
section.onvent-testimonials h3 {
    max-width: 1030px;
}
section.onvent-testimonials,
.onvent-testimonials-slider {
    background: #f5f5f5;
}
.onvent-testimonials-slider .onvent-testimonials-slider-wrapper {
    position: relative;
}
.onvent-testimonials-slider .testimonial-wrapper {
    max-width: 1284px;
    padding: 0 100px;
    margin: 0 auto;
}
.testimonial-wrapper .single-testimonial {
    display: flex;
}
.testimonial-wrapper .testimonial-container {
    display: flex;
    position: relative;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 1px solid $disabled-text;
    padding: 50px 65px 50px 50px;
    background-color: #fff;
    width: 70%;
}
.testimonial-wrapper .testimonial-container svg path {
    fill: $disabled-text;
}
.testimonial-wrapper:hover .testimonial-container svg path {
    fill: $primary;
}
.testimonial-wrapper .testimonial-container p {
    margin: 0;
    padding-left: 30px;
}
.testimonial-wrapper .client-avatar {
    width: 80px;
    height: 80px;
    border: 1px solid #ffffff;
    box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
    border-radius: 50%;
}
.testimonial-wrapper .testimonial-container .client-avatar {
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
    filter: grayscale(100%);
}
.testimonial-wrapper .client-details .client-avatar {
    display: none;
}
.testimonial-wrapper:hover .testimonial-container .client-avatar {
    filter: none;
}
.testimonial-wrapper .client-details {
    padding-left: 60px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.testimonial-wrapper .client-details .client-name {
    font-size: 20px;
    line-height: 30px;
    color: $dark;
    margin: 0;
}
.testimonial-wrapper .client-details .client-position {
    color: $text-grey;
    font-size: 16px;
    line-height: 24px;
    margin: 5px 0 0 0;
}
.onvent-testimonials-slider {
    padding-bottom: 170px;
}
.onvent-testimonials-slider .slick-dots {
    position: absolute;
    bottom: -50px;
}

section.onvent-cards {
    padding-top: 130px;
    padding-bottom: 156px;
}
section.onvent-cards .cards-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
}
section.onvent-cards .cards-container .single-card-container {
    flex-grow: 1;
    width: 33%;
    padding: 0 12px 24px;
}
section.onvent-cards .cards-container .single-card-container .card {
    border-radius: 8px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    padding: 44px 24px 30px;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
}

section.onvent-cards .cards-container .single-card-container .card h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: $dark;
    margin: 0;
    padding: 40px 0 34px;
    text-align: center;
}
section.onvent-cards .cards-container .single-card-container .card p {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.005em;
    color: $text-grey;
    margin: 0;
}
section.onvent-form {
    position: relative;
}
section.onvent-form .full-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
}
section.onvent-form .full-image-overlay {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.5) 34.91%, rgba(0, 0, 0, 0.25) 84.85%);
}
section.onvent-form h3 {
    max-width: 900px;
}

section.onvent-form .form-container {
    background-color: #fff;
    max-width: 600px;
    margin: auto;
    border: 1px solid $disabled-text;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 32px;
    position: relative;
}
section.onvent-form .form-container form {
    padding: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    & > div {
        width: 100%;
    }
}
section.onvent-form .form-container button {
    width: 200px;
    border-radius: 8px 8px 8px 0;
    margin-top: 24px;
    background-color: $secondary;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    border: 0;
    padding: 10px 0;
    outline: 0;
    cursor: pointer;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
}
section.onvent-form .form-container button:disabled {
    background-color: $grey;
}

section.onvent-form .form-container .success-message {
    color: $primary;
    font-weight: bold;
    font-size: 14px;
    margin: 32px 0 0 0;
    align-self: flex-start;
}
section.onvent-form .form-container.success {
    background-color: rgba(90, 180, 83, 0.9);
}
section.onvent-form .form-container.success form {
    opacity: 0;
    z-index: -1;
    position: relative;
}
section.onvent-form .form-container .success-container {
    opacity: 0;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    color: #fff;
}
section.onvent-form .form-container .success-container .title {
    font-size: 34px;
    line-height: 34px;
    margin: 0;
}
section.onvent-form .form-container .success-container .sub-title {
    color: $dark;
    font-size: 14px;
    line-height: 14px;
    margin: 22px 0 55px 0;
}
section.onvent-form .form-container .success-container .end-text {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    text-transform: uppercase;
}
section.onvent-form .form-container.success .success-container {
    opacity: 1;
    z-index: 1;
}
.platform-footer-wrapper {
    padding: 180px 0 40px 0;
    background-color: transparent;
}
.platform-footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.platform-footer-container .title {
    font-size: 24px;
    line-height: 36px;
    color: $dark;
    margin: 40px 0 44px 0;
}
.platform-footer-container .title span {
    display: inline-block;
    padding: 5px;
    color: #fff;
}
.platform-footer-container ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}
.platform-footer-container .social-buttons-wrapper li {
    padding: 0 20px;
}

.platform-footer-container .inner-links li {
    padding: 0 20px;
}
.platform-footer-container .inner-links li a {
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
}
.platform-footer-container .social-buttons-wrapper {
    margin-bottom: 32px;
}

@media (hover: hover) {
    .platform-homepage .arrow:hover {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        background-color: $secondary;
    }
    section.onvent-cards .cards-container .single-card-container:hover .card {
        box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
    }
}

section.onvent-video-presentation .video-container .main-image .image-container {
    position: relative;
    font-size: 0;
    background-color: #fff;
}
section.onvent-video-presentation .video-container .react-player {
    position: absolute;
    border-radius: 8px;
    overflow: hidden;
    opacity: 0;
    transition: all 0.5s;
}
section.onvent-video-presentation.playing .video-container .react-player {
    position: absolute;
    border-radius: 8px;
    overflow: hidden;
    opacity: 1;
    transition: all 0.5s;
}
section.onvent-video-presentation .video-container .main-image .image-container img {
    opacity: 1;
    transition: all 0.5s;
    position: relative;
}
section.onvent-video-presentation.playing .video-container .main-image .image-container img {
    opacity: 0;
    transition: all 0.5s;
}
section.onvent-video-presentation.playing .video-container .play-icon-container {
    display: none;
}
section.onvent-video-presentation.playing .left-effect,
section.onvent-video-presentation.playing .right-effect {
    transform: translate(0, -50%) !important;
}

@media (max-width: 1365.9px) {
    section.onvent-video-presentation .video-container .effect-image {
        display: none;
    }
    section.onvent-video-presentation .left-effect,
    section.onvent-video-presentation .right-effect {
        height: 100%;
    }
    section.onvent-video-presentation .left-effect img,
    section.onvent-video-presentation .right-effect img {
        height: 100%;
        width: auto;
    }
    section.onvent-video-presentation .left-effect {
        transform: translate(0, -50%);
    }
    section.onvent-video-presentation .right-effect {
        transform: translate(0, -50%);
    }
}

@media (min-width: 1365.9px) {
    section.onvent-video-presentation .video-container {
        max-width: 1136px;
        height: 634px;
    }
    section.onvent-video-presentation .video-container .main-image {
        font-size: 0;
        width: 100%;
        height: 100%;
        padding: 31px 50px;
    }
    section.onvent-video-presentation .video-container .main-image .image-container {
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: 1px solid $disabled-text;
        box-sizing: border-box;
    }
    section.onvent-video-presentation .video-container:hover .play-icon-container svg path {
        fill: $secondary;
        transition: all 1s;
    }
    section.onvent-video-presentation .video-container img {
        height: 100%;
        transform: scale(1.1);
        transition: transform 1s;
        object-position: center;
        border: none;
    }
    section.onvent-video-presentation .video-container:hover img {
        transform: scale(1);
        transition: transform 1s;
    }

    section.onvent-video-presentation .video-container .effect-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 1s;
        display: block;
    }
    section.onvent-video-presentation .video-container .effect-image .image-container {
        overflow: hidden;
        width: 100%;
        height: 100%;
        opacity: 0;
        border-radius: 8px;
        border: 1px solid $disabled-text;
        transition: all 1s;
    }
    section.onvent-video-presentation .video-container:hover .effect-image {
        padding: 31px 50px;
        transition: all 1s;
    }
    section.onvent-video-presentation .video-container:hover .effect-image .image-container {
        opacity: 1;
        transition: all 1s;
    }
}

@media (max-width: 1023.9px) {
    section.onvent-video-presentation .video-container .play-icon-container svg path {
        fill: $secondary;
    }
    body.no-scroll {
        overflow: auto !important;
    }
    body.no-scroll .sections-wrapper {
        height: auto;
        overflow: initial;
    }
    .platform-homepage header {
        opacity: 1 !important;
        z-index: 99;
    }
    .platform-homepage .animated-top-section {
        display: none;
    }
    .platform-homepage .top-section {
        display: block;
    }
    .platform-homepage .container,
    .onvent-testimonials-slider .testimonial-wrapper {
        padding: 0 84px;
    }
    .platform-homepage .top-section .section-heading h1,
    .platform-homepage .top-section .section-heading h2 {
        font-size: 72px;
    }
    .onvent-look-slider .presentation-container {
        flex-flow: column;
    }
    .onvent-look-slider .presentation-container h4 {
        width: 100%;
        padding-bottom: 16px;
        padding-right: 0;
    }
    .onvent-look-slider .presentation-container p {
        border-left: none;
        padding-left: 0;
    }
    section.onvent-video-presentation-title h3 {
        max-width: 560px;
    }
    .testimonial-wrapper .single-testimonial {
        flex-flow: column;
        background: #ffffff;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 50px 32px 50px 50px;
    }
    .testimonial-wrapper .testimonial-container {
        border: none;
        border-radius: 0;
        padding: 0;
        width: 100%;
        box-shadow: none;
    }
    .testimonial-wrapper .testimonial-container svg path {
        fill: $primary;
    }
    .testimonial-wrapper .testimonial-container .client-avatar {
        display: none;
    }
    .testimonial-wrapper .client-details {
        position: relative;
        padding-left: 156px;
        margin-top: 32px;
    }
    .testimonial-wrapper .client-details .client-avatar {
        display: block;
        position: absolute;
        left: 52px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
    section.onvent-cards .cards-container .single-card-container {
        width: 100%;
    }
    section.onvent-cards .cards-container .single-card-container .card {
        max-width: 392px;
        margin: auto;
    }
}

@media (max-width: 767.9px) {
    .platform-homepage .top-section .section-heading {
        justify-content: flex-start;
    }
    .platform-homepage .top-section .section-heading h1 {
        padding-top: 175px;
    }
    .platform-homepage .top-section .section-heading h1,
    .platform-homepage .top-section .section-heading h2 {
        font-size: 34px;
        line-height: 34px;
    }
    .platform-homepage .top-section .section-heading h2 {
        margin: 0;
    }
    .platform-homepage h3 {
        font-weight: normal;
    }
    .platform-homepage .container,
    .onvent-testimonials-slider .testimonial-wrapper {
        padding: 0 16px;
    }
    .platform-homepage h3 {
        padding-left: 28px;
        padding-right: 28px;
        font-size: 34px;
        line-height: 34px;
    }
    .platform-homepage .arrow {
        display: none;
    }
    .onvent-look-slider .slider-image-container,
    .onvent-look-slider .presentation-container,
    section.onvent-video-presentation .container {
        padding-left: 44px;
        padding-right: 44px;
    }
    .onvent-look-slider .slider-image-container .image-overlay {
        display: flex;
    }
    .onvent-look-slider .presentation-container h4 {
        font-size: 34px;
        line-height: 34px;
    }
    section.onvent-testimonials h3 {
        padding-bottom: 47px;
    }
    .onvent-testimonials-slider .testimonial-wrapper {
        padding-bottom: 50px;
    }
    .onvent-testimonials-slider {
        padding-bottom: 220px;
    }
    .testimonial-wrapper .single-testimonial {
        padding: 24px 24px 50px 24px;
    }
    .testimonial-wrapper .testimonial-container {
        flex-flow: column;
    }
    .testimonial-wrapper .testimonial-container p {
        padding-left: 0;
        padding-top: 24px;
    }
    .testimonial-wrapper .client-details {
        padding-left: 0;
    }
    .testimonial-wrapper .client-details .client-avatar {
        bottom: -130px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .onvent-testimonials-slider .slick-dots {
        bottom: -50px;
    }
    .platform-footer-wrapper {
        padding-bottom: 120px;
    }
    .platform-footer-container .inner-links ul {
        flex-flow: column;
    }
    .platform-footer-container .inner-links ul li {
        padding: 12px 0;
    }
}

.register-form-wrapper {
    .choose-package .package-description {
        word-break: break-word;
    }
    legend {
        word-wrap: break-word;
    }
}


@media (max-width: 349.9px) {
    .platform-homepage .top-section .section-heading h1,
    .platform-homepage .top-section .section-heading h2 {
        font-size: 30px;
    }
}
@media screen and (orientation: landscape) {
}
