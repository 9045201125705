@import '../../CSS/variables.module';

.polls-container {
    background-color: #f5f5f5;
    flex: 1 1;
    padding: 8px 0;
}

.polls {
    will-change: unset;
}

.polls-container {
    font-size: 14px;
    text-align: left;
    .no-polls {
        display: flex;
        flex: 1;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: rgba(137, 137, 137, 0.5);
            margin-top: 0;
            margin-bottom: 32px;
            text-align: center;
        }
    }
    .poll {
        button {
            margin-top: 5px;
            width: 100%;
        }
        &.-vote-availible .poll_option {
            cursor: pointer;
        }
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        &_header {
            padding: 16px;
        }

        &_title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $primary;
            margin: 0 0 12px;
            text-align: start;
        }
        &_status {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.4px;
            display: flex;
            align-items: center;
            &-icon {
                margin-right: 5px;
            }
        }
        &_button {
            background: $primary;
            margin: 5px auto;
            width: calc(100% - 10px);
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.0125em;
            text-transform: uppercase;
            font-weight: bold;
            color: #ffffff;
            height: 44px;
            border-radius: 8px 8px 8px 8px;
        }
        &_option {
            padding-top: 8px;
            padding-bottom: 4px;
            padding-inline-start: 8px;
            padding-inline-end: 16px;
            border-bottom: 1px solid $disabled-text;
            min-height: 60px;
            position: relative;
            overflow: hidden;
            &.-is-checked {
                background: rgba(102, 36, 209, 0.1);
                @at-root & .poll_option-title {
                    color: $secondary;
                }
                @at-root & .poll_option-progress {
                    background-color: $secondary;
                }
            }
            &:hover {
                .poll_option-title {
                    color: $secondary;
                }
            }
            &-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                transition: color 0.3s ease-out;
                color: $dark;
                margin: 0;
                text-align: start;
            }
            &-text {
                margin: 0;
            }
            &-icon {
                &.-colored {
                    color: $primary;
                }
            }
            &-container {
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.4px;
                margin-bottom: 5px;
            }
            &-progress-wrapper {
                width: 100%;
                height: 6px;
                border-radius: 6px;
                overflow: hidden;
            }
            &-progress {
                border-radius: 6px;
                width: 100%;
                height: 100%;
                background-color: $secondary;
                transition: transform 0.3s ease-out;
                transform: translateX(-100%);
            }
        }
    }
}
