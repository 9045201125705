@import 'CSS/variables.module';
@import './CSS/hpColors';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body .api-page {
    margin-top: 150px;
    text-align: center;
}

@media screen and (min-width: 1140px) and (max-width: 1180px) {
    body {
        overflow-x: hidden;
    }
}

.ignore-rtl {
    direction: ltr !important;
}

.MuiTooltip-tooltip {
    word-break: break-word;
}

.break-word {
    word-break: break-word;
}

.justify-content-between {
    justify-content: space-between !important;
}
.padding-LR15 {
    padding: 0 15px !important;
}
.full-max-width {
    max-width: 100% !important;
}
#signup-text p {
    font-size: 14px;
    margin: 0 0 10px 0;
    font-weight: normal;
}
#cookie-banner {
    position: fixed;
    z-index: 999999;
    bottom: 0;
    width: 100%;
    color: white;
    display: flex;
    background: $secondary-banner2;
    background: -moz-linear-gradient(-45deg, $secondary-banner2 0%, $secondary-banner1 100%);
    background: -webkit-linear-gradient(-45deg, $secondary-banner2 0%, $secondary-banner1 100%);
    background: linear-gradient(135deg, $secondary-banner2 0%, $secondary-banner1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$secondary-banner2', endColorstr='$secondary-banner1', GradientType=1);
}
#cookie-banner .container-banner {
    width: 100%;
    margin: auto;
    padding: 16px 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#cookie-banner .container-banner p {
    margin: 0;
    max-width: 1200px;
}
#cookie-banner a {
    font-weight: bold;
    color: $primary;
}
#cookie-banner .accept-button {
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 50px;
    border: 2px solid #fff;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
}
a:focus {
    outline: none !important;
}
.loading {
    overflow: hidden;
    height: 100vh;
}
.spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    flex-flow: column;
    font-weight: bold;
}
.lobby-spinner {
    position: absolute;
    top: 69px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
}
.organizer-spinner {
    position: absolute;
    top: 115px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
}
.organizer-spinner-side {
    position: absolute;
    top: 115px;
    left: 256px;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
}
.organizer-spinner-side-double {
    position: absolute;
    top: 115px;
    left: 512px;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
}
.wall-spinner {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    flex-flow: column;
    font-weight: bold;
    div {
        width: 30px !important;
        height: 30px !important;
    }
}
.login-page .spinner {
    border-radius: 8px;
}
.full-page-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.full-page-spinner .spinner-container {
    position: absolute;
}
.full-page-spinner svg {
    color: #fff;
}
.full-page-spinner .logo-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(256, 256, 256, 0.5);
    padding: 21px 43px;
    z-index: 1;
    font-size: 0;
}
// TODO: move utility classes to separate file
.text-capitalize {
    text-transform: capitalize;
}
.text-uppercase {
    text-transform: uppercase;
}
.d-none {
    display: none !important;
}
.d-flex {
    display: flex !important;
}

.d-block {
    display: block !important;
}

.pl-10 {
    padding-inline-start: 10px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.ml-helper {
    flex-direction: column;
    height: 90px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.cursor-pointer {
    cursor: pointer !important;
}
.cursor-pointer:hover {
    cursor: pointer !important;
}
.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
/*if you want to use ellipsis with line property, you should give a height to the related element*/
.ellipsis-second-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.ellipsis-third-line {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.ellipsis-fourth-line {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.ellipsis-fifth-line {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.legend-width-500 {
    legend {
        max-width: 500px;
    }
}
.align-tems-flex-start {
    align-items: flex-start !important;
}
.m-0 {
    margin: 0 !important;
}
.mtb-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mr-5 {
    margin-right: 5px !important;
}
.mr-20 {
    margin-right: 20px !important;
}
.mt-17 {
    margin-top: 17px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.mr10 {
    margin-right: 10px !important;
}
.p6-0 {
    padding: 6px 0 !important;
}
.pl-0 {
    padding-left: 0 !important;
}
.pr-0 {
    padding-right: 0 !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.m-top {
    margin-top: 30px !important;
}
.m-bottom {
    margin-bottom: 30px !important;
}
.m-left {
    margin-left: 30px !important;
}
.m-right {
    margin-right: 30px !important;
}
.text-lowercase {
    text-transform: lowercase;
}
.pL0mL0 {
    padding-left: 0 !important;
    margin-left: 0 !important;
}
.mr-auto {
    margin-right: auto !important;
}
.ml-0 {
    margin-left: 0 !important;
}
.mr-0 {
    margin-right: 0 !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.mt-25 {
    margin-top: 25px !important;
}
.mr-8 {
    margin-right: 8px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-16 {
    margin-bottom: 16px !important;
}
.server-down-info-container {
    position: absolute;
    z-index: 9999 !important;
    background-color: rgba(102, 36, 209, 0.85);
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px !important;
    padding: 0 16px;
}
.server-down-info-container span {
    display: inline-block;
    font-weight: bold;
    color: #fff;
    padding: 0 10px;
    cursor: pointer;
}
.send-container {
    margin-top: 100px;
}
.input-bg-white {
    background-color: $white;
}
.lobby-slider {
    width: 140px;
    position: absolute;
    top: 38vh;
    left: 47vw;
}
.lobby-slider img {
    width: 100%;
    height: auto;
}
input[type='date']::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}
body .api-page input {
    padding: 5px 10px;
}
.margin-top {
    margin-top: 50px;
    margin-bottom: 50px;
}
.full-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.full-overlay .content {
    background-color: white;
    padding: 0;
    border-radius: 0;
    min-width: 400px;
    @media screen and (max-width: 479px) {
        min-width: initial;
        width: 100%;
    }
}
.full-overlay .content p {
    padding: 0 10px;
    max-width: 500px;
    text-align: center;
}
.full-overlay .content button {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $error-light;
    display: block;
    margin: 8px 0 4px auto;
}
.full-overlay .content h3 {
    padding: 0 10px;
    text-align: center;
    background-color: $primary;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    margin: 0 0 30px 0;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.13);
    color: white;
    font-weight: 800;
    font-size: 18px;
    text-transform: uppercase;
}
.full-overlay .content .my-events-dialog {
    padding: 20px 0 10px 0;
    max-width: 300px;
    margin: auto;
}
.full-overlay .content .my-events-dialog p {
    text-align: center;
    color: white;
    background-color: $primary;
    padding: 10px;
    margin: 0;
}
.my-events-container {
    max-height: 150px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: rgb(137, 137, 137) #fff0;
    direction: rtl;
    padding: 0 4px;

    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(137, 137, 137);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
.full-overlay .content .my-events-dialog ul {
    list-style: none;
    padding-top: 15px;
    direction: ltr;
}
.full-overlay .content .my-events-dialog ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
}
.full-overlay .content .my-events-dialog ul li:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}
.full-overlay .content .my-events-dialog ul li span {
    padding: 0;
}
.full-overlay .content .my-events-dialog ul li:hover {
    color: $primary;
}
.imgPreview img {
    max-width: 250px;
}
a {
    text-decoration: none;
}
h3.page-title {
    margin: 50px 0;
}
.previewText {
    margin-top: 30px;
}
.upload-image-button {
    margin-top: 16px;
    margin-bottom: 24px;
}
div.login {
    margin-top: 16px;
}
div.recoverPassword {
    margin-top: 48px;
}
.live-wall {
    list-style: none;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-inline-start: 0;
    width: 500px;
}
.live-wall li {
    display: flex;
    align-items: center;
    padding-bottom: 0;
    margin-bottom: 10px;
}
.live-wall li p {
    margin: 0;
    line-height: 16px;
}
.live-wall li img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 30px;
}
.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-inline-end: 30px;
    object-fit: cover;
    display: flex;
}
.not-read {
    font-weight: bold;
}
.live-wall .bottomChat {
    margin: 0;
    padding: 0;
    height: 0;
    border: none;
}
.wall .single-post-container:first-child {
    margin-top: 0;
}
.single-post-container .post-header,
.comment-header {
    display: flex;
    align-items: center;
}
.comment-header span {
    padding-right: 5px;
}
.comment-header .avatar {
    width: 20px;
    height: 20px;
    margin-right: 15px;
}
.post-content {
    padding: 0 15px 30px 70px;
    margin: 0;
}
.post-comments p {
    margin: 0;
}
.comment-text {
    padding-left: 35px;
    padding-top: 3px;
}

.single-event-container .actions-container button {
    margin-bottom: 20px;
    display: block;
}
.container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}
.smallContainer {
    max-width: 720px;
    margin: auto;
    position: relative;
}
.position-relative {
    position: relative !important;
}
.position-absolute {
    position: absolute !important;
}
.loginPage {
    position: relative;
}
.overlayWhite {
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.h-100 {
    height: 100% !important;
}
.loginPage h1 {
    font-size: 60px;
    margin: 0;
}
.loginPage .subtitle {
    font-size: 40px;
    margin: 0;
}
.loginPage img {
    display: block;
    margin: auto;
    padding-top: 50px;
}
.carouselContainer img {
    height: 500px;
}

form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0 1000 transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

.testPage {
    display: flex;
}
.testPage .logo {
    position: absolute;
}
.sideDivs {
    flex-grow: 1;
}
.bottomDiv {
    background-color: green;
    height: 200px;
}
.eventButtons {
    position: absolute;
    width: 1366px;
    height: 100vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
}
.eventButtonsContainer {
    width: 100%;
    height: 100%;
    position: relative;
}
.logo {
    position: absolute;
    top: 120px;
    left: 140px;
}
.auditorium-page.smallContainer.fullscreen {
    position: static;
}
.auditorium1 {
    position: absolute;
    top: 135px;
    left: 500px;
}
.auditorium2 {
    position: absolute;
    top: 140px;
    left: 880px;
}

ul.comments-list-container {
    list-style: none;
    padding-left: 0;
    margin: 0;
}
ul.comments-list-container li {
    margin-bottom: 15px;
    position: relative;
}
.main-menu-container .actions-container button:not(.sm-share-button) {
    margin: 0;
}

.program-entry {
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}
.program-entry.no-speakers .program-container .topic {
    width: 100%;
}
.dropdown-paper {
    border-radius: 0 !important;
    margin-top: -2px;
    border: 2px solid $primary;
    box-shadow: none !important;
    .MuiListItem-gutters {
        padding-left: 14px;
        padding-right: 14px;
    }
    &.importance-level .MuiListItem-gutters {
        padding-left: 12px;
        padding-right: 12px;
    }
}
.dropdown-component {
    cursor: initial;
}
.dropdown-component,
.left-dropdown-container {
    position: absolute;
    background-color: rgba(239, 237, 237, 0.85);
    /*height: calc(100vh - 300px);*/
    left: 0;
    top: 70px;
    width: 730px;
    overflow: auto;
    z-index: 5;
}
.my-account-component.dropdown-component {
    z-index: 6;
}
.left-dropdown-container {
    left: initial;
    right: 0;
    max-height: 100%;
}
.main-menu-container .actions-container .notification-count {
    padding: 0 5px;
    margin-right: 0;
}
.notification-count span {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
}
.edit-group-members > div {
    width: 30%;
    display: inline-block;
}
.edit-popup {
}
.wide-dialog {
    width: 600px;
}
.exhibitorShowfloorBooth img {
    max-width: 100%;
}
.showfloorBoothsContainer {
    margin-top: 50px;
}
.exhibitorShowfloorBooth {
    padding: 15px;
}

.group-chat-name,
.private-chat-name {
    max-width: 150px;
    text-align: start;
}
.group-chat-name.privateChat {
    max-width: 370px;
    padding-inline-end: 20px;
    flex: 1;
}
.expanded .group-chat-name.privateChat {
    max-width: 610px;
}
.expanded .group-chat-name.privateChat.without-meeting {
    max-width: 540px;
}
.group-chat-name.privateChat.without-meeting {
    max-width: 155px;
}
.time-ago-wrapper {
    padding-right: 8px;
}
.time-ago-wrapper span {
    font-size: 12px;
    color: $text-grey;
    font-weight: 500;
    display: none;
}
.expanded .time-ago-wrapper span {
    display: block;
}
.program-section {
    margin-bottom: 30px;
    &.is-loading {
        position: relative;
    }
}
.program-column {
    width: 50%;
}
.program-column > p:nth-child(2) {
    font-weight: 600;
}
.program-column.left-column {
    float: left;
}
.program-column.right-column {
    float: right;
}
.program-column p {
    margin: 0;
    word-break: break-word;
}
.program-column .speakers-container {
    margin-top: 10px;
}
.clear-column {
    clear: both;
}
.small-label {
    font-size: 14px;
    color: grey;
    margin: 0;
}
.speakers-container .speaker {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.speaker > div p:last-of-type {
    font-size: 14px;
    color: grey;
}
.speakers-container .avatar,
.speakers-list .avatar {
    margin-right: 10px;
}
.single-auditorium-archive-container .speakers-container .avatar {
    flex: 0 0 40px;
}
.right-column .program-time {
    float: left;
}
.program-time.start {
    margin-right: 20px;
}
.program-time p:nth-child(2) {
    font-weight: 600;
}
.right-column .video-url {
    margin-top: 10px;
}
.right-column .time-slot {
    margin-top: 10px;
}
.program-description {
    margin-top: 10px;
}
.time-slot button:first-of-type {
    margin-right: 10px;
}

.booth {
    height: 100vh;
    overflow: hidden;
    position: relative;
}
.booth .booth-preview-container {
    width: 100%;
    position: absolute;
    height: 100%;
    text-align: center;
}
.background-event {
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.booth .booth-preview-container img {
    width: auto;
    height: 100%;
}
.current-logo-container img {
    max-width: 100%;
    height: auto;
}
.booth-management-container {
    display: flex;
    flex-direction: column;
    .booth-dashboard-container {
        display: flex;
        justify-content: center;
    }
}
.contacts-page.dropdown-component {
    overflow: initial;
    top: 56px;
}
.contacts-page.dropdown-component.contacts-mobile {
    top: 56px;
    width: 100%;
    max-width: initial;
    height: calc(100vh - 112px);
}
.contacts-page.dropdown-component .connection.no-action .participant-button.open-chat {
    margin: 5.5% 0;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -webkit-filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -ms-flex-item-align: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
        }
    }
}
.contacts-page.dropdown-component.contacts-mobile .received-requests-title {
    display: none;
}
.contacts-page .custom-scroll-container {
    height: calc(100vh - 500px);
}
.contacts-page.contacts-mobile .custom-scroll-container {
    height: calc(100vh - 160px);
}
.contacts-page.contacts-mobile .custom-scroll-container > div {
    height: auto;
    padding: 0;
    max-height: 100%;
    overflow-y: auto;
    &.already-contacts-wrapper {
        height: 100%;
    }
}
.contacts-page.contacts-mobile .page-title {
    height: 48px;
    line-height: 48px;
    padding-inline-start: 16px;
    text-align: start;
}
.contacts-page.contacts-mobile .page-title h3 {
    font-weight: 600;
    font-size: 16px;
}
.predefinedAvatar {
    width: 50px;
    height: 50px;
}
.video-ended-image-container {
    margin-top: 20px;
}
.video-ended-image-container img {
    width: auto;
    height: 200px;
    margin-top: 10px;
}
.video-ended-image-container button {
    margin-top: 10px;
}
.video-ended-image-container button label {
    cursor: pointer;
}
.videoWrapper img {
    max-width: 100%;
    height: auto;
}
.booth-background {
    width: auto;
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100vh;
    left: 50%;
    transform: translateX(-50%);
}
.booth-page {
    position: relative;
}
.booth-page img {
    width: 200px;
    height: auto;
}
.booth-player-container {
    margin-bottom: 100px;
}
.exhibitor-representative {
    cursor: pointer;
}
.timeslot-video-image {
    width: 300px;
    height: auto;
}

.new-file-upload-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.manage-booth-representatives .representative-container .info-container .avatar {
    width: 60px;
    height: 60px;
    align-self: center;
}
.centerOfPage {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
}
.relativeCenter {
    position: relative;
}
.relativeCenter .clickable-element {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.relativeCenter .window {
    position: absolute;
    font-size: 0;
    margin: 0;
    background: transparent;
    z-index: -1;
}
.clickable-element-window-left {
    left: -119vh;
    height: 100vh;
    aspect-ratio: 1/1;
    z-index: -1;
}
.clickable-element-window-right {
    right: -212vh;
    height: 100vh;
    aspect-ratio: 1/1;
    z-index: -1;
}
.clickable-element.disable-lobby-door {
    cursor: default;
}
.relativeCenter .clickable-element.medalion-representatives {
    cursor: initial;
}
.centerOfPage .clickable-element:hover {
    box-shadow: 0 0 3vh 1px $secondary-hover;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.centerOfPage .clickable-element.no-hover {
    cursor: initial;
}
.centerOfPage .clickable-element.no-hover:hover {
    box-shadow: none;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.centerOfPage #show-video .image-preview-video {
    width: 100%;
    height: 100%;
    background-size: cover;
}
.centerOfPage #show-video .image-preview-video.no-video img {
    object-fit: contain;
}
.centerOfPage #show-video .image-preview-video img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
#show-video .play-button {
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -15vh;
    width: 100%;
    height: 15vh;
}
.fullscreen .booth-video-dialog-container .booth-video-container {
    width: calc(100% - 20px);
    height: calc(100vh - 150px);
    margin: 0 auto;
}
.booth-video-container {
    position: relative;
    width: 500px;
    height: 281px;
    padding: 0 10px;
    overflow: hidden;

    .booth-thumbnail {
        width: 100%;
        object-fit: cover;
        padding: 0 10px;
        transition: 800ms ease;
        transition-property: opacity;
        &.hide {
            display: none;
        }
    }

    .progress-bar {
        z-index: 10;
        height: 13px;
        position: relative;
        bottom: 13px;
        padding: 0;
        background-color: #f5f5f5;
        opacity: 0;
        transition: opacity 0.5s ease;

        .MuiSlider-rail {
            opacity: 0;
            height: 0;
        }

        .MuiSlider-track {
            height: 100%;
        }

        .MuiSlider-thumb {
            opacity: 0;
        }

        &.hide {
            opacity: 0;
        }
    }

    @media screen and (min-width: 1025px) {
        &:hover {
            .progress-bar {
                opacity: 1;
            }
        }
    }
}
.booth-video-dialog-container #form-dialog-title {
    padding-top: 0;
}
.fullscreen .booth-video-actions-container {
    padding: 0 20px;
}
.booth-video-actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: auto;
    padding: 0 10px;
}
.booth-video-actions-container .fullscreen-button {
    font-size: 30px;
    background-color: transparent;
    border: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    outline: 0;
    cursor: pointer;
    padding: 0;
}
.on-demand-title {
    font-size: 16px !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.position-background {
    /*overflow: hidden;*/
    /*position: absolute;*/
    position: fixed;
    width: 100%;
    height: 100vh;
    text-align: center;
    font-size: 0;
    // MATCHING BANNERS
    // Apply this for desktops only, else hide and show mobile version
    .matching-banner,
    .matching-banner-closed {
        display: none;
    }
    @media all and (min-width: 1025px) {
        .matching-banner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 48px;
            z-index: 10;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 6px 16px;
            background: linear-gradient(
                165deg,
                $primary -10.88%,
                $primary 48.89%,
                $matchingBanner1 60.4%,
                $matchingBanner2 75.65%,
                $matchingBanner3 79.67%,
                $matchingBanner4 82.13%,
                $matchingBanner5 106.78%
            );
            box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
            animation: matchingShow 0.5s 3s forwards;
            transform: translate(0, -104px);
            &.wall-networking-isOpen {
                z-index: 11;
                width: calc(100% - 323px);
                padding: 6px 16px;
            }
            .left-side {
                display: flex;
                align-items: center;
                text-align: left;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: $white;
                .matching-banner-close {
                    color: $white;
                    margin: 0 12px;
                    padding: 0 !important;
                    min-width: 24px;
                    .MuiButton-label {
                        width: 24px !important;
                    }
                }
            }
            .matching-banner-try {
                min-width: unset;
                padding: 9px 8px;
                background-color: $white;
                span {
                    font-family: $fontFamily, sans-serif;
                    font-weight: 600 !important;
                    font-size: 14px !important;
                    line-height: 120% !important;
                    text-transform: uppercase;
                    color: $dark;
                    white-space: nowrap;
                }
            }
        }
        .matching-banner-closed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 48px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 6px 16px 6px 52px;
            background: $secondary;
            box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: $white;
            animation: matchingHide 0.5s 6s forwards;
            transform: translate(0, 0);
            &.wall-networking-isOpen {
                z-index: 11;
                width: calc(100% - 323px);
                padding: 6px 54px 6px 52px;
            }
            .matching-banner-try {
                padding: 9px 8px;
                background-color: $white;
                span {
                    font-family: $fontFamily, sans-serif;
                    font-weight: 600 !important;
                    font-size: 14px !important;
                    line-height: 120% !important;
                    text-transform: uppercase;
                    color: $dark;
                }
            }
        }
    }
    @keyframes matchingShow {
        to {
            transform: translate(0, 0);
        }
    }
    @keyframes matchingHide {
        to {
            transform: translate(0, -127px);
        }
    }
}
.position-background.overlay-grey:after,
.showfloor-page.overlay-grey:after,
.meetings-mobile.active:after,
#program-mobile.active:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(43, 43, 43, 0.25);
    z-index: 10;
}
#my-account-mobile.overlay-grey:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(43, 43, 43, 0.25);
    z-index: 10;
}
.booth-dialog .booth-dialog-content {
    padding: 0 !important;
}
.booth-dialog-content #form-dialog-title {
    padding: 0;
    text-align: center;
    background-color: $primary;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    min-width: 400px;
}
.booth-dialog-content .subtitle {
    font-weight: 500;
    font-size: 10px;
    font-family: $fontFamily, sans-serif;
}
.booth-dialog .booth-dialog-content .content-container {
    padding: 10px;
    min-width: 300px;
}
.booth-dialog .booth-dialog-content .content-container p {
    margin: 0 0 10px 0;
}
.booth-dialog .booth-dialog-content .content-container p a {
    font-size: 16px;
    line-height: 20px;
    color: $dark;
}
.booth-dialog .booth-dialog-content .content-container p a .link {
    color: $secondary;
    font-weight: bold;
    word-break: break-all;
}
.booth-dialog .booth-cancel-button {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $error-light;
}
.booth-dialog .booth-action-button {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $primary;
}
.recover-password-dialog {
    .MuiDialog-paper {
        background: transparent;
    }
    .booth-dialog-content,
    .buttons-actions {
        background: $white;
    }
    #form-dialog-title {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
}
.booth-dialog-content #form-dialog-title h2 {
    color: white;
    font-weight: 800;
    font-size: 18px;
    text-transform: uppercase;
    padding: 0 16px;
}
.recover-password-button {
    margin: 10px 0 20px 0 !important;
}

.showfloor-page {
    position: relative;
    overflow: hidden;
}
.showfloor-page .exhibitorShowfloorBooth {
    position: absolute;
    padding: 0;
    z-index: 1;
}
.showfloor-page .exhibitorShowfloorBooth img {
    width: 100px;
}
.auditorium-archive-page .program-column > p:nth-child(2) a {
    font-weight: 600;
    color: black;
}
.auditorium-archive-page .program-column > p:nth-child(2) a,
.speaker > div p:last-of-type {
    padding-right: 20px;
    display: block;
}
.error-message,
.error-text {
    color: $error-light !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    margin: 5px 0 !important;
    display: block !important;
    font-family: $fontFamily, sans-serif;
    line-height: normal !important;
    letter-spacing: 0.03333em;
}
.platform-not-available-page {
    width: 100vw;
    height: 100vh;
    font-size: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 1400;
}
.platform-not-available-page .text-container {
    width: 300px;
    height: 300px;
    background-color: rgb(255, 255, 255);
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 15px;
    border-radius: 20px;
}
.platform-not-available-page .text-container span {
    display: block;
    margin-bottom: 15px;
}
#socketDebugPage {
    font-size: 18px;
}
.showfloor-page .booth {
    height: calc(100vh - 70px);
    width: calc(100% - 323px);
    margin-left: 0;
    margin-right: auto;
}
.single-booth-participant-view .button {
    position: absolute;
    top: 50%;
    left: 0;
    width: 90px;
    transform: translateY(-50%);
    z-index: 101;
    cursor: pointer;
}
.single-booth-participant-view .button .button-container {
    position: relative;
}
.single-booth-participant-view .button .details {
    box-shadow: 0 14px 24px rgba(0, 0, 0, 0.25);
    width: 180px;
    height: 180px;
    padding: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.single-booth-participant-view .button:hover .details {
    background-color: #fff;
}
.single-booth-participant-view .button.next .details {
    justify-content: flex-start;
}
.single-booth-participant-view .button.next .details svg {
    margin-left: 28px;
}
.single-booth-participant-view .button.previous .details {
    margin-right: 0;
    margin-left: auto;
}
.single-booth-participant-view .previous {
    left: -90px;
}
.single-booth-participant-view .next {
    right: 0;
    left: auto;
}
.single-booth-participant-view .previous .details > svg {
    transform: rotate(180deg);
    margin-right: 28px;
}
.single-booth-participant-view .button .logo-container {
    position: absolute;
    left: 90px;
    width: 240px;
    height: 130px;
    bottom: 100%;
    padding: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 14px 24px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: none;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}
.single-booth-participant-view .button.next .logo-container {
    right: 0;
    left: auto;
}
.single-booth-participant-view .button.previous .logo-container {
    padding-left: 110px;
}
.single-booth-participant-view .button.next .logo-container {
    padding-right: 110px;
}
.single-booth-participant-view .button:hover .logo-container {
    display: block;
    p {
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        max-height: 80px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}
.single-booth-participant-view .button .logo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logo-container .no-image {
    position: relative;
    height: 100%;
    width: 100%;
}
.logo-container .no-image p {
    position: absolute;
    font-size: 17px;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.networking-wrapper {
    font-size: 14px !important;
}

.reload-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary-banner2;
    color: white;
    width: 100%;
    height: 100vh;
}

.user-container {
    .participant-avatar {
        margin-inline-end: 16px;
    }
}

@media screen and (max-width: 1291px) {
    .organizer-spinner {
        top: 130px;
    }

    .organizer-spinner-side {
        top: 134px;
    }
}
@media screen and (min-width: 1025px) {
    .contacts-page.dropdown-component {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        z-index: 6;
    }
}
@media screen and (max-width: 1024px) {
    .event-mobile-page {
        height: calc(calc(var(--vh, 1vh) * 100) - 112px);
        overflow: auto;
        &.hide-overflow {
            overflow: hidden;
        }
    }
    .fullscreen-video .event-mobile-page {
        overflow: initial;
    }
    .contacts-page.contacts-mobile .custom-scroll-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 160px);
    }
}
@media screen and (max-width: 1024px) {
    .server-down-info-container {
        height: auto;
        padding: 16px;
    }
    .server-down-info-container p {
        margin: 0;
    }
    .wall .single-post-container:first-child.highlight {
        margin-top: 5px;
    }
}
@media screen and (max-width: 480px) {
    .server-down-info-container p {
        font-size: 14px;
    }
}
//Helper font-weights
.fw-500 {
    font-weight: 500;
}

.matching-with-cb {
    padding: 0 9px !important;
}
.remove-label-click {
    pointer-events: none !important;
}
.auto-pointer-events {
    pointer-events: auto;
}
.MuiTypography-root,
.MuiFormLabel-root,
.MuiButton-root,
.MuiButtonBase-root {
    font-family: $fontFamily, sans-serif !important;
}
.margin-left-auto {
    margin-left: auto !important;
}
.margin-right-auto {
    margin-right: auto !important;
}
.font-weight-bold {
    font-weight: bold !important;
}

span.cursor-pointer {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: $primary;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    svg {
        fill: $primary;
    }
}

.scaled-select-arrow .MuiSvgIcon-root {
    scale: 1.5;
}

::-webkit-scrollbar {
    width: 4px;
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: rgb(137, 137, 137);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.edit-user-information {
    .align-helper {
        text-align: left;
    }
}

.MuiMenu-paper {
    scrollbar-width: thin;
    scrollbar-color: rgb(137, 137, 137) #fff0;
}
.MuiFormControlLabel-root:focus-visible {
    outline: 0;
}

.z-index-1 {
    z-index: 1;
}

.flip-photo {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.direction-rtl {
    direction: rtl;
}
.m-auto {
    margin: auto !important;
}
@media screen and (min-width: 768px) {
    .columns-2 {
        column-count: 2;
    }
}
.branding-participant-registration,
.branding-exhibitor-registration,
.branding-scholar-registration {
    .scroll-helper-gdpr svg {
        position: absolute !important;
        top: 15px !important;
        right: 15px !important;
    }
    input[type='text'].MuiInputBase-input,
    textarea.MuiInputBase-input {
        max-width: 460px;
    }
}
.title.dnd-helper {
    margin-right: auto !important;
    margin-left: 20px !important;
}
.organizer-dashboard-page .field-wrapper .custom-fields-container.dnd-support {
    padding-left: 0;
    .dragDrop-element {
        margin-right: 12px;
    }
    .single-option-container {
        background: $white;
    }
}
.dragDrop-element.disabled {
    pointer-events: none;
    cursor: default !important;
    svg path {
        fill: $grey;
    }
}
.advanced-options-container.field-accordion.dnd-support {
    .MuiAccordionSummary-root {
        padding: 0 !important;
    }
}
