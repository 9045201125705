@import '../../CSS/variables.module';
.on-demand-video {
    padding: 0 12px 12px;
    .image-preview-video {
        border: 0.5px solid #ffffff;
        filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
        border-radius: 8px;
        font-size: 0;
        overflow: hidden;
        height: 0;
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
                107.43deg,
                rgba(50, 35, 105, 0.4) 0.46%,
                rgba(84, 47, 217, 0.356) 52.7%,
                rgba(52, 18, 172, 0) 98.37%
            );
        }
        .play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
        }
    }
    .video-details-container {
        word-wrap: break-word;
        .speakers-container {
            color: $secondary;
            font-size: 14px;
            font-weight: 500;
            padding-top: 8px;
            .speaker {
                display: block !important;
                max-width: 350px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        .title {
            display: block;
            color: #2b2b2b;
            padding-top: 8px;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.25;
        }
        .date {
            color: $text-grey;
            padding-top: 8px;
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
        }
    }
}
