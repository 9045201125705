@import '../../CSS/variables.module';

.search-list-user {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 95.9%;
    color: $dark;
}
.mb-0.search-list-user {
    margin-bottom: 4px !important;
}
.add-field-dialog form {
    width: 500px;
}