.mui-edited.register-participant-step-2 .form-container .right-form form .input-wrapper {
  .MuiFormHelperText-root {
    bottom: 2px !important;
    min-height: 30px;
  }
  .tooltip {
    word-break: break-word;
  }
}

.mui-edited.register-participant-step-2 .form-container .right-form form .field-container > div.Mui-error {
  margin-bottom: 40px !important;
}

.mui-edited.register-participant-step-2 .form-container .right-form form .invitation-code-wrapper .MuiFormHelperText-root.Mui-error {
  position: absolute;
  top: 52px;
}

.mui-edited.register-participant-step-2 .form-container .right-form form .select-validator .MuiFormHelperText-root.Mui-error {
  position: absolute;
  top: 52px;
}
