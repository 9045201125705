@import 'CSS/variables.module';
.email-domain-table {
    margin-bottom: 30px;
    background-color: $white;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
    border-radius: 6px 6px 6px 6px;
    .cell {
        &.title {
            width: 27.5%;
        }
        &.show-users {
            width: 27.5%;
        }
        &.number-users {
            width: 25%;
        }
        &.email-domain-active {
            width: 15.5%;
        }
        &.email-domain-delete {
            width: 14.5%;
        }
    }
    .row {
        display: flex;
        font-size: 12px;
        line-height: 16px;
        align-items: center;
        .cell {
            font-size: 12px;
            color: $grey-dark;
            font-weight: 500;
            line-height: 16px;
            &.title {
                padding-left: 16px;
            }
            &.show-users,
            &.number-users,
            &.email-domain-active,
            &.email-domain-delete {
                text-align: center;
            }
        }
        &.tabel-header {
            background-color: $light-grey;
            border-radius: 6px 6px 0 0;
            min-height: 56px;
        }
        &.single-email-domain {
            border-top: 1px solid $disabled-text;
            min-height: 45px;
            .cell:not(.number-users) {
                padding-top: 0;
                padding-bottom: 0;
                font-size: 12px;
                color: $dark;
            }
            .number-users {
                font-size: 14px;
                line-height: 13.43px;
                color: $dark;
            }
        }
    }
    .disabled-button {
        svg {
            fill: $disabled-text;
        }
    }
}

.email-domains-list-wrapper {
    max-width: 1120px;
    .email-domains-table {
        max-width: 50%;
    }
    .email-domains-list {
        flex: 0 0 50%;
        padding-right: 24px;
        .statistics-email-domain-title {
            max-width: 35%;
        }
    }
}

.email-domain-error {
    .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error.MuiFormHelperText-filled,
    .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error.Mui-focused {
        color: #ffffff !important;
    }
}

.email-domain-table-chart {
    margin-bottom: 30px;
    background-color: $white;
    border: 1px solid $disabled-text;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
    border-radius: 6px 6px 6px 6px;
    .data-visualization-title {
        font-weight: 600;
        line-height: 17.07px;
        padding: 0 0 0 16px;
    }
    .row {
        display: flex;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        &.tabel-header {
            background-color: $light-grey;
            border-radius: 6px 6px 0 0;
            min-height: 46px;
        }
    }
}

//no email domains container
.statistics {
    .advanced-options-container-email-domains {
        max-width: 1112px;
        background-color: #ffffff;
        border-radius: 6px;
        padding: 24px;
    }
}
@media all and (max-height: 859px) {
    .statistics {
        .advanced-options-container-email-domains {
            padding: 24px;
        }
    }
}

#no-email-domains-message {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: $grey-dark;
    line-height: 18px;
    margin: 0;
}

.email-domain-title-tooltip {
    word-break: break-all;
}

// styling for add email domain dialog
.add-email-domain-dialog {
    .MuiDialog-paperWidthSm {
        min-width: 560px;
        min-height: 239px;
    }
    .MuiPaper-rounded {
        border-radius: 6px;
    }
    .MuiDialogTitle-root {
        margin: 0 !important;
        padding: 24px 24px 12px 25px !important;
    }
    .MuiTextField-root {
        margin: 0 !important;
    }
    form {
        margin: 0 !important;
        padding: 0 24px !important;
    }
    .MuiDialogContent-root {
        &:first-child {
            padding: 0 !important;
        }
    }
    .MuiInputAdornment-positionEnd {
        cursor: pointer;
    }
}

#add-email-domain-tooltip {
    .MuiTooltip-tooltip {
        word-break: normal;
        max-width: 440px;
    }
}

.email-domain-statistics-tabel-header {
    display: flex;
    padding: 6px 16px;
    min-height: 56px;
    background-color: $light-grey;
    border-radius: 6px 6px 0 0;
    justify-content: space-between;
    .cell {
        font-size: 14px !important;
        font-weight: 600;
        line-height: 17;
    }
}

.download-users-email-domains {
    .dialog-btn-uppercase {
        font-size: 14px !important;
    }
}
.legend {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    margin-top: 30px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    .single-domain {
        display: flex;
        align-items: center;
        padding: 6px 5px;
        width: 50%;
        .label {
            word-break: break-all;
            line-height: 16px;
        }
        .color {
            flex: 0 0 16px;
            width: 16px;
            height: 16px;
            margin-right: 5px;
            border-radius: 50%;
        }
    }
}
.snackbar-email-domains {
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    transform: none !important;
    @media (min-width: 600px) {
        .MuiSnackbar-anchorOriginBottomCenter {
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            transform: none !important;
        }
    }

    .MuiSnackbarContent-root {
        border-radius: 0 !important;
        width: 100%;
        background: $primary;
        min-height: 74px;
    }
}
.snackbar-email-domains.snackbar-email-domains-error {
    .MuiSnackbarContent-root {
        background: $error !important;
    }
}

.email-domain-snackbar-message {
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 13.43px;
}

.title-description-container {
    span {
        font-weight: bold;
    }
    .description-title {
        margin: 0;
        font-size: 24px;
        color: $dark;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 10px;
    }
    div {
        line-height: 27px;
        font-size: 18px;
        color: $dark;
        font-weight: 400;
        letter-spacing: 0.0005em;
    }
}

.second-part {
    flex-direction: column !important;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    min-height: calc(100vh - 270px);
    padding: 45px 45px 27px;
    .part-bottom {
        align-items: center;
        width: 100%;
    }
}

.bottom-container {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: flex-end;
    flex-direction: row;
}

.item-two {
    button {
        background: $secondary;
        margin-inline-start: 98px;
        min-width: 328px;
        min-height: 44px;
        border-radius: 8px 8px 8px 0;
        color: $white;
        font-size: 16px;
        &:hover {
            background: $secondary !important;
        }
    }
}

.item-one {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $dark;
}

.pie-chart-container {
    display: flex;
    flex-flow: column;
    flex: 0 0 50%;
    height: 100%;
    max-width: 50%;
    .email-domains-list {
        .dialog-btn-uppercase {
            font-weight: 600;
            font-size: 14px;
            line-height: 16.8px;
        }
        .statistics-email-domain-title {
            color: #211e16;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
        }
        .table-details {
            filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
            border: 1px solid $disabled-text;
            border-radius: 6px;
            .StatisticUsers {
                border-radius: 6px;
            }
        }
    }

    .email-domains-pie-chart-table {
        padding-right: 24px;
        margin-top: 20px;
        .statistics-chart-email-domains {
            flex: 0 0 50%;
            padding: 0 12px;
        }
        .tabel-header .title {
            font-size: 14px;
            width: 40%;
            font-weight: 600;
            color: #211e16;
        }
        .email-domains-chart {
            padding: 4px;
            position: relative;
            height: 100%;
            canvas {
                height: 100% !important;
                max-width: 300px !important;
                margin: 0 auto;
            }
        }
    }
}

.organizer-dashboard-page {
    #email-domains-container {
        overflow: unset;
        border-radius: 6px;
    }
}

.email-domains-list .StatisticUsers__userListHolder .scrollbar .box {
    max-height: 250px;
    ul {
        list-style-type: none;
        padding-inline-start: 8px;
    }
}

.empty-statistics-users-email-domains {
    min-height: 250px;
    display: flex;
    align-items: center;
    .empty-statistics-details {
        margin: 0 auto;
        text-align: center;
        max-width: 279px;
        .empty-statistics-info-text {
            font-size: 16px;
            font-weight: 600;
            color: $grey-dark;
            &.empty-statistics-info-text-users {
                margin-block-start: 20.9px;
                margin-block-end: 0;
            }
            &.empty-statistics-info-text-pie-chart {
                margin-block-start: 19.75px;
                margin-block-end: 0;
            }
        }
    }
}

.email-domains-list {
    .participant-avatar {
        width: 36px;
        height: 36px;
    }
    .box {
        list-style-type: none;
        .ReactVirtualized__Grid__innerScrollContainer {
            margin: 0;
            .Row {
                padding-left: 10px;
                &:first-child {
                    padding-top: 10px;
                }
            }
        }
    }
    .user-container {
        .user-details {
            border-bottom: none;
        }
    }
    .statistics-header {
        background-color: $light-grey;
        border-radius: 6px 6px 0 0;
    }
}

//delete dialog
.extra-long-word {
    word-break: break-all;
}

//mobile
@media all and (max-width: 480px) and (max-height: 926px) {
    .title-description-container {
        .description-title {
            line-height: 30px !important;
        }
    }
    .item-one {
        .steps-container {
            display: none !important;
        }
    }
    .second-part {
        padding: 45px 45px 23px;
        min-height: calc(100vh - 208px);
        .part-bottom {
            margin-inline-start: 0;
        }
    }
    .item-two {
        margin: auto;
        button {
            margin-inline-start: 0;
            min-width: 250px;
        }
    }
}

@media (max-width: 767.9px) {
}
//
//
//
//
//@media all and (max-width: 480px) and (max-height: 668px) {
//  .second-part {
//    min-height: calc(100vh - 208px);
//  }
//}
//
@media all and (max-width: 540px) and (max-height: 720px) {
    .second-part {
        min-height: calc(100vh - 208px);
        padding: 22px 22px 11px;
    }
}

@media all and (max-width: 280px) and (max-height: 653px) {
    .second-part {
        min-height: calc(100vh - 232px);
        padding: 11px;
    }
}

//@media all and (max-width: 1440px) and (max-height: 900px) {
//  .second-part {
//    min-height: calc(100vh - 208px);
//  }
//}

.svg-info {
    &:focus {
        outline: none 0;
    }
    &.focus-visible {
        outline: none 0;
    }
}
