//Media
$xs-width: 475px;
$sm-width: 768px;
$md-width: 992px;
$lg-width: 1200px;

// desk 1440
// tabl 768px
// mobile 475px

@mixin xs {
    @media (max-width: #{$xs-width}) {
        @content;
    }
}

@mixin sm {
    @media (max-width: #{$sm-width}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$sm-width}) and (max-width: #{$md-width - 1px}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$md-width}) and (max-width: #{$lg-width - 1px}) {
        @content;
    }
}
@mixin xl {
    @media (min-width: #{$lg-width}) {
        @content;
    }
}
